import { Asset, AssetPermission } from '../../../@types/asset'
import { AssetContentState, AssetContentStateStatus } from '../../../@types/asset-content'
import { ReadinessContainer } from '../ReadinessContainer'
import { Stack } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { LoadingButton } from '@mui/lab'
import { VisibleForPermission } from '../../../guards/VisibleForPermission.tsx'
import { useRetryAssetDescription } from '../../../hooks/useAssetContent.ts'

export function DescriptionReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const { mutateAsync: retryDescriptionScan, isLoading: retryInProgress } = useRetryAssetDescription()
  const handleRetry = () => retryDescriptionScan({ assetId: asset.assetId })
  const isMissing = state.status === AssetContentStateStatus.MISSING
  const buttonsDisabled = false

  return (
    <ReadinessContainer
      icon='ph:scan'
      state={state}
      title='Description'
      subtitle='If a description exists, is it scanned and ready for usage in conversations?'
    >
      <Stack direction='row' spacing={1}>

        {isMissing && (
          <VisibleForPermission permission={AssetPermission.EDIT_METADATA} level={asset.accessLevel}>
            <LoadingButton
              disabled={buttonsDisabled}
              variant='text'
              size='small'
              startIcon={<Iconify icon='ph:arrow-clockwise' />}
              loading={retryInProgress}
              loadingPosition='start'
              onClick={handleRetry}
            >
              Scan Description
            </LoadingButton>
          </VisibleForPermission>
        )}
      </Stack>

    </ReadinessContainer>
  )
}