
export type ProfileUserInfo = {
  firstName: string,
  lastName: string,
  email: string,
}

export type UpdateProfileError = {
  errorCodes: EmailCheckErrorCode[]
}

export enum EmailCheckErrorCode {
  DUPLICATE_EMAIL = "DUPLICATE_EMAIL",
  INVALID_DOMAIN = "INVALID_DOMAIN",
  SERVER_ERROR = "SERVER_ERROR"
}

export const EMAIL_CHECK_MESSAGES = {
  [EmailCheckErrorCode.DUPLICATE_EMAIL] : "Email is already used by another user",
  [EmailCheckErrorCode.INVALID_DOMAIN] : "Email must belong to company domain(s)",
  [EmailCheckErrorCode.SERVER_ERROR] : "Unable to verify email due to unexpected error"
}

export type UpdatePasswordRequest = {
  currentPassword: string,
  newPassword: string,
  newPasswordConfirmation: string
}

export type UpdatePasswordResponse = {
  result: UpdatePasswordResultType
}

export enum UpdatePasswordResultType {
  SUCCESS = "SUCCESS",
  INVALID_USER = "INVALID_USER",
  WRONG_CURRENT_PASSWORD = "WRONG_CURRENT_PASSWORD",
  MISMATCHED_NEW_PASSWORD = "MISMATCHED_NEW_PASSWORD",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  UNKNOWN_ERROR = "UNKNOWN_ERROR" // this is client side only
}

export const PASSWORD_RESULT_MESSAGES = {
 [UpdatePasswordResultType.SUCCESS] : "Your password was updated!",
 [UpdatePasswordResultType.INVALID_USER] : "You tried to update the password for an invalid user. Please try again later.",
 [UpdatePasswordResultType.WRONG_CURRENT_PASSWORD] : "Your current password is invalid. Please update it and try again.",
 [UpdatePasswordResultType.MISMATCHED_NEW_PASSWORD] : "Your new password and confirmation did not match. Please update it and try again.",
 [UpdatePasswordResultType.INVALID_PASSWORD] : "Your new password doesn't meet requirements. Please update it and try again.",
 [UpdatePasswordResultType.UNKNOWN_ERROR] : "Something went wrong. Please try again later.",
}