import { AssetExternalShare } from '../../../../@types/asset-external-share.ts'
import { Stack } from '@mui/material'
import EmptyContent from '../../../EmptyContent.tsx'
import { ExternalShareItem } from './ExternalShareItem.tsx'

type Props = {
  shares: AssetExternalShare[],
  searchTerm: string
}
export function ExternalSharesList({shares, searchTerm}: Props){
  const isSearching = searchTerm.length != 0
  if(isSearching){
    return <></>
  }
  return (
    <Stack>
      {shares.map(it => {
        return <ExternalShareItem key={it.externalShareId} share={it} />
      })}
      {
        shares.length == 0 && <EmptyRooms />
      }
    </Stack>
  )
}

function EmptyRooms() {
  return (
    <EmptyContent
      title='Not currently shared with external prospects'
      description='Use the button above to share'
      img='/assets/illustrations/illustration_no_data.svg'
      imageHeight={64}
    />
  )
}
