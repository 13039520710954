import { ChangeEvent, useState } from 'react'
import { Box, Button, Dialog, FormControl, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import Iconify from '../../Iconify'
import { LoadingButton } from '@mui/lab'
import { Category } from '../../../@types/category'
import { useRenameCategoryMutation } from '../../../hooks/useCategories'

export default function CategoryRenameButton({ category }: { category: Category }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>(category.name);
  const { mutateAsync: renameCategory } = useRenameCategoryMutation()

  const onConfirmClick = async () => {
    setLoading(true);
    await renameCategory({categoryId: category.categoryId, name})
    setLoading(false);
    setOpen(false);
  };

  const onNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setName(ev.target.value || "")
  }

  return (
    <>
      <Tooltip title="Rename">
        <IconButton
          size="small"
          onClick={() => setOpen(true)}
        >
          <Iconify icon="eva:edit-outline" />
        </IconButton>
      </Tooltip>


      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth={true}
        onClose={() => setOpen(false)}
      >

        <Box p={7} sx={{ textAlign: "center" }}>
          <Box sx={{ position: "absolute", right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Box>

          <Iconify sx={{ mb: 2, fontSize: 48 }} icon="eva:alert-circle-outline" color="text.mint" />

          <Typography variant="h2" mb={4}>
            What would you like to rename your category?
          </Typography>

          <FormControl fullWidth sx={{ mb: 4 }} size="small">
            <TextField
              id="select"
              value={name}
              label="Name"
              onChange={onNameChange}
            />
          </FormControl>

          <Stack spacing={2}>
            <LoadingButton
              loadingPosition="start"
              loading={loading}
              fullWidth={true}
              variant="contained"
              color="primary"
              size="large"
              onClick={onConfirmClick}
              disabled={!name || name === category.name}
            >
              Update category name
            </LoadingButton>
            <Button
              fullWidth={true}
              variant="outlined"
              size="large"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  );
}