import { Asset } from '../../../../@types/asset.ts'
import { AssetContentState } from '../../../../@types/asset-content.ts'
import { useRetryRawFileExtraction } from '../../../../hooks/useAssetContent.ts'
import { Box, Button, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

export function RawFileInternalStorageMissing({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const { mutateAsync: retryExtraction, isLoading } = useRetryRawFileExtraction()
  const handleRetryDownloadClick = () => retryExtraction({ assetId: asset.assetId })
  return (
    <Stack spacing={2}>
      <Typography variant='small'>Despite having an upload, it looks like we haven't extracted the raw file yet. Under normal circumstances, this shouldn't happen.</Typography>
      <Typography variant="small">And yet, here we are... To resolve the issue, you can attempt to extract the asset file by clicking below.</Typography>
      <Box>
        <LoadingButton
          variant="outlined"
          size="small"
          loading={isLoading}
          loadingPosition="start"
          onClick={handleRetryDownloadClick}
        >
          Re-Extract the File
        </LoadingButton>
      </Box>
      <Typography variant="small">If that does not resolve your issue. Please let us know by reaching out to support@getmasset.com.</Typography>
    </Stack>
  )
}