import { Invitation } from "../@types/invitation";
import api from "@api";

export const getInvitations = async() => {
  const response = await api.get<Invitation[]>("/api/v1/invitations")
  return response.data
}

export const resendInvitationToUser = async(userId: string) => {
  const response = await api.post<Invitation>(`/api/v1/invitations/resend`, { userId })
  return response.data
}