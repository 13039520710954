import { Grid } from '@mui/material'
import TotalViews from '../../asset-detail/tabs/analytics/view/TotalViews'
import ViewRank from '../../asset-detail/tabs/analytics/view/ViewRank'
import BucketedViews from '../../asset-detail/tabs/analytics/view/BucketedViews'
import TopViewers from '../../asset-detail/tabs/analytics/view/TopViewers'
import { TimeRange } from '../../../@types/analytics/analytics-common'
import { Asset } from '../../../@types/asset'

export default function ViewMetricsTableRowLayout({ asset, range }: { asset: Asset, range: TimeRange }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TotalViews asset={asset} range={range} />
      </Grid>
      <Grid item xs={6}>
        <ViewRank asset={asset} range={range} />
      </Grid>
      <Grid item xs={6}>
        <BucketedViews asset={asset} range={range} />
      </Grid>
      <Grid item xs={6}>
        <TopViewers asset={asset} range={range} />
      </Grid>
    </Grid>
  )
}
