import { TimeRange } from './analytics-common'

export type TimelineOptions = {
  range?: TimeRange
}

export type TimelineResponse = {
  events: TimelineEvent[]
}

export type TimelineEvent = {
  type: TimelineEventType,
  createdAt: Date
}

export interface BootstrapEvent extends TimelineEvent {
  data: BootstrapEventData
}

export interface DownloadEvent extends TimelineEvent {
  data: DownloadEventData
}

export interface ShareEvent extends TimelineEvent {
  data: ShareEventData
}

export interface ViewEvent extends TimelineEvent {
  data: ViewEventData
}

export interface BootstrapEventData {}
export interface DownloadEventData {
  assetId: string,
  assetName: string
}
export interface ShareEventData {
  assetId: string,
  assetName: string
}
export interface ViewEventData {
  assetId: string,
  assetName: string
}

export enum TimelineEventType {
  BOOTSTRAP = 10,
  DOWNLOAD = 100,
  SHARE = 110,
  VIEW = 120,
  UNKNOWN = 400
}