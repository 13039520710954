import { useContext, useMemo } from 'react'
import { AdminAnalyticsContext, AdminAnalyticsFilters, LocalCustomFilter } from '../../contexts/AdminAnalyticsContext'
import { useQuery } from '@tanstack/react-query'
import { createAssetAnalyticsExport, getAssetAnalytics, getAssetSummaries } from '../../clients/AssetAnalyticsClient'
import {
  AdminAnalyticsAssetResult,
  AdminAnalyticsSearchResponse,
  AnalyticsFilterType,
  AnalyticType,
  CategoryValueFilterMode,
  GraphSummary,
} from '../../@types/analytics/analytics-asset'
import { downloadFile } from '../../clients/DownloadClient'

export const PLACEHOLDER_ASSET_ANALYTICS = {
  results: [],
  totalResultCount: 0,
  totalDownloads: 0,
  totalFavorites: 0,
  totalShares: 0,
  totalViews: 0,
} as AdminAnalyticsSearchResponse

export const PLACEHOLDER_ASSET_SUMMARIES = { summaries: {} as { [key: string]: GraphSummary } }

/**
 * Provides access to asset analytics
 */
export function useAssetAnalytics() {
  const context = useContext(AdminAnalyticsContext)
  if (!context) {
    throw Error('useAssetAnalytics must be used inside of AdminAnalyticsContext')
  }
  const { filters } = context
  const options = toOptions(filters)
  return useQuery({
    queryKey: ['asset-analytics', options],
    queryFn: async () => getAssetAnalytics(options),
    refetchOnWindowFocus: false,
    staleTime: 30000,
  })
}

/**
 * provides access to asset analytic summaries
 */
export function useAssetAnalyticSummaries() {
  const { data } = useAssetAnalytics()
  const { summaryGraphType, filters } = useContext(AdminAnalyticsContext)
  const options = toSummaryOptions(data?.results, summaryGraphType, filters)
  return useQuery({
    queryKey: ['asset-analytics-summaries', options],
    queryFn: async () => getAssetSummaries(options),
    enabled: !!data?.results,
    refetchOnWindowFocus: false,
    staleTime: 30000,
  })
}

/**
 * provides easy access to analytics exports
 */
export function useAssetAnalyticsExport() {
  const context = useContext(AdminAnalyticsContext)
  if (!context) {
    throw Error('useAssetAnalytics must be used inside of AdminAnalyticsContext')
  }
  const { filters } = context
  return useMemo(() =>
      ({ exportAnalytics: () => exportAnalytics(filters) })
    , [filters])
}


/// end hooks

const exportAnalytics = async (filters: AdminAnalyticsFilters) => {
  const { fileDownloadId } = await createAssetAnalyticsExport(toOptions(filters))
  await downloadFile(fileDownloadId)
}

function toOptions(filters: AdminAnalyticsFilters) {
  const filterArr = [
    { filterType: 'BOUNDING_DATE', start: filters.boundingStartDate, end: filters.boundingEndDate },
    ...convertCustomFiltersToServerDto(filters.customFilters),
  ] as any[]

  return {
    sortBy: filters.sortBy,
    sortDirection: filters.sortDirection,
    filters: filterArr,
    offset: filters.currentPage * filters.pageSize,
    limit: filters.pageSize,
  }
}

function convertCustomFiltersToServerDto(customFilters: LocalCustomFilter[]) {
  return customFilters
    .filter(it => !it.isDraft)
    // @ts-ignore
    .flatMap(it => {
      if (
        it.filterType === AnalyticsFilterType.HAS_CATEGORY ||
        it.filterType === AnalyticsFilterType.N_HAS_CATEGORY
      ) {
        if (!it.categoryValueFilter) return []
        const filter = it.categoryValueFilter
        // @ts-ignore
        return toCategoryValueFilters(filter.categoryValueIds, filter.mode, filter.filterType)
      }

      if(it.occurrenceFilter){
        return [it.occurrenceFilter]
      }

      if(it.sharingFilter){
        return [it.sharingFilter]
      }

      return []
    })
}

function toCategoryValueFilters(categoryValueIds: string[], mode: CategoryValueFilterMode, filterType: AnalyticsFilterType) {
  const filteredIds = categoryValueIds.filter(it => !!it)
  if (!filteredIds.length) return []

  if (mode === CategoryValueFilterMode.ONE_OF) {
    return [{ filterType, categoryValueIds: filteredIds }]
  }

  if (mode === CategoryValueFilterMode.ALL_OF) {
    return filteredIds.map(it => ({ filterType, categoryValueIds: [it] }))
  }

  return []

}

function toSummaryOptions(
  assets: AdminAnalyticsAssetResult[] | undefined,
  summaryGraphType: AnalyticType,
  filters: AdminAnalyticsFilters,
) {
  return {
    assetIds: (assets || []).map(it => it.assetId),
    analyticType: summaryGraphType,
    periodStart: filters.boundingStartDate,
    periodEnd: filters.boundingEndDate,
  }
}
