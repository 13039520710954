import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { ContentRoom } from '../../../@types/content-room'
import { useForm } from 'react-hook-form'
import { useUpdateContentRoom } from '../../../hooks/useContentRooms'
import { Link, useNavigate } from 'react-router-dom'
import ActionBar from '../../action/ActionBar'
import { Box, Button, Drawer, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { FormProvider, RHFTextField } from '../../hook-form'
import { RHFTagsField } from '../../hook-form/RHFTagsField'

export function UpdateContentRoomForm({ room }: { room: ContentRoom }) {
  // hooks
  const navigate = useNavigate()
  const { mutateAsync: updateContentRoom } = useUpdateContentRoom()
  const methods = useForm<InternalFormProps>({
    defaultValues: getDefaults(room),
    resolver,
    mode: 'onChange',
  })
  const { handleSubmit, formState: { isValid, dirtyFields } } = methods

  // handlers
  const handleSave = (data: InternalFormProps) => {
    console.log(dirtyFields.password)
    const params = {
      contentRoomId: room.contentRoomId,
      passwordUpdated: dirtyFields.password || false,
      ...data
    }
    updateContentRoom(params)
      .then(() => navigate('/content-rooms'))
  }

  return (
    <Box>
      <FormProvider methods={methods}>
        <Stack direction='column' spacing={3} sx={{ maxWidth: 420 }}>
          <RHFTextField
            name='name'
            label='Name'
          />
          <RHFTextField
            name='password'
            type='password'
            label='Password'
            placeholder='Enter a password to secure this Content Room'
          />
          <RHFTextField name='expiresAt' type='datetime-local' />
        </Stack>

        <Stack direction='column' spacing={6} sx={{ maxWidth: 640 }} mt={6}>
          <Box>
            <Typography component='div' variant='smallHighlight' mb={2}>Include content with tags</Typography>
            <RHFTagsField name='includedCategoryValueIds' />
          </Box>

          <Box>
            <Typography component='div' variant='smallHighlight' mb={2}>Exclude content with tags</Typography>
            <RHFTagsField name='excludedCategoryValueIds' />
          </Box>
        </Stack>
      </FormProvider>

      <Drawer
        anchor='bottom'
        variant='permanent'
      >
        <ActionBar
          spacing={2}
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          <Button
            component={Link}
            to='/content-rooms'
            size='large'
            variant='outlined'
            color='primary'
          >
            Cancel
          </Button>
          <LoadingButton
            size='large'
            variant='contained'
            color='primary'
            onClick={handleSubmit(handleSave)}
            disabled={!isValid}
          >
            Save
          </LoadingButton>
        </ActionBar>
      </Drawer>
    </Box>
  )
}

const FormSchema = Yup.object().shape({
  name: Yup.string(),
  expires: Yup.date(),
})
const resolver = yupResolver(FormSchema)

const getDefaults = (room: ContentRoom | null) => ({
  name: room?.name || '',
  password: '',
  expiresAt: room?.expiresAt,
  includedCategoryValueIds: room?.includedCategoryValueIds || [],
  excludedCategoryValueIds: room?.excludedCategoryValueIds || [],
})

type InternalFormProps = {
  name: string,
  password: string | null
  expiresAt: Date | null,
  includedCategoryValueIds: string[],
  excludedCategoryValueIds: string[],
}