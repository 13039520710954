import { Box, Link, Typography } from '@mui/material'
import { ContentConversation } from '../../@types/conversation.ts'
import { alpha, useTheme } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'
import { useHover } from 'usehooks-ts'
import { useRef, useState } from 'react'
import { ConversationActionsButton } from './ConversationActionsButton.tsx'

export function ConversationMenuItem({ conversation }: { conversation: ContentConversation }) {
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)
  const ref = useRef<HTMLAnchorElement>(null)
  const isHovered = useHover(ref)

  const handleMenuOpen = () => setOpen(true)
  const handleMenuClose = () => setOpen(false)

  const isActive = isHovered || open
  return (
    <Link
      component={RouterLink}
      to={`/conversations/${conversation.contentConversationId}`}
      style={{ textDecoration: 'none' }}
      ref={ref}
    >
      <Box
        pl={1}
        pr={isActive ? 5 : 2}
        py={1}
        ml={-1}
        sx={{
          maxWidth: '100%',
          overflow: 'hidden',
          cursor: 'pointer',
          position: "relative",
          borderRadius: .5,
          background: isActive ? alpha(theme.palette.background.neutral, .075) : "inherit"
        }}
      >
        <Typography
          component='div'
          variant='smallHighlight'
          noWrap
          overflow='hidden'
          textOverflow='ellipsis'
          color='text.deemphasized'
        >{conversation.name}</Typography>

        {
          isActive && (
            <Box sx={{
              position: "absolute",
              right: 0,
              top: 2,
              zIndex: 999,
              pl: 1,
            }}>
              <ConversationActionsButton
                conversation={conversation}
                size='small'
                id={"conv-list-" + conversation.contentConversationId}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
              />
            </Box>
          )
        }
      </Box>
    </Link>
  )
}