import { useRef, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material'
import Iconify from '../../Iconify'
import Typography from '@mui/material/Typography'
import { useAssetDocumentSummary, useUpdateDocumentSummary } from '../../../hooks/useAssetContent'
import { Asset, AssetPermission } from '../../../@types/asset'
import { useTheme } from '@mui/material/styles'
import { fDateTime } from '../../../utils/formatTime.ts'
import { LoadingButton } from '@mui/lab'
import { VisibleForPermission } from '../../../guards/VisibleForPermission.tsx'

interface ViewTranscriptPopoverProps {
  asset: Asset
}

export function ViewDocumentSummaryPopover({ asset }: ViewTranscriptPopoverProps) {
  // hooks
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>()
  const [open, setOpen] = useState(false)
  const { data: summary } = useAssetDocumentSummary(asset.assetId, open)
  const { mutateAsync: updateSummary, isLoading: saving } = useUpdateDocumentSummary()
  const [mode, setMode] = useState<'read' | 'edit'>('read')


  // handlers
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleEdit = () => setMode('edit')
  const handleEditCancel = () => setMode('read')
  const handleEditSave = () => {
    const data = {
      assetId: asset.assetId,
      assetContentId: summary!!.assetContentId,
      contents: inputRef?.current?.value || '',
    }
    updateSummary(data).then(() => setMode('read'))
  }

  return (
    <>
      <Button
        variant='text'
        size='small'
        onClick={handleOpen}
      >
        View Summary
      </Button>

      <Dialog
        open={open}
        maxWidth='md'
        fullWidth={true}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant='h2' paragraph>Summary (Auto-Generated)</Typography>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          {mode == 'read' && (
            <Stack spacing={3}>
              {summary && (<Typography variant='small' component='div' sx={{ mb: 1 }}>
                Generated on {fDateTime(summary.createdAt)}
              </Typography>)}

              <Box
                sx={{
                  mt: 2,
                  pl: 2,
                  borderLeft: `4px solid ${theme.palette.primary.darker}`,
                }}
              >
                <Typography
                  variant='standard'
                  sx={{
                    whiteSpace: 'pre-line',
                  }}
                >
                  {summary?.summary}
                </Typography>
              </Box>

              <VisibleForPermission permission={AssetPermission.EDIT_METADATA} level={asset.accessLevel}>
                <Stack
                  direction='row'
                  spacing={1}
                >
                  <Button
                    variant='outlined'
                    size='small'
                    startIcon={<Iconify icon='ph:pencil' />}
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>

                </Stack>
              </VisibleForPermission>
            </Stack>
          )}

          {mode == 'edit' && (
            <Stack spacing={3}>
              <TextField
                autoFocus={true}
                inputRef={inputRef}
                multiline={true}
                defaultValue={summary?.summary}
              />

              <Stack
                direction='row'
                spacing={1}
              >
                <LoadingButton
                  size='small'
                  onClick={handleEditSave}
                  loading={saving}
                  loadingPosition='start'
                  startIcon={<Iconify icon='ph:floppy-disk' />}
                  variant='outlined'
                >
                  Save
                </LoadingButton>
                <Button
                  variant='outlined'
                  size='small'
                  color='inherit'
                  onClick={handleEditCancel}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          )}

        </DialogContent>

      </Dialog>
    </>
  )
}