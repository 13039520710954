import {useFormContext} from "react-hook-form";
import {MenuItem, Stack} from "@mui/material";
import {RHFSelect, RHFTextField} from "../hook-form";
import {useEffect, useState} from "react";
import {Department, HARD_CODED_DEPARTMENTS} from "../../@types/company";


type RequestChangeFormProps = {}
export default function RequestChangeForm({}: RequestChangeFormProps) {
    const {setValue} = useFormContext();
    const [departments, setDepartments] = useState<Department[] | null>(null)

    useEffect(() => {
        // todo: load departments
        setDepartments(HARD_CODED_DEPARTMENTS)
    }, [])

    const handleSelectDepartment = (departmentId: string) => setValue(`department`, departmentId)

    return (
        <Stack direction="column" spacing={4}>
            {/*<RHFSelect*/}
            {/*    name="departmentId"*/}
            {/*    label="Your Department (not required)"*/}
            {/*    variant="filled"*/}
            {/*    SelectProps={{native: false}}*/}
            {/*>*/}
            {/*    {(!departments || departments?.length === 0) &&*/}
            {/*        <MenuItem>Loading</MenuItem>*/}
            {/*    }*/}
            {/*    {departments?.map((option) => (*/}
            {/*        <MenuItem*/}
            {/*            key={option.departmentId}*/}
            {/*            value={option.name}*/}
            {/*            onClick={() => handleSelectDepartment(option.departmentId)}*/}
            {/*            sx={{*/}
            {/*                mx: 1,*/}
            {/*                my: 0.5,*/}
            {/*                borderRadius: 0.75,*/}
            {/*                typography: 'body2',*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {option.name}*/}
            {/*        </MenuItem>*/}
            {/*    ))}*/}
            {/*</RHFSelect>*/}

            <RHFTextField
                name="changeDescription"
                label="Please let us know what changes you would like."
                multiline
                variant="filled"
                rows={5}
            />
        </Stack>
    )
}