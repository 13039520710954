import { Asset, AudioMetadata, VideoMetadata } from "../../@types/asset";
import { Box, Stack } from "@mui/material";
import AssetProperty from "../asset-detail/AssetProperty";
import { format, parseISO } from "date-fns";
import DefaultUpdateableFields from "./DefaultUpdateableFields";
import { fFileSize } from "../../utils/formatFileSize";
import { fMsToTime } from "../../utils/formatDuration";

type AudioMetadataProps = {
  asset: Asset
}

export default function AudioMetadataCmp({ asset }: AudioMetadataProps) {
  const metadata = asset.properties as AudioMetadata
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 3,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
        },
      }}
    >
      <AssetProperty title="Uploaded" property={format(parseISO(asset.createdAt), "MMM d, yyyy h:mm aa")}/>
      <AssetProperty title="Uploaded by" property={`${asset.createdBy.firstName} ${asset.createdBy.lastName}`}/>
      <AssetProperty title="Downloads" property={asset.downloadCount}/>
      <AssetProperty title="File Size" property={fFileSize(asset.fileSizeBytes)}/>
      {metadata.durationMs && <AssetProperty title="Duration" property={`${fMsToTime(metadata.durationMs)}`}/>}
      {metadata.channelType && <AssetProperty title="Channel Type" property={metadata.channelType}/>}
      {metadata.sampleRate && <AssetProperty title="Sample Rate" property={metadata.sampleRate}/>}
      {metadata.artist && <AssetProperty title="Artist" property={metadata.artist}/>}
      {metadata.album && <AssetProperty title="Album" property={metadata.album}/>}
      {metadata.genre && <AssetProperty title="Genre" property={metadata.genre}/>}
      {metadata.title && <AssetProperty title="Title" property={metadata.title}/>}
    </Box>
  )
}
