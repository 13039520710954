import { useContext } from 'react';
import Pagination from '../../pagination/Pagination'
import { UserAnalyticsContext } from '../../../contexts/UserAnalyticsContext'
import { useUserAnalytics } from '../../../hooks/analytics/useUserAnalytics'

export default function UserAnalyticsPagination() {
  const { filters: { currentPage, pageSize }, setPage } = useContext(UserAnalyticsContext)
  const { data } = useUserAnalytics()
  const totalResultCount = data?.totalResultCount || 0
  const handlePageChange = (pageNum: number) => setPage(pageNum)
  return (
    <Pagination
      currentPage={currentPage}
      pageSize={pageSize}
      totalResultCount={totalResultCount}
      onPageChange={handlePageChange}
      scrollOnPageChange={true}
    />
  )
}