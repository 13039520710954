import Iconify from '../Iconify'
import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useContext, useState } from 'react'
import { Asset, AssetPermission } from '../../@types/asset'
import { useNavigate } from 'react-router-dom'
import { AssetContext } from '../../contexts/AssetContext'
import { VisibleForPermission } from '../../guards/VisibleForPermission'

type AssetDeleteButtonProps = {
  asset: Asset
}

export function AssetDeleteButton({ asset }: AssetDeleteButtonProps) {
  const { deleteAsset } = useContext(AssetContext)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const onDeleteAssetClick = async () => {
    setLoading(true)
    await deleteAsset(asset.assetId)
    setLoading(false)
    setOpen(false)
    navigate(`/assets/search`, { state: { preservePrevSearch: true } })
  }

  return (
    <VisibleForPermission permission={AssetPermission.DELETE} level={asset.accessLevel}>
      <Button
        variant='outlined'
        color='red'
        fullWidth
        onClick={() => setOpen(true)}
        startIcon={<Iconify color='text.red' icon='eva:trash-2-outline' />}
        sx={{ '& .MuiButton-startIcon': { marginRight: '4px' } }}
      >Delete Asset</Button>

      <Dialog
        open={open}
        maxWidth='xs'
        fullWidth={true}
        onClose={() => setOpen(false)}
      >

        <Box p={7} sx={{ textAlign: 'center' }}>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Iconify sx={{ mb: 2, fontSize: 48 }} icon='eva:alert-circle-outline' color='text.mint' />

          <Typography variant='h2' mb={4}>
            Are you sure you want to delete this asset?
          </Typography>

          <Typography variant='standard' color='text.secondary' component='div' mb={4}>
            Deleting the asset will permanently remove this asset from Masset.
            It will no longer have be accessible from the Masset portal.
          </Typography>

          <Stack spacing={2}>
            <LoadingButton
              loading={loading}
              fullWidth={true}
              variant='contained'
              color='primary'
              size='large'
              onClick={onDeleteAssetClick}
            >
              Delete Asset
            </LoadingButton>
            <Button
              fullWidth={true}
              variant='outlined'
              size='large'
              onClick={() => {
                setOpen(false)
              }}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </VisibleForPermission>
  )
}