import { Alert } from '@mui/material'

export function LoginError({ errorCode }: { errorCode: number }) {
  const message = ERROR_MESSAGES[errorCode] || 'Unknown error. Try again later or reach out to support@getmasset.com.'
  return (
    <Alert severity='error'>{message}</Alert>
  )
}


const ERROR_MESSAGES = {
  14101: 'Social Login is disabled for the provided account or company.',
  15000: 'Something went wrong with your social login. Try again later or reach out to support@getmasset.com',
} as { [key: number]: string }