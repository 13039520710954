import Image from '../Image.tsx'
import { useTheme } from '@mui/material/styles'

export function MassetBotAvatar(){
  const theme = useTheme()
  return (
    <Image
      src="/assets/robot512x512.webp"
      sx={{
        height: 48,
        width: 48,
        marginTop: -1,
        borderRadius: "50%",
        border: `2px solid ${theme.palette.primary.main}`,
        paddingTop: .25,
        paddingX: .25
      }}
      backgroundType="contain"
      disabledEffect={true}
    />
  )
}