import { Box, Dialog, IconButton } from '@mui/material'
import Iconify from '../../../../Iconify'
import TopUsers, { UserListItem } from './TopUsers'

export type AllTopUsersDialogProps = {
  open: boolean,
  onClose?: () => void,
  users?: UserListItem[]
}

export default function AllTopUsersDialog({ open, onClose, users }: AllTopUsersDialogProps) {
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullWidth={true}
      onClose={onClose}
    >

      <Box px={3} py={7}>
        <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
          <IconButton onClick={onClose}>
            <Iconify icon='eva:close-fill' />
          </IconButton>
        </Box>

        <TopUsers
          title='Top Users'
          list={users || []}
        />

      </Box>

    </Dialog>
  )
}
