import { Asset, AssetPermission } from '../../../@types/asset'
import { AssetContentState, AssetContentStateStatus } from '../../../@types/asset-content'
import { ReadinessContainer } from '../ReadinessContainer'
import { ViewTranscriptPopover } from './ViewTranscriptPopover'
import { LoadingButton } from '@mui/lab'
import Iconify from '../../Iconify.tsx'
import { VisibleForPermission } from '../../../guards/VisibleForPermission.tsx'
import { useCreateAssetTranscription } from '../../../hooks/useAssetContent.ts'

export function TranscriptionReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const { mutateAsync: createNewTranscription } = useCreateAssetTranscription()
  const handleRetry = () => createNewTranscription({ assetId: asset.assetId })
  const isReady = state.status === AssetContentStateStatus.SUCCESS
  const isMissing = state.status === AssetContentStateStatus.MISSING
  const isFailed = state.status === AssetContentStateStatus.FAILED
  return (
    <ReadinessContainer
      icon="ph:microphone"
      state={state}
      title="Transcription"
      subtitle="Has Masset generated a transcription for this asset?"
    >
      { isReady && (<ViewTranscriptPopover asset={asset} />)}
      { (isMissing || isFailed) && (
        <VisibleForPermission permission={AssetPermission.EDIT_METADATA} level={asset.accessLevel}>
          <LoadingButton
            variant='text'
            size='small'
            startIcon={<Iconify icon='ph:arrow-clockwise' />}
            loadingPosition='start'
            onClick={handleRetry}
          >
            Rerun
          </LoadingButton>
        </VisibleForPermission>
      )}
    </ReadinessContainer>
  )
}