import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Iconify from '../Iconify'
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Category } from '../../@types/category'
import ToggleTag from '../tags/ToggleTag'
import { CategoryValueFilterMode } from '../../@types/analytics/analytics-asset'
import { useCategories } from '../../hooks/useCategories'

interface TagPickerMenuProps {
  initialSelectedIds: string[]
  initialMode: CategoryValueFilterMode,
  onApply?: (categoryValueIds: string[], filterMode: CategoryValueFilterMode) => void,
  onCancel?: () => void,
  disableModeSelection?: boolean
}

export function TagPickerMenu({ initialSelectedIds, initialMode, onApply, onCancel, disableModeSelection = false }: TagPickerMenuProps) {
  const selectedById =  useMemo(() => initialSelectedIds.reduce((memo, it) => {
    memo[it] = true
    return memo
  }, {} as { [key: string]: boolean }), [initialSelectedIds])

  // contexts
  const { data: categories = [] } = useCategories()

  // state
  const [search, setSearch] = useState<string>('')
  const [selectedIds, setSelectedIds] = useState(selectedById)
  const [mode, setMode] = useState<CategoryValueFilterMode>(initialMode)

  // effects
  useEffect(() => {
    setSearch('')
    setSelectedIds(selectedById)
    setMode(initialMode)
  }, [selectedById, initialMode])

  // handlers
  const handleClearClick = () => setSearch('')
  const handleApply = () => {
    const toApply = Object.entries(selectedIds).filter(it => it[1]).map(it => it[0]).filter(it => !!it)
    onApply && onApply(toApply, mode)
  }
  const handleTagSelected = (categoryValueId: string, selected: boolean) => {
    setSelectedIds({ ...selectedIds, [categoryValueId]: selected })
  }
  const handleModeChange = (e: ChangeEvent<HTMLInputElement>, mode: string) => setMode(mode as CategoryValueFilterMode)

  return (
    <Box p={1} width={250}>
      <TextField
        fullWidth
        size='small'
        onChange={(e) => setSearch(e.target.value)}
        placeholder='Search...'
        value={search}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Iconify icon='eva:search-outline' />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position='end'
              onClick={handleClearClick}
              sx={{
                visibility: search ? 'visible' : 'hidden',
                cursor: 'pointer',
              }}
            >
              <Iconify icon='eva:close-fill' />
            </InputAdornment>
          ),
        }}
      />


      <Divider sx={{ my: 1 }} />

      <Box maxHeight={300} sx={{overflowY: "scroll"}} >
        <CategoryList
          categories={categories}
          search={search}
          selectedIds={selectedIds}
          onSelected={handleTagSelected}
        />
      </Box>
      {
        !disableModeSelection && (
          <>
            <Divider sx={{ my: 1 }} />
            <RadioGroup
              defaultValue={mode}
              value={mode}
              onChange={handleModeChange}
              sx={{ ml: .5, mt: 1 }}
            >
              <FormControlLabel
                value='ONE_OF'
                control={<Radio size='small' sx={{ p: .5 }} />}
                label={<Typography variant='small'>Can have any tag</Typography>}
              />
              <FormControlLabel
                value='ALL_OF'
                control={<Radio size='small' sx={{ p: .5 }} />}
                label={<Typography variant='small'>Must include all tags</Typography>}
              />
            </RadioGroup>
          </>
        )
      }

      <Divider sx={{ my: 1 }} />

      <Stack direction='row' spacing={1} alignItems='center' justifyContent='end'>
        <Button color='inherit' variant='outlined' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant='contained'
          size='small'
          color='mint'
          onClick={handleApply}
        >
          Apply
        </Button>
      </Stack>
    </Box>

  )
}


interface CategoryListProps {
  categories: Category[]
  search?: string
  selectedIds?: { [key: string]: boolean }
  onSelected?: (categoryValueId: string, selected: boolean) => void
}

function CategoryList({ categories, search, selectedIds, onSelected }: CategoryListProps) {
  return (
    <div>
      {categories.map(it => {
        return (
          <CategoryValueList
            key={it.categoryId}
            category={it}
            search={search}
            selectedIds={selectedIds}
            onSelected={onSelected}
          />
        )
      })}
    </div>
  )
}

interface CategoryValueListProps {
  category: Category
  search?: string
  selectedIds?: { [key: string]: boolean }
  onSelected?: (categoryValueId: string, selected: boolean) => void
}

function CategoryValueList({ category, search, selectedIds, onSelected }: CategoryValueListProps) {
  const lowercaseSearch = search?.toLocaleLowerCase() || ''
  const matchesCategoryName = category.name.toLocaleLowerCase().includes(lowercaseSearch)
  const toRender = matchesCategoryName ? category.values :
    category.values.filter(it => it.value.toLocaleLowerCase().includes(lowercaseSearch))
  return (
    <>
      {
        toRender.map(value => {
          return (
            <Box
              key={value.categoryValueId}
              mb={1}
            >
              <ToggleTag
                displayText={`${category.name} > ${value.value}`}
                selected={selectedIds ? selectedIds[value.categoryValueId] : false}
                categoryValueId={value.categoryValueId}
                onChange={onSelected || noop}
              />
            </Box>
          )
        })
      }
    </>
  )
}

function noop() {
}
