import { CompanySharingSettingsResponse } from '../../@types/company'
import { useUpdateCompanySharingSettings } from '../../hooks/useCompanySettings'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { Box, Button, Stack, Typography } from '@mui/material'
import { GeneralAccessType, ShareLevel } from '../../@types/sharing'
import { DEFAULT_SHARING_ACCESS_TYPE, DEFAULT_SHARING_SHARE_LEVEL } from '../../utils/appConstants'
import { FormProvider } from '../hook-form'
import { GeneralAccessSelector } from '../asset-detail/sharing/internal/GeneralAccessSelector'

export function SharingSettingsForm({ sharingSettings }: { sharingSettings?: CompanySharingSettingsResponse }) {
  // hooks
  const { mutateAsync: updateSettings } = useUpdateCompanySharingSettings()
  const { enqueueSnackbar } = useSnackbar()
  const methods = useForm<FormValues>({
    values: getDefaultValues(sharingSettings),
    mode: 'onChange',
  })

  // handlers
  const handleSave = async (data: FormValues) => {
    await updateSettings({
      accessType: data.accessType,
      shareLevel: data.shareLevel,
    })
    enqueueSnackbar(
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography component='div' variant='small' noWrap>Your sharing defaults have been updated!</Typography>
      </Stack>,
    )
  }

  // calculated props
  const { handleSubmit, watch } = methods

  return (
    <FormProvider methods={methods}>
      <Stack spacing={6}>

        <GeneralAccessSelector
          accessTypeName="accessType"
          accessLevelName="shareLevel"
        />

        <Box>
          <Button
            variant='contained'
            size='small'
            onClick={handleSubmit(handleSave)}
          >
            Update Sharing Defaults
          </Button>
        </Box>
      </Stack>
    </FormProvider>
  )
}

type FormValues = {
  accessType: GeneralAccessType,
  shareLevel: ShareLevel,
}

function getDefaultValues(settings?: CompanySharingSettingsResponse) {
  return {
    accessType: settings?.defaultAccessType || DEFAULT_SHARING_ACCESS_TYPE,
    shareLevel: settings?.defaultShareLevel || DEFAULT_SHARING_SHARE_LEVEL,
  }
}