import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Alert, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
// components
import { FormProvider, RHFTextField } from '../../components/hook-form';
import RHFPasswordField from "../../components/hook-form/RHFPasswordField";
import { acceptInvite } from "../../clients/InviteTokenClient";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

type FormValuesProps = {
  newPassword: string;
  newPasswordConfirmation: string;
  afterSubmit?: string; // error message after submit
};

const FORM_VALIDATOR = yupResolver(Yup.object().shape({
  newPassword: Yup.string()
    .password()
    .min(12)
    .required("New Password is required"),
  newPasswordConfirmation:  Yup.string().test('passwords-match', 'Passwords must match', function(value){
    return this.parent.newPassword === value
  }),
}), {abortEarly: false})

const DEFAULT_VALUES = {
  newPassword: '',
  newPasswordConfirmation: '',
};


export default function AcceptInviteForm({ inviteToken } : { inviteToken: string }) {
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate()
  const isMountedRef = useIsMountedRef();

  const methods = useForm<FormValuesProps>({
    resolver: FORM_VALIDATOR,
    defaultValues: DEFAULT_VALUES,
    criteriaMode: "all",
    mode: "onChange"
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { isValid, errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await acceptInvite(inviteToken, data);
      enqueueSnackbar((<>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="smallHighlight">👍 Success!</Typography>
          <Typography component="div" variant="small" noWrap>Your account has been setup!</Typography>
        </Stack>
      </>))
      navigate("/login")
    } catch (error) {
      if (isMountedRef.current) {
        const message = "There was an error accepting your invite. Please try again later."
        setError('afterSubmit', { ...error, message: message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} mb={4}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFPasswordField
          name="newPassword"
          label="Password"
          size="small"
          variant="filled"
        />

        <RHFTextField
          name="newPasswordConfirmation"
          label="Confirm your Password"
          type="password"
          size="small"
          variant="filled"
        />

      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        disabled={!isValid}
      >
        Complete Setup
      </LoadingButton>
    </FormProvider>
  );
}
