import { Category, CategoryValue } from "../../@types/category";
import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import AddTagsToCategoryDialog from "./AddTagsToCategoryDialog";
import { useSelectedCategoryValues } from "./hooks";
import ToggleTag from "../../components/tags/ToggleTag";

type UpdateCategoryProps = {
  category: Category
}


export default function AssetCategory({ category }: UpdateCategoryProps) {
  const { selectedValuesAsSet, updateCategoryValues, selectCategoryValues } = useSelectedCategoryValues();
  const selectedNum = category.values.filter(cv => selectedValuesAsSet.has(cv.categoryValueId)).length;
  const selectedState = selectedNum === category.values.length ? "all" : selectedNum ? "some" : "none";
  const handleSelectAll = () => {
    const ids = category.values.map(it => it.categoryValueId);
    selectedState === "all" ? updateCategoryValues(ids, false) : selectCategoryValues(ids);
  };

  const onValuesAdded = (categoryValues: CategoryValue[], shouldSelect: boolean) => {
    if (shouldSelect) {
      selectCategoryValues(categoryValues.map(it => it.categoryValueId));
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Typography variant="smallHighlight"> {category.name} </Typography>
        <Box>
          <FormControlLabel
            label={<Typography variant="small" color={"text.deemphasized"}>Select all</Typography>}
            control={
              <Checkbox
                disableRipple
                size="small"
                checked={selectedState === "all"}
                indeterminate={selectedState === "some"}
                onChange={() => handleSelectAll()}
                sx={{ color: "text.deemphasized" }}
              />
            }
          />
          <AddTagsToCategoryDialog categoryId={category.categoryId} onValuesAdded={onValuesAdded} />
        </Box>
      </Stack>
      <div>
        {category.values.map(value => {
          const selected = selectedValuesAsSet.has(value.categoryValueId);
          return (
            <ToggleTag
              key={value.categoryValueId}
              selected={selected}
              categoryValueId={value.categoryValueId}
              displayText={value.value}
              sx={{ mr: 2, mb: 2 }}
              onChange={() => {
                updateCategoryValues([value.categoryValueId], !selected);
              }}
            />
          );
        })}
      </div>
    </>
  );
}