import { TimeRange } from '../../@types/analytics/analytics-common'
import { useQuery } from '@tanstack/react-query'
import {
  getBucketedDownloads,
  getDownloadRank,
  getTopDownloaders,
  getTotalDownloads,
} from '../../clients/AssetAnalyticDownloadClient'
import { getBucketedShares, getShareRank, getTopSharers, getTotalShares } from '../../clients/AssetAnalyticShareClient'

export function useAssetTotalShares(assetId: string, options: { range: TimeRange }) {
  return useQuery({
    queryKey: ['total-shares', assetId, options],
    queryFn: async () => getTotalShares(assetId, options),
    refetchOnWindowFocus: false,
  })
}

export function useShareRank(assetId: string, options: { range: TimeRange }) {
  return useQuery({
    queryKey: ["share-rank", assetId, options],
    queryFn: async () => getShareRank(assetId, options),
    refetchOnWindowFocus: false,
  });
}

export function useBucketeShares(assetId: string, options: { range: TimeRange }){
  return useQuery({
    queryKey: ['bucketed-shares', assetId, options],
    queryFn: async () => getBucketedShares(assetId, options),
    refetchOnWindowFocus: false,
  })
}

export function useTopSharers(assetId: string, limit: number, options: { range: TimeRange }, enabled: boolean = true){
  return useQuery({
    queryKey: ['top-sharers', assetId, limit, options],
    queryFn: async () => getTopSharers(assetId, options, limit),
    refetchOnWindowFocus: false,
    enabled: enabled
  })
}