import { useEffect, useState } from "react";
import Page from "../../components/Page";
import {
  Button,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Invitation } from "../../@types/invitation";
import { fDateTime } from "../../utils/formatTime";
import { getInvitations, resendInvitationToUser } from "../../clients/InvitationClient";
import Iconify from "../../components/Iconify";

export default function AdminInvitations() {
  const [loading, setLoading] = useState<boolean>(true)
  const [invitations, setInvitations] = useState<Invitation[]>([])

  const handleResendInvitation = async (userId: string) => {
    await resendInvitationToUser(userId)
    const invitations = await getInvitations()
    setInvitations(invitations)
  }

  useEffect(() => {
    getInvitations().then(its => {
        setInvitations(its)
        setLoading(false)
      }
    )
  }, [])

  return (
    <Page title="Invitations">
      <Typography variant="h2">Pending Invitations</Typography>
      <Table sx={{ mt: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Invited Date</TableCell>
            <TableCell>Expires</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        {loading && <CircularProgress/>}
        {!loading && (<TableBody>
          {
            invitations.map(it => {
              const userName = `${it.user.firstName} ${it.user.lastName}`
              const isExpired = new Date(it.expiresAt) < new Date()
              return (
                <TableRow key={it.user.userId}>
                  <TableCell>
                    <Stack direction="column">
                      <Typography variant="standardHighlight">{it.user.email}</Typography>
                      <Typography variant="small">{userName}</Typography>
                      <InvitedByText invitation={it} />
                    </Stack>
                  </TableCell>
                  <TableCell>{fDateTime(it.invitedAt)}</TableCell>
                  <TableCell>
                    <Typography variant="small" color={isExpired ? "warning.main" : "text.primary"}>
                      {fDateTime(it.expiresAt)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="text"
                      color="mint"
                      startIcon={
                        <Iconify color="text.mint" icon='eva:email-outline'/>
                      }
                      sx={{
                        "& .MuiButton-startIcon": { marginRight: "4px" }
                      }}
                      onClick={() => handleResendInvitation(it.user.userId)}
                    >Resend</Button>
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>)}
      </Table>

    </Page>
  )
}

function InvitedByText({invitation} : {invitation: Invitation}){
  if(invitation.invitedBy == null){
    return <></>
  }

  const userName = `${invitation.invitedBy.firstName} ${invitation.invitedBy.lastName}`

  return (
    <Typography variant="tiny" mt={1}>Invited by {userName}</Typography>
  )
}