import { OauthProvider } from '../@types/oauth'
import api from '@api'
import { GetAccountResponse, GetAccountsResponse } from '../@types/account'

export async function getAccount(accountId: string) {
  const response = await api.get<GetAccountResponse>(`/api/v1/accounts/${accountId}`)
  return response.data
}

export async function getAccounts() {
  const response = await api.get<GetAccountsResponse>('/api/v1/accounts')
  return response.data
}

export async function getAccountsOfType(type: OauthProvider) {
  const response = await api.get<GetAccountsResponse>('/api/v1/accounts', { params: { provider: type } })
  return response.data
}

export async function deleteAccount(accountId: string) {
  const response = await api.delete(`/api/v1/accounts/${accountId}`)
  return response.data
}