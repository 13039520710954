import { useParams } from 'react-router'
import Page from '../components/Page'
import { Button, Grid, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Iconify from '../components/Iconify'
import LoadingScreen from '../components/LoadingScreen'
import { AssetDetailInformation } from '../components/asset-detail/AssetDetailInformation'
import { AssetDetailsPreview } from '../components/asset/preview/AssetDetailsPreview'
import { useAsset } from '../hooks/useAsset'
import { AssetForbidden } from '../components/asset/AssetForbidden'
import { AxiosError } from 'axios'
import { InternalAssetWarning } from '../components/asset-detail/InternalAssetWarning'

export function AssetDetail() {
  const { assetId } = useParams();
  const navigate = useNavigate();
  const { data: asset, isError, error } = useAsset(assetId)
  const isRequestError = error instanceof AxiosError

  if(isError && isRequestError && error?.response?.status === 403){
    return <AssetForbidden assetId={assetId}/>
  }

  if (!asset) {
    return <LoadingScreen />;
  }

  return (
    <Page title="Asset Details">
      <Button
        variant="text"
        size={"small"}
        onClick={() => navigate("/assets/search", { state: { preservePrevSearch: true } })}
        sx={{ mb: 1, "& .MuiButton-startIcon": { marginRight: "4px" } }}
        startIcon={<Iconify icon={"eva:chevron-left-fill"} />}
      >Back</Button>

      <Grid container spacing={6}>
        {
          asset?.isInternal && (
            <Grid item xs={12}>
              <InternalAssetWarning />
            </Grid>
          )
        }

        <Grid item xs={12} sm={6}>
          <Stack direction="column" spacing={2}>
            <AssetDetailsPreview asset={asset} allowFavorites={true} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AssetDetailInformation asset={asset} />
        </Grid>
      </Grid>

      {/*<Divider sx={{mt: 5, mb: 3}}/>*/}

      {/*<AssetDetailMoreLikeThis />*/}

    </Page>
  );
}