import { Asset } from '../../../../../@types/asset'
import { TimeRange } from '../../../../../@types/analytics/analytics-common'
import TotalWidget from '../common/TotalWidget'
import ViewIllustration from '../../../../../assets/illustration_view'
import { useAssetTotalViews } from '../../../../../hooks/analytics/useAssetViewAnalytics'

export default function TotalViews({ asset, range }: { asset: Asset, range: TimeRange }) {
  const { data, isLoading } = useAssetTotalViews(asset.assetId, { range })

  if(isLoading){
    return <></>
  }

  return (
    <TotalWidget
      title="Total Views"
      total={data?.totalViews || 0}
      icon={<ViewIllustration />}
    />
  )
}