import TotalWidget from '../../../asset-detail/tabs/analytics/common/TotalWidget'
import { useUserTotalChangeRequests } from '../../../../hooks/analytics/useUserAnalytics'
import ChangeRequestIllustration from '../../../../assets/illustration_change_request'

export default function UserAssetChangeRequestsTotal({ userId }: { userId: string }) {
  const { data, isLoading } = useUserTotalChangeRequests(userId)

  if (isLoading) {
    return <></>
  }

  return (
    <TotalWidget
      title='Change Requests'
      total={data?.total || 0}
      icon={<ChangeRequestIllustration />}
    />
  )
}