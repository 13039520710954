import { AdminAnalyticsContext, LocalCustomFilter } from '../../../contexts/AdminAnalyticsContext'
import { useContext, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { AnalyticsFilterType, CategoryValueFilterMode } from '../../../@types/analytics/analytics-asset'
import { Popover, Stack, Typography } from '@mui/material'
import { TagPickerMenu } from '../TagPickerMenu'
import { useCategoryValuesWithCategories } from '../../../hooks/useCategories'

export function CategoryValueCustomFilter({ filter }: { filter: LocalCustomFilter }) {
  // contexts
  const categoryValuesWithCategories = useCategoryValuesWithCategories()
  const { updateCustomTagFilter, removeCustomFilter } = useContext(AdminAnalyticsContext)

  // state
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const ref = useRef<HTMLDivElement | null>(null)

  // handlers
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleCancel = () => {
    handleClose()
    if(filter.isDraft){
      removeCustomFilter(filter.id)
    }
  }
  const handleApply = (ids: string[], mode: CategoryValueFilterMode) => {
    handleClose()
    updateCustomTagFilter(filter.id, ids, mode)
  }

  // effects
  useEffect(() => {
    if(ref.current && filter.isDraft){
      setAnchorEl(ref.current)
    }
  }, [ref.current, filter.isDraft])

  // computed properties
  if(!filter.categoryValueFilter) return <></>
  const isInclude = filter.categoryValueFilter.filterType === AnalyticsFilterType.HAS_CATEGORY
  const prefix = isInclude ? 'Includes:' : 'Excludes:'
  const names = filter.categoryValueFilter.categoryValueIds.map(it => {
    const pair = categoryValuesWithCategories[it]
    return pair ? `${pair.category.name} > ${pair.categoryValue.value}` : ''
  }).filter(it => !!it).join(', ')

  const open = Boolean(anchorEl)
  const id = open ? filter.id : undefined
  return (
    <>
      <Stack
        direction='row'
        spacing={1}
        onClick={handleOpen}
        ref={ref}
        sx={{cursor: "pointer"}}
      >
        <Typography variant='small'>{prefix}</Typography>
        <Typography variant='smallHighlight'>{names}</Typography>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: -4, horizontal: 0 }}
      >
        <TagPickerMenu
          initialSelectedIds={filter.categoryValueFilter.categoryValueIds}
          initialMode={filter.categoryValueFilter.mode}
          onCancel={handleCancel}
          onApply={handleApply}
          disableModeSelection={!isInclude}
        />
      </Popover>
    </>
  )
}
