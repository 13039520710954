import { FormProvider, RHFUploadMultiFile, RHFUploadSingleFile } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FormValuesProps } from "../../components/asset-upload/AssetFileUpload";
import { useCallback } from "react";
import { bulkUploadUsers } from "../../clients/UserClient";

export function BulkUsers(){

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(Yup.object().shape({
      files: Yup.array(),
    })),
    defaultValues: { files: [] },
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      bulkUploadUsers(acceptedFiles[0]).then((data) => {
        console.log(data)
      })

    },
    []
  );

  return (
    <FormProvider methods={methods}>
      <RHFUploadSingleFile
        name="files"
        maxFiles={1}
        onDrop={handleDrop}
      />
    </FormProvider>
  )
}