import api from "@api"
import { ProfileUserInfo, UpdatePasswordRequest, UpdatePasswordResponse } from "../@types/profile";

export const loadUserInfo = async() => {
  const response = await api.get<ProfileUserInfo>("/api/v1/profile/user-info")
  return response.data
}

export const updateUserInfo = async(updateUserInfoRequest: ProfileUserInfo) => {
  const response = await api.put<ProfileUserInfo>("/api/v1/profile/user-info", updateUserInfoRequest)
  return response.data
}

export const updatePassword = async(updatePasswordRequest: UpdatePasswordRequest) => {
  const response = await api.put<UpdatePasswordResponse>("/api/v1/profile/password", updatePasswordRequest)
  return response.data
}