import api from "@api";
import { ContentGPSResponse } from "../@types/content-gps";

export const getContentGPSForAsset = async (assetId: string) => {
  const response = await api.get<ContentGPSResponse>(`/api/v1/assets/${assetId}/content-gps`)
  return response.data
}

export const retryContentGPSCalculation = async (assetId: string) => {
  const response = await api.post<ContentGPSResponse>(`/api/v1/assets/${assetId}/content-gps`)
  return response.data
}