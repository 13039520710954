import {
  Asset,
  AssetPalette,
  AssetPalettes,
  AssetType,
  isTaskStatusRunning,
  PaletteTypeNames,
  TaskStatus,
} from '../../@types/asset'
import { Box, CircularProgress, Stack, Tooltip, Typography } from '@mui/material'
import { useAssetTasks, useRetryTasksMutation } from '../../hooks/useAsset'

type AssetPalettesCmpProps = {
  asset?: Asset | null
}

export default function AssetPalettesCmp({ asset }: AssetPalettesCmpProps) {
  const { data: assetTasks } = useAssetTasks(asset?.assetId)

  // only render for images for now
  if (!asset || asset?.assetType !== AssetType.IMAGE) {
    return <></>
  }

  // loading state based on status
  if (isTaskStatusRunning(assetTasks?.palettes?.status)) {
    return <ProcessingPaletteCmp />
  }

  if (assetTasks?.palettes?.status === TaskStatus.FAILED) {
    return <FailedPaletteCmp assetId={asset.assetId} />
  }

  if (assetTasks?.palettes?.status === TaskStatus.MISSING) {
    return <MissingPaletteCmp assetId={asset.assetId} />
  }

  if (!assetTasks) {
    return <></>
  }

  return (
    <Box>
      <SuccessfulPaletteCmp palettes={assetTasks.palettes} />
    </Box>
  )
}

function SuccessfulPaletteCmp({ palettes }: { palettes: AssetPalettes }) {
  return (
    <Stack direction='column' spacing={2}>
      <AssetPaletteCmp palette={palettes.general} />
      <AssetPaletteCmp palette={palettes.foreground} />
      <AssetPaletteCmp palette={palettes.background} />
    </Stack>
  )
}

function ProcessingPaletteCmp() {
  return (
    <Box>
      <Typography variant='h6' mb={2}>Palettes</Typography>
      <Stack direction='row' spacing={1}>
        <CircularProgress size={12} />
        <Typography variant='small' color='text.deemphasized'>Analyzing...</Typography>
      </Stack>
    </Box>
  )
}

function FailedPaletteCmp({ assetId }: { assetId: string }) {
  const { mutate: retryTasks } = useRetryTasksMutation()
  return (
    <Box>
      <Typography variant='h6'>Palettes</Typography>
      <Stack direction='column' spacing={1}>
        <Typography variant='small' color='text.deemphasized'>Hmmm... it looks like we couldn't detect your
          palette.</Typography>
        <Typography
          variant='small'
          color='text.mint'
          sx={{ cursor: 'pointer' }}
          onClick={() => retryTasks(assetId)}>
          Try again?
        </Typography>
      </Stack>
    </Box>
  )
}

function MissingPaletteCmp({ assetId }: { assetId: string }) {
  const { mutate: retryTasks } = useRetryTasksMutation()
  return (
    <Box>
      <Typography variant='h6'>Palettes</Typography>
      <Stack direction='column' spacing={1}>
        <Typography variant='small' color='text.deemphasized'>It looks like we've never detected your
          palette.</Typography>
        <Typography
          variant='small'
          color='text.mint'
          sx={{ cursor: 'pointer' }}
          onClick={() => retryTasks(assetId)}>
          Start detection?
        </Typography>
      </Stack>
    </Box>
  )
}

function AssetPaletteCmp({ palette }: { palette: AssetPalette | null }) {

  // if no palette was detected, return empty
  if (!palette) {
    return <></>
  }

  const paletteName = PaletteTypeNames[palette.paletteType] || 'General'
  const colors = palette.colors

  return (
    <Box>
      <Typography component='div' variant='small' color='text.deemphasized'>{paletteName}</Typography>
      <Stack direction='row' spacing={1} sx={{ mt: .5 }}>
        {colors.map(color => (
          <Tooltip
            key={color.hex}
            title={color.hex}
          >
            <Box
              sx={{ height: 24, width: 24, borderRadius: .5, background: color.hex }}
            />
          </Tooltip>
        ))}
      </Stack>
    </Box>
  )
}
