import { Stack } from "@mui/material";
import { RHFTextField } from "../hook-form";


export default function AssetGiveFeedbackForm() {
    return (
        <Stack direction="column" spacing={4}>
            <RHFTextField
                name="message"
                label="Please let us know your feedback."
                multiline
                variant="filled"
                rows={5}
            />
        </Stack>
    )
}