import axios from 'axios'
import { history } from '../utils/history'

const axiosInstance = axios.create();

// if we get a 401, redirect to the login page
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const response = error.response
    if (response.status === 401) {
      // this is a terrible way to do this... it needs to be cleaned up
      const isUnauthenticatedRoute =
        window.location.pathname.includes("login") ||
        window.location.pathname.includes("invite") ||
        window.location.pathname.includes("password-reset") ||
        window.location.pathname.startsWith("/cr") ||
        window.location.pathname.includes("slack/oauth-completion") ||
        window.location.pathname.includes("slack/oauth-cancellation")

      // if we get a 401, redirect to the login if we aren't there already
      if (!isUnauthenticatedRoute) {
        const pathWithParams = window.location.pathname + window.location.search
        window.localStorage.setItem("requestedLocation", pathWithParams)
        history.replace("/login")
      }
    }

    return Promise.reject(error)
  })

// configure company header
axiosInstance.interceptors.request.use(function (request) {
  const headers = request.headers
  if (headers) {
    headers['x-masset-company-id'] = localStorage.getItem("currentCompanyId") || ""
  }
  return request
})

axiosInstance.defaults.headers.common['X-Requested-With'] = "XMLHttpRequest";

export default axiosInstance;
