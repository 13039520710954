import { Account } from '../../../../@types/account.ts'
import { Asset } from '../../../../@types/asset.ts'
import { useMemo, useState } from 'react';
import { useGoogleDriveFileAccess } from '../../../../hooks/integrations/useGoogleDrive.ts'
import { useDrivePicker } from '../../../google-picker/useDrivePicker.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { retryExtractionWorkflow } from '../../../../clients/AssetContentStateClient.ts'
import { Alert, AlertTitle, Button, Stack, Typography } from '@mui/material'
import Iconify from '../../../Iconify.tsx'



export function GrantGoogleDriveAccess({ account, asset }: {
  account: Account | null,
  asset: Asset
}) {
  const ICON_SIZE = 24

  const fileId = useMemo(() => {
    const ID_EXTRACTION_PATTERN = /\/d\/(.*?)\//g
    const parts = ID_EXTRACTION_PATTERN.exec(asset.storageExternalRef) || []
    return parts[1]
  }, [asset.storageExternalRef])
  const [wrongFileWarning, setWrongFileWarning] = useState<boolean>(false)
  const { data: accessInfo } = useGoogleDriveFileAccess(account?.accountId, fileId)
  const { openPicker } = useDrivePicker()
  const queryClient = useQueryClient()

  // handlers
  const handlePickerResponse = (data: any) => {
    const result = data as google.picker.ResponseObject
    if (result[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
      const toScan = result[google.picker.Response.DOCUMENTS]
      const matchingFile = toScan.find(it => it[google.picker.Document.ID] == fileId)
      const selectedMatchingFile = Boolean(matchingFile)
      setWrongFileWarning(!selectedMatchingFile)
      if (selectedMatchingFile) {
        retryExtractionWorkflow(asset.assetId)
      }
    }
    queryClient.invalidateQueries(['google-drive-file-access'])
  }

  const handlePickerClick = () => {
    account && openPicker(account.accountId, { callback: handlePickerResponse })
  }

  // calculated props
  const hasAccount = Boolean(account)
  const hasAccess = Boolean(accessInfo?.hasAccess)

  if (hasAccess) {
    return (
      <Stack direction='row' spacing={1} alignItems='center'>
        <Iconify icon='eva:checkmark-circle-2-fill'
                 sx={{
                   height: ICON_SIZE,
                   width: ICON_SIZE,
                   color: 'success.darker',
                   background: '#FFF',
                   borderRadius: ICON_SIZE / 2,
                 }} />
        <Typography variant='small'>Access has been granted to Masset</Typography>
      </Stack>
    )
  }

  return (
    <Stack spacing={2}>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Button
          variant='outlined'
          size='small'
          color='primary'
          disabled={!hasAccount}
          onClick={handlePickerClick}
        >Grant Masset Access To Google Drive File</Button>
      </Stack>

      {wrongFileWarning && (
        <Alert severity='warning' variant='outlined'>
          <AlertTitle>No Access</AlertTitle>
          Hmmm... it looks like Masset still doesn't have access even after you selected the file. Are you sure you
          selected the right file?
        </Alert>
      )}
    </Stack>
  )
}