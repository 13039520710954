import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createContentRoom,
  deleteContentRoom,
  getContentRoom,
  getContentRooms,
  updateContentRoom,
} from '../clients/ContentRoomClient'
import { CreateContentRoomRequest, UpdateContentRoomRequest } from '../@types/content-room'

type CreateContentRoomParams = CreateContentRoomRequest

interface UpdateContentRoomParams extends UpdateContentRoomRequest {
  contentRoomId: string
}

type DeleteContentRoomParams = {
  contentRoomId: string
}

export function useContentRoom(contentRoomId: string | undefined) {
  return useQuery({
    enabled: Boolean(contentRoomId),
    queryKey: ['content-rooms', contentRoomId],
    queryFn: async () => getContentRoom(contentRoomId!!),
  })
}

export function useContentRooms() {
  return useQuery({
    queryKey: ['content-rooms'],
    queryFn: async () => getContentRooms(),
  })
}

export function useCreateContentRoom() {
  return useMutation({
    mutationFn: async (params: CreateContentRoomParams) => createContentRoom(params),
  })
}

export function useUpdateContentRoom() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: UpdateContentRoomParams) => {
      const { contentRoomId, ...dto } = params
      return updateContentRoom(contentRoomId, dto)
    },
    onSuccess: async data => {
      queryClient.invalidateQueries({ queryKey: ['content-rooms', data.contentRoomId] })
    },
  })
}

export function useDeleteContentRoom() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: DeleteContentRoomParams) => deleteContentRoom(params.contentRoomId),
    onSuccess: async data => {
      queryClient.invalidateQueries({ queryKey: ['content-rooms'] })
    },
  })
}