import { AdminAnalyticsSortBy } from '../../@types/analytics/analytics-asset'
import { useContext } from 'react';
import * as React from 'react';
import { AdminAnalyticsContext } from '../../contexts/AdminAnalyticsContext'
import { Box, Stack, TableCell, TableSortLabel, Theme, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { fShortenNumber } from '../../utils/formatNumber'
import { SxProps } from '@mui/system'

interface SortableTableCellProps {
  label: string,
  col: AdminAnalyticsSortBy,
  icon?: React.ReactElement,
  total?: number,
  curSortBy: AdminAnalyticsSortBy,
  curSortDirection: "asc" | "desc",
  setSort: (sortBy: AdminAnalyticsSortBy, direction: 'asc' | 'desc') => Promise<void>
  numeric?: Boolean,
  sx?: SxProps<Theme>
}
export function AnalyticsSortableTableCell({label, col, icon, total, curSortBy, curSortDirection, setSort, sx, numeric = true}: SortableTableCellProps){
  const isActive = curSortBy === col
  const align = numeric ? "right" : "left"

  const onSortChange = () => {
    const opposite = curSortDirection === "asc" ? "desc" : "asc"
    const newDirection = curSortBy === col ? opposite : 'desc'
    setSort(col, newDirection)
  }

  return (
    <TableCell align={align} sx={{px:1, pt:.5, pb: .75, ...sx}}>
      <TableSortLabel
        active={isActive}
        direction={isActive ? curSortDirection : 'desc'}
        onClick={() => onSortChange()}
      >
        {isActive ? (
          <Box component="span" sx={visuallyHidden}>
            {curSortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
        <Typography variant='small'>{label}</Typography>

      </TableSortLabel>
      {
        icon && total !== undefined && (<Stack direction="row" alignItems="center" spacing={1} justifyContent="end">
          {icon}
          <Typography variant="tiny" color="text.deemphasized">{fShortenNumber(total)}</Typography>
        </Stack>)
      }

    </TableCell>
  )
}