import { Alert, Typography } from '@mui/material'
import { useIsScimEnabled, useScimStatus } from '../../../hooks/useScim.ts'
import { fDateTime } from '../../../utils/formatTime.ts'

export function UserListScimAlertBanner() {
  const scimEnabled = useIsScimEnabled()
  const { data: scimStatus } = useScimStatus()
  if (!scimEnabled) return <></>
  return (
    <Alert severity='info' variant='outlined'>
      Users are externally managed and synced into Masset.

      {scimStatus && (
        <>
        <Typography variant='inherit'><b>Last successfully synced
          on:</b> {scimStatus.lastSuccessfulDate ? fDateTime(scimStatus.lastSuccessfulDate) : 'Never'}</Typography>

          <Typography variant='inherit'><b>Last attempted on:</b> {scimStatus.lastAttemptedDate ? fDateTime(scimStatus.lastAttemptedDate) : 'Never'}</Typography>
        </>
      )}
    </Alert>
  )
}