import { Box, Stack } from '@mui/material'
import { useEffect } from 'react'
import { DocumentPreviewItem } from './DocumentPreviewItem.tsx'
import { useActivePreviewContext } from './context/ActivePreviewContext.tsx'
import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room.ts'
import { AssetPreview } from '../../../../@types/asset.ts'
import { PoweredByMasset } from '../../PoweredByMasset.tsx'

export function DocumentPreviewSection({ asset, previews }: {
  asset: HostedContentRoomAsset,
  previews: AssetPreview[]
}) {
  const { rightPanePreviewRefs } = useActivePreviewContext()

  // effects
  useEffect(() => {
    rightPanePreviewRefs.current = rightPanePreviewRefs.current.slice(0, previews.length)
  }, [previews.length])

  return (
    <Box
      // this matches the header height
      mt={16}
      p={8}
      sx={{
        flexGrow: 1,
        minHeight: 'calc(100vh - 64px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack spacing={4} sx={{ flex: 1 }}>
        {previews && previews.map((it, idx) => {
          return (
            <Box
              key={it.assetPreviewId}
              ref={(el: HTMLElement) => rightPanePreviewRefs.current[idx] = { preview: it, el }}
            >
              <DocumentPreviewItem
                asset={asset}
                preview={it}
              />
            </Box>
          )
        })}
      </Stack>


      <Box mt={6} sx={{ maxWidth: 450 }}>
        <PoweredByMasset />
      </Box>
    </Box>

  )
}