import { createContext, ReactNode } from 'react'
import { ActionMap } from '../@types/reducer.ts'
import { useImmerReducer } from 'use-immer'
import { ContentAnalysisSortBy, ContentGrade } from '../@types/content-audit.ts'

export type ContentAnalysisFilterState = {
  currentPage: number,
  pageSize: number,
  sortBy: ContentAnalysisSortBy,
  sortDirection: 'asc' | 'desc',
  includedGrades: Set<ContentGrade>,
  hasFileAccess: boolean | null,
  availableInConversations: boolean | null
}

type ContentAnalysisActionCreators = {
  setSort: (sortBy: ContentAnalysisSortBy, direction: 'asc' | 'desc') => Promise<void>
  setPage: (page: number) => Promise<void>
  setIncludedGrades: (grades: ContentGrade[], reset: boolean) => Promise<void>,
  setHasFileAccess: (hasFileAccess: boolean | null, reset: boolean) => Promise<void>
  setAvailableInConversations: (availableInConversations: boolean | null, reset: boolean) => Promise<void>
}

enum ActionTypes {
  SetPage = 'SET_PAGE',
  SetSort = 'SET_SORT',
  SetIncludedGrades = 'SET_INCLUDED_GRADES',
  SetHasFileAccess = 'SET_HAS_FILE_ACCESS',
  SetAvailableInConversations = 'SET_AVAILABLE_IN_CONVERSATIONS',
}

type ContentAnalysisActionPayload = {
  [ActionTypes.SetPage]: { pageNumber: number }
  [ActionTypes.SetSort]: { sortBy: ContentAnalysisSortBy, sortDirection: 'asc' | 'desc' }
  [ActionTypes.SetIncludedGrades]: { includedGrades: ContentGrade[], reset: boolean }
  [ActionTypes.SetHasFileAccess]: { hasFileAccess: boolean | null, reset: boolean }
  [ActionTypes.SetAvailableInConversations]: { availableInConversations: boolean | null, reset: boolean }
}

type ContentAnalysisAction = ActionMap<ContentAnalysisActionPayload>[keyof ActionMap<ContentAnalysisActionPayload>]
const Reducer = (state: ContentAnalysisFilterState, action: ContentAnalysisAction) => {
  function resetFilters() {
    state.includedGrades = new Set<ContentGrade>()
    state.availableInConversations = null
    state.hasFileAccess = null
  }

  switch (action.type) {
    case 'SET_PAGE':
      state.currentPage = action.payload.pageNumber
      return
    case 'SET_SORT':
      state.sortBy = action.payload.sortBy
      state.sortDirection = action.payload.sortDirection
      state.currentPage = 0
      return
    case 'SET_INCLUDED_GRADES':
      if (action.payload.reset) resetFilters()
      state.includedGrades = new Set<ContentGrade>(action.payload.includedGrades)
      state.currentPage = 0
      break
    case 'SET_HAS_FILE_ACCESS':
      if (action.payload.reset) resetFilters()
      state.hasFileAccess = action.payload.hasFileAccess
      state.currentPage = 0
      break
    case 'SET_AVAILABLE_IN_CONVERSATIONS':
      if (action.payload.reset) resetFilters()
      state.availableInConversations = action.payload.availableInConversations
      state.currentPage = 0
      break
  }
}

type ContentAnalysisContextType = ContentAnalysisFilterState & ContentAnalysisActionCreators
const ContentAnalysisFilterContext = createContext<ContentAnalysisContextType>({} as ContentAnalysisContextType)

const DEFAULT_INITIAL_STATE = {
  currentPage: 0,
  pageSize: 20,
  sortBy: ContentAnalysisSortBy.GRADE_LEVEL,
  sortDirection: 'asc',
  includedGrades: new Set<ContentGrade>(),
  hasFileAccess: null,
  availableInConversations: null,
} as ContentAnalysisFilterState

const ContentAnalysisProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useImmerReducer(Reducer, DEFAULT_INITIAL_STATE)

  const setSort = async (sortBy: ContentAnalysisSortBy, sortDirection: 'asc' | 'desc') =>
    dispatch({ type: ActionTypes.SetSort, payload: { sortBy, sortDirection } })

  const setPage = async (pageNumber: number) =>
    dispatch({ type: ActionTypes.SetPage, payload: { pageNumber } })

  const setIncludedGrades = async (includedGrades: ContentGrade[], reset: boolean) => {
    dispatch({ type: ActionTypes.SetIncludedGrades, payload: { includedGrades, reset } })
  }

  const setHasFileAccess = async (hasFileAccess: boolean | null, reset: boolean) => {
    dispatch({ type: ActionTypes.SetHasFileAccess, payload: { hasFileAccess, reset } })
  }

  const setAvailableInConversations = async (availableInConversations: boolean | null, reset: boolean) => {
    dispatch({ type: ActionTypes.SetAvailableInConversations, payload: { availableInConversations, reset } })
  }

  return (
    <ContentAnalysisFilterContext.Provider
      value={{
        setSort,
        setPage,
        setIncludedGrades,
        setHasFileAccess,
        setAvailableInConversations,
        ...state,
      }}
    >
      {children}
    </ContentAnalysisFilterContext.Provider>
  )
}

export { ContentAnalysisFilterContext, ContentAnalysisProvider }
