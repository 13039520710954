import * as React from 'react'
import { useContext } from 'react'
import { SearchContext } from '../../contexts/SearchContext'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'

export default function SearchOnlyFavoritesToggle() {
  const { filters: { includeOnlyFavorites }, setIncludeOnlyFavorites } = useContext(SearchContext)
  const onToggleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setIncludeOnlyFavorites(checked)

  return (
    <FormControlLabel
      label={<Typography variant='smallHighlight' >Filter to Favorites</Typography>}
      control={
        <Checkbox
          disableRipple
          size='small'
          checked={includeOnlyFavorites}
          onChange={onToggleChange}
        />
      }
    />
  )
}