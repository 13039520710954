import { useCallback, useContext, useState } from "react";
import { Alert, Box, Button, CircularProgress, LinearProgress, Stack, Typography } from "@mui/material";
import { FormProvider, RHFUploadSingleFile } from "../hook-form";
import { useForm } from "react-hook-form";
import { FormValuesProps } from "./AssetFileUpload";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { bulkUploadLinkedAssets } from "../../clients/AssetClient";
import { AssetUploadContext } from "../../contexts/AssetUploadContext";
import { useNavigate } from "react-router-dom";
import CompactBlockContent from "../upload/CompactBlockContent";
import { styled } from "@mui/material/styles";
import { BulkImportResultType, LinkedAssetsImportErrorType, LinkedAssetsImportResult } from "../../@types/asset";
import { LoadingButton } from "@mui/lab";
import Iconify from "../Iconify";
import ResultSummary from "./upload/ResultSummary";
import InvalidFileError from "./upload/InvalidFileError";

const NumberedList = styled('ol')(({ theme }) => ({
  display: "table",
  tableLayout: "fixed",
  listStylePosition: "inside"
}));

const NumberedItem = styled('li')(({ theme }) => ({
  display: "table-row"
}))

const Number = styled('div')(({ theme }) => ({
  display: "table-cell",
  textAlign: "right"
}))

const Instruction = styled('div')(({ theme }) => ({
  display: "table-cell",
  paddingLeft: 8
}))


export function AssetLinkUpload({ setOpen }: { setOpen: (open: boolean) => void }) {
  const [uploading, setUploading] = useState(false)
  const [uploadResult, setUploadResult] = useState<LinkedAssetsImportResult | null>(null)

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      setUploading(true)
      bulkUploadLinkedAssets(acceptedFiles[0])
        .then((data) => {
          setUploadResult(data)
        })
        .finally(() => setUploading(false))
    },
    []
  );

  const handleReset = () => {
    setUploadResult(null)
  }

  return (
    <Box>
      {uploading && <UploadingStep/>}
      {!uploading && !uploadResult && <PreUploadStep handleDrop={handleDrop}/>}
      {!uploading && uploadResult && <PostUploadStep result={uploadResult} onReset={handleReset}/>}
    </Box>
  )
}

function PostUploadStep({ result, onReset }: { result: LinkedAssetsImportResult, onReset: () => void }) {
  const { setLinkedAssetsFromBatchResult } = useContext(AssetUploadContext)
  const navigate = useNavigate()

  const handleContinue = () => {
    setLinkedAssetsFromBatchResult(result).then(() => {
      navigate("/assets/upload/finalize")
    })
  }

  const fileProcessed = result.type === BulkImportResultType.PROCESSED || result.type === BulkImportResultType.VALID

  return (
    <Box>

      <Box sx={{mb: 4}}>
        {!fileProcessed && <InvalidFileError result={result}/>}
        {fileProcessed && <ResultSummary result={result}/>}
      </Box>

      <Stack spacing={2}>
        <Button
          fullWidth={true}
          startIcon={<Iconify icon="eva:arrow-ios-forward-fill"/>}
          variant="contained"
          color="primary"
          onClick={() => handleContinue()}
          disabled={!fileProcessed}
        >
          Continue
        </Button>
        <Button
          fullWidth={true}
          variant="text"
          sx={{ bgcolor: "white" }}
          onClick={() => {
            onReset()
          }}
        >
          Cancel
        </Button>
      </Stack>
    </Box>
  )
}



function UploadingStep() {
  return (
    <Stack direction="column" spacing={2} sx={{ px: 4, mt: 4 }}>
      <Box> <LinearProgress variant="indeterminate"/> </Box>
      <Typography variant="small" color="text.deemphasized">We're processing your upload. Don't close this page, this
        might take a minute...</Typography>
    </Stack>
  )
}

function PreUploadStep({ handleDrop }: { handleDrop: (acceptedFiles: File[]) => void }) {
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(Yup.object().shape({
      files: Yup.array(),
    })),
    defaultValues: { files: [] },
  });

  const downloadTemplate = () => {
    window.open(`/api/v1/assets/linked/bulk-template`, "_blank")
  }

  return (
    <Stack direction="row" spacing={1} alignItems="top">
      <Box sx={{ flex: 1 }}>
        <Typography variant="h3" mb={1}>How to upload</Typography>
        <NumberedList>

          <NumberedItem>
            <Number>1.</Number>
            <Instruction>
              <Typography variant="small">
                Download the&nbsp;
                <Typography
                  variant="small"
                  component="span"
                  sx={{ color: 'primary.main', textDecoration: 'underline', cursor: "pointer" }}
                  onClick={() => downloadTemplate()}
                >
                  upload template
                </Typography>
              </Typography>
            </Instruction>
          </NumberedItem>

          <NumberedItem>
            <Number>2.</Number>
            <Instruction>
              <Stack direction="column" spacing={1}>
                <Typography variant="small">Add your data to the template file</Typography>
                <Typography variant="small" color="text.deemphasized">If using Excel, make sure to export or save as a
                  CSV</Typography>
              </Stack>
            </Instruction>
          </NumberedItem>

          <NumberedItem>
            <Number>3.</Number>
            <Instruction>
              <Typography variant="small">Upload the file for processing</Typography>
            </Instruction>
          </NumberedItem>

        </NumberedList>
      </Box>

      <Box sx={{ flex: 1 }}>
        <FormProvider methods={methods}>
          <RHFUploadSingleFile
            name="file"
            accept={{ 'text/csv': [] }}
            maxFiles={1}
            onDrop={handleDrop}
            customBlockContent={<CompactBlockContent/>}
          />
        </FormProvider>
      </Box>
    </Stack>

  )
}