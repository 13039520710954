import { ContentRoomThemeProvider } from '../../hosted-content-room/ContentRoomThemeProvider'
import { alpha, useTheme } from '@mui/material/styles'
import { AppBar, Box, Button, Grid, InputAdornment, Skeleton, Stack, TextField, Toolbar } from '@mui/material'
import { useMemo } from 'react';
import Iconify from '../../Iconify'

type ContentRoomPreviewProps = {
  primary: string | null,
  secondary: string | null
}

export function ContentRoomPreview({primary, secondary} : ContentRoomPreviewProps){
  const theme = useTheme()
  const palette = useMemo(() => ({
    primary: primary || theme.palette.primary.main,
    secondary: secondary || theme.palette.secondary.main
  }), [primary, secondary, theme])
  return (
    <ContentRoomThemeProvider palette={palette}>
      <PreviewContent />
    </ContentRoomThemeProvider>
  )
}

export function PreviewContent(){
  const theme = useTheme()
  return (
    <Box sx={{
      border: `1px solid #ffffff33`,
      borderRadius: 1,
      height: 256,
      minWidth: 640,
      overflow: "hidden",
      background: theme.palette.background.default
    }}>
      <AppBar position="relative" elevation={0}>
        <Toolbar
          sx={{
            height: 1,
            px: '16px !important',
          }}
        >
          <Stack direction='row' spacing={2} alignItems='center' sx={{ width: '100%' }}>
            {/* company logo preview */}
            <Box
              sx={{ width: 40, height: 40, borderRadius: '20%' }}
              component='img'
              src={"/api/v1/company/settings/logo"}
            />
            {/* search preview */}
            <TextField
              size="small"
              placeholder="Search..."
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-outline"/>
                  </InputAdornment>
                )
              }}
            />

            <Box sx={{ flex: 1 }} />

            {/* chat */}
            <Button variant="outlined"> Chat </Button>

            {/* CTA */}
            <Button variant="contained"> Schedule a Demo </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      <Grid container spacing={3} p={4}>
        <Grid item xs={3}>
          <Skeleton variant="rectangular" animation={false} height={200} sx={{borderRadius: 1, background: alpha(theme.palette.primary.main, .16)}}/>
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="rectangular" animation={false} height={60} sx={{borderRadius: 1}} />
          <Skeleton variant="rectangular" animation={false} height={100} sx={{mt:3, borderRadius: 1}} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="rectangular" animation={false} height={110} sx={{borderRadius: 1}} />
          <Skeleton variant="rectangular" animation={false} height={60} sx={{mt:3, borderRadius: 1, background: alpha(theme.palette.primary.main, .16)}} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="rectangular" animation={false} height={200} sx={{borderRadius: 1}}/>
        </Grid>
      </Grid>

    </Box>
  )
}