import { Asset } from '../../../@types/asset'
import { Stack } from '@mui/material'
import { AssetAiReadiness } from '../../asset-ai/AssetAiReadiness'

type AssetDetailAIReadinessTabProps = {
  asset: Asset
}

export function AssetDetailAIReadinessTab({ asset }: AssetDetailAIReadinessTabProps) {
  return (
    <Stack spacing={3}>
      <AssetAiReadiness asset={asset} />
    </Stack>
  )
}