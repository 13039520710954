import { useContext, useMemo } from 'react'
import { UserAnalyticsContext, UserAnalyticsFilters } from '../../contexts/UserAnalyticsContext'
import { useQuery } from '@tanstack/react-query'
import { downloadFile } from '../../clients/DownloadClient'
import {
  createUserAnalyticsExport, getTimelineForUser, getTopAssetsForUser,
  getTotalAssetFlagsForUser, getTotalAssetRequestsForUser, getTotalChangeRequestsForUser,
  getUserAnalytics,
  getUserSummaries,
} from '../../clients/UserAnalyticsClient'
import { AnalyticType } from '../../@types/analytics/analytics-asset'
import {
  AnalyticsUserResult,
  UserAnalyticsSearchResponse,
  UserGraphSummary,
} from '../../@types/analytics/analytics-user'

export const PLACEHOLDER_USER_ANALYTICS = {
  results: [],
  totalResultCount: 0,
  totalDownloads: 0,
  totalFavorites: 0,
  totalShares: 0,
  totalViews: 0,
} as UserAnalyticsSearchResponse

export const PLACEHOLDER_USER_SUMMARIES = { summaries: {} as { [key: string]: UserGraphSummary } }

export function useUserAnalytics() {
  const context = useContext(UserAnalyticsContext)
  if (!context) {
    throw Error('useUserAnalytics must be used inside of UserAnalyticsContext')
  }
  const { filters } = context
  const options = toOptions(filters)
  return useQuery({
    queryKey: ['user-analytics', options],
    queryFn: async () => getUserAnalytics(options),
    refetchOnWindowFocus: false,
    staleTime: 30000,
  })
}


/**
 * provides access to asset analytic summaries
 */
export function useUserAnalyticSummaries() {
  const { data } = useUserAnalytics()
  const { summaryGraphType, filters } = useContext(UserAnalyticsContext)
  const options = toSummaryOptions(data?.results, summaryGraphType, filters)
  return useQuery({
    queryKey: ['user-analytics-summaries', options],
    queryFn: async () => getUserSummaries(options),
    enabled: !!data?.results,
    refetchOnWindowFocus: false,
    staleTime: 30000,
  })
}

/**
 * provides easy access to analytics exports
 */
export function useAssetAnalyticsExport() {
  const context = useContext(UserAnalyticsContext)
  if (!context) {
    throw Error('useAssetAnalytics must be used inside of AdminAnalyticsContext')
  }
  const { filters } = context
  return useMemo(() =>
      ({ exportAnalytics: () => exportAnalytics(filters) })
    , [filters])
}

export function useUserTotalAssetRequests(userId: string) {
  const { filters: { boundingStartDate, boundingEndDate } } = useContext(UserAnalyticsContext)
  const range = { start: boundingStartDate, end: boundingEndDate }
  return useQuery({
    queryKey: ['total-user-asset-requests', userId, range],
    queryFn: async () => getTotalAssetRequestsForUser(userId, { range }),
    refetchOnWindowFocus: false,
  })
}

export function useUserTotalChangeRequests(userId: string) {
  const { filters: { boundingStartDate, boundingEndDate } } = useContext(UserAnalyticsContext)
  const range = { start: boundingStartDate, end: boundingEndDate }
  return useQuery({
    queryKey: ['total-user-asset-change-requests', userId, range],
    queryFn: async () => getTotalChangeRequestsForUser(userId, { range }),
    refetchOnWindowFocus: false,
  })
}

export function useUserTotalAssetFlags(userId: string) {
  const { filters: { boundingStartDate, boundingEndDate } } = useContext(UserAnalyticsContext)
  const range = { start: boundingStartDate, end: boundingEndDate }
  return useQuery({
    queryKey: ['total-user-flags', userId, range],
    queryFn: async () => getTotalAssetFlagsForUser(userId, { range }),
    refetchOnWindowFocus: false,
  })
}

export function useUserTopAssets(userId: string) {
  const { filters: { boundingStartDate, boundingEndDate } } = useContext(UserAnalyticsContext)
  const range = { start: boundingStartDate, end: boundingEndDate }
  return useQuery({
    queryKey: ['user-top-assets', userId, range],
    queryFn: async () => getTopAssetsForUser(userId, { range }),
    refetchOnWindowFocus: false,
  })
}

export function useUserTimeline(userId: string, limit: number = 5, enabled: boolean = true) {
  const { filters: { boundingStartDate, boundingEndDate } } = useContext(UserAnalyticsContext)
  const range = { start: boundingStartDate, end: boundingEndDate }
  const options = { range, limit }
  return useQuery({
    queryKey: ['user-timeline', userId, options],
    queryFn: async () => getTimelineForUser(userId, options),
    refetchOnWindowFocus: false,
    enabled: enabled,
  })
}


const exportAnalytics = async (filters: UserAnalyticsFilters) => {
  const { fileDownloadId } = await createUserAnalyticsExport(toOptions(filters))
  await downloadFile(fileDownloadId)
}


function toOptions(filters: UserAnalyticsFilters) {
  const filterArr = [
    { filterType: 'BOUNDING_DATE', start: filters.boundingStartDate, end: filters.boundingEndDate },
  ] as any[]

  return {
    sortBy: filters.sortBy,
    sortDirection: filters.sortDirection,
    filters: filterArr,
    offset: filters.currentPage * filters.pageSize,
    limit: filters.pageSize,
  }
}

function toSummaryOptions(
  users: AnalyticsUserResult[] | undefined,
  summaryGraphType: AnalyticType,
  filters: UserAnalyticsFilters,
) {
  return {
    userIds: (users || []).map(it => it.userId),
    analyticType: summaryGraphType,
    periodStart: filters.boundingStartDate,
    periodEnd: filters.boundingEndDate,
  }
}
