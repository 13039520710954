import { useMemo, useState } from 'react';
import * as React from 'react';
import { createStaticRanges, DateRangePicker, Range } from 'react-date-range'
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfToday,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import { Box, Button, darken, Divider, Popover, Stack, Typography } from '@mui/material'
import Iconify from '../Iconify'
import { alpha, styled, useTheme } from '@mui/material/styles'
import { StyledDateRangePicker } from './StyledDateRangePicker'

interface DateRangeDropdownProps {
  onChange?: (range: Range) => void
  defaultRange?: Range
}
export function DateRangeDropdown({onChange, defaultRange}: DateRangeDropdownProps) {
  const [renderedRange, setRenderedRange] = useState(defaultRange || INITIAL_RANGE)
  const [selectedRange, setSelectedRange] = useState(defaultRange || INITIAL_RANGE)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const theme = useTheme()
  const isLight = theme.palette.mode === 'light'
  const rangeColor = isLight ? theme.palette.text.mint : alpha(theme.palette.text.mint, .4)

  // handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleApply = () => {
    setRenderedRange(selectedRange)
    handleClose()
    onChange && onChange(selectedRange)
  }

  // calculated props
  const open = Boolean(anchorEl)
  const id = open ? 'date-range-picker' : undefined
  const renderLabel = useMemo(() => {
    if (!renderedRange.startDate) { return 'All Time' }

    const fNullableDate = (d: Date | undefined) => d ? format(d, 'MMM d \'\'yy') : null
    const fStartDate = fNullableDate(renderedRange.startDate)
    const fEndDate = fNullableDate(renderedRange.endDate)

    // search for a matching label from the static ranges
    const labelFromStaticRanges = staticRanges.find(it => {
      const toCheck = it.range()
      return fNullableDate(toCheck.startDate) == fStartDate && fNullableDate(toCheck.endDate) == fEndDate
    })

    if(labelFromStaticRanges){ return labelFromStaticRanges.label}
    return [fStartDate, fEndDate].filter(it => !!it).join(' - ')
  }, [renderedRange])


  return (
    <div>
      <Button
        color='inherit'
        disableRipple
        variant='text'
        onClick={handleClick}
        sx={{
          '& .MuiButton-endIcon': { ml: 0 },
          '& .MuiButton-startIcon': { ml: .5 },
        }}
        startIcon={
          <Iconify icon={'eva:calendar-outline'} />
        }
        endIcon={
          <Iconify
            color='text.mint'
            icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          />
        }
      >
        <Stack direction='row' spacing={1} alignItems='center'>

          <Typography sx={{ color: 'text.mint' }} variant="smallHighlight">
            {renderLabel}
          </Typography>
        </Stack>


      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: -4, horizontal: 0 }}
      >
        <Box p={1}>
          <StyledDateRangePicker
            showDateDisplay={false}
            inputRanges={[]}
            onChange={item => {
              // @ts-ignore
              setSelectedRange(item.selection)
            }}
            months={2}
            minDate={addDays(new Date(), -365 * 3)}
            maxDate={defineds.endOfToday}
            ranges={[selectedRange]}
            rangeColors={[rangeColor]}
            staticRanges={staticRanges}
            showPreview={false}
            direction="horizontal"
          />
          <Divider sx={{ my: 1 }} />
          <Stack direction='row' spacing={1} alignItems='center' justifyContent='end'>
            <Button color='inherit' variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant='contained'
              size='small'
              color='mint'
              onClick={handleApply}
            >
              Apply
            </Button>
          </Stack>
        </Box>

      </Popover>
    </div>
  )
}



const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfToday(),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOf3MonthsAgo: startOfMonth(addMonths(new Date(), -3)),
  endOf3MonthsAgo: endOfMonth(addMonths(new Date(), -3)),
}

const INITIAL_RANGE = {
  startDate: startOfDay(addDays(new Date(), -30)),
  endDate: defineds.endOfToday,
  key: 'selection',
}

// @ts-ignore
const staticRanges = createStaticRanges([
  {
    label: 'Last 7 Days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -7)),
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 30 Days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -30)),
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 90 Days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -90)),
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 180 Days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -180)),
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 365 Days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -365)),
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'All Time',
    range: () => ({
      startDate: undefined,
      endDate: defineds.endOfToday,
    }),
  },
])



