import { Box, Link, Stack, Typography } from '@mui/material'
import { m } from 'framer-motion'
import { varBounce } from '../animate'
import { useHostedContentRoomContext } from '../../contexts/HostedContentRoomContext.tsx'

export function PoweredByMasset(){
  const { room } = useHostedContentRoomContext()
  const companyName = room?.companyName || ""
  return (
    <Stack spacing={2} >
      <Stack direction="row" alignItems="center">
        <Typography >Powered by</Typography>
        <Box
          sx={{ width: 24, height: 24, borderRadius: '20%', display: "inline-block", mx: 1 }}
          component='img'
          src='/assets/icons/logos/masset_slack_logo_512x512.jpg'
        />
        <Typography variant="h3">Masset</Typography>
        {companyName && (
          <Typography>&nbsp; on behalf of {companyName}</Typography>
        )}
      </Stack>

      <Stack direction='row' spacing={2} alignItems="center">
        <m.div variants={varBounce().in}>
          <Typography variant='standard'>
            Interested in using Personalized Content Rooms to generate more revenue for your own business?
            <Typography variant='standard' component='span'>
              &nbsp; &nbsp;
              <Link href='https://www.getmasset.com' target='_blank'>Find out how</Link>
            </Typography>
          </Typography>
        </m.div>
      </Stack>
    </Stack>
  )
}