import { ConversationGrouping } from '../../@types/conversation.ts'
import { Box, Stack, Typography } from '@mui/material'
import { ConversationMenuItem } from './ConversationMenuItem.tsx'

export function ConversationGroup({ group }: { group: ConversationGrouping }) {
  return (
    <Stack spacing={1}>
      <Typography variant="standardHighlight">{group.name}</Typography>
      <Stack>
        {group.conversations.map(it => {
          return <ConversationMenuItem key={it.contentConversationId} conversation={it} />
        })}
      </Stack>
    </Stack>

  )
}