import { useCompanySharingSettings } from '../../hooks/useCompanySettings'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { SharingSettingsForm } from './SharingSettingsForm'

export function SharingSettings() {
  const { data: sharingSettings } = useCompanySharingSettings()
  return (
    <Box>
      <Typography
        variant='h2'
      >
        Sharing Defaults
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant='standard' paragraph mb={4} sx={{maxWidth: 640}}>
        Default share settings are applied on newly created on assets when they are uploaded.
        Sharing can always be changed after the upload, but this will be the default.
      </Typography>

      <Grid container spacing={12} rowSpacing={2}>
        <Grid item xs={12} sm={7}>
          <SharingSettingsForm sharingSettings={sharingSettings} />
        </Grid>
        <Grid item xs={12} sm={5} />
      </Grid>
    </Box>
  )
}