import { Select, SelectChangeEvent, Stack } from '@mui/material'
import { ShareLevel } from '../../../../@types/sharing'
import Iconify from '../../../Iconify'
import { useShareField } from './AssetShareFieldHooks'
import { InternalFormShareItem } from './AssetShareField'
import { ShareItemDisplayInfo } from './ShareItemDisplayInfo'
import { SharingHoverableItem } from './SharingHoverableItem'

export function ShareItem({ item }: { item: InternalFormShareItem }) {
  const { removeItem, changeShareLevel } = useShareField()

  // handlers
  const handleRemove = () => {
    removeItem(item)
  }
  const handleShareLevelChange = (ev: SelectChangeEvent<ShareLevel>) =>
    changeShareLevel(item, parseInt(ev.target.value + '', 10))


  return (
    <SharingHoverableItem>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <ShareItemDisplayInfo share={item.share} />
        <Stack direction='row' spacing={1} alignItems='center'>
          <Select
            size='small'
            native={true}
            value={item.shareLevel}
            onChange={handleShareLevelChange}
            inputProps={{
              sx: {
                fontSize: 12,
                lineHeight: 1,
              },
            }}
          >
            <option value={ShareLevel.OWNER}>Owner</option>
            <option value={ShareLevel.EDIT}>Editor</option>
            <option value={ShareLevel.VIEW}>Viewer</option>
          </Select>
          <Iconify
            icon='eva:close-outline'
            onClick={handleRemove}
            sx={{ cursor: 'pointer' }}
          />
        </Stack>

      </Stack>
    </SharingHoverableItem>
  )
}

