import { DEFAULT_GROUP_LIST_ITEMS_DATA, useGroupsList, useUpsertGroupMutation } from '../../../hooks/useGroups'
import { useContext, useEffect } from 'react';
import { FormProvider, RHFSelect, RHFTextField } from '../../hook-form'
import { useForm } from 'react-hook-form'
import { Box, Button, Drawer, Stack, Typography } from '@mui/material'
import ActionBar from '../../action/ActionBar'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { Group } from '../../../@types/group'
import { ShareLevel } from '../../../@types/sharing'
import { GroupMembersField, InternalFormGroupUser } from './field/GroupMembersField'
import { User } from '../../../@types/user'
import { internalGroupUserForUser } from './field/group-member-field-util'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import useAuth from '../../../hooks/useAuth'


export function EditGroupForm({ group }: { group: Group | null }) {
  // hooks
  const { user } = useAuth()
  const navigate = useNavigate()
  const { data } = useGroupsList()
  const { mutateAsync: upsertGroup } = useUpsertGroupMutation()
  const { enqueueSnackbar } = useSnackbar()
  const methods = useForm<InternalFormProps>({
    defaultValues: getDefaults(group, user),
    resolver,
    mode: "onChange"
  })
  const { handleSubmit, reset, formState: { isValid } } = methods

  // effects
  useEffect(() => reset(getDefaults(group, user)), [group, user])

  // handlers
  const handleSave = (data: InternalFormProps) => {
    const reqData = {
      groupId: group?.groupId,
      name: data.name,
      description: data.description,
      parentGroupId: data.parentGroupId,
      members: data.members?.map(it => ({ userId: it.userId, shareLevel: it.shareLevel })) || [],
    }
    upsertGroup(reqData).then(() => navigate('/admin/groups'))
  }
  const handleCancel = () => {
    enqueueSnackbar((<>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography variant='smallHighlight'>Cancelled.</Typography>
        <Typography component='div' variant='small' noWrap>You have cancelled your new user creation</Typography>
      </Stack>
    </>))
    navigate('/admin/groups')
  }

  // calculated props
  const { groups } = data || DEFAULT_GROUP_LIST_ITEMS_DATA
  const selectableGroups = groups.filter(it => it.groupId !== group?.groupId)

  return (
    <Box maxWidth={440}>
      <FormProvider methods={methods}>
        <Stack direction='column' spacing={3}>
          <RHFTextField
            name='name'
            label='Name'
          />
          <RHFTextField
            name="description"
            label="Description"
            multiline={true}
            minRows={3}
            maxRows={6}
          />
          <RHFSelect
            name='parentGroupId'
            label='Parent Group'
          >
            <option key='' value=''></option>
            {
              selectableGroups.map(it => {
                return <option key={it.groupId} value={it.groupId + ''}>{it.name}</option>
              })
            }
          </RHFSelect>
        </Stack>

        <Stack mt={4} direction='column' spacing={2}>
          <Typography variant='h6' mb={1}>Group Members</Typography>
          <GroupMembersField name='members' />
        </Stack>

      </FormProvider>

      <Drawer
        anchor='bottom'
        variant='permanent'
      >
        <ActionBar
          spacing={2}
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          <Button
            size='large'
            variant='outlined'
            color='primary'
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            size='large'
            variant='contained'
            color='primary'
            onClick={handleSubmit(handleSave)}
            disabled={!isValid}
          >
            Save
          </LoadingButton>
        </ActionBar>
      </Drawer>

    </Box>
  )
}


const FormSchema = Yup.object().shape({ name: Yup.string().required() })
const resolver = yupResolver(FormSchema)

const getDefaults = (group: Group | null, currentUser: User | null | undefined) => ({
  name: group?.name || '',
  description: group?.description || '',
  parentGroupId: group?.parentGroupId || '',
  members: group ? group.members : [internalGroupUserForUser(currentUser, ShareLevel.OWNER)],
})

type InternalFormProps = {
  name: string,
  description: string | null,
  parentGroupId: string | null,
  members: InternalFormGroupUser[]
}

