import { Asset } from '../../../../@types/asset.ts'
import { AssetContentState } from '../../../../@types/asset-content.ts'
import { ForbiddenErrorData } from '../../../../@types/asset-content-errors.ts'
import { useAccount } from '../../../../hooks/useAccounts.ts'
import { useRetryRawFileExtraction } from '../../../../hooks/useAssetContent.ts'
import { Stack, Typography } from '@mui/material'
import { fDateTime } from '../../../../utils/formatTime.ts'
import { LoadingButton } from '@mui/lab'
import { AccessResolutionByAccountType } from '../access/AccessResolutionByAccountType.tsx'

export function RawFileForbiddenError({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const data = state.errorData as ForbiddenErrorData
  const { data: account } = useAccount(data?.oauthAccountId)
  const { mutateAsync: retryExtractionWorkflow, isLoading: retryLoading } = useRetryRawFileExtraction()
  const handleRetryDownloadClick = () => retryExtractionWorkflow({ assetId: asset.assetId })
  return (
    <Stack spacing={2}>
      <Typography variant='standardHighlight' color="warning.main" paragraph>
        Access is forbidden
      </Typography>

      <Typography variant='standard'>This may happen if: </Typography>
      <Typography variant='standard' sx={{ pl: 4 }}>
        <ol>
          <li>Your connected account does not have access to the specified asset</li>
          <li>Your link is private or inaccessible from the public internet</li>
          <li>Your link is password-protected</li>
          <li>The linked location is blocking Masset traffic</li>
        </ol>
      </Typography>

      <Typography variant='standard'>We last attempted to download this
        asset <b>{fDateTime(state.updatedAt)}</b></Typography>

      <AccessResolutionByAccountType account={account} asset={asset}/>

      <Stack pt={1} direction='row' spacing={1} alignItems='center'>
        <Typography variant='standard'>Got it fixed?</Typography>
        <LoadingButton
          variant='text'
          size='small'
          onClick={handleRetryDownloadClick}
          color='primary'
          loading={retryLoading}
          loadingPosition='start'
        >
          &nbsp; Redownload
        </LoadingButton>
      </Stack>
    </Stack>
  )
}