import { useForm } from 'react-hook-form'
import { User, UserType } from '../../../@types/user'
import { Alert, Box, Button, Drawer, Stack, Typography } from '@mui/material'
import { FormProvider, RHFTextField } from '../../hook-form'
import RHFUserTypeSelector from './RHFUserTypeSelector'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { useEffect } from 'react';
import ActionBar from '../../action/ActionBar'
import { useIsScimEnabled } from '../../../hooks/useScim.ts'
import { useSaveUser, useSetUserRole } from '../../../hooks/useUsers.ts'

type InternalFormProps = {
  firstName: string
  lastName: string
  email: string
  userType: UserType
}

type EditUserFormProps = {
  user: User | undefined
}

const getDefaults = (user: User | undefined) => ({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  email: user?.email || '',
  userType: user?.userType || UserType.VIEWER,
})

// todo: validate that the current user isn't demoting themself
// todo: validate that the added email is permitted for this company
export function EditUserForm({ user }: EditUserFormProps) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { mutateAsync: saveUser, isLoading: userSaving } = useSaveUser()
  const { mutateAsync: setUserRole, isLoading: userRoleSaving } = useSetUserRole()
  const scimEnabled = useIsScimEnabled()

  const methods = useForm<InternalFormProps>({
    defaultValues: getDefaults(user),
  })
  const { handleSubmit, reset } = methods

  useEffect(() => reset(getDefaults(user)), [user])

  const saveUserFull = async (data: InternalFormProps) => {
    await saveUser({ userId: user?.userId, data: data })
    enqueueSnackbar((<>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography variant='smallHighlight'>Success!</Typography>
        <Typography component='div' variant='small' noWrap>A new user was created!</Typography>
      </Stack>
    </>))
    navigate('/admin/users')
  }

  const updateUserRole = async (data: InternalFormProps) => {
    if (user?.userId) {
      await setUserRole({ userId: user?.userId, userType: data.userType })
      enqueueSnackbar((<>
        <Stack spacing={1} direction='row' alignItems='center'>
          <Typography variant='smallHighlight'>Success!</Typography>
          <Typography component='div' variant='small' noWrap>Your user was updated!</Typography>
        </Stack>
      </>))
      navigate('/admin/users')
    }
  }

  const onSubmit = async (data: InternalFormProps) => {
    if (scimEnabled) {
      return updateUserRole(data)
    } else {
      return saveUserFull(data)
    }
  }

  const onCancel = () => {
    enqueueSnackbar((<>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography variant='smallHighlight'>Cancelled.</Typography>
        <Typography component='div' variant='small' noWrap>You have cancelled your new user creation</Typography>
      </Stack>
    </>))
    navigate('/admin/users')
  }

  const formDisabled = scimEnabled
  const isSaving = userRoleSaving || userSaving

  return (
    <>
      <Box maxWidth={440}>
        <FormProvider methods={methods}>
          <Stack direction='column' spacing={3}>
            {scimEnabled && (
              <Alert severity='info' variant='outlined'>
                User information is synced from an external system.<br /> <br />
                You <b>can update permissions</b> but not personal information.
              </Alert>
            )}

            <RHFTextField
              name='firstName'
              label='First Name'
              variant='filled'
              size='small'
              disabled={formDisabled}
            />
            <RHFTextField
              name='lastName'
              label='Last Name'
              variant='filled'
              size='small'
              disabled={formDisabled}
            />
            <RHFTextField
              name='email'
              label='Email'
              variant='filled'
              size='small'
              disabled={formDisabled}
            />
          </Stack>

          <Typography variant='h3' mt={8} mb={2}>Permissions</Typography>
          <RHFUserTypeSelector name='userType' />

        </FormProvider>
      </Box>

      <Drawer
        anchor='bottom'
        variant='permanent'
      >
        <ActionBar
          spacing={2}
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          <Button
            size='large'
            variant='outlined'
            color='primary'
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            size='large'
            variant='contained'
            color='primary'
            onClick={handleSubmit(onSubmit)}
            loading={isSaving}
          >
            Save
          </LoadingButton>
        </ActionBar>
      </Drawer>
    </>
  )
}