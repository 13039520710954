import { ReactNode } from 'react';
import { AssetContentState, AssetContentStateStatus } from '../../@types/asset-content'
import Iconify from '../Iconify'
import { Box, Paper, Stack, Typography } from '@mui/material'

type ReadinessContainerProps = {
  icon: string,
  state: AssetContentState,
  title: string,
  subtitle: string,
  children: ReactNode
}

export function ReadinessContainer({ children, icon, state, title, subtitle }: ReadinessContainerProps) {
  return (
    <Paper sx={{ p: 2 }}>
      <Stack direction='row' spacing={2} alignItems='start'>
        <StatusIndicator icon={icon} state={state} />
        <Box>
          <Typography variant='h4'>{title}</Typography>
          <Typography variant='caption' paragraph mb={0}>{subtitle}</Typography>
          {children && (
            <Box mt={2}>
              {children}
            </Box>
          )}
        </Box>
      </Stack>
    </Paper>
  )
}


function StatusIndicator({ icon, state }: { icon: string, state: AssetContentState }) {
  const overlayIcon = ICON_BY_STATE_STATUS[state.status] || 'eva:question-mark-circle-fill'
  const overlayIconColor = ICON_COLOR_BY_STATE_STATUS[state.status] || 'warning.main'
  const animation = state.status == AssetContentStateStatus.IN_PROGRESS
  return (
    <Box
      sx={{
        position: 'relative',
        lineHeight: 1,
      }}
    >
      <Iconify icon={icon} sx={{ height: 48, width: 48 }} />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: -4,
          zIndex: 1,
        }}
      >
        <Iconify
          icon={overlayIcon}
          sx={{
            height: 24,
            width: 24,
            color: overlayIconColor,
            background: '#FFF',
            borderRadius: 12,
            '@keyframes spin': {
              '100%': {
                transform: 'rotate(360deg)',
              },
            },
            animation: animation ? "spin 2s linear infinite" : ""
          }} />
      </Box>
    </Box>
  )
}


const ICON_BY_STATE_STATUS = {
  [AssetContentStateStatus.FAILED]: 'eva:alert-circle-fill',
  [AssetContentStateStatus.MISSING]: 'eva:question-mark-circle-fill',
  [AssetContentStateStatus.SUCCESS]: 'eva:checkmark-circle-2-fill',
  [AssetContentStateStatus.IN_PROGRESS]: 'ph:spinner-gap',
}

const ICON_COLOR_BY_STATE_STATUS = {
  [AssetContentStateStatus.FAILED]: 'error.dark',
  [AssetContentStateStatus.MISSING]: 'warning.dark',
  [AssetContentStateStatus.SUCCESS]: 'success.darker',
  [AssetContentStateStatus.IN_PROGRESS]: 'info.dark',
}