import { Box, Divider, Grid, Typography } from '@mui/material'
import { CompanyInfo } from './CompanyInfo'
import { CompanyLogoUpdate } from './CompanyLogoUpdate'

export function GeneralSettings() {
  return (
    <Box>
      <Typography
        variant='h2'
      >
        General Settings
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={12} rowSpacing={2}>
        <Grid item xs={12} sm={7}>
          <CompanyInfo />
        </Grid>
        <Grid item xs={12} sm={5}>
          <CompanyLogoUpdate />
        </Grid>
      </Grid>
    </Box>
  )
}