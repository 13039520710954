import { Asset, AssetPreviewType } from "../../@types/asset";
import { Box, Stack } from "@mui/material";
import Image from "../Image";
import { srcForPreviewItem } from "../asset-search/images";
import AssetProperty from "./AssetProperty";

type AssetInlinePreviewProps = {
    asset: Asset
}

export function AssetInlinePreview({ asset } : AssetInlinePreviewProps){
    const imgSrc = srcForPreviewItem(asset?.previews.assetPreviews[0]?.assetPreviewId, AssetPreviewType.THUMBNAIL_SM)

    return (
        <Stack direction="row" spacing={3} alignItems="center">
            <Box sx={{
                width: 80,
                bgcolor: "bg.warmGrey3",
                borderRadius: "8px",
            }}>
                <Image alt={asset.name} src={imgSrc} ratio="1/1" backgroundType="contain"/>
            </Box>

            {/* todo: does this need to be asset.originalFilename or something? */}
            <AssetProperty title="File Name" property={asset.name}/>

        </Stack>
    )
}