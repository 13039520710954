import { useTheme } from '@mui/material/styles'
import { Avatar, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { AVATAR_SIZE, InternalFormGroupUser } from './GroupMembersField'
import { HoverableItem } from './HoverableItem'
import { ShareLevel } from '../../../../@types/sharing'
import Iconify from '../../../Iconify'
import { useFormContext } from 'react-hook-form'

export function GroupMember({ groupMember, fieldName }: { groupMember: InternalFormGroupUser, fieldName: string }) {
  const theme = useTheme()
  const { getValues, setValue } = useFormContext()

  // handlers
  const handleRemove = () => {
    const values = getValues(fieldName) as InternalFormGroupUser[]
    const withoutUser = values.filter(it => it.userId !== groupMember.userId)
    setValue(fieldName, withoutUser, { shouldDirty: true })
  }
  const handleShareLevelChange = (ev: SelectChangeEvent<ShareLevel>) => {
    const values = getValues(fieldName) as InternalFormGroupUser[]
    const entry = values.find(it => it.userId === groupMember.userId)
    const updatedEntry = {...entry, shareLevel: parseInt(ev.target.value + "", 10)}
    const updatedValues = values.map(it => it.userId == groupMember.userId ? updatedEntry : it)
    setValue(fieldName, updatedValues, { shouldDirty: true })
  }

  const initials = groupMember.name.charAt(0)

  return (
    <HoverableItem>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Avatar
            sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, bgcolor: theme.palette.bg.mintDark }}>{initials}</Avatar>
          <Stack direction='column'>
            <Typography>{groupMember.name}</Typography>
            <Typography component='span' variant='small'>{groupMember.email}</Typography>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Select
            size='small'
            native={true}
            value={groupMember.shareLevel}
            onChange={handleShareLevelChange}
            inputProps={{
              sx: {
                fontSize: 12,
                lineHeight: 1,
              },
            }}
          >
            <option value={ShareLevel.OWNER}>Owner</option>
            <option value={ShareLevel.EDIT}>Editor</option>
            <option value={ShareLevel.VIEW}>Member</option>
          </Select>
          <Iconify
            icon='eva:close-outline'
            onClick={handleRemove}
            sx={{ cursor: 'pointer' }}
          />
        </Stack>

      </Stack>
    </HoverableItem>
  )
}


