import { useScript } from 'usehooks-ts'
import { useEffect, useState } from 'react'
import { getGooglePickerInfo } from '../../clients/GoogleDriveClient'

const GOOGLE_JS_API_URL = 'https://apis.google.com/js/api.js'
const GOOGLE_GSI_URL = 'https://accounts.google.com/gsi/client'

export function useDrivePicker() {
  const jsApiStatus = useScript(GOOGLE_JS_API_URL, { removeOnUnmount: false })
  const gsiApiStatus = useScript(GOOGLE_GSI_URL, { removeOnUnmount: false })
  const [pickerApiLoaded, setPickerApiLoaded] = useState<boolean>(false)

  const handlePickerApiLoaded = () => setPickerApiLoaded(true)

  useEffect(() => {
    if (jsApiStatus == 'ready' && gsiApiStatus == 'ready' && !pickerApiLoaded) {
      // @ts-ignore
      window.gapi.load('picker', { callback: handlePickerApiLoaded })
    }
  }, [jsApiStatus, gsiApiStatus, pickerApiLoaded])


  const openPicker = (oauthAccountId: string, options: PickerOptions) => {
    getGooglePickerInfo(oauthAccountId).then((response) => {
      const view = new google.picker.DocsView(google.picker.ViewId.DOCS)
        .setIncludeFolders(true)
        .setParent("root")
      const builder = new google.picker.PickerBuilder()
        .addView(view)
        .setOAuthToken(response.accessToken)
        .setDeveloperKey(response.developerKey)
        .setCallback(options.callback)
        .setAppId(response.appId)
        .setMaxItems(100)

      if(options.multiSelect){
        builder.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      }

      const picker = builder.build()
      picker.setVisible(true)
    })
  }

  return { openPicker }
}

type PickerOptions = {
  callback: (data: any) => any
  multiSelect?: boolean
}