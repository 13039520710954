import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createSlackUserRegistration,
  deleteSlackUserRegistration,
  getSlackUserRegistrationBySlackIds,
} from '../../clients/SlackIntegrationClient'

export function useSlackUserRegistration(teamId: string | null, userId: string | null) {
  return useQuery({
    queryKey: ['slack-user-registration-lookup', teamId, userId],
    queryFn: async () => getSlackUserRegistrationBySlackIds(teamId!!, userId!!),
    enabled: Boolean(teamId) && Boolean(userId),
  })
}

type CreateSlackUserRegistrationParams = {
  teamId: string,
  userId: string
}

export function useCreateSlackUserRegistration() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ teamId, userId }: CreateSlackUserRegistrationParams) =>
      createSlackUserRegistration(teamId, userId),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: ['slack-user-registration-lookup', variables.teamId, variables.userId]
      })
    },
  })
}

type DeleteSlackUserRegistrationParams = {
  registrationId: string
}
export function useDeleteSlackUserRegistration() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ registrationId }: DeleteSlackUserRegistrationParams) =>
      deleteSlackUserRegistration(registrationId),
    onSuccess: async () => {
      queryClient.invalidateQueries({queryKey: ['slack-user-registration-lookup']})
    },
  })
}