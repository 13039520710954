import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useHostedContentRoomBootstrap } from '../../hooks/useHostedContentRoom'
import LoadingScreen from '../../components/LoadingScreen'
import { CompanyLogo } from '../../components/hosted-content-room/CompanyLogo'
import { useParams } from 'react-router'
import { ContentRoomThemeProvider } from '../../components/hosted-content-room/ContentRoomThemeProvider'
import { useTheme } from '@mui/material/styles'
import { HostedContentRoomProvider } from '../../contexts/HostedContentRoomContext.tsx'

export function HostedContentRoomLayout() {
  const { shortCode } = useParams()
  const { data: contentRoom } = useHostedContentRoomBootstrap(shortCode)

  if (!contentRoom?.customization) {
    return <LoadingScreen />
  }

  return (
    <ContentRoomThemeProvider palette={contentRoom.customization.palette}>
      <HostedContentRoomProvider room={contentRoom}>
        <AppBar
          position='fixed'
          elevation={0}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar
            sx={{
              height: 1,
              px: '40px !important',
            }}
          >
            <Stack direction='row' spacing={2} alignItems='center' sx={{ width: '100%' }}>
              <CompanyLogo settings={contentRoom.customization.logo} />
              <Box>
                <Typography variant='standard'>File share from <b>{contentRoom.companyName}</b></Typography>
              </Box>
              {/*<HostedContentRoomSearch />*/}
              <Box sx={{ flex: 1 }} />
              {/*<ChatButton />*/}
              {/*<CallToActionButton />*/}
            </Stack>
          </Toolbar>
        </AppBar>

        <LayoutContent />
      </HostedContentRoomProvider>
    </ContentRoomThemeProvider>
  )
}

function LayoutContent() {
  const theme = useTheme()
  return (
    <Box sx={{ background: theme.palette.background.default }}>
      <Outlet />
    </Box>
  )
}

