import Page from '../../components/Page'
import { Button, Typography } from '@mui/material'
import Iconify from '../../components/Iconify'
import { Link } from 'react-router-dom'
import { CreateContentRoomForm } from '../../components/content-room/create/CreateContentRoomForm'

export function CreateContentRoom(){
  return (
    <Page title="Create New Content Room">
      <Button
        component={Link}
        to="/content-rooms"
        variant="text"
        size="small"
        startIcon={<Iconify icon={"eva:chevron-left-fill"}/>}
      >
        Back to Content Rooms
      </Button>
      <Typography variant="h2" mb={4} mt={2} > Create Content Room </Typography>
      <CreateContentRoomForm />
    </Page>
  )
}