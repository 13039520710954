import { useUserSearch } from '../../../../hooks/useUsers'
import { useMemo } from 'react';
import keyBy from 'lodash/keyBy'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { UserToAddItem } from './UserToAddItem'
import { InternalFormGroupUser } from './GroupMembersField'
import { NoMatchesItem } from './NoMatchesItem'

type NotInThisGroupListProps = {
  members: InternalFormGroupUser[],
  searchTerm: string,
  fieldName: string
}
export function NotInThisGroupList({ members, searchTerm, fieldName }: NotInThisGroupListProps) {
  const { data, isFetching } = useUserSearch(searchTerm, 100)
  const membersById = useMemo(() => keyBy(members, 'userId'), [members])
  const matchingUsers = data?.users || []
  const searchedUsersNotInGroup = matchingUsers.filter(it => !membersById[it.userId]).slice(0, 15)
  return (
    <Box>
      <Box px={2}><Typography variant='smallHighlight'>Not in this group</Typography></Box>
      {isFetching && <Box py={2} px={3.5}><CircularProgress size={16} /></Box> }
      {!isFetching && (
        <Stack>
          {searchedUsersNotInGroup.map(it => <UserToAddItem key={it.userId} user={it} fieldName={fieldName} />)}
        </Stack>
      )}
      {!isFetching && searchedUsersNotInGroup.length === 0 && <NoMatchesItem />}
    </Box>
  )
}


