import { createContext, ReactNode } from 'react'
import { ActionMap } from '../@types/reducer'
import { useImmerReducer } from 'use-immer'
import {
  AdminAnalyticsSearchResponse,
  AdminAnalyticsSortBy,
  AdminAnalyticsSummariesResponse,
  ANALYTIC_TYPE_BY_SORT,
  AnalyticsFilterType,
  AnalyticType,
  CategoryValueFilterMode,
  OccurenceFilter,
  OccurrenceOperator,
  SharingFilter,
} from '../@types/analytics/analytics-asset'
import { v4 as uuidv4 } from 'uuid'
import { addDays, endOfToday, startOfDay } from 'date-fns'
import { GeneralAccessType } from '../@types/sharing'


export type AdminAnalyticsState = {
  filters: AdminAnalyticsFilters,
  summaryGraphType: AnalyticType
}

export type AdminAnalyticsFilters = {
  currentPage: number,
  pageSize: number,
  sortBy: AdminAnalyticsSortBy,
  sortDirection: 'asc' | 'desc',

  // we include a date filter by default for easier ux
  boundingStartDate: Date | null | undefined
  boundingEndDate: Date | null | undefined

  customFilters: LocalCustomFilter[]
}

export type RangeFilter = {
  startDate?: Date | null,
  endDate?: Date | null
}

// this is a local representation of the filters that is easier to deal
// with for client manipulations than the server contract
export type LocalCustomFilter = {
  id: string,
  isDraft: boolean,
  filterType: AnalyticsFilterType,
  categoryValueFilter?: LocalCategoryValueFilter,
  occurrenceFilter?: OccurenceFilter,
  sharingFilter?: SharingFilter
}

export type LocalCategoryValueFilter = {
  filterType: AnalyticsFilterType
  categoryValueIds: string[],
  mode: CategoryValueFilterMode
}

type AdminAnalyticsActionCreators = {
  setDateRange: (rangeFilter: RangeFilter) => Promise<void>
  setSort: (sortBy: AdminAnalyticsSortBy, direction: 'asc' | 'desc') => Promise<void>
  setPage: (page: number) => Promise<void>
  setSummaryGraphType: (type: AnalyticType) => Promise<void>
  addTagFilter: (categoryValueIds: string[], include: boolean, mode: CategoryValueFilterMode, isDraft: boolean) => Promise<void>
  addOccurrenceFilter: (metric: AnalyticType, op: OccurrenceOperator, num: number, isDraft: boolean) => Promise<void>
  addSharingFilter: (sharingType: GeneralAccessType, isDraft: boolean) => Promise<void>
  removeCustomFilter: (customFilterId: string) => Promise<void>
  updateCustomTagFilter: (filterId: string, categoryValueIds: string[], mode: CategoryValueFilterMode) => Promise<void>
  updateCustomOccurrenceFilter: (filterId: string, metric: AnalyticType, op: OccurrenceOperator, num: number) => Promise<void>
  updateCustomSharingFilter: (filterId: string, sharingType: GeneralAccessType) => Promise<void>
}

type AdminAnalyticsContextType = AdminAnalyticsState & AdminAnalyticsActionCreators
const AdminAnalyticsContext = createContext<AdminAnalyticsContextType>({} as AdminAnalyticsContextType)

enum ActionTypes {
  SetPage = 'SET_PAGE',
  SetResults = 'SET_RESULTS',
  SetSummaries = 'SET_SUMMARIES',
  SetDateRange = 'SET_DATE_RANGE',
  SetSort = 'SET_SORT',
  SetSummaryGraphType = 'SET_SUMMARY_GRAPH_TYPE',
  AddTagFilter = 'ADD_TAG_FILTER',
  AddOccurrenceFilter = 'ADD_OCCURRENCE_FILTER',
  AddSharingFilter = 'ADD_SHARING_FILTER',
  UpdateCustomTagFilter = 'UPDATE_CUSTOM_TAG_FILTER',
  UpdateCustomOccurrenceFilter = 'UPDATE_CUSTOM_OCC_FILTER',
  UpdateCustomSharingFilter = 'UPDATE_CUSTOM_SHARING_FILTER',
  RemoveCustomFilter = 'REMOVE_CUSTOM_FILTER'
}

type AdminAnalyticsActionPayload = {
  [ActionTypes.SetPage]: { pageNumber: number }
  [ActionTypes.SetResults]: AdminAnalyticsSearchResponse
  [ActionTypes.SetSummaries]: AdminAnalyticsSummariesResponse
  [ActionTypes.SetDateRange]: { range: RangeFilter }
  [ActionTypes.SetSort]: { sortBy: AdminAnalyticsSortBy, sortDirection: 'asc' | 'desc' }
  [ActionTypes.SetSummaryGraphType]: { type: AnalyticType }
  [ActionTypes.AddTagFilter]: {
    categoryValueIds: string[],
    include: boolean,
    mode: CategoryValueFilterMode,
    isDraft: boolean
  }
  [ActionTypes.AddOccurrenceFilter]: { metric: AnalyticType, op: OccurrenceOperator, num: number, isDraft: boolean }
  [ActionTypes.AddSharingFilter]: { sharingType: GeneralAccessType, isDraft: boolean }
  [ActionTypes.UpdateCustomTagFilter]: { filterId: string, categoryValueIds: string[], mode: CategoryValueFilterMode }
  [ActionTypes.UpdateCustomOccurrenceFilter]: {
    filterId: string,
    metric: AnalyticType,
    op: OccurrenceOperator,
    num: number
  }
  [ActionTypes.UpdateCustomSharingFilter]: { filterId: string, sharingType: GeneralAccessType }
  [ActionTypes.RemoveCustomFilter]: { customFilterId: string }
}
type AdminAnalyticsAction = ActionMap<AdminAnalyticsActionPayload>[keyof ActionMap<AdminAnalyticsActionPayload>]

const Reducer = (state: AdminAnalyticsState, action: AdminAnalyticsAction) => {
  switch (action.type) {
    case ActionTypes.SetPage:
      state.filters.currentPage = action.payload.pageNumber
      return
    case ActionTypes.SetDateRange:
      state.filters.boundingStartDate = action.payload.range.startDate
      state.filters.boundingEndDate = action.payload.range.endDate
      state.filters.currentPage = 0
      return
    case ActionTypes.SetSort:
      state.filters.sortBy = action.payload.sortBy
      state.filters.sortDirection = action.payload.sortDirection
      state.filters.currentPage = 0
      // default the graph to the same as the sort. the user can change it if wanted
      state.summaryGraphType = ANALYTIC_TYPE_BY_SORT[action.payload.sortBy] || state.summaryGraphType
      return
    case ActionTypes.SetSummaryGraphType:
      state.summaryGraphType = action.payload.type
      return
    case ActionTypes.AddTagFilter: {
      const filter = {
        filterType: action.payload.include ? AnalyticsFilterType.HAS_CATEGORY : AnalyticsFilterType.N_HAS_CATEGORY,
        categoryValueIds: action.payload.categoryValueIds.filter(it => !!it),
        mode: action.payload.mode,
      }
      const localFilter = {
        id: uuidv4(),
        isDraft: action.payload.isDraft,
        filterType: filter.filterType,
        categoryValueFilter: filter,
      }
      state.filters.customFilters.push(localFilter)
      return
    }
    case ActionTypes.AddOccurrenceFilter: {
      const filter = {
        filterType: AnalyticsFilterType.OCCURRENCE,
        event: action.payload.metric,
        operator: action.payload.op,
        numOccurrences: action.payload.num,
      } as unknown as OccurenceFilter
      const localFilter = {
        id: uuidv4(),
        isDraft: action.payload.isDraft,
        filterType: filter.filterType,
        occurrenceFilter: filter,
      }
      state.filters.customFilters.push(localFilter)
      return
    }
    case ActionTypes.AddSharingFilter: {
      const localFilter = {
        id: uuidv4(),
        isDraft: action.payload.isDraft,
        filterType: AnalyticsFilterType.SHARING,
        sharingFilter: {
          filterType: AnalyticsFilterType.SHARING,
          sharingType: action.payload.sharingType,
        } as SharingFilter,
      }
      state.filters.customFilters.push(localFilter)
      return
    }
    case ActionTypes.RemoveCustomFilter:
      state.filters.customFilters = state.filters.customFilters.filter(it => it.id !== action.payload.customFilterId)
      return
    case ActionTypes.UpdateCustomTagFilter: {
      const filter = state.filters.customFilters.find(it => it.id === action.payload.filterId)
      if (filter) {
        filter.isDraft = false
        if (filter.categoryValueFilter) {
          filter.categoryValueFilter.categoryValueIds = action.payload.categoryValueIds.filter(it => it!!)
          filter.categoryValueFilter.mode = action.payload.mode
        }
      }
      return
    }
    case ActionTypes.UpdateCustomOccurrenceFilter: {
      const filter = state.filters.customFilters.find(it => it.id === action.payload.filterId)
      if (filter) {
        filter.isDraft = false
        if (filter.occurrenceFilter) {
          filter.occurrenceFilter.event = action.payload.metric
          filter.occurrenceFilter.operator = action.payload.op
          filter.occurrenceFilter.numOccurrences = action.payload.num
        }
      }
      return
    }
    case ActionTypes.UpdateCustomSharingFilter: {
      const filter = state.filters.customFilters.find(it => it.id === action.payload.filterId)
      if (filter) {
        filter.isDraft = false
        if (filter.sharingFilter) {
          filter.sharingFilter.sharingType = action.payload.sharingType
        }
      }
      return
    }
    default:
      return
  }
}

const DEFAULT_INITIAL_STATE = {
  summaryGraphType: AnalyticType.DOWNLOAD,
  filters: {
    currentPage: 0,
    pageSize: 30,
    boundingStartDate: startOfDay(addDays(new Date(), -30)),
    boundingEndDate: endOfToday(),
    sortBy: AdminAnalyticsSortBy.NUM_DOWNLOADS,
    sortDirection: 'desc',
    includedCategoryValueIds: [] as string[],
    categoryValueFilterMode: 'ONE_OF',
    customFilters: [] as LocalCustomFilter[],
  },
} as AdminAnalyticsState

const AssetAnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useImmerReducer(Reducer, DEFAULT_INITIAL_STATE)

  const setDateRange = async (range: RangeFilter) =>
    dispatch({ type: ActionTypes.SetDateRange, payload: { range } })

  const setSort = async (sortBy: AdminAnalyticsSortBy, sortDirection: 'asc' | 'desc') =>
    dispatch({ type: ActionTypes.SetSort, payload: { sortBy, sortDirection } })

  const setPage = async (pageNumber: number) =>
    dispatch({ type: ActionTypes.SetPage, payload: { pageNumber } })

  const setSummaryGraphType = async (type: AnalyticType) =>
    dispatch({ type: ActionTypes.SetSummaryGraphType, payload: { type } })

  const addTagFilter = async (categoryValueIds: string[], include: boolean, mode: CategoryValueFilterMode, isDraft: boolean = false) =>
    dispatch({ type: ActionTypes.AddTagFilter, payload: { categoryValueIds, include, isDraft, mode } })

  const addOccurrenceFilter = async (metric: AnalyticType, op: OccurrenceOperator, num: number, isDraft: boolean) =>
    dispatch({ type: ActionTypes.AddOccurrenceFilter, payload: { metric, op, num, isDraft } })

  const addSharingFilter = async (sharingType: GeneralAccessType, isDraft: boolean) =>
    dispatch({ type: ActionTypes.AddSharingFilter, payload: { sharingType, isDraft } })

  const removeCustomFilter = async (customFilterId: string) =>
    dispatch({ type: ActionTypes.RemoveCustomFilter, payload: { customFilterId } })

  const updateCustomTagFilter = async (filterId: string, categoryValueIds: string[], mode: CategoryValueFilterMode) =>
    dispatch({ type: ActionTypes.UpdateCustomTagFilter, payload: { filterId, categoryValueIds, mode } })

  const updateCustomOccurrenceFilter = async (filterId: string, metric: AnalyticType, op: OccurrenceOperator, num: number) =>
    dispatch({ type: ActionTypes.UpdateCustomOccurrenceFilter, payload: { filterId, metric, op, num } })

  const updateCustomSharingFilter = async (filterId: string, sharingType: GeneralAccessType) =>
    dispatch({ type: ActionTypes.UpdateCustomSharingFilter, payload: { filterId, sharingType } })


  return (
    <AdminAnalyticsContext.Provider
      value={{
        setDateRange,
        setSort,
        setPage,
        setSummaryGraphType,
        addTagFilter,
        addOccurrenceFilter,
        addSharingFilter,
        updateCustomTagFilter,
        updateCustomOccurrenceFilter,
        updateCustomSharingFilter,
        removeCustomFilter,
        ...state,
      }}
    >
      {children}
    </AdminAnalyticsContext.Provider>
  )
}

export { AdminAnalyticsContext, AssetAnalyticsProvider }