import { User } from '../../../../@types/user'
import { ShareLevel } from '../../../../@types/sharing'

export const internalGroupUserForUser = (currentUser: User | null | undefined, shareLevel: ShareLevel = ShareLevel.VIEW) => (
  {
    userId: currentUser?.userId,
    name: `${currentUser?.firstName} ${currentUser?.lastName}`,
    email: currentUser?.email,
    shareLevel: shareLevel
  }
)
