import api from '@api'
import {
  CreateGroupRequest,
  GetGroupListItemsResponse,
  GetGroupsResponse,
  Group,
  UpdateGroupRequest,
} from '../@types/group'

export const getGroups = async() => {
  const response = await api.get<GetGroupsResponse>("/api/v1/groups")
  return response.data
}

export const getGroupListItems = async() => {
  const response = await api.get<GetGroupListItemsResponse>("/api/v1/groups/list")
  return response.data
}

export const getGroup = async(groupId: string) => {
  const response = await api.get<Group>(`/api/v1/groups/${groupId}`)
  return response.data
}

export const createGroup = async(dto: CreateGroupRequest) => {
  const response = await api.post<Group>(`/api/v1/groups`, dto)
  return response.data
}

export const updateGroup = async(groupId: string, dto: UpdateGroupRequest) => {
  const response = await api.put<Group>(`/api/v1/groups/${groupId}`, dto)
  return response.data
}

export const deleteGroup = async(groupId: string) => {
  const response = await api.delete(`/api/v1/groups/${groupId}`)
  return response.data
}