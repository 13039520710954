import Page from '../../components/Page'
import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Iconify from '../../components/Iconify'
import { UpdateContentRoomForm } from '../../components/content-room/update/UpdateContentRoomForm'
import { useParams } from 'react-router'
import { useContentRoom } from '../../hooks/useContentRooms'

export function EditContentRoom(){
  const { contentRoomId } = useParams();
  const { data } = useContentRoom(contentRoomId)
  return (
    <Page title="Edit Content Room">
      <Button
        component={Link}
        to="/content-rooms"
        variant="text"
        size="small"
        startIcon={<Iconify icon={"eva:chevron-left-fill"}/>}
      >
        Back to Content Rooms
      </Button>
      <Typography variant="h2" mb={4} mt={2} >Edit Content Room </Typography>
      { data && <UpdateContentRoomForm room={data} /> }
    </Page>
  )
}