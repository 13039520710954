import { Asset } from '../../../../../@types/asset'
import { dateFormatForTimeRange, TimeRange } from '../../../../../@types/analytics/analytics-common'
import moment from 'moment-timezone'
import TrendGraph from '../common/TrendGraph'
import { useBucketedViews } from '../../../../../hooks/analytics/useAssetViewAnalytics'

export default function BucketedViews({ asset, range }: { asset: Asset, range: TimeRange }) {
  const { data } = useBucketedViews(asset.assetId, { range })
  const seriesData = data?.buckets?.map(it => ({ date: moment.utc(it.bucket), y: it.numViews })) || []
  const series = { name: 'Views', data: seriesData }
  const dateFormat = dateFormatForTimeRange(range)
  return (
    <TrendGraph title='Views Over Time' series={series} dateFormat={dateFormat} />
  )
}