import Page from '../../components/Page'
import { Paper, Stack, Typography } from '@mui/material'
import { m } from 'framer-motion'
import { varBounce } from '../../components/animate'
import Iconify from '../../components/Iconify'
import { HostedContentRoomModalLayout } from '../../layouts/hosted-content-room/HostedContentRoomModalLayout'


export function HostedContentRoom410() {
  return (
    <HostedContentRoomModalLayout>
      <Page title='Content Room Expired'>
        <Paper
          elevation={2}
          sx={{
            p: 6,
            borderRadius: 2,
            textAlign: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Iconify
            icon='eva:clock-fill'
            sx={{
              height: 96,
              width: 96,
              position: 'absolute',
              top: -64,
              left: '50%',
              marginLeft: '-48px',
            }}
          />
          <Stack spacing={1}>
            <m.div variants={varBounce().in}>
              <Typography variant='h2' paragraph>
                Looks like you ran out of time...
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography variant='h4' paragraph>
                ...that <Typography color='primary' variant='h3' component='span'>Personal Content
                Room</Typography> has expired!
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: 'text.secondary' }}>
                You may need to reach out to the sender to get an extension.
              </Typography>
            </m.div>
          </Stack>
        </Paper>
      </Page>
    </HostedContentRoomModalLayout>
  )
}
