import { createContext, ReactNode } from 'react'
import { ActionMap } from '../@types/reducer'
import { useImmerReducer } from 'use-immer'
import {
  AdminAnalyticsAssetResult,
  AdminAnalyticsSortBy,
  ANALYTIC_TYPE_BY_SORT,
  AnalyticType,
} from '../@types/analytics/analytics-asset'
import { addDays, endOfToday, startOfDay } from 'date-fns'


export type UserAnalyticsState = {
  filters: UserAnalyticsFilters,
  summaryGraphType: AnalyticType,
}

export type UserAnalyticsFilters = {
  currentPage: number,
  pageSize: number,
  sortBy: AdminAnalyticsSortBy,
  sortDirection: 'asc' | 'desc',

  // we include a date filter by default for easier ux
  boundingStartDate: Date | null | undefined
  boundingEndDate: Date | null | undefined
}

export type RangeFilter = {
  startDate?: Date | null,
  endDate?: Date | null
}

type UserAnalyticsActionCreators = {
  setDateRange: (rangeFilter: RangeFilter) => Promise<void>
  setSort: (sortBy: AdminAnalyticsSortBy, direction: 'asc' | 'desc') => Promise<void>
  setPage: (page: number) => Promise<void>
  setSummaryGraphType: (type: AnalyticType) => Promise<void>
}

type UserAnalyticsContextType = UserAnalyticsState & UserAnalyticsActionCreators
const UserAnalyticsContext = createContext<UserAnalyticsContextType>({} as UserAnalyticsContextType)

enum ActionTypes {
  SetPage = 'SET_PAGE',
  SetDateRange = 'SET_DATE_RANGE',
  SetSort = 'SET_SORT',
  SetSummaryGraphType = 'SET_SUMMARY_GRAPH_TYPE',
}

type UserAnalyticsActionPayload = {
  [ActionTypes.SetPage]: { pageNumber: number }
  [ActionTypes.SetDateRange]: { range: RangeFilter }
  [ActionTypes.SetSort]: { sortBy: AdminAnalyticsSortBy, sortDirection: 'asc' | 'desc' }
  [ActionTypes.SetSummaryGraphType]: { type: AnalyticType }
}
type UserAnalyticsAction = ActionMap<UserAnalyticsActionPayload>[keyof ActionMap<UserAnalyticsActionPayload>]

const Reducer = (state: UserAnalyticsState, action: UserAnalyticsAction) => {
  switch (action.type) {
    case ActionTypes.SetPage:
      state.filters.currentPage = action.payload.pageNumber
      return
    case ActionTypes.SetDateRange:
      state.filters.boundingStartDate = action.payload.range.startDate
      state.filters.boundingEndDate = action.payload.range.endDate
      state.filters.currentPage = 0
      return
    case ActionTypes.SetSort:
      state.filters.sortBy = action.payload.sortBy
      state.filters.sortDirection = action.payload.sortDirection
      state.filters.currentPage = 0
      // default the graph to the same as the sort. the user can change it if wanted
      state.summaryGraphType = ANALYTIC_TYPE_BY_SORT[action.payload.sortBy] || state.summaryGraphType
      return
    case ActionTypes.SetSummaryGraphType:
      state.summaryGraphType = action.payload.type
      return
    default:
      return
  }
}

const DEFAULT_INITIAL_STATE = {
  summaryGraphType: AnalyticType.DOWNLOAD,
  filters: {
    currentPage: 0,
    pageSize: 30,
    boundingStartDate: startOfDay(addDays(new Date(), -30)),
    boundingEndDate: endOfToday(),
    sortBy: AdminAnalyticsSortBy.NUM_DOWNLOADS,
    sortDirection: 'desc',
  },
} as UserAnalyticsState


const UserAnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useImmerReducer(Reducer, DEFAULT_INITIAL_STATE)

  const setDateRange = async (range: RangeFilter) =>
    dispatch({ type: ActionTypes.SetDateRange, payload: { range } })

  const setSort = async (sortBy: AdminAnalyticsSortBy, sortDirection: 'asc' | 'desc') =>
    dispatch({ type: ActionTypes.SetSort, payload: { sortBy, sortDirection } })

  const setPage = async (pageNumber: number) =>
    dispatch({ type: ActionTypes.SetPage, payload: { pageNumber } })

  const setSummaryGraphType = async (type: AnalyticType) =>
    dispatch({ type: ActionTypes.SetSummaryGraphType, payload: { type } })


  return (
    <UserAnalyticsContext.Provider
      value={{
        setDateRange,
        setSort,
        setPage,
        setSummaryGraphType,
        ...state,
      }}
    >
      {children}
    </UserAnalyticsContext.Provider>
  )
}

export { UserAnalyticsContext, UserAnalyticsProvider }

