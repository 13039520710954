import { ReactNode } from 'react';
import { Box } from '@mui/material'
import { alpha, Theme, useTheme } from '@mui/material/styles'
import { SxProps } from '@mui/system'

type HoverableItemProps = {
  children: ReactNode,
  onClick?: () => void,
  sx?: SxProps<Theme>
}

export function HoverableItem({ children, onClick, sx }: HoverableItemProps) {
  const theme = useTheme()
  return (
    <Box
      onClick={onClick}
      sx={{
        ...sx,
        padding: 2,
        '&:hover': {
          background: alpha(theme.palette.bg.warmGreyLight, .025),
        },
      }}>
      {children}
    </Box>
  )
}