import { createContext, ReactNode, useReducer } from "react";
import {
  Asset,
  AssetLabels,
  AssetPalettes,
  AssetTasksResponseDto,
  BulkUpdateAssetsDto, LinkedAssetsImportResult,
  UpdateAssetDto
} from "../@types/asset";
import * as assetClient from "../clients/AssetClient";
import { ActionMap } from "../@types/reducer";
import { pollForCompletion } from "../clients/AssetTaskPoller";
import { FileRejection } from "react-dropzone";

type State = {
  uploads: AssetUpload[],
}

type ActionCreators = {
  createUploadBatch: (file: File[], rejectedFiles: FileRejection[]) => Promise<void>
  createLinkedAsset: (location: string) => Promise<void>
  setLinkedAssetsFromBatchResult: (result: LinkedAssetsImportResult) => Promise<void>
  finalizeAsset: (assetId: string, asset: UpdateAssetDto) => Promise<void>
  bulkFinalizeAssets: (bulkUpdate: BulkUpdateAssetsDto) => Promise<void>
  retryTasks: (assetId: string) => Promise<void>
}
type ContextType = State & ActionCreators
const defaultState = { uploads: [] } as any as ContextType
const Context = createContext<ContextType>(defaultState)

export type AssetUpload = {
  key: number,
  asset: Asset | null,
  loading: boolean,
  success: boolean
  file: File | null,
  progress: UploadProgress
}

export type UploadProgress = {
  currentBytes: number,
  totalBytes: number,
  timestamp: number
}

// reducer stuff
enum ActionTypes {
  SetUploadBatch = "SET_UPLOAD_BATCH",
  AssetUploadProgress = "ASSET_UPLOAD_PROGRESS",
  AssetUploadComplete = "ASSET_UPLOAD_COMPLETE",
  SetTasks = "SET_TASKS",
}

type Payload = {
  [ActionTypes.SetUploadBatch]: {
    uploads: AssetUpload[]
  },
  [ActionTypes.AssetUploadProgress]: {
    key: number,
    progress: UploadProgress
  }
  [ActionTypes.AssetUploadComplete]: {
    key: number,
    asset: Asset | null,
    success: boolean
  },
  [ActionTypes.SetTasks]: {
    assetId: string,
    palettes: AssetPalettes
    labels: AssetLabels
  }
}
type Action = ActionMap<Payload>[keyof ActionMap<Payload>];
const Reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.SetUploadBatch:
      return {
        ...state,
        uploads: action.payload.uploads
      }
    case ActionTypes.AssetUploadProgress:
      return {
        ...state,
        uploads: state.uploads.map(upload => {
          return upload.key === action.payload.key ? {
            ...upload,
            progress: action.payload.progress
          } : upload
        })
      }
    case ActionTypes.AssetUploadComplete:
      return {
        ...state,
        uploads: state.uploads.map(upload => {
          return upload.key === action.payload.key ? {
            ...upload,
            asset: action.payload.asset,
            success: action.payload.success
          } : upload
        })
      }
    case ActionTypes.SetTasks:
      const newUploads = state.uploads.map(upload => {
        if (upload.asset?.assetId === action.payload.assetId) {
          return {
            ...upload,
            asset: {
              ...upload.asset,
              palettes: action.payload.palettes,
              labels: action.payload.labels,
            }
          }
        } else {
          return upload
        }
      })
      return {
        ...state,
        uploads: newUploads
      }
    default:
      return state
  }
}

const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, defaultState);

  const createUploadBatch = async (files: File[]) => {
    // create placeholders for all items
    const uploads = files.map((file, idx) => {
      return {
        key: idx,
        asset: null,
        loading: true,
        success: false,
        file: file,
        progress: {
          currentBytes: 0,
          totalBytes: 0,
          timestamp: Date.now()
        }
      }
    })

    // set the placeholders to the ui
    dispatch({ type: ActionTypes.SetUploadBatch, payload: { uploads: uploads } })

    // issue upload requests for all placeholders
    uploads.map(upload => {
      const onUploadProgress = (progressEvent: ProgressEvent) => {
        const payload = {
          key: upload.key, progress: {
            totalBytes: progressEvent.total,
            currentBytes: progressEvent.loaded,
            timestamp: progressEvent.timeStamp
          }
        }
        dispatch({ type: ActionTypes.AssetUploadProgress, payload })
      }
      assetClient.createAsset(upload.file, onUploadProgress)
        .then(asset => {
          dispatch({
            type: ActionTypes.AssetUploadComplete,
            payload: { key: upload.key, asset, success: true }
          })

          // poll for tasks if this is the only upload
          if (uploads.length === 1) {

            const processResponse = (tasksDto: AssetTasksResponseDto) => {
              dispatch({ type: ActionTypes.SetTasks, payload: tasksDto })
            }

            // until we have Pusher implemented, just poll for the task completion
            pollForCompletion(asset.assetId, asset)
              .then(processResponse)
              .catch(processResponse)
          }

        })
        .catch(() => {
          dispatch({
            type: ActionTypes.AssetUploadComplete,
            payload: { key: upload.key, asset: null, success: false }
          })
        })
    })
  }

  const createLinkedAsset = async (location: string) => {
    const uploads = [{
      key: 0,
      asset: null,
      loading: true,
      success: false,
      file: null,
      progress: {
        totalBytes: 0,
        currentBytes: 0,
        timestamp: Date.now()
      }
    }]

    // set the placeholders to the ui
    dispatch({ type: ActionTypes.SetUploadBatch, payload: { uploads: uploads } })

    assetClient.createLinkedAsset(location)
      .then(asset => {
        dispatch({
          type: ActionTypes.AssetUploadComplete,
          payload: { key: 0, asset, success: true }
        })
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.AssetUploadComplete,
          payload: { key: 0, asset: null, success: false }
        })
      })
  }

  const setLinkedAssetsFromBatchResult = async (bulkResult: LinkedAssetsImportResult) => {
    const uploads = bulkResult.assets.map((it, idx) => {
      return {
        key: idx,
        asset: it,
        loading: false,
        success: true,
        file: null,
        progress: {
          totalBytes: 0,
          currentBytes: 0,
          timestamp: Date.now()
        }
      }
    })

    // set the placeholders to the ui
    dispatch({ type: ActionTypes.SetUploadBatch, payload: { uploads: uploads } })
  }

  const finalizeAsset = async (assetId: string, assetUpdate: UpdateAssetDto) => {
    const asset = await assetClient.updateAsset(assetId, assetUpdate)
    dispatch({ type: ActionTypes.SetUploadBatch, payload: { uploads: [] } })
  }

  const bulkFinalizeAssets = async (bulkUpdate: BulkUpdateAssetsDto) => {
    await assetClient.bulkFinalizeAssets(bulkUpdate)
    dispatch({ type: ActionTypes.SetUploadBatch, payload: { uploads: [] } })
  }

  const retryTasks = async (assetId: string) => {
    const tasks = await assetClient.retryTasks(assetId)
    const payload = {
      assetId: tasks.assetId,
      labels: tasks.labels,
      palettes: tasks.palettes
    }
    dispatch({ type: ActionTypes.SetTasks, payload })

    const processResponse = (dto: AssetTasksResponseDto) => dispatch({ type: ActionTypes.SetTasks, payload: dto })
    pollForCompletion(assetId)
      .then(processResponse)
      .catch(processResponse)
  }


  return (
    <Context.Provider
      value={{
        ...state,
        createUploadBatch,
        createLinkedAsset,
        setLinkedAssetsFromBatchResult,
        finalizeAsset,
        bulkFinalizeAssets,
        retryTasks
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context as AssetUploadContext, Provider as AssetUploadProvider }