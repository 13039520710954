import { Box, Stack, Tooltip } from "@mui/material";
import AssetProperty from "../AssetProperty";
import AssetMetadataCmp from "../../asset-metadata/AssetMetadataCmp";
import { AssetTagList } from "../AssetTagList";
import { Asset, AssetStorageType } from "../../../@types/asset";
import Iconify from "../../Iconify";
import { AssetDeleteButton } from '../AssetDeleteButton'

export function AssetDetailMetadataTab({ asset }: { asset: Asset }) {
  const isExternal = asset.storageType === AssetStorageType.EXTERNAL_HTTP

  return (
    <>
      <Box mb={4}>
        <AssetProperty
          title="Name"
          property={asset.name}
        />
      </Box>

      {isExternal && (
        <Box mb={4}>
          <AssetProperty
            title={
              <Stack direction="row" spacing={1}>
                <span> External Url </span>
                <Tooltip
                  title="This asset is linked. To get the full benefits of Masset, upload the asset directly"
                  placement="right"
                  arrow>
                  <Box sx={{ display: "flex" }}>
                    <Iconify icon="eva:link-outline"/>
                  </Box>
                </Tooltip>
              </Stack>
            }
            property={asset.storageExternalRef}
          />
        </Box>
      )}

      {
        asset.description && (
          <Box mb={4}>
            <AssetProperty
              title="Description"
              property={asset.description}
            />
          </Box>
        )
      }

      <AssetMetadataCmp asset={asset}/>
      <Box mb={4}/>

      <AssetTagList asset={asset}/>

      <Box mb={4}/>
      <AssetDeleteButton asset={asset} />

    </>
  )
}