import { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material'
import { GroupMember } from './GroupMember'
import { NoMatchesItem } from './NoMatchesItem'
import { InternalFormGroupUser } from './GroupMembersField'

type FilteredGroupMemberListProps = {
  members: InternalFormGroupUser[],
  searchTerm: string,
  fieldName: string
}

export function FilteredGroupMemberList({ members, searchTerm, fieldName }: FilteredGroupMemberListProps) {
  const filteredMembers = useMemo(() => {
    const toMatch = searchTerm.toLowerCase().trim()
    return members.filter(it => it.email.toLowerCase().includes(toMatch) || it.name.toLowerCase().includes(toMatch))
  }, [members, searchTerm])
  return (
    <Box>
      <Box px={2}><Typography variant='smallHighlight'>In this group</Typography></Box>
      <Stack>
        {filteredMembers.map(it => <GroupMember key={it.userId} groupMember={it} fieldName={fieldName} />)}
      </Stack>
      {filteredMembers.length === 0 && <NoMatchesItem />}
    </Box>
  )
}
