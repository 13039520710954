import {useContext, useState} from "react";
import {SearchContext} from "../../contexts/SearchContext";
import {Button, MenuItem, Typography} from "@mui/material";
import {DATE_FILTER_OPTIONS, SearchDateFilter, SearchSortBy} from "../../@types/search";
import MenuPopover from "../MenuPopover";
import Iconify from "../Iconify";

const renderLabel = (dateFilter: SearchDateFilter) => DATE_FILTER_OPTIONS.find(it => it.value === dateFilter)?.label

export default function SearchDateFilterDropdown() {
    const [open, setOpen] = useState<HTMLButtonElement | null>(null);
    const {filters: {dateFilter}, setDateFilter} = useContext(SearchContext)

    const handleOpen = (currentTarget: HTMLButtonElement) => {
        setOpen(currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleDateFilter = (value: SearchDateFilter) => {
        handleClose();
        setDateFilter(value)
    };

    return (
        <>
            <Button
                color="inherit"
                disableRipple
                variant="text"
                onClick={(e) => handleOpen(e.currentTarget)}
                sx={{"& .MuiButton-endIcon": {ml: 0}}}
                endIcon={
                    <Iconify
                        color="text.mint"
                        icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
                    />
                }
            >
                <Typography component="span" variant="small" sx={{color: 'text.deemphasized', mr: 1}}>
                    Date Uploaded:
                </Typography>
                <Typography component="span" variant="smallHighlight" sx={{color: 'text.mint'}}>
                    {renderLabel(dateFilter)}
                </Typography>
            </Button>

            <MenuPopover
                anchorEl={open}
                open={Boolean(open)}
                onClose={handleClose}
                sx={{
                    width: 'auto',
                    '& .MuiMenuItem-root': {typography: 'body2', borderRadius: 0.75},
                }}
            >
                {DATE_FILTER_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === dateFilter}
                        onClick={() => handleDateFilter(option.value)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </MenuPopover>
        </>
    )
}