import { Asset, AssetStorageType } from "../../@types/asset";
import { RHFTextField } from "../hook-form";
import { InputAdornment, Stack } from "@mui/material";
import Iconify from "../Iconify";

type DefaultUpdateableFieldsProps = {
  asset: Asset | null
}

export default function DefaultUpdateableFields({ asset }: DefaultUpdateableFieldsProps) {
  if (!asset) return <></>
  const isExternal = asset.storageType === AssetStorageType.EXTERNAL_HTTP
  return (
    <Stack
      direction="column"
      spacing={4}
    >
      <RHFTextField
        name="name"
        label="Asset Name"
        variant="filled"
      />

      {
        isExternal && (
          <RHFTextField
            name="storageExternalRef"
            placeholder="https://example.com/public/asset.jpg"
            variant="filled"
            label="Asset Url"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:link-outline"/>
                </InputAdornment>
              )
            }}
          />
        )
      }

      <RHFTextField
        name="description"
        label="Description"
        variant="filled"
        multiline={true}
        rows={5}
      />
    </Stack>
  )
}