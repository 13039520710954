import { AssetUpload, UploadProgress } from "../contexts/AssetUploadContext";
import { AssetPreview } from "./AssetPreview";
import { Box, Card, CircularProgress, LinearProgress, Typography } from "@mui/material";
import Image from "./Image";
import { styled } from "@mui/material/styles";
import { fPercent } from "../utils/formatNumber";
import { ScrollPosition } from "react-lazy-load-image-component";

type AssetUploadPreviewProps = {
  upload: AssetUpload
  compact: boolean,
  scrollPosition?: ScrollPosition
}

const RootStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? theme.palette.bg.warmGreyLight : theme.palette.background.neutral,
  position: "relative",
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
}))

export default function AssetUploadPreview({ upload, compact, scrollPosition }: AssetUploadPreviewProps) {
  const assetExists = !!upload.asset

  // todo: handle error states
  return (
    <RootStyle>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        opacity: .75
      }}>
        {!assetExists && <UploadProgressCmp progress={upload.progress} compact={compact} />}
      </Box>

      {
        !assetExists ? <Image
          ratio="1/1"
          src={""}
          sx={{ height: "100%" }}
        /> : <AssetPreview asset={upload.asset!!} scrollPosition={scrollPosition} />
      }
    </RootStyle>
  )
}

function UploadProgressCmp({ progress, compact }: { progress: UploadProgress, compact: boolean }) {
  const percentComplete = (progress.currentBytes / Math.max(progress.totalBytes, 1)) * 100
  const isUploadComplete = Math.round(percentComplete) === 100
  const variant = isUploadComplete ? "indeterminate" : "determinate"
  return (
    <Box sx={{ width: "50%" }}>
      <StyledLinearProgress variant={variant} value={percentComplete}/>
      { compact && (<CompactProgressDetails complete={isUploadComplete} percent={percentComplete} />)}
      { !compact && (<FullProgressDetails complete={isUploadComplete} percent={percentComplete} />)}
    </Box>
  )
}

function CompactProgressDetails({ complete, percent } : { complete: boolean, percent: number }){
  return (
    <>
      { !complete && (<Typography variant="small">{fPercent(percent)}</Typography>) }
      { complete && (<Typography variant="small">Processing...</Typography>)}
    </>
  )
}

function FullProgressDetails({ complete, percent } : { complete: boolean, percent: number }){
  return (
    <>
      { !complete && (<Typography variant="small">Uploading: {fPercent(percent)}</Typography>) }
      { complete && (<Typography variant="small">Extracting metadata...</Typography>)}
    </>
  )
}