export enum OauthProvider {
  HUBSPOT = "hubspot",
  GOOGLE_DRIVE = "google-drive",
  GOOGLE_YOUTUBE_DATA = "google-youtube-data"
}

export const OAUTH_PROVIDER_NAMES = {
  [OauthProvider.HUBSPOT] : "HubSpot",
  [OauthProvider.GOOGLE_DRIVE] : "Google Drive",
  [OauthProvider.GOOGLE_YOUTUBE_DATA] : "YouTube"
}