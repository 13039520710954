import api from '@api'
import { BucketedDownloads, TopDownloaders, TotalDownloads } from '../@types/analytics/downloads'
import { Rank, TimeRange } from '../@types/analytics/analytics-common'


export async function getTopDownloaders(assetId: string, options: { range: TimeRange }, limit: number = 5) {
  const params = {
    limit,
    start: options.range?.start,
    end: options.range?.end,
  }
  const response = await api.get<TopDownloaders>(`/api/v1/analytics/assets/${assetId}/downloads/top-users`, {
    params,
  })
  return response.data
}

export async function getBucketedDownloads(assetId: string, options: { range: TimeRange }) {
  const params = { start: options.range?.start, end: options.range?.end }
  const response = await api.get<BucketedDownloads>(`/api/v1/analytics/assets/${assetId}/downloads/bucketed`, { params })
  return response.data
}

export async function getDownloadRank(assetId: string, options: { range: TimeRange }) {
  const params = { start: options.range?.start, end: options.range?.end }
  const response = await api.get<Rank>(`/api/v1/analytics/assets/${assetId}/downloads/rank`, { params })
  return response.data
}

export async function getTotalDownloads(assetId: string, options: { range: TimeRange }) {
  const params = { start: options.range?.start, end: options.range?.end }
  const response = await api.get<TotalDownloads>(`/api/v1/analytics/assets/${assetId}/downloads/total`, { params })
  return response.data
}