import api from "@api"
import { SCIMStatusResponse, SCIMSyncResponse } from '../@types/scim.ts'

export async function getStatus(){
  const response = await api.get<SCIMStatusResponse>("/api/v1/scim/status")
  return response.data
}

export async function manualSync(){
  const response = await api.post<SCIMSyncResponse>("/api/v1/scim/syncs")
  return response.data
}