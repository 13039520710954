import { Asset } from '../../../../@types/asset.ts'
import { AssetContentState } from '../../../../@types/asset-content.ts'
import { useRetryRawFileExtraction } from '../../../../hooks/useAssetContent.ts'
import { Box, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

export function RawFileExternalStorageMissing({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const { mutateAsync: retryExtraction, isLoading } = useRetryRawFileExtraction()
  const handleRetryDownloadClick = () => retryExtraction({ assetId: asset.assetId })
  return (
    <Stack spacing={2}>
      <Typography variant='small'>It looks like we've never tried to download this file before.</Typography>
      <Typography variant='small'>Click below to try.</Typography>
      <Box>
        <LoadingButton
          variant="outlined"
          size="small"
          loading={isLoading}
          loadingPosition="start"
          onClick={handleRetryDownloadClick}
        >
          Download the File
        </LoadingButton>
      </Box>
      <Typography variant="small">If that does not resolve your issue. Please let us know by reaching out to support@getmasset.com.</Typography>
    </Stack>
  )
}