import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { AnalyticType } from '../../@types/analytics/analytics-asset'
import { useContext } from 'react';
import { AdminAnalyticsContext } from '../../contexts/AdminAnalyticsContext'

type AnalyticsGraphTypeSelectProps = {
  type: AnalyticType,
  onTypeChange: (type: AnalyticType) => void
}
export function AnalyticsGraphTypeSelect({type, onTypeChange} : AnalyticsGraphTypeSelectProps){
  const handleMetricChange =
    (event: SelectChangeEvent) => onTypeChange(event.target.value as unknown as AnalyticType)
  return (
    <Select
      size='small'
      value={type + ''}
      defaultValue={type + ''}
      onChange={handleMetricChange}
      sx={{ height: 24, fontSize: 11 }}
      margin='dense'
    >
      <MenuItem value={AnalyticType.FAVORITE}>Favorites</MenuItem>
      <MenuItem value={AnalyticType.DOWNLOAD}>Downloads</MenuItem>
      <MenuItem value={AnalyticType.SHARE}>Shares</MenuItem>
      <MenuItem value={AnalyticType.VIEW}>Views</MenuItem>
    </Select>
  )
}