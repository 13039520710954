import { AdminAnalyticsExportResponse } from '../@types/analytics/analytics-asset'
import api from '@api'
import {
  AnalyticsUserSummariesResponse,
  TopAssetsForUserResponse,
  TopAssetsOptions,
  TotalRequestOptions,
  TotalResponse,
  UserAnalyticsRequestOptions,
  UserAnalyticsSearchResponse,
  UserAnalyticsSummariesRequestOptions,
  UserGraphSummary,
} from '../@types/analytics/analytics-user'
import { parseJSON } from 'date-fns'
import { TimelineOptions, TimelineResponse } from '../@types/analytics/timeline-events'

export const getUserAnalytics = async (options: UserAnalyticsRequestOptions) => {
  const response = await api.post<UserAnalyticsSearchResponse>('/api/v1/analytics/users/search', options)
  const mappedResults = response.data.results.map(it => ({...it, lastSessionAt: it.lastSessionAt && parseJSON(it.lastSessionAt) }))
  return { ...response.data, results: mappedResults } as UserAnalyticsSearchResponse
}

export const getUserSummaries = async (options: UserAnalyticsSummariesRequestOptions) => {
  const response = await api.post<AnalyticsUserSummariesResponse>('/api/v1/analytics/users/graph/summary', options)
  const entries = Object.entries(response.data.summaries).map(entry => [entry[0], transformSummary(entry[1])])
  const result = { summaries: Object.fromEntries(entries) } as AnalyticsUserSummariesResponse
  return result
}


export const createUserAnalyticsExport = async (options: UserAnalyticsRequestOptions) => {
  const response = await api.post<AdminAnalyticsExportResponse>('/api/v1/analytics/users/search/export', options)
  return response.data
}

export const getTotalAssetRequestsForUser = async (userId: string, options: TotalRequestOptions) => {
  const response = await api.post<TotalResponse>(`/api/v1/analytics/users/${userId}/asset-requests/total`, options)
  return response.data
}

export const getTotalChangeRequestsForUser = async (userId: string, options: TotalRequestOptions) => {
  const response = await api.post<TotalResponse>(`/api/v1/analytics/users/${userId}/change-requests/total`, options)
  return response.data
}

export const getTotalAssetFlagsForUser = async (userId: string, options: TotalRequestOptions) => {
  const response = await api.post<TotalResponse>(`/api/v1/analytics/users/${userId}/asset-flags/total`, options)
  return response.data
}

export const getTopAssetsForUser = async (userId: string, options: TopAssetsOptions) => {
  const response = await api.post<TopAssetsForUserResponse>(`/api/v1/analytics/users/${userId}/assets/top`, options)
  return response.data
}

export const getTimelineForUser = async (userId: string, options: TimelineOptions) => {
  const response = await api.post<TimelineResponse>(`/api/v1/analytics/users/${userId}/timeline`, options)
  return response.data
}

function transformSummary(summary: UserGraphSummary) {
  return {
    ...summary,
    entries: summary.entries.map(it => ({
      count: it.count,
      bucketStart: parseJSON(it.bucketStart),
      bucketEnd: parseJSON(it.bucketEnd),
    })),
  }
}