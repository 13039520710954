export type MassetBotMessage = {
  type: MassetBotMessageType,
  text: string
}

export enum MassetBotMessageType {
  BOT = 1,
  USER = 2
}

export type MassetBotSendMessageResponse = {
  botMessage: string
}