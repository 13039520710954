import { Box, Divider, Stack, Typography } from '@mui/material'
import { GeneralAccessSelector } from '../asset-detail/sharing/internal/GeneralAccessSelector'
import { RHFSwitch } from '../hook-form'

export function AssetUploadSharing({ subtext }: { subtext: String }) {
  return (
    <Box>
      <Typography variant='h6'>General Access</Typography>
      <Typography component='div' variant='small' color='text.deemphasized' mb={2}>
        {subtext}
      </Typography>
      <Divider sx={{ mt: 2, mb: 3 }} />
      <GeneralAccessSelector
        accessTypeName='generalAccessType'
        accessLevelName='generalAccessShareLevel'
      />
      <Box mt={4} mb={2}>
        <RHFSwitch
          name='isInternal'
          sx={{mb: 4}}
          label={<Box ml={2}>
            <Typography component='div' variant='standardHighlight'>For Internal Use Only</Typography>
            <Typography variant='small' color='text.secondary'>Internal content is not available for download by Masset users</Typography>
          </Box>}
          labelPlacement='end'
        />
        <RHFSwitch
          name='isThirdParty'
          label={<Box ml={2}>
            <Typography component='div' variant='standardHighlight'>Third Party Content</Typography>
            <Typography variant='small' color='text.secondary'>Third Party Content is generated by external parties, but stored or linked within Masset. Masset users will be informed they are consuming third party content when it is viewed.</Typography>
          </Box>}
          labelPlacement='end'
        />
      </Box>
    </Box>
  )
}