import { Box, Stack } from '@mui/material'
import { AddPeopleItem } from './AddPeopleItem'
import { InternalFormGroupUser } from './GroupMembersField'
import { GroupMember } from './GroupMember'

export function DefaultGroupMembersList({ members, fieldName }: {
  members: InternalFormGroupUser[],
  fieldName: string
}) {
  return (
    <Stack>
      <AddPeopleItem fieldName={fieldName} />
      <Box>
        {members.map(it => {
          return <GroupMember key={it.userId} groupMember={it} fieldName={fieldName} />
        })}
      </Box>
    </Stack>
  )
}