import api from '@api'
import {
  CompanyBrandResponse,
  CompanyInfoResponse, CompanySharingSettingsResponse, UpdateCompanyBrandRequest,
  UpdateCompanyInfoRequest,
  UpdateCompanyInfoResponse,
} from '../@types/company'
import { GeneralAccessType, ShareLevel } from '../@types/sharing'

export async function getCompanyInfo() {
  const response = await api.get<CompanyInfoResponse>('/api/v1/company/settings/info')
  return response.data
}

export async function updateCompanyInfo(data: UpdateCompanyInfoRequest) {
  const response = await api.put<UpdateCompanyInfoResponse>('/api/v1/company/settings/info', data)
  return response.data
}

export async function getCompanyBrandSettings() {
  const response = await api.get<CompanyBrandResponse>('/api/v1/company/settings/brand')
  return response.data
}

export async function updateCompanyBrand(data: UpdateCompanyBrandRequest) {
  const response = await api.put<UpdateCompanyInfoResponse>('/api/v1/company/settings/brand', data)
  return response.data
}

export async function updateCompanyLogo(file: File) {
  const formData = new FormData()
  formData.append('file', file)
  const response = await api.put('/api/v1/company/settings/logo', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return response.data
}

export async function getCompanySharingSettings(){
  const response = await api.get<CompanySharingSettingsResponse>('/api/v1/company/settings/sharing')
  return response.data
}

export async function updateCompanySharingSettings(accessType: GeneralAccessType, accessLevel: ShareLevel){
  const data = {
    defaultAccessType: accessType,
    defaultShareLevel: accessLevel
  }
  const response = await api.put<CompanySharingSettingsResponse>('/api/v1/company/settings/sharing', data)
  return response.data
}