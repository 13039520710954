import { alpha, styled } from "@mui/material/styles";
import { CardActionArea, Grid, hexToRgb, RadioGroup, Stack, Typography, useTheme } from "@mui/material";
import useSettings from "../../../hooks/useSettings";
import Iconify from "../../Iconify";
import BoxMask from "../../settings/drawer/BoxMask";

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

// ----------------------------------------------------------------------


export default function ThemeSelector() {
  const theme = useTheme()
  const { themeMode, onChangeMode } = useSettings();
  const systemBg = themeMode === "light" ? "grey.200" : "grey.500"
  return (
    <RadioGroup name="themeMode" value={themeMode} onChange={onChangeMode}>
      <Grid dir="ltr" container spacing={2.5}>
        <Grid item xs={4}>
          <ThemeBox
            icon="ph:sun-duotone"
            name="Light Mode"
            mode="light"
            isSelected={"light" === themeMode}
            background="common.white"
          />
        </Grid>
        <Grid item xs={4}>
          <ThemeBox
            icon="ph:moon-duotone"
            name="Dark Mode"
            mode="dark"
            isSelected={"dark" === themeMode}
            background="grey.800"
          />
        </Grid>
        <Grid item xs={4}>
          <ThemeBox
            icon="ph:gear-duotone"
            name="System"
            mode="system"
            isSelected={"system" === themeMode}
            background={"grey.200"}
          />
        </Grid>
      </Grid>
    </RadioGroup>
  );
}

interface ThemeBoxProps {
  icon: string,
  name: string,
  mode: string,
  isSelected: boolean,
  background: string
}

function ThemeBox({ icon, name, mode, background, isSelected }: ThemeBoxProps) {
  return (
    <BoxStyle
      sx={{
        backgroundColor: background,
        ...(isSelected && {
          color: 'primary.main',
          borderColor: 'primary.main',
          boxShadow: (theme) => `0 0 16px -4px ${theme.palette.primary.main}`
        }),
      }}
    >
      <Stack spacing={2} alignItems="center">
        <Iconify icon={icon} width={36} height={36}/>
        <Typography variant="h4">{name}</Typography>
      </Stack>
      <BoxMask value={mode}/>
    </BoxStyle>
  )
}
