import { useQuery } from '@tanstack/react-query'
import { getFileAccessInfo, getGoogleDriveFiles } from '../../clients/GoogleDriveClient'

export function useGoogleDriveFileAccess(oauthAccountId: string | undefined, fileId: string | undefined){
  return useQuery({
    enabled: Boolean(oauthAccountId) && Boolean(fileId),
    queryKey: ["google-drive-file-access", oauthAccountId, fileId],
    queryFn: async() => getFileAccessInfo(oauthAccountId!!, fileId!!)
  })
}

export function useGoogleDriveFiles(oauthAccountId: string){
  return useQuery({
    queryKey: ["google-drive-files", oauthAccountId],
    queryFn: async() => getGoogleDriveFiles(oauthAccountId)
  })
}