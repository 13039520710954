import { BulkImportResultType, LinkedAssetsImportResult } from "../../../@types/asset";
import { Alert, Typography } from "@mui/material";

export default function InvalidFileError({ result }: { result: LinkedAssetsImportResult }) {
  if (result.type === BulkImportResultType.INVALID_FILE_FORMAT) {
    return (
      <Alert severity="info" variant="outlined">
        <Typography variant="small" component="div">Invalid file type</Typography>
        <Typography variant="small" color="text.deemphasized">Uploaded files must be in the CSV format.</Typography>
      </Alert>
    )
  }

  if (result.type === BulkImportResultType.INVALID_SCHEMA) {
    return (
      <Alert severity="info" variant="outlined" >
        <Typography variant="small" component="div">Missing fields</Typography>
        <Typography variant="small" color="text.deemphasized">Valid CSVs must include fields for "Name" and "Link". Add
          these columns and try again, or redownload the template file</Typography>
      </Alert>
    )
  }

  return <></>
}
