import { alpha, styled } from "@mui/material/styles";
import { Tab, TabProps } from "@mui/material";
import { LinkProps } from "react-router-dom";

const NavVerticalTab = styled(Tab)<TabProps & LinkProps & { component: React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>> }>(({ theme }) => ({
  minHeight: 48,
  alignItems: "center",
  justifyContent: "start",
  flexDirection: "row",
  marginRight: "8px !important",
  paddingLeft: 16,
  borderRadius: 8,
  "&.Mui-selected": {
    background: theme.palette.mode === "light" ? theme.palette.bg.warmGreyLight : theme.palette.background.neutral
  },
  "& svg": {
    fontSize: 18
  },
  "&:hover": {
    background: theme.palette.mode === "light" ? alpha(theme.palette.bg.warmGreyLight, .075) : alpha(theme.palette.background.neutral, .075)
  }
}))
NavVerticalTab.defaultProps = {
  ...NavVerticalTab.defaultProps,
  iconPosition: "start",
}
export default NavVerticalTab