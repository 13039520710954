import { Box, CircularProgress, Dialog, Stack } from '@mui/material'
import { Timeline, timelineContentClasses, timelineItemClasses } from '@mui/lab'
import { TimelineEvent } from '../../../../@types/analytics/timeline-events'
import { useUserTimeline } from '../../../../hooks/analytics/useUserAnalytics'
import { UserTimelineEvent } from './UserTimelineEvent'

export interface UserTimelineDialogProps{
  userId: string,
  open: boolean,
  onClose: () => void
}
export function UserTimelineDialog({userId, open, onClose}: UserTimelineDialogProps){
  const { data, isLoading } = useUserTimeline(userId, 500, open)
  const events = data?.events || []
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
    >
      <Box p={4}>
        {isLoading && (
         <Stack p={3} direction="row" justifyContent="center">
           <CircularProgress />
         </Stack>
        )}

        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            [`& .${timelineContentClasses.root}`]: {
              paddingBottom: 1,
            },
          }}
        >
          {
            events.map((it: TimelineEvent) => {
              return (
                <UserTimelineEvent key={it.createdAt + ''} event={it} />
              )
            })
          }
        </Timeline>
      </Box>
    </Dialog>
  )
}