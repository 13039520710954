import { Asset, AssetStorageType } from '../../../@types/asset.ts'
import { AssetContentState } from '../../../@types/asset-content.ts'
import { RawFileInternalStorageMissing } from './missing/RawFileInternalStorageMissing.tsx'
import { RawFileExternalStorageMissing } from './missing/RawFileExternalMissing.tsx'

export function RawFileMissingPivot({ asset, state }: { asset: Asset, state: AssetContentState }){
  if(asset.storageType == AssetStorageType.INTERNAL_S3){
    return <RawFileInternalStorageMissing asset={asset} state={state}/>
  }

  if(asset.storageType == AssetStorageType.EXTERNAL_HTTP){
    return <RawFileExternalStorageMissing asset={asset} state={state}/>
  }

  return <></>
}