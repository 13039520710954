import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Neue Haas Grotesk Display Pro'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 450,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 500,
    lineHeight: "36px",
    fontSize: 32,
    letterSpacing: 2,
    // ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 500,
    lineHeight: "28px",
    fontSize: 24,
    // ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 500,
    lineHeight: "22px",
    fontSize: 18,
    // ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 500,
    lineHeight: "20px",
    fontSize: 16,
    // ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    // ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    // ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 500,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
  standard: {
    fontWeight: 450,
    fontSize: 14,
    lineHeight: "19px"
  },
  standardHighlight: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "19px"
  },
  small: {
    fontWeight: 450,
    fontSize: 12,
    lineHeight: "15px"
  },
  smallHighlight: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "15px"
  },
  tiny: {
    fontWeight: 450,
    fontSize: 10,
    lineHeight: "14px"
  },
  tinyHighlight: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: "14px"
  },
} as const;

declare module '@mui/material/styles' {
  interface TypographyVariants {
    standard: React.CSSProperties;
    standardHighlight: React.CSSProperties;
    small: React.CSSProperties;
    smallHighlight: React.CSSProperties;
    tiny: React.CSSProperties;
    tinyHighlight: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    standard?: React.CSSProperties;
    standardHighlight?: React.CSSProperties;
    small?: React.CSSProperties;
    smallHighlight?: React.CSSProperties;
    tiny?: React.CSSProperties;
    tinyHighlight?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    standard: true;
    standardHighlight: true;
    standardLink: true;
    small: true;
    smallHighlight: true;
    smallLink: true;
    tiny: true;
    tinyHighlight: true;
    tinyLink: true;
  }
}

export default typography;
