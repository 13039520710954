// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// components
import ThemeSettings from './components/settings'
import ScrollToTop from './components/ScrollToTop'
import { ProgressBarStyle } from './components/ProgressBar'
import NotistackProvider from './components/NotistackProvider'
import MotionLazyContainer from './components/animate/MotionLazyContainer'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './utils/queryClient'

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <QueryClientProvider client={queryClient}>
            <NotistackProvider>
              <ProgressBarStyle/>
              <ScrollToTop/>
              <Router/>
            </NotistackProvider>
          </QueryClientProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
