import { Stack } from '@mui/material'
import { InternalFormGroupUser } from './GroupMembersField'
import { AddPeopleItem } from './AddPeopleItem'
import { FilteredGroupMemberList } from './FilteredGroupMemberList'
import { NotInThisGroupList } from './NotInThisGroupList'

type SearchResultsProps = {
  members: InternalFormGroupUser[],
  searchTerm: string,
  fieldName: string
}
export function SearchResults({ members, searchTerm, fieldName }: SearchResultsProps) {
  return (
    <Stack>
      <AddPeopleItem fieldName={fieldName} />
      <FilteredGroupMemberList members={members} searchTerm={searchTerm} fieldName={fieldName} />
      <NotInThisGroupList members={members} searchTerm={searchTerm} fieldName={fieldName} />
    </Stack>
  )
}
