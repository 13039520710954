import { Box, Typography } from "@mui/material";
import ProfileResetPasswordForm from "../../components/profile/security/ProfileResetPasswordForm";
import Page from "../../components/Page";

export default function ProfileSecurity() {

  return (
    <Page title="Security">
      <Box mb={12}>
        <Typography
          variant="h2"
          mb={4}
        >
          Security
        </Typography>

        <ProfileResetPasswordForm />
      </Box>
    </Page>
  )
}