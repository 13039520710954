import { useContext } from "react";
import { AssetUploadContext } from "../contexts/AssetUploadContext";
import AssetUploadFinalizeSingle from "./AssetUploadFinalizeSingle";
import AssetUploadFinalizeMultiple from "./AssetUploadFinalizeMultiple";
import { Navigate } from "react-router-dom"

export default function AssetUploadFinalize(){
  const { uploads } = useContext(AssetUploadContext)

  if(uploads.length === 0){
    return <Navigate to="/assets/upload"/>
  }

  return uploads.length === 1 ?
    <AssetUploadFinalizeSingle /> :
    <AssetUploadFinalizeMultiple />
}