import { SidebarPreviewThumbnail } from './SidebarPreviewThumbnail.tsx'
import { Box, Stack } from '@mui/material'
import { AssetPreview } from '../../../../@types/asset.ts'
import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room.ts'
import { useActivePreviewContext } from './context/ActivePreviewContext.tsx'
import { useEffect } from 'react'

type SidebarPreviewListProps = {
  asset: HostedContentRoomAsset;
  previews: AssetPreview[];
}

export function SidebarPreviewSection({ asset, previews }: SidebarPreviewListProps) {
  const { leftPanePreviewRefs } = useActivePreviewContext()

  // effects
  useEffect(() => {
    leftPanePreviewRefs.current = leftPanePreviewRefs.current.slice(0, previews.length)
  }, [previews.length])

  return (
    <Stack spacing={3} p={3}>
      {previews && previews.map((it, idx) => {
        return (
          <Box
            key={it.assetPreviewId}
            ref={(el: HTMLElement) => leftPanePreviewRefs.current[idx] = { preview: it, el }}
          >
            <SidebarPreviewThumbnail
              key={it.assetPreviewId}
              asset={asset}
              preview={it}
            />
          </Box>
        )
      })}
    </Stack>
  )
}