import { Asset } from '../../../@types/asset'
import { AssetContentState } from '../../../@types/asset-content'
import { RawFileGenericError } from './errors/RawFileGenericError'
import { RawFileNotFoundError } from './errors/RawFileNotFoundError.tsx'
import { RawFileForbiddenError } from './errors/RawFileForbiddenError.tsx'
import { RawFileUnauthenticatedError } from './errors/RawFileUnauthenticatedError.tsx'
import { RawFileNoValidAccountError } from './errors/RawFileNoValidAccountError.tsx'
import { AssetContentStateErrorCode } from '../../../@types/asset-content-errors.ts'
import { RawFileMissingIdError } from './errors/RawFileMissingIdError.tsx'

export function RawFileErrorPivot({ asset, state }: { asset: Asset, state: AssetContentState }) {
  if(state.errorCode == AssetContentStateErrorCode.NO_VALID_ACCOUNT){
    return <RawFileNoValidAccountError asset={asset} state={state} />
  }

  if(state.errorCode == AssetContentStateErrorCode.UNAUTHENTICATED){
    return <RawFileUnauthenticatedError asset={asset} state={state} />
  }

  if(state.errorCode == AssetContentStateErrorCode.FORBIDDEN){
    return <RawFileForbiddenError asset={asset} state={state} />
  }

  if (state.errorCode == AssetContentStateErrorCode.NOT_FOUND) {
    return <RawFileNotFoundError asset={asset} state={state} />
  }

  if (state.errorCode == AssetContentStateErrorCode.MISSING_ID) {
    return <RawFileMissingIdError asset={asset} state={state} />
  }

  // generic unknown error
  return (
    <RawFileGenericError asset={asset} state={state} />
  )
}