import TopUsers from '../common/TopUsers'
import { Asset } from '../../../../../@types/asset'
import { TimeRange } from '../../../../../@types/analytics/analytics-common'
import { useState } from 'react'
import AllTopUsersDialog from '../common/AllTopUsersDialog'
import { TopViewer } from '../../../../../@types/analytics/views'
import { useTopViewers } from '../../../../../hooks/analytics/useAssetViewAnalytics'

export default function TopViewers({ asset, range }: { asset: Asset, range: TimeRange }) {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { data } = useTopViewers(asset.assetId, 5, { range })
  const { data: allViewersData } = useTopViewers(asset.assetId, 5, { range }, modalOpen)
  const allViewers = allViewersData?.viewers.map(toUserListItem)
  const viewers = data?.viewers.map(toUserListItem)
  return (
    <>
      <TopUsers
        title='Top Viewers'
        list={viewers}
        moreResults={data?.hasMore}
        onSeeAllClick={() => setModalOpen(true)}
      />

      <AllTopUsersDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        users={allViewers}
      />

    </>

  )
}

function toUserListItem(it: TopViewer){
  return {
    userId: it.userId,
    firstName: it.firstName,
    lastName: it.lastName,
    metricValue: it.numViews,
    metricLabel: it.numViews === 1 ? "view" : "views",
    metricIcon: "eva:search-fill"
  }
}