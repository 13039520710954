import { TimeRange } from '../../@types/analytics/analytics-common'
import { useQuery } from '@tanstack/react-query'
import {
  getBucketedDownloads,
  getDownloadRank,
  getTopDownloaders,
  getTotalDownloads,
} from '../../clients/AssetAnalyticDownloadClient'
import { getBucketedViews, getTopViewers, getTotalViews, getViewRank } from '../../clients/AssetAnalyticViewClient'

export function useAssetTotalViews(assetId: string, options: { range: TimeRange }) {
  return useQuery({
    queryKey: ['total-views', assetId, options],
    queryFn: async () => getTotalViews(assetId, options),
    refetchOnWindowFocus: false,
  })
}

export function useViewRank(assetId: string, options: { range: TimeRange }) {
  return useQuery({
    queryKey: ["view-rank", assetId, options],
    queryFn: async () => getViewRank(assetId, options),
    refetchOnWindowFocus: false,
  });
}

export function useBucketedViews(assetId: string, options: { range: TimeRange }){
  return useQuery({
    queryKey: ['bucketed-views', assetId, options],
    queryFn: async () => getBucketedViews(assetId, options),
    refetchOnWindowFocus: false,
  })
}

export function useTopViewers(assetId: string, limit: number, options: { range: TimeRange }, enabled: boolean = true){
  return useQuery({
    queryKey: ['top-viewers', assetId, limit, options],
    queryFn: async () => getTopViewers(assetId, options, limit),
    refetchOnWindowFocus: false,
    enabled: enabled
  })
}