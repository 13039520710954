import { useParams } from "react-router";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getUser } from "../../clients/UserClient";
import { User } from "../../@types/user";
import { EditUserForm } from "../../components/admin/user/EditUserForm";
import { useNavigate } from "react-router-dom";
import Iconify from "../../components/Iconify";
import Page from "../../components/Page";

export default function EditUser() {
  const { userId } = useParams()
  const [user, setUser] = useState<User | undefined>()
  const navigate = useNavigate()

  useEffect(() => {
    if (userId === "new" || !userId) {

    } else {
      getUser(userId).then((user) => setUser(user))
    }
  }, [userId])

  const isNew = userId === "new"
  const title = isNew ? "Create New User" : "Edit User"

  return (
    <Page title="Edit User">
      <Box mb={12}>
        <Button
          variant="text"
          size="small"
          startIcon={<Iconify icon={"eva:chevron-left-fill"}/>}
          onClick={() => navigate("/admin/users")}
        >
          Back to user list
        </Button>
        <Typography
          variant="h2"
          mb={4}
          mt={2}
        >
          {title}
        </Typography>

        <EditUserForm user={user}/>
      </Box>
    </Page>
  )
}