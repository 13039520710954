import api from '@api'
import {
  AssetSharesResponse,
  GeneralAccessType,
  ShareLevel,
  SharingSearchResponse,
  UpdateAssetShareItem,
} from '../@types/sharing'
import orderBy from 'lodash/orderBy'

export const getSharesForAsset = async (assetId: string) => {
  const response = await api.get<AssetSharesResponse>(`/api/v1/assets/${assetId}/shares`)
  const sortedShares = orderBy(response.data.shares, "name")
  return { ...response.data, shares: sortedShares }
}

export const updateAssetShares = async (
  assetId: string,
  shares: UpdateAssetShareItem[],
  generalAccessType: GeneralAccessType,
  generalAccessShareLevel: ShareLevel | null
) => {
  const data = { shares, generalAccessType, generalAccessShareLevel }
  const response = await api.put(`/api/v1/assets/${assetId}/shares`, data)
  return response.data
}

export const searchForShareableItems = async (term: string, limit: number = 10) => {
  const response = await api.post<SharingSearchResponse>('/api/v1/sharing/search', { term: term || '', limit })
  return response.data
}