import { MenuItem, Stack } from "@mui/material";
import { RHFSelect, RHFTextField } from "../hook-form";
import { AssetFileType } from "../../@types/asset";

const fileTypes = Object.keys(AssetFileType)
  .filter(it => isNaN(Number(it)))
  // @ts-ignore
  .map((it => ({ label: it, value: Number(AssetFileType[it])})))
  .sort((a,b) => a.label.localeCompare(b.label))

export default function RequestNewAssetForm() {
  return (
    <Stack direction="column" spacing={4}>
      {/*<RHFSelect*/}
      {/*  name="fileType"*/}
      {/*  label="File Type"*/}
      {/*  InputLabelProps={{ shrink: true }}*/}
      {/*  SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}*/}
      {/*>*/}
      {/*  {fileTypes.map((option) => (*/}
      {/*    <MenuItem*/}
      {/*      key={option.value}*/}
      {/*      value={option.value}*/}
      {/*      sx={{*/}
      {/*        mx: 1,*/}
      {/*        my: 0.5,*/}
      {/*        borderRadius: 0.75,*/}
      {/*        typography: 'body2',*/}
      {/*        textTransform: 'capitalize',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {option.label}*/}
      {/*    </MenuItem>*/}
      {/*  ))}*/}
      {/*</RHFSelect>*/}

      <RHFTextField
        name="fileType"
        label="Type of file wanted (whitepaper, case study, testimonial, etc)"
        variant="filled"
      />

      <RHFTextField
        name="message"
        label="Please let us know the details of the asset you would like."
        multiline
        variant="filled"
        rows={5}
      />
    </Stack>
  )
}