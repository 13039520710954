import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import { Asset, AssetPreview as AssetPreviewDto, AssetPreviewType } from "../@types/asset";
import { useRef, useState } from "react";
import Image from './Image';
import { CarouselArrowIndex } from "./carousel";
import LightboxModal from "./LightboxModal";
import { srcForPreviewItem } from "./asset-search/images";
import { ScrollPosition } from "react-lazy-load-image-component";


export type AssetPreviewProps = {
    asset: Asset,
    previewType?: AssetPreviewType,
    scrollPosition?: ScrollPosition
}

export function AssetPreview({asset, previewType = AssetPreviewType.THUMBNAIL_MD, scrollPosition}: AssetPreviewProps) {
    const [openLightbox, setOpenLightbox] = useState(false);
    const [selectedImage, setSelectedImage] = useState<number>(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const slider = useRef<Slider | null>(null);

    const settings = {
        speed: 320,
        dots: false,
        arrows: false,
        slidesToShow: 1,
        draggable: false,
        slidesToScroll: 1,
        adaptiveHeight: true,
        beforeChange: (current: number, next: number) => setCurrentIndex(next),
    };

    const handlePrevious = () => {
        slider.current?.slickPrev();
    };

    const handleNext = () => {
        slider.current?.slickNext();
    };

    const assetPreviews = asset.previews.assetPreviews
    const lightboxImages = assetPreviews.map(item => srcForPreviewItem(item.assetPreviewId, AssetPreviewType.THUMBNAIL_XL))
    const handleOpenLightbox = (toSelect: AssetPreviewDto) => {
        const selectedImage = assetPreviews.findIndex((item) => item.assetPreviewId === toSelect.assetPreviewId);
        setOpenLightbox(true);
        setSelectedImage(selectedImage);
    };

    return (
        <Box>
            <Box sx={{p: 1}}>
                <Box sx={{zIndex: 0, borderRadius: 2, overflow: 'hidden', position: 'relative'}}>
                    <Slider {...settings} ref={slider}>
                        {assetPreviews.map((item) => (
                            <Image
                                key={item.assetPreviewId}
                                ratio="1/1"
                                backgroundType="contain"
                                src={srcForPreviewItem(item.assetPreviewId, previewType)}
                                onClick={() => handleOpenLightbox(item)}
                                sx={{cursor: 'zoom-in'}}
                                scrollPosition={scrollPosition}
                            />
                        ))}
                    </Slider>
                    {
                      assetPreviews.length > 1 && (
                            <CarouselArrowIndex
                                index={currentIndex}
                                total={assetPreviews.length}
                                onNext={handleNext}
                                onPrevious={handlePrevious}
                            />
                        )
                    }

                </Box>
            </Box>

            <LightboxModal
                animationDuration={320}
                images={lightboxImages}
                mainSrc={lightboxImages[selectedImage]}
                photoIndex={selectedImage}
                setPhotoIndex={setSelectedImage}
                isOpen={openLightbox}
                onCloseRequest={() => setOpenLightbox(false)}
                onMovePrevRequest={() => {
                    handlePrevious();
                    setSelectedImage((selectedImage + lightboxImages.length - 1) % lightboxImages.length);
                }}
                onMoveNextRequest={() => {
                    handleNext();
                    setSelectedImage((selectedImage + 1) % lightboxImages.length);
                }}
            />
        </Box>
    )
}