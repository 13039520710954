// scroll bar
import 'simplebar/src/simplebar.css'

// lightbox
import 'react-image-lightbox/style.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'

// slick-carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'

// Yup
import './yupSetup'

// routing
import { history } from './utils/history'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'

// contexts
import { SettingsProvider } from './contexts/SettingsContext'
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext'

//
import App from './App'

// react-date-range
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

// sentry
import * as Sentry from '@sentry/react'

// determine sentry environment (short term hack)
const ENVIRONMENTS = {
  'staging.getmasset.com': 'staging',
  'app.getmasset.com': 'prod',
} as any
const environment = ENVIRONMENTS[window.location.hostname] || 'unknown'

// if we are in a live environment, init sentry
if (environment !== 'unknown') {
  Sentry.init({
    dsn: 'https://938df112df024d2eb5d9cd39228cf8d1@o4504172684050432.ingest.sentry.io/4505050149617664',
    environment,
  })
}


// ----------------------------------------------------------------------
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <HelmetProvider>
    <SettingsProvider>
      <CollapseDrawerProvider>
        <HistoryRouter history={history}>
          <App />
        </HistoryRouter>
      </CollapseDrawerProvider>
    </SettingsProvider>
  </HelmetProvider>,
)
