import { Box, Button, Stack, Typography } from '@mui/material'
import ReactImage from '../../components/Image'
import { ChangeEvent, useState } from 'react';
import { styled } from '@mui/material/styles'
import { useUpdateCompanyLogo } from '../../hooks/useCompanySettings'

const MAX_FILE_SIZE = 1024 * 1024 * 5

export function CompanyLogoUpdate() {

  const {mutateAsync: uploadLogo} = useUpdateCompanyLogo()
  const [path, setPath] = useState("/api/v1/company/settings/logo")
  const [error, setError] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget
    const file = input.files && input.files[0]
    if (file == null) return
    if(file.size > MAX_FILE_SIZE) return

    const img = new Image()
    img.src = window.URL.createObjectURL(file)
    img.onload = () => {
      const width = img.naturalWidth
      const height = img.naturalHeight

      // unload it
      window.URL.revokeObjectURL(img.src)

      // check its dimensions
      if (width < 512 || height < 512) {
        input.value = ''
        setError(true)
      } else {
        setError(false)
        handleUpload(file)
      }
    }
  }

  const handleUpload = async (file: File) => {
    await uploadLogo(file)
    setPath(`/api/v1/company/settings/logo?bust=${Date.now()}`)
  }

  return (
    <Box>
      <Typography variant='h4' mb={2}>Company Logo</Typography>
      <Stack spacing={3}>
        <ReactImage
          src={path}
          sx={{
            height: 200,
            width: 200,
            borderRadius: 2,
          }}
        />

        <Box>
          <Button
            variant='outlined'
            color='inherit'
            component='label'
            role={undefined}
          >
            Upload new logo
            <VisuallyHiddenInput
              type='file'
              accept='image/*'
              onChange={handleChange}
            />
          </Button>
        </Box>

        <Typography variant='small' color='text.deemphasized'>
          Note: Company logos should be square, with dimensions of 512x512 and a maximum file size of 5mb.
        </Typography>

        { error && (
          <Typography variant='small' color='error'>
            The image provided was invalid. Please ensure it meets the minimum requirements and try again
          </Typography>
        )}
      </Stack>
    </Box>

  )
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})