import { Asset } from '../../@types/asset'
import { AssetContentState } from '../../@types/asset-content'
import { Stack, Typography } from '@mui/material'
import { ReadinessContainer } from './ReadinessContainer'
import AssetPalettesCmp from '../asset-palette/AssetPalettes'

export function PaletteReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  return (
    <ReadinessContainer
      icon="ph:palette"
      state={state}
      title="Palette"
      subtitle="Allowing search and discovery by color by extracting color palettes"
    >
      <AssetPalettesCmp asset={asset} />
    </ReadinessContainer>
  )
}