import { Box, Button, Stack } from '@mui/material'
import { SharingItem, SharingSearchResult } from '../../../../@types/sharing'
import { ShareItemDisplayInfo } from './ShareItemDisplayInfo'
import { useShareField } from './AssetShareFieldHooks'
import { SharingHoverableItem } from './SharingHoverableItem'

export function ShareItemToAdd({ item }: { item: SharingSearchResult }) {
  const { addItem } = useShareField()
  const handleAdd = () => addItem(item)
  return (
    <SharingHoverableItem>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <ShareItemDisplayInfo share={item as SharingItem} />
        <Box>
          <Button
            size='small'
            variant='outlined'
            onClick={handleAdd}
          >
            Add
          </Button>
        </Box>
      </Stack>
    </SharingHoverableItem>
  )
}
