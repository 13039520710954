import { Box, Button, Dialog, Divider, IconButton, Stack, Typography } from "@mui/material";
import { Asset } from "../../@types/asset";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { flagAssetAsOutdated } from "../../clients/AssetClient";
import Iconify from "../Iconify";
import { AssetInlinePreview } from "./AssetInlinePreview";
import { FormProvider, RHFTextField } from "../hook-form";
import { LoadingButton } from "@mui/lab";

const FlagAssetFormSchema = Yup.object().shape({
  message: Yup.string().nullable()
})
const formValidationResolver = yupResolver(FlagAssetFormSchema)
type FlagAssetFormValues = { message: string | '' }
const DEFAULT_VALUES = { message: '' }

type AssetFlagOutdatedButtonProps = { asset: Asset }

export function AssetFlagOutdatedButton({ asset } : AssetFlagOutdatedButtonProps){
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const methods = useForm<FlagAssetFormValues>({resolver: formValidationResolver, defaultValues: DEFAULT_VALUES });
  const {handleSubmit, reset} = methods
  const {enqueueSnackbar} = useSnackbar();

  // reset the form whenever the form opens
  useEffect(() => {
    if(open){
      setLoading(false)
      reset(DEFAULT_VALUES)
    }
  }, [open])

  // handle the form submission
  const onSubmit = async (data: FlagAssetFormValues) => {
    setLoading(true)
    await flagAssetAsOutdated(asset.assetId, data)
    enqueueSnackbar((<>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="smallHighlight">👍 Success!</Typography>
        <Typography component="div" variant="small" noWrap>Your request to flag the asset as outdated has been sent</Typography>
      </Stack>
    </>))
    setOpen(false)
  }

  return (
    <>
      <Button
        variant="outlined"
        fullWidth
        size="small"
        onClick={() => setOpen(true)}
        startIcon={<Iconify icon="eva:message-square-outline" />}
      >
        Flag as Outdated
      </Button>

      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setOpen(false)}
      >

        <Box p={7}>
          <Box sx={{position: "absolute", right: 0, top: 0, mr: 1, mt: 1}}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon="eva:close-fill"/>
            </IconButton>
          </Box>

          <Typography variant="h2" >Flag Outdated Asset</Typography>
          <Typography variant="standard" >Flagging an outdated asset lets admins know that this asset is no longer relevant and needs to be updated or removed.</Typography>

          <Box sx={{mb: 5}}/>

          <AssetInlinePreview asset={asset} />

          <Box sx={{mb: 5}}/>

          <FormProvider methods={methods}>
            <Stack direction="column" spacing={4}>
              <RHFTextField
                name="message"
                label="Please let us know any additional details."
                multiline
                variant="filled"
                rows={5}
              />
            </Stack>
          </FormProvider>

          <Divider sx={{my: 3}}/>

          <Stack spacing={3} >
            <LoadingButton
              loadingPosition="start"
              loading={loading}
              fullWidth={true}
              startIcon={<Iconify icon="eva:arrow-ios-forward-fill"/>}
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Flag Asset
            </LoadingButton>
            <Button
              fullWidth={true}
              variant="text"
              sx={{bgcolor: "white"}}
              onClick={() => {
                setOpen(false)
              }}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  )
}