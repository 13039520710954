import { Asset } from '../../../@types/asset'
import { TimeRange } from '../../../@types/analytics/analytics-common'
import { Grid } from '@mui/material'
import TotalShares from '../../asset-detail/tabs/analytics/share/TotalShares'
import ShareRank from '../../asset-detail/tabs/analytics/share/ShareRank'
import BucketedShares from '../../asset-detail/tabs/analytics/share/BucketedShares'
import TopSharers from '../../asset-detail/tabs/analytics/share/TopSharers'

export default function ShareMetricsTableRowLayout({ asset, range }: { asset: Asset, range: TimeRange }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TotalShares asset={asset} range={range} />
      </Grid>
      <Grid item xs={6}>
        <ShareRank asset={asset} range={range} />
      </Grid>
      <Grid item xs={6}>
        <BucketedShares asset={asset} range={range} />
      </Grid>
      <Grid item xs={6}>
        <TopSharers asset={asset} range={range} />
      </Grid>
    </Grid>
  )
}
