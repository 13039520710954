import { Asset, AssetPermission } from '../../../@types/asset'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useAssetImageSummary, useUpdateImageSummaryContents, useEditOCRContents } from '../../../hooks/useAssetContent'
import { fDateTime } from '../../../utils/formatTime'
import { useTheme } from '@mui/material/styles'
import { useRef, useState } from 'react';
import Iconify from '../../Iconify.tsx'
import { LoadingButton } from '@mui/lab'
import { VisibleForPermission } from '../../../guards/VisibleForPermission.tsx'

interface ImageSummaryContentsProps {
  asset: Asset
}

export function ImageSummaryPopover({ asset }: ImageSummaryContentsProps) {

  // hooks
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>()
  const { data: summary } = useAssetImageSummary(asset.assetId)
  const { mutateAsync: updateImageSummary, isLoading: saving } = useUpdateImageSummaryContents()
  const [open, setOpen] = useState(false)
  const [mode, setMode] = useState<'read' | 'edit'>('read')

  // handlers
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleEdit = () => setMode('edit')
  const handleEditCancel = () => setMode('read')
  const handleEditSave = () => {
    const data = {
      assetId: asset.assetId,
      assetContentId: summary!!.assetContentId,
      contents: inputRef?.current?.value || '',
    }
    updateImageSummary(data).then(() => setMode('read'))
  }

  return (
    <>
      <Button
        variant='text'
        size='small'
        onClick={handleOpen}
      >
        View Summary
      </Button>

      <Dialog
        open={open}
        maxWidth='md'
        fullWidth={true}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Typography variant='h2' paragraph>Summary (Auto-Generated)</Typography>
        </DialogTitle>

        <DialogContent>
          { mode == "read" && (
            <Stack spacing={3}>
              {summary && (<Typography variant='small' component='div'>
                Generated on {fDateTime(summary.createdAt)}
              </Typography>)}
              <Box
                sx={{
                  pl: 2,
                  borderLeft: `4px solid ${theme.palette.primary.darker}`,
                }}
              >
                <Typography variant='standard' sx={{ whiteSpace: 'pre-line' }}>{summary?.summary}</Typography>
              </Box>

              <VisibleForPermission permission={AssetPermission.EDIT_METADATA} level={asset.accessLevel}>
                <Stack
                  direction='row'
                  spacing={1}
                >
                  <Button
                    variant='outlined'
                    size='small'
                    startIcon={<Iconify icon='ph:pencil' />}
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>

                </Stack>
              </VisibleForPermission>
            </Stack>
          )}

          {
            mode == "edit" && (
              <Stack spacing={3}>
                <TextField
                  autoFocus={true}
                  inputRef={inputRef}
                  multiline={true}
                  defaultValue={summary?.summary}
                />

                <Stack
                  direction='row'
                  spacing={1}
                >
                  <LoadingButton
                    size='small'
                    onClick={handleEditSave}
                    loading={saving}
                    loadingPosition='start'
                    startIcon={<Iconify icon='ph:floppy-disk' />}
                    variant='outlined'
                  >
                    Save
                  </LoadingButton>
                  <Button
                    variant='outlined'
                    size='small'
                    color='inherit'
                    onClick={handleEditCancel}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            )
          }

        </DialogContent>

      </Dialog>
    </>
  )
}