import { createContext, ReactNode, useState } from 'react'

type IDialogContext = {
  open: boolean,
  setOpen: (open: boolean) => void
}
const DialogContext = createContext<IDialogContext>({} as IDialogContext)

function DialogProvider({children}: {children: ReactNode}){
  const [open, setOpen] = useState<boolean>(false)
  return (
    <DialogContext.Provider
      value={{
        open,
        setOpen
      }}
    >
      {children}
    </DialogContext.Provider>
  )
}

export {DialogContext, DialogProvider}