import { useFormContext } from 'react-hook-form'
import { InternalFormShareItem } from './AssetShareField'
import { ShareLevel, SharingSearchResult } from '../../../../@types/sharing'
import orderBy from 'lodash/orderBy'
import { useContext } from 'react'
import { AssetShareFieldContext } from './AssetShareFieldContext'


export const useShareField = () => {
  const { getValues, setValue } = useFormContext()
  const { fieldName, setSearchTerm } = useContext(AssetShareFieldContext)

  /**
   * Handles the removal of a share item
   */
  const removeItem = (item: InternalFormShareItem) => {
    const values = getValues(fieldName) as InternalFormShareItem[]
    const withoutShare = values.filter(it => !selector(item, it))
    setValue(fieldName, withoutShare, { shouldDirty: true })
  }

  /**
   * Handles the changing of the share level for an item
   */
  const changeShareLevel = (item: InternalFormShareItem, shareLevel: ShareLevel) => {
    const values = getValues(fieldName) as InternalFormShareItem[]
    const entry = values.find(it => selector(item, it))
    const updatedEntry = { ...entry, shareLevel }
    const updatedValues = values.map(it => selector(item, it) ? updatedEntry : it)
    setValue(fieldName, updatedValues, { shouldDirty: true })
  }

  /**
   * Handles the addition of a new share item
   */
  const addItem = (share: SharingSearchResult) => {
    const values = getValues(fieldName)
    const newValue = orderBy([...values, internalFormShareForSharingItem(share, ShareLevel.VIEW)], 'share.name', 'asc')
    setValue(fieldName, newValue, { shouldDirty: true })
    setSearchTerm('')
  }

  return { removeItem, changeShareLevel, addItem }
}


export function internalFormShareForSharingItem(share: SharingSearchResult, level: ShareLevel) {
  return {
    uniqueId: share.uniqueId,
    shareType: share.shareType,
    shareLevel: level,
    share: { ...share, shareLevel: level },
  }
}

const selector = (toFind: InternalFormShareItem, itr: InternalFormShareItem) =>
  toFind.uniqueId == itr.uniqueId