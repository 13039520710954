import { styled } from '@mui/material/styles'
import { TableRow } from '@mui/material'

type StripedTableRowProps = {
  isStriped: boolean
}
export const StripedTableRow = styled(
  TableRow,
  { shouldForwardProp: (prop) => prop !== 'isStriped' },
)<StripedTableRowProps>(({ theme, isStriped }) => ({
  backgroundColor: (isStriped ? theme.palette.table.zebraRowBackground : theme.palette.table.nonZebraRowBackground) + "!important",
}))