import { styled } from "@mui/material/styles";
import { Card, lighten, Typography } from "@mui/material";
import { RequestNewAssetButton } from "./RequestNewAssetButton";
import Image from "../Image";

const RootStyle = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#d0e7e0" : "#2d453e",
  padding: theme.spacing(4, 2),
  textAlign: "center"
}));

export default function RequestNewAssetCard(){
  return (
    <RootStyle sx={{}}>
      <Image
        disabledEffect
        visibleByDefault
        alt="empty content"
        src={'/assets/illustrations/illustration_empty_content.svg'}
        sx={{  mt: 1, mb: 3 }}
      />

      <Typography variant="h4" sx={{mb: 3}}>
        Not finding that specific thing you need?
      </Typography>

      <RequestNewAssetButton />
    </RootStyle>
  )
}