import { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import { fDate } from '../../../../utils/formatTime'

export function GenericIntegrationDescription({name, description, createdDate} : {name: ReactNode, description: ReactNode, createdDate: Date}){
  return (
    <Box>
      <Typography variant="h3">{name}</Typography>
      <Typography variant="standard" paragraph>Added on {fDate(createdDate)}</Typography>
      <Typography variant="standard">{description}</Typography>
    </Box>
  )
}