export type PasswordResetDto = {
  newPassword: string
  newPasswordConfirmation: string
}

export type PasswordResetTokenResponse = {
  status : PasswordResetStatus
}

export enum PasswordResetStatus {
  VALID = "VALID",
  EXPIRED = "EXPIRED",
  NOT_FOUND = "NOT_FOUND"
}