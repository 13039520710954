import api from '@api'
import {
  AdminAnalyticsExportResponse, AdminAnalyticsRequestOptions,
  AdminAnalyticsSearchResponse, AdminAnalyticsSummariesRequestOptions, AdminAnalyticsSummariesResponse, GraphSummary,
} from '../@types/analytics/analytics-asset'
import { parseJSON } from 'date-fns'

export const getAssetAnalytics = async (options: AdminAnalyticsRequestOptions) => {
  const response = await api.post<AdminAnalyticsSearchResponse>('/api/v1/analytics/assets/search', options)
  return response.data
}

export const getAssetSummaries = async (options: AdminAnalyticsSummariesRequestOptions) => {
  const response = await api.post<AdminAnalyticsSummariesResponse>('/api/v1/analytics/assets/graph/summary', options)
  const entries = Object.entries(response.data.summaries).map(entry => [entry[0], transformSummary(entry[1])])
  const result = { summaries: Object.fromEntries(entries) } as AdminAnalyticsSummariesResponse
  return result
}

export const createAssetAnalyticsExport = async (options: AdminAnalyticsRequestOptions) => {
  const response = await api.post<AdminAnalyticsExportResponse>('/api/v1/analytics/assets/search/export', options)
  return response.data
}


function transformSummary(summary: GraphSummary) {
  return {
    ...summary,
    entries: summary.entries.map(it => ({
      count: it.count,
      bucketStart: parseJSON(it.bucketStart),
      bucketEnd: parseJSON(it.bucketEnd),
    })),
  }
}