import { Box, Button, Drawer, Stack, Typography } from '@mui/material'
import AssetMetadataCmp from '../../asset-metadata/AssetMetadataCmp'
import DefaultUpdateableFields from '../../asset-metadata/DefaultUpdateableFields'
import UpdateAssetTags from '../../../pages/asset/UpdateAssetTags'
import { Asset } from '../../../@types/asset'
import { useFormContext } from 'react-hook-form'
import { AssetUpdateFormData } from './AssetUpdateFormProvider'
import ActionBar from '../../action/ActionBar'
import { AssetUploadSharing } from '../../asset-upload/AssetUploadSharing'

type AssetUpdateFormProps = {
  assetUnderEdit: Asset | null,
  onSubmit?: (data: AssetUpdateFormData) => Promise<void>,
  onCancel?: () => Promise<void>
}
export default function AssetUpdateForm({
                                          assetUnderEdit,
                                          onSubmit,
                                          onCancel,
                                        }: AssetUpdateFormProps) {
  return (
    <Stack spacing={6}>
      <Box>
        <Typography variant='h6' mb={3}> Asset Info </Typography>
        <AssetMetadataCmp asset={assetUnderEdit} />
        <Box mb={4} />
        <DefaultUpdateableFields asset={assetUnderEdit} />
      </Box>
      <AssetUploadSharing subtext="Who has access to see this asset by default?"/>
      <UpdateAssetTags />
      <ActionDrawer onSubmit={onSubmit} onCancel={onCancel} />
    </Stack>
  )
}

type ActionDrawerProps = {
  onSubmit?: (data: AssetUpdateFormData) => Promise<void>
  onCancel?: () => Promise<void>
}
const ActionDrawer = ({ onSubmit, onCancel }: ActionDrawerProps) => {
  const { handleSubmit } = useFormContext<AssetUpdateFormData>()
  const submitHandler = onSubmit || (() => {
  })
  const cancelHandler = onCancel || (() => {
  })

  return (
    <Drawer
      anchor='bottom'
      variant='permanent'
      sx={{}}
    >
      <ActionBar
        spacing={2}
        direction='row'
        alignItems='center'
        justifyContent='center'
      >
        <Button
          size='large'
          variant='outlined'
          color='primary'
          onClick={() => cancelHandler()}
        >
          Cancel
        </Button>

        <Button
          size='large'
          variant='contained'
          color='primary'
          onClick={handleSubmit(submitHandler)}
        >
          Save
        </Button>

      </ActionBar>
    </Drawer>
  )
}