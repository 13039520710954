import { ContentGrade } from '../../@types/content-audit.ts'

export const GRADE_NAMES = {
  [ContentGrade.GOLD] : "Gold",
  [ContentGrade.SILVER] : "Silver",
  [ContentGrade.BRONZE] : "Bronze",
  [ContentGrade.UNCERTIFIED] : "Uncertified",
}

export const GRADE_COLORS = {
  [ContentGrade.GOLD] : "#FFD700",
  [ContentGrade.SILVER] : "#C0C0C0",
  [ContentGrade.BRONZE] : "#CD7F32",
  [ContentGrade.UNCERTIFIED] : "#B72136",
}