import { useSnackbar } from 'notistack'
import { Button, Stack, Typography } from '@mui/material'
import { AssetStorageType } from '../@types/asset'

type ConsumeableAsset = {
  assetId: string,
  storageType: AssetStorageType
}

export function useDownload() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const downloadAsset = (assetId: string) => {
    enqueueSnackbar((<>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography variant='smallHighlight'>🙏 Thanks!</Typography>
        <Typography component='div' variant='small' noWrap>Your download should start shortly. If it doesn't,
          please:</Typography>
        <Stack spacing={1} direction='row'>
          <Button size='small' variant='contained' onClick={() => downloadAsset(assetId)}>Try Again</Button>
          <Button size='small' variant='contained' onClick={() => closeSnackbar()}>Close</Button>
        </Stack>
      </Stack>
    </>))
    window.location.assign(`/api/v1/assets/${assetId}/download-redirect`)
  }

  const openLinkedAsset = (assetId: string) => {
    enqueueSnackbar((<>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography variant='smallHighlight'>🙏 Thanks!</Typography>
        <Typography component='div' variant='small' noWrap>Your linked asset was opened in a new tab</Typography>
      </Stack>
    </>))
    window.open(`/api/v1/assets/${assetId}/redirect`, '_blank')
  }

  const consumeAsset = (asset: ConsumeableAsset) => {
    if (asset.storageType === AssetStorageType.INTERNAL_S3) {
      return downloadAsset(asset.assetId)
    } else {
      return openLinkedAsset(asset.assetId)
    }
  }

  return { consumeAsset }
}


export function useHostedContentRoomDownload() {
  const { enqueueSnackbar } = useSnackbar()

  const downloadAsset = (roomShortCode: string, assetId: string) => {
    enqueueSnackbar((<>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography variant='smallHighlight'>🙏 Thanks!</Typography>
        <Typography component='div' variant='small' noWrap>Your download should start shortly. If it doesn't, please try
          again.</Typography>
      </Stack>
    </>), { autoHideDuration: 3000 })
    window.location.assign(`/api/v1/cr/${roomShortCode}/assets/${assetId}/download-redirect`)
  }

  const openLinkedAsset = (roomShortCode: string, assetId: string) => {
    enqueueSnackbar((<>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography variant='smallHighlight'>🙏 Thanks!</Typography>
        <Typography component='div' variant='small' noWrap>Your linked asset was opened in a new tab</Typography>
      </Stack>
    </>), { autoHideDuration: 3000 })
    window.open(`/api/v1/cr/${roomShortCode}/assets/${assetId}/link-redirect`, '_blank')
  }

  const consumeAsset = (roomShortCode: string, asset: ConsumeableAsset) => {
    if (asset.storageType === AssetStorageType.INTERNAL_S3) {
      return downloadAsset(roomShortCode, asset.assetId)
    } else {
      return openLinkedAsset(roomShortCode, asset.assetId)
    }
  }

  return { consumeAsset }
}