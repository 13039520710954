import api from "@api";
import { AcceptInviteDto, CheckInviteTokenResponse } from "../@types/invite-token";

export const checkInviteToken = async (inviteToken: string) => {
  const response = await api.get<CheckInviteTokenResponse>(`/api/v1/invite-token/${inviteToken}`)
  return response.data
}

export const acceptInvite = async(inviteToken: string, acceptInviteDto: AcceptInviteDto) => {
  const response = await api.post(`/api/v1/invite-token/${inviteToken}/accept`, acceptInviteDto)
  return response
}

