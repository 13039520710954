import { useContext } from 'react';
import { AdminAnalyticsContext } from '../../contexts/AdminAnalyticsContext'
import AssetAnalyticsFilterBar from './AssetAnalyticsFilterBar'
import {
  Box,
  Link,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import AssetAnalyticsTableHead from './AssetAnalyticsTableHead'
import AssetAnalyticsPagination from './AssetAnalyticsPagination'
import { styled, useTheme } from '@mui/material/styles'
import { AdminAnalyticsAssetResult } from '../../@types/analytics/analytics-asset'
import { srcForPreviewItem } from '../asset-search/images'
import { AssetPreviewType } from '../../@types/asset'
import Image from '../Image'
import { fNumber } from '../../utils/formatNumber'
import { CompactCategoryValues } from './AnalyticsCompactCategoryValues'
import { SimpleSparkGraph } from './AnalyticsSparkGraph'
import { Link as RouterLink } from 'react-router-dom'
import EmptyContent from '../EmptyContent'
import { useAssetAnalytics, useAssetAnalyticSummaries } from '../../hooks/analytics/useAssetAnalytics'
import { AnalyticsAssetRow, AssetSkeletonTableRow } from './asset/AssetAnalyticsTableRow'


export function AssetAnalyticsTable() {

  return (
    <Stack direction='column' spacing={2}>
      <AssetAnalyticsFilterBar />
      <Table stickyHeader>
        <AssetAnalyticsTableHead />
        <AssetAnalyticsTableBody />
      </Table>
      <AssetAnalyticsPagination />
    </Stack>
  )
}

function AssetAnalyticsTableBody() {
  const { data, isLoading } = useAssetAnalytics()
  const results = data?.results || []
  const toRender = Array.from(Array(10).keys())

  if (isLoading) {
    return (
      <TableBody>
        {toRender.map((it,idx) => {
          return <AssetSkeletonTableRow key={it} rowNum={idx} />
        })}
      </TableBody>
    )
  }

  return (
    <TableBody>
      {
        results.map((it, idx) => <AnalyticsAssetRow key={it.assetId} asset={it} rowNum={idx} />)
      }
      {
        results.length == 0 && (
          <TableRow>
            <TableCell colSpan={6}>
              <EmptyContent
                title='No results'
                description='No assets matched your filters'
              />
            </TableCell>
          </TableRow>

        )
      }
    </TableBody>
  )
}