import {useFormContext, useWatch} from "react-hook-form";
import {useMemo} from "react";
import { AssetUpdateFormData } from "../../components/asset/form/AssetUpdateFormProvider";

export function useSelectedCategoryValues(){
    const { setValue } = useFormContext<AssetUpdateFormData>();
    const selectedCategoryValues = useWatch<AssetUpdateFormData>({ name: "selectedCategoryValueUUIDs" }) as string[]

    function toSet(items: string[]){
        const set = new Set()
        items.forEach(it => set.add(it))
        return set
    }

    const selectedValuesAsSet = useMemo(() => {
        return toSet(selectedCategoryValues)
    } ,[selectedCategoryValues])

    const updateCategoryValues = (toSelect: string[], selected: boolean) => {
        const toSelectAsSet = toSet(toSelect)
        // filter to the new set of values
        const newSelectedValues = selected ?
            [...selectedCategoryValues, ...toSelect] :
            selectedCategoryValues.filter(it => !toSelectAsSet.has(it) )

        // update to the new value
        setValue("selectedCategoryValueUUIDs", newSelectedValues)
    }

    const selectCategoryValues = (values: string[]) => updateCategoryValues(values, true)
    const deselectCategoryValues = (values: string[]) => updateCategoryValues(values, false)

    return {
        selectedCategoryValues,
        selectedValuesAsSet,
        selectCategoryValues,
        deselectCategoryValues,
        updateCategoryValues
    }
}
