import api from '@api'
import {
  ContentRoom,
  CreateContentRoomRequest,
  CreateContentRoomResponse, GetContentRoomResponse,
  GetContentRoomsResponse, UpdateContentRoomRequest, UpdateContentRoomResponse,
} from '../@types/content-room'
import { parseISO } from 'date-fns'

export const getContentRoom = async (contentRoomId: string) => {
  const { data } = await api.get<GetContentRoomResponse>(`/api/v1/content-rooms/${contentRoomId}`)
  return data
}

export const getContentRooms = async () => {
  const { data } = await api.get<GetContentRoomsResponse>("/api/v1/content-rooms")
  return { ...data, rooms: data.rooms.map(withDates) }
}

export const createContentRoom = async (dto: CreateContentRoomRequest) => {
  const toPost = {...dto, expiresAt: dto.expiresAt ? new Date(dto.expiresAt) : null}
  const { data } = await api.post<CreateContentRoomResponse>("/api/v1/content-rooms", toPost)
  return withDates(data)
}

export const updateContentRoom = async (contentRoomId: string, dto: UpdateContentRoomRequest) => {
  const toUpdate = {...dto, expiresAt: dto.expiresAt ? new Date(dto.expiresAt) : null}
  const {data} = await api.put<UpdateContentRoomResponse>(`/api/v1/content-rooms/${contentRoomId}`, toUpdate)
  return withDates(data)
}

export const deleteContentRoom = async (contentRoomId: string) => {
  return api.delete(`/api/v1/content-rooms/${contentRoomId}`)
}

function withDates(room: ContentRoom) {
  const expiresAt = room.expiresAt ? parseISO(room.expiresAt + "") : null
  return { ...room, expiresAt }
}