import { useState } from 'react';
import type { Property } from 'csstype';
import { styled } from "@mui/material/styles";
import { is } from "date-fns/locale";


export interface ActivityRingContainerOptions {
  // value of the css height property of the rings-container, defaults to 100%
  containerHeight?: Property.Height;
  // value of the css width property of the rings-container, defaults to 100%
  containerWidth?: Property.Width;
  // the padding between the single rings
  paddingBetweenRings?: number;
  // the radius of the innermost ring
  initialRadius?: number;
  // the duration of the appearance animation in milliseconds
  animationDurationMillis?: number;
  // the css-compatible animation timing-function to use, defaults to ease-in-out
  animationTimingFunction?: Property.AnimationTimingFunction;
  // the opacity of the ring background
  backgroundOpacity?: number;
}

export interface ActivityRing {
  // a float between 0-1 representing the filled-ness in percent
  filledPercentage: number;
  // a css-property compatible color string of the ring i.e. "#FF0000" or "rgb(255, 0, 0)"
  color: Property.Color;
}

interface ActivityRingsProps {
  rings: ActivityRing[];
  options?: ActivityRingContainerOptions;
}

const defaultOptions: ActivityRingContainerOptions = {
  containerHeight: '100%',
  containerWidth: '100%',
  paddingBetweenRings: 0.75,
  initialRadius: 30,
  animationDurationMillis: 1000,
  animationTimingFunction: 'ease-in-out',
  backgroundOpacity: 0.4,
};

const InnerRingBackground = styled("circle")(({ theme }) => ({
  transformOrigin: "50%",
  transform: "rotate(-90deg)",
  fill: "none"
}));

const InnerRing = styled("circle")(({ theme }) => ({
  transformOrigin: "50%",
  transform: "rotate(-90deg)",
  fill: "none",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));

export default function ActivityRings(props: ActivityRingsProps) {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const options = Object.assign({}, defaultOptions, props.options) as Required<ActivityRingContainerOptions>;
  const viewBoxSize = 100 + props.rings.length * (21.5 + options.paddingBetweenRings);

  return (
    <svg
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      height={options.containerHeight}
      width={options.containerWidth}
    >
      <g>
        {props.rings.map((ring, index) => (
          <InnerActivityRing
            key={index}
            index={index}
            ring={ring}
            options={options}
            hoveredIndex={hoveredIndex}
            setHoveredIndex={(_index) => setHoveredIndex(_index)}
          />
        ))}
      </g>
    </svg>
  );
}

interface InnerActivityRingProps {
  // the index of the ring, where 0 is the innermost
  index: number;
  // the ActivityRing properties object
  ring: ActivityRing;
  // the ActivityRingContainer options object
  options: Required<ActivityRingContainerOptions>;
  // which ring is hovered?
  hoveredIndex: number|null
  // callback to set the hovered state
  setHoveredIndex: (index:number|null) => void;
}

function InnerActivityRing(props: InnerActivityRingProps) {
  const radius = props.options.initialRadius + (props.index * (12 + props.options.paddingBetweenRings));
  const dashArrayPart = (Math.min(0.999, props.ring.filledPercentage)) * (2 * radius * Math.PI);
  const isActive = props.hoveredIndex === null || props.hoveredIndex === props.index
  const globalOpacity = isActive ? 1: .2
  return (
    <g
      opacity={globalOpacity}
      onMouseEnter={() => {props.setHoveredIndex(props.index)}}
      onMouseLeave={() => {props.setHoveredIndex(null)}}
    >
      <InnerRingBackground
        cx='50%'
        cy='50%'
        r={radius}
        stroke={props.ring.color}
        strokeOpacity={props.options.backgroundOpacity}
        strokeWidth={12}
      />

      <InnerRing
        cx='50%'
        cy='50%'
        r={radius}
        strokeDasharray={`${dashArrayPart} ${'9'.repeat(props.index + 4)}`}
        stroke={props.ring.color}
        strokeWidth={12}
        style={{
          animationDuration: `${props.options.animationDurationMillis}ms`,
          animationTimingFunction: props.options.animationTimingFunction
        }}
      />
    </g>
  )
}