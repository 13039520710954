import { useWatch } from 'react-hook-form'
import { Box, InputAdornment, TextField } from '@mui/material'
import { alpha, useTheme } from '@mui/material/styles'
import { useState } from 'react';
import { ShareLevel } from '../../../../@types/sharing'
import Iconify from '../../../Iconify'
import { SearchResults } from './SearchResults'
import { DefaultGroupMembersList } from './DefaultGroupMemberList'

export type InternalFormGroupUser = {
  userId: string,
  name: string,
  email: string,
  shareLevel: ShareLevel
}

const SEARCH_CONTENT_HEIGHT = 500
export const AVATAR_SIZE = 40
export const ICON_SIZE = AVATAR_SIZE / 2

export function GroupMembersField({ name }: { name: string }) {
  const theme = useTheme()
  const members = useWatch({ name }) as InternalFormGroupUser[]
  const [searchTerm, setSearchTerm] = useState<string>('')
  const handleSearchTermClear = () => setSearchTerm("")
  const isSearching = Boolean(searchTerm)
  return (
    <Box sx={{
      border: `1px solid ${alpha(theme.palette.text.deemphasized, .25)}`,
      borderRadius: theme.spacing(1),
    }}>
      <Box sx={{
        padding: 2,
      }}>
        <TextField
          fullWidth
          size='small'
          value={searchTerm}
          onChange={(ev) => setSearchTerm(ev.target.value)}
          placeholder='Search users...'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Iconify icon='eva:search-outline' />
              </InputAdornment>
            ),
            endAdornment: !searchTerm ? undefined : (
              <InputAdornment position='end'>
                <Iconify icon='eva:close-outline' sx={{cursor: "pointer"}} onClick={handleSearchTermClear} />
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box
        sx={{
          height: SEARCH_CONTENT_HEIGHT,
          overflow: 'scroll',
        }}
      >
        {!isSearching && <DefaultGroupMembersList members={members} fieldName={name} />}
        {isSearching && <SearchResults searchTerm={searchTerm} members={members} fieldName={name} />}
      </Box>
    </Box>
  )
}