import { useContext, useState } from 'react';
import { ContentAnalysisFilterContext } from '../../../contexts/ContentAnalysisFilterContext.tsx'
import { Box, Button, Stack, ToggleButton, Typography } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import MenuPopover from '../../MenuPopover.tsx'
import { ContentGrade } from '../../../@types/content-audit.ts'
import { GRADE_NAMES } from '../ContentAnalysisConstants.tsx'

export function ContentGradeFilterDropdown() {
  const { includedGrades, setIncludedGrades } = useContext(ContentAnalysisFilterContext)
  const [open, setOpen] = useState<HTMLButtonElement | null>(null)

  const handleOpen = (currentTarget: HTMLButtonElement) => {
    setOpen(currentTarget)
  }

  const handleClose = () => setOpen(null)

  const handleGradeSelection = (grade: ContentGrade, selected: boolean) => {
    const newValues = new Set<ContentGrade>(includedGrades)
    if (selected) {
      newValues.add(grade)
    } else {
      newValues.delete(grade)
    }
    setIncludedGrades([...newValues], false)
  }

  return (
    <>
      <Button
        color='inherit'
        disableRipple
        variant='text'
        size='small'
        onClick={(e) => handleOpen(e.currentTarget)}
        sx={{ '& .MuiButton-endIcon': { ml: 0 } }}
        endIcon={
          <Iconify
            color='text.mint'
            icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          />
        }
      >
        <Typography component='span' variant='small' sx={{ color: 'text.deemphasized', mr: 1 }}>
          Grades:
        </Typography>
        <Typography component='span' variant='smallHighlight' sx={{ color: 'text.mint' }}>
          {renderLabel(includedGrades)}
        </Typography>
      </Button>

      <MenuPopover
        anchorEl={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(open)}
        onClose={handleClose}
        sx={{
          width: 'auto',
          '& .MuiMenuItem-root': { typography: 'body2', borderRadius: 0.75 },
        }}
      >
        {OPTIONS.map((option) => {
          const selected = includedGrades.has(option.value)
          return (
            <Box
              key={option.value}
              mb={1}
            >
              <ToggleButton
                value={option.value}
                selected={selected}
                color='tag'
                size='small'
                onChange={() => handleGradeSelection(option.value, !selected)}
              >
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Iconify icon={selected ? 'eva:checkmark-fill' : 'eva:radio-button-off-fill'} />
                  <Typography variant='small'>{option.label}</Typography>
                </Stack>
              </ToggleButton>
            </Box>
          )
        })}
      </MenuPopover>
    </>
  )
}


function renderLabel(selectedGrades: Set<ContentGrade>) {
  if (selectedGrades.size == 0 || selectedGrades.size == OPTIONS.length) return 'All'
  return OPTIONS
    .filter(it => selectedGrades.has(it.value))
    .map(it => it.label)
    .join(', ')
}

const OPTIONS = [
  { value: ContentGrade.GOLD, label: GRADE_NAMES[ContentGrade.GOLD] },
  { value: ContentGrade.SILVER, label: GRADE_NAMES[ContentGrade.SILVER] },
  { value: ContentGrade.BRONZE, label: GRADE_NAMES[ContentGrade.BRONZE] },
  { value: ContentGrade.UNCERTIFIED, label: GRADE_NAMES[ContentGrade.UNCERTIFIED] },
]