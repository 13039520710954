import { Outlet } from 'react-router-dom'
import { AnalyticsPageSelector } from '../components/analytics/AnalyticsPageSelector'

export default function AnalyticsExploration() {
  return (
    <>
      <AnalyticsPageSelector />
      <Outlet />
    </>
  )
}