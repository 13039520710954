import { Stack, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useContext } from 'react';
import { AdminAnalyticsSortBy, AnalyticType } from '../../@types/analytics/analytics-asset'
import { AnalyticsSortableTableCell } from './AnalyticsSortableTableCell'
import { AdminAnalyticsContext } from '../../contexts/AdminAnalyticsContext'
import Iconify from '../Iconify'
import { AnalyticsGraphTypeSelect } from './AnalyticsGraphTypeSelect'
import { PLACEHOLDER_ASSET_ANALYTICS, useAssetAnalytics } from '../../hooks/analytics/useAssetAnalytics'

export default function AssetAnalyticsTableHead() {
  const {
    filters: { sortBy, sortDirection },
    summaryGraphType,
    setSort,
    setSummaryGraphType,
  } = useContext(AdminAnalyticsContext)
  const { data } = useAssetAnalytics()
  const {
    totalDownloads,
    totalFavorites,
    totalShares,
    totalViews,
  } = data || PLACEHOLDER_ASSET_ANALYTICS

  return (
    <TableHead>
      <TableRow>
        {/* image cell */}
        {/* name / tags */}
        <TableCell width='100%'></TableCell>

        {/* spark line*/}
        <TableCell sx={{ minWidth: 170 }}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography variant='tiny'>Graph:</Typography>
            <AnalyticsGraphTypeSelect type={summaryGraphType} onTypeChange={setSummaryGraphType} />
          </Stack>
        </TableCell>

        <AnalyticsSortableTableCell
          label='Favorites'
          col={AdminAnalyticsSortBy.NUM_FAVORITES}
          icon={<Iconify icon='eva:star-outline' />}
          total={totalFavorites}
          curSortBy={sortBy}
          curSortDirection={sortDirection}
          setSort={setSort}
        />

        <AnalyticsSortableTableCell
          label='Downloads'
          col={AdminAnalyticsSortBy.NUM_DOWNLOADS}
          icon={<Iconify icon='eva:download-outline' />}
          total={totalDownloads}
          curSortBy={sortBy}
          curSortDirection={sortDirection}
          setSort={setSort}
        />

        <AnalyticsSortableTableCell
          label='Shares'
          col={AdminAnalyticsSortBy.NUM_SHARES}
          icon={<Iconify icon='eva:share-outline' />}
          total={totalShares}
          curSortBy={sortBy}
          curSortDirection={sortDirection}
          setSort={setSort}
        />

        <AnalyticsSortableTableCell
          label='Views'
          col={AdminAnalyticsSortBy.NUM_VIEWS}
          icon={<Iconify icon='eva:eye-outline' />}
          total={totalViews}
          curSortBy={sortBy}
          curSortDirection={sortDirection}
          setSort={setSort}
        />
      </TableRow>
    </TableHead>
  )
}


