import { Stack } from "@mui/material";
import SearchSortDropdown from "./SearchSortDropdown";
import SearchDateFilterDropdown from "./SearchDateFilterDropdown";
import SearchResultsPagination from "./SearchResultsPagination";
import { SearchSelectionMode } from './SearchSelectionMode'

export default function SearchOptionsHeader() {

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={3} alignItems="center">
        <SearchSortDropdown />
        <SearchDateFilterDropdown />
      </Stack>
      <Stack direction="row" alignItems="center">
        {/*<SearchSelectionMode />*/}
        <SearchResultsPagination />
      </Stack>
    </Stack>
  );
}


