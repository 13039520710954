import { ReactNode, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Asset, AssetMetadata } from "../../../@types/asset";
import { FormProvider } from "../../hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { GeneralAccessType, ShareLevel } from '../../../@types/sharing'
import useMassetSettings from '../../../hooks/useMassetSettings'
import { BootstrapSettings } from '../../../@types/auth'

// todo: having the update and new forms combined is causing unnecessary complications. We should separate them

export type AssetUpdateFormData = {
  name: string,
  description: string,
  storageExternalRef: string | null,
  properties: AssetMetadata,
  selectedCategoryValueUUIDs: string[],
  generalAccessType?:  GeneralAccessType,
  generalAccessShareLevel?: ShareLevel,
  isInternal: boolean,
  isThirdParty: boolean
}

function getDefaultsFromAsset(asset: Asset | null, settings: BootstrapSettings) {
  return {
    name: asset?.name || "",
    description: asset?.description || "",
    storageExternalRef: asset?.storageExternalRef,
    properties: asset?.properties || {},
    selectedCategoryValueUUIDs: asset?.selectedCategoryValueUUIDs || [],
    generalAccessType: asset?.generalAccessType || settings.defaultAssetGeneralAccessType,
    generalAccessShareLevel: asset?.generalAccessLevel || settings.defaultAssetShareLevel,
    isInternal: asset?.isInternal || false,
    isThirdParty: asset?.isThirdParty || false
  }
}

// todo: need to make this conditional based on storage type
const resolver = yupResolver(Yup.object().shape({
  // storageExternalRef: Yup.string().url("Must be a valid URL")
}))

type Props = {
  children: ReactNode,
  assetUnderEdit: Asset | null
}

export function AssetUpdateFormProvider({ children, assetUnderEdit }: Props) {
  const settings = useMassetSettings()
  const methods = useForm<AssetUpdateFormData>({
    resolver,
    defaultValues: getDefaultsFromAsset(assetUnderEdit, settings),
    mode: "onChange"
  })

  // update the default form values after load
  useEffect(() => methods.reset(getDefaultsFromAsset(assetUnderEdit, settings)), [assetUnderEdit?.assetId, settings])

  return (
    <FormProvider methods={methods}>
      {children}
    </FormProvider>
  )
}