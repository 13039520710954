import { Asset } from '../../@types/asset'
import { AssetContentState } from '../../@types/asset-content'
import { ReadinessContainer } from './ReadinessContainer'

export function RawTextReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  return (
    <ReadinessContainer
      icon="eva:file-text-outline"
      state={state}
      title="Content Analysis"
      subtitle="Can Masset analyze the raw content of your asset?"
    >

    </ReadinessContainer>
  )
}