import Page from '../../components/Page'
import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material'
import { NewConversationInput } from '../../components/conversations/NewConversationInput.tsx'
import { NewConversationSuggestions } from '../../components/conversations/NewConversationSuggestions.tsx'
import { useNavigate } from 'react-router-dom'
import { ContentConversation } from '../../@types/conversation.ts'
import { styled } from '@mui/material/styles'

export function ConversationsHomePage() {
  const navigate = useNavigate()
  const handleConversationCreated = (conversation: ContentConversation) => {
    navigate(`/conversations/${conversation.contentConversationId}`)
  }
  return (
    <Page title='Conversations' sx={{ width: '100%' }}>
      <Stack
        spacing={8}
        sx={{
          paddingTop: 16,
          width: 640,
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <Box textAlign='left'>
          <Alert variant='outlined' severity='info'>
            <AlertTitle>Content Conversations is in Early Release</AlertTitle>
            <Typography paragraph variant='standard'>Congratulations, you're one of a lucky few to try Content
              Conversations in it's early form!</Typography>
            <Typography paragraph variant='standard'>
              Today, Conversations work best with <b><i>general knowledge questions</i></b> and <b><i>requests to find
              content</i></b>. Good example questions are provided below.
            </Typography>
            <Typography paragraph variant='standard'>Improvements and new question types are being added frequently, so
              if it doesn't work today, try again another time! As you communicate, please make an extra effort to
              provide feedback on what works and doesn't!</Typography>
          </Alert>
        </Box>
        <Stack direction='row' justifyContent='center'>
          <Typography variant='h1' fontWeight='lighter'>Get to know your content</Typography>
          <Box>
            <BetaTagBox>beta</BetaTagBox>
          </Box>
        </Stack>
        <NewConversationInput onConversationCreated={handleConversationCreated} />
        <NewConversationSuggestions />
      </Stack>
    </Page>
  )
}

const BetaTagBox = styled(Box)(({ theme }) => {
  return {
    color: theme.palette.info.main,
    borderRadius: theme.spacing(.5),
    padding: '2px 4px',
    border: `1px solid`,
    backgroundColor: 'transparent',
    display: 'inline-block',
    fontSize: '10px',
    textTransform: 'lowercase',
    marginLeft: '6px',
  }
})