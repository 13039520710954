import { Asset } from '../../../../@types/asset.ts'
import { useForm } from 'react-hook-form'
import { FormProvider, RHFSwitch, RHFTextField } from '../../../hook-form'
import { Box, Button, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useCreateAssetExternalShare } from '../../../../hooks/useAssetExternalShares.ts'
import { ASSET_SHARE_PADDING_X } from '../field/AssetShareField.tsx'
import { alpha, useTheme } from '@mui/material/styles'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

type AssetContentRoomFormValues = {
  name: string,
  passwordProtected: boolean
}

type Props = {
  asset: Asset,
  onCancel?: () => void,
  onCreated?: () => void
}

const FormShape = Yup.object().shape({
  name: Yup.string().required(),
})

const formValidationResolver = yupResolver(FormShape)

export function CreateNewAssetExternalShareForm({ asset, onCancel, onCreated }: Props) {
  const theme = useTheme()
  const { mutateAsync: createAssetContentRoom, isLoading } = useCreateAssetExternalShare()
  const formMethods = useForm<AssetContentRoomFormValues>({
    defaultValues: { name: '', passwordProtected: false },
    resolver: formValidationResolver,
    mode: 'onChange',
  })
  const { handleSubmit: rhfHandleSubmit, formState: { isValid } } = formMethods
  const handleSubmit = (formData: AssetContentRoomFormValues) =>
    createAssetContentRoom({
      ...formData,
      assetId: asset.assetId,
    }).then(() => {
      onCreated && onCreated()
    })
  const handleCancel = () => onCancel && onCancel()

  return (
    <FormProvider methods={formMethods}>
      <Box
        sx={{
          paddingY: 3,
          paddingX: ASSET_SHARE_PADDING_X,
          background: alpha(theme.palette.bg.warmGreyLight, .04),
        }}>
        <Stack spacing={1}>
          <RHFTextField name='name' size='small' label='Name' autoFocus />
          <RHFSwitch name='passwordProtected'
                     label={<Typography variant='small'>Password protected?</Typography>} />
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="end">
          <Button
            variant='text'
            onClick={handleCancel}
            size="small"
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            loadingPosition='start'
            loading={isLoading}
            variant='outlined'
            color='primary'
            size="small"
            onClick={rhfHandleSubmit(handleSubmit)}
            disabled={!isValid || isLoading}
          >
            Save
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  )
}