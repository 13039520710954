import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    // set default options for queries. These can still be enabled per-query
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
})