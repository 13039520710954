import SearchOptionsSidebar from '../components/asset-search/SearchOptionsSidebar'
import { SearchProvider } from '../contexts/SearchContext'
import SearchResults from '../components/asset-search/SearchResults'
import { Box } from '@mui/material'
import Page from '../components/Page'

export default function AssetSearch() {
  return (
    <Page title='Search'>
      <SearchProvider>
        <Box sx={{ display: 'flex', height: 1 }}>
          <SearchOptionsSidebar />
          <SearchResults />
        </Box>
      </SearchProvider>
    </Page>
  )
}