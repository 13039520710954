import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Tab, Typography } from '@mui/material'
import { Asset } from '../../../@types/asset'
import { useContext, useState } from 'react'
import * as React from 'react'
import Iconify from '../../Iconify'
import { AssetShareInternalTab } from './internal/AssetShareInternalTab'
import { DialogContext, DialogProvider } from '../../../contexts/DialogContext'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { AssetShareExternalTab } from './external/AssetShareExternalTab.tsx'

type AssetShareButtonProps = { asset: Asset }

export function AssetShareButtonV3({ asset }: AssetShareButtonProps) {
  return (
    <DialogProvider>
      <TriggerButton />
      <ShareDialog asset={asset} />
    </DialogProvider>
  )
}

function TriggerButton() {
  const { setOpen } = useContext(DialogContext)
  const handleOpen = () => setOpen(true)

  return (
    <Button
      variant='outlined'
      size='small'
      fullWidth
      onClick={handleOpen}
      startIcon={<Iconify icon='eva:share-outline' />}
    >
      Share
    </Button>
  )
}

function ShareDialog({ asset }: { asset: Asset }) {
  const { open, setOpen } = useContext(DialogContext)
  const [selectedTab, setSelectedTab] = useState<string>('external')

  // handle the form submission
  const handleClose = () => setOpen(false)
  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => setSelectedTab(newValue)
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullWidth={true}
      onClose={handleClose}
      PaperProps={{
        sx: { minHeight: 600 },
      }}
    >
      <DialogTitle>
        <Typography variant='h2' mb={2}>Share "{asset.name}"</Typography>
        <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
          <IconButton onClick={handleClose}>
            <Iconify icon='eva:close-fill' />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>

        <TabContext value={selectedTab}>
          <Box px={5}>
            <TabList
              onChange={handleTabChange}
              variant='scrollable'
              scrollButtons='auto'
            >
              <Tab label='With Prospects' value='external' />
              <Tab label='Permissions' value='internal' />
            </TabList>
          </Box>

          <Divider sx={{ mb: 4 }} />

          <TabPanel value='internal'>
            <AssetShareInternalTab asset={asset} />
          </TabPanel>

          <TabPanel value='external'>
            <AssetShareExternalTab asset={asset} />
          </TabPanel>
        </TabContext>

      </DialogContent>
    </Dialog>
  )
}