import { Box, Button, Card, Grid, List, Stack, Typography } from "@mui/material";
import AccountBillingPaymentMethod from "../../components/admin/billing/AccountBillingPaymentMethod";
import AccountBillingAddressBook from "../../components/admin/billing/AccountBillingAddressBook";
import AccountBillingInvoiceHistory from "../../components/admin/billing/AccountBillingInvoiceHistory";
import { useState } from "react";
import { _userAddressBook, _userInvoices, _userPayment } from "../../_mock";
import { styled } from "@mui/material/styles";
import Iconify from "../../components/Iconify";
import Page from "../../components/Page";

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

export default function AdminBilling() {
  const [open, setOpen] = useState(false);
  const cards = _userPayment
  const addressBook = _userAddressBook
  const invoices = _userInvoices

  return (
    <Page title="Billing">
      <Box mb={12}>
        <Typography
          variant="h2"
          mb={4}
        >
          Billing
        </Typography>

        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              <Card sx={{ p: 3 }}>
                <Typography
                  variant="overline"
                  sx={{ mb: 3, display: 'block', color: 'text.secondary' }}
                >
                  Your Plan
                </Typography>
                <Typography variant="h4" mb={2}>Premium</Typography>

                <Stack direction="row" mb={1}>
                  <IconStyle icon={"eva:person-outline"}/>
                  <Typography>Unlimited Users</Typography>
                </Stack>

                <Stack direction="row" mb={1}>
                  <IconStyle icon={"eva:image-outline"}/>
                  <Typography>Unlimited Assets</Typography>
                </Stack>

                <Stack direction="row" mb={1}>
                  <IconStyle icon={"eva:hard-drive-outline"}/>
                  <Typography>10 TB Storage</Typography>
                </Stack>

                <Stack direction="row" mb={1}>
                  <IconStyle icon={"eva:phone-call-outline"}/>
                  <Typography>24/7 Support</Typography>
                </Stack>

                <Box
                  sx={{
                    mt: { xs: 2, sm: 0 },
                    position: { sm: 'absolute' },
                    top: { sm: 24 },
                    right: { sm: 24 },
                  }}
                >
                  <Button size="small" color="inherit" variant="outlined" sx={{ mr: 1 }}>
                    Cancel plan
                  </Button>
                  <Button size="small" variant="outlined">
                    Upgrade plan
                  </Button>
                </Box>
              </Card>

              <AccountBillingPaymentMethod
                cards={cards}
                isOpen={open}
                onOpen={() => setOpen(!open)}
                onCancel={() => setOpen(false)}
              />

              <AccountBillingAddressBook addressBook={addressBook}/>
            </Stack>
          </Grid>

          <Grid item xs={12} md={4}>
            <AccountBillingInvoiceHistory invoices={invoices}/>
          </Grid>
        </Grid>

      </Box>
    </Page>
  )
}