import { Stack } from '@mui/material'
import { useContext } from 'react';
import { InternalFormShareItem } from './AssetShareField'
import { ShareItem } from './ShareItem'
import EmptyContent from '../../../EmptyContent'
import { AssetShareFieldContext } from './AssetShareFieldContext'

export function DefaultSharingItemsList({ items }: {
  items: InternalFormShareItem[],
}) {
  const { searchTerm } = useContext(AssetShareFieldContext)
  const isSearching = Boolean(searchTerm)
  if (isSearching) {
    return <></>
  }
  return (
    <Stack>
      {items.map(it => {
        return <ShareItem key={it.uniqueId} item={it} />
      })}
      {
        items.length == 0 && <EmptyShares />
      }
    </Stack>
  )
}

function EmptyShares() {
  return (
    <EmptyContent
      title='Not currently shared'
      description='Use the search bar above to add users or groups'
      img='/assets/illustrations/illustration_no_data.svg'
      imageHeight={64}
    />
  )
}