import { useBootstrap } from './useBootstrap'

const searchParams = new URLSearchParams(window.location.search)
const featureParam = searchParams.get('_f') || ''
const features = featureParam.split(',').map(it => it.trim())
const urlFeaturesAsMap = features.reduce((memo, it) => {
  memo[it] = true
  return memo
}, {} as { [key: string]: boolean })

export function useFeatures() {
  const { data } = useBootstrap()
  const serverFeatures = data?.enabledFeatures.reduce((memo, feature) => {
    memo[feature] = true
    return memo
  }, {} as { [key: string]: boolean }) || {}
  return {
    ...urlFeaturesAsMap,
    ...serverFeatures,
  }
}

export function useIsFeatureEnabled(name: string) {
  const features = useFeatures()
  return features[name] || false
}
