import { Moment } from 'moment-timezone'
import merge from 'lodash/merge'
import { BaseOptionChart } from '../../../../chart'
import { Box, Card, CardHeader } from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import { useMemo } from 'react'

export type TrendGraphSeries = {
  name: string,
  data: TrendGraphPoint[]
}
export type TrendGraphPoint = {
  date: Moment,
  y: number
}

export default function TrendGraph({ title, series, dateFormat, ...other }: {
  title: string,
  series: TrendGraphSeries,
  dateFormat?: string
}) {
  const format = dateFormat || 'MMM D'
  const innerSeries = useMemo(() => {
    return {
      ...series,
      data: series.data.map(it => ({ ...it, x: it.date.format(format) }))
    }
  }, [series])

  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: {
      bar: {
        columnWidth: '70%',
      },
    },
    stroke: { width: 2, },
    xaxis: {
      type: 'category',
      tickAmount: 11
    },
    tooltip: {
      theme: 'dark',
      x: { show: true },
      y: { formatter: (value: any) => value, },
    },
  })

  return (
    <Card {...other}>
      <CardHeader title={title} />
      <Box sx={{ mt: 3, mx: 3 }} dir='ltr'>
        <ReactApexChart type='bar' series={[innerSeries]} options={chartOptions} height={240} />
      </Box>
    </Card>
  )
}