import { Box, Collapse, Link, Skeleton, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles'
import { AdminAnalyticsAssetResult } from '../../../@types/analytics/analytics-asset'
import { PLACEHOLDER_ASSET_SUMMARIES, useAssetAnalyticSummaries } from '../../../hooks/analytics/useAssetAnalytics'
import { srcForPreviewItem } from '../../asset-search/images'
import { AssetPreviewType } from '../../../@types/asset'
import { Link as RouterLink } from 'react-router-dom'
import Image from '../../Image'
import { CompactCategoryValues } from '../AnalyticsCompactCategoryValues'
import { SimpleSparkGraph } from '../AnalyticsSparkGraph'
import { fNumber } from '../../../utils/formatNumber'
import { StripedTableRow } from '../common/StripedTableRow'
import Iconify from '../../Iconify'
import { AssetAnalyticsTableRowDetails } from './AssetAnalyticsTableRowDetails'


export function AnalyticsAssetRow({ asset, rowNum }: { asset: AdminAnalyticsAssetResult, rowNum: number }) {
  // hooks
  const { data = PLACEHOLDER_ASSET_SUMMARIES } = useAssetAnalyticSummaries()
  const [expanded, setExpanded] = useState<boolean>(false)
  const theme = useTheme()

  // handlers
  const handleExpand = () => setExpanded(!expanded)

  // calculated
  const { summaries } = data
  const isStriped = rowNum % 2 == 0
  const iconRotation = expanded ? '90deg' : '0deg'
  const imgSrc = srcForPreviewItem(asset.assetPreviewId, AssetPreviewType.THUMBNAIL_SM)
  const chartData = summaries[asset.assetId]?.entries
      .map(it => ({ value: it.count, startDate: it.bucketStart, endDate: it.bucketEnd }))
    || generateDefaultChartData()

  return (
    <>
      <StripedTableRow isStriped={isStriped}>
        <TableCell sx={{ py: 1.5 }}>
          <Stack direction='row' spacing={3}>
            <Stack direction='row' spacing={2} alignItems='center'>
              <Iconify
                onClick={handleExpand}
                icon='eva:chevron-right-fill'
                sx={{
                  fontSize: 20,
                  transition: '100ms all ease-in-out',
                  rotate: iconRotation,
                  cursor: 'pointer',
                }}
              />
              <Link component={RouterLink} to={`/assets/detail/${asset.assetId}`} target='_blank'>
                <PreviewContainer>
                  <Image alt={asset.name} src={imgSrc} ratio='1/1' backgroundType='contain' />
                </PreviewContainer>
              </Link>
            </Stack>

            <Box>
              <Link component={RouterLink} to={`/assets/detail/${asset.assetId}`} target='_blank' color='inherit'
                    underline='none'>
                <Typography variant='smallHighlight' mb={1} component='div'>{asset.name}</Typography>
              </Link>
              <Box><CompactCategoryValues ids={asset.categoryValueUUIDs} /></Box>
            </Box>
          </Stack>
        </TableCell>
        <TableCell>
          <SimpleSparkGraph color={theme.palette.chart.green[0]} data={chartData} />
        </TableCell>
        <NumberTableCell>
          <Typography variant='small'>{fNumber(asset.numFavorites)}</Typography>
        </NumberTableCell>
        <NumberTableCell>
          <Typography variant='small'>{fNumber(asset.numDownloads)}</Typography>
        </NumberTableCell>
        <NumberTableCell>
          <Typography variant='small'>{fNumber(asset.numShares)}</Typography>
        </NumberTableCell>
        <NumberTableCell>
          <Typography variant='small'>{fNumber(asset.numViews)}</Typography>
        </NumberTableCell>
      </StripedTableRow>
      {
        expanded && (
          <StripedTableRow isStriped={isStriped}>
            <TableCell colSpan={6}>
              <Collapse in={expanded} timeout='auto' unmountOnExit>
                <AssetAnalyticsTableRowDetails asset={asset} />
              </Collapse>
            </TableCell>
          </StripedTableRow>
        )
      }
    </>
  )
}


export function AssetSkeletonTableRow({ rowNum }: { rowNum: number }) {
  const isStriped = rowNum % 2 == 0
  return (
    <StripedTableRow isStriped={isStriped}>
      <TableCell sx={{ py: 1.5, height: 72 }}>
        <Stack direction='row' spacing={3}>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Iconify
              icon='eva:chevron-right-fill'
              sx={{
                fontSize: 20,
                transition: '100ms all ease-in-out',
                cursor: 'pointer',
              }}
            />
            <PreviewContainer />
          </Stack>
          <Box>
            <Typography variant='smallHighlight' mb={1} component='div'>
              <Skeleton variant='text' sx={{ width: 350 }} />
            </Typography>
            <Stack direction='row' spacing={1}>
              <Skeleton variant='rectangular' height={18} width={75} />
              <Skeleton variant='rectangular' height={18} width={100} />
            </Stack>
          </Box>
        </Stack>
      </TableCell>
      <TableCell>
      </TableCell>
      <NumberTableCell>
        <Stack direction='row' justifyContent='end'>
          <Skeleton variant='text' sx={{ width: 25 }} component={Stack} />
        </Stack>
      </NumberTableCell>
      <NumberTableCell>
        <Stack direction='row' justifyContent='end'>
          <Skeleton variant='text' sx={{ width: 25 }} />
        </Stack>
      </NumberTableCell>
      <NumberTableCell>
        <Stack direction='row' justifyContent='end'>
          <Skeleton variant='text' sx={{ width: 25 }} />
        </Stack>
      </NumberTableCell>
      <NumberTableCell>
        <Stack direction='row' justifyContent='end'>
          <Skeleton variant='text' sx={{ width: 25 }} />
        </Stack>
      </NumberTableCell>
    </StripedTableRow>
  )
}


const PreviewContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.bg.warmGreyLight : theme.palette.background.neutral,
  borderRadius: 8,
  overflow: 'hidden',
  height: 48,
  width: 48,
}))

const NumberTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'right',
  paddingRight: 8,
}))

function generateDefaultChartData() {
  return Array(30).fill(0).map(it => ({
    value: it,
  }))
}
