import { ReactNode } from 'react'
import { useParams } from 'react-router'
import { useHostedContentRoomBootstrap } from '../hooks/useHostedContentRoom'
import { HostedContentRoomLogin } from '../pages/hosted-content-room/HostedContentRoomLogin'
import { HostedContentRoom404 } from '../pages/hosted-content-room/HostedContentRoom404'
import { AxiosError } from 'axios'
import { HostedContentRoom410 } from '../pages/hosted-content-room/HostedContentRoom410'
import { HostedContentRoomUnknownError } from '../pages/hosted-content-room/HostedContentRoomUnknownError'

export function HostedContentRoomGuard({ children }: { children: ReactNode }) {
  const { shortCode } = useParams()
  const { data, isError, isFetching, error } = useHostedContentRoomBootstrap(shortCode)
  const isRequestError = error instanceof AxiosError
  const notFound = isError && isRequestError && error?.response?.status === 404
  const expired = isError && isRequestError && error?.response?.status === 410

  if (isFetching) {
    return <></>
  }

  if (isError && notFound) {
    return <HostedContentRoom404 />
  }

  if (isError && expired) {
    return <HostedContentRoom410 />
  }

  if (isError) {
    return <HostedContentRoomUnknownError />
  }

  if (!data?.authed && !data?.passwordProtected) {
    return <HostedContentRoomUnknownError />
  }

  if (!data?.authed && data?.passwordProtected) {
    return <HostedContentRoomLogin />
  }

  if (data?.authed) {
    return <>{children}</>
  }

  return <>Loading</>
}