import { Box, ClickAwayListener, MenuItem, MenuList, Popover, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Category, CategoryValue } from '../../@types/category'
import { useContext, useState } from 'react';
import * as React from 'react';
import { AdminAnalyticsContext } from '../../contexts/AdminAnalyticsContext'
import { CategoryValueFilterMode } from '../../@types/analytics/analytics-asset'

type ReadOnlyTagProps = {
  categoryValue: CategoryValue,
  category: Category,
}

/**
 * This is a custom component just for the analytics page because
 * it allows for the creation of filters on click of the tag
 * @param props
 * @constructor
 */
export function AnalyticsReadOnlyTag({ category, categoryValue }: ReadOnlyTagProps) {
  const { addTagFilter } = useContext(AdminAnalyticsContext)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleInclude = () => {
    addTagFilter([categoryValue.categoryValueId], true, CategoryValueFilterMode.ONE_OF, false)
    handleClose()
  }
  const handleExclude = () => {
    addTagFilter([categoryValue.categoryValueId], false, CategoryValueFilterMode.ONE_OF,false)
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? categoryValue.categoryValueId : undefined

  return (
    <>
      <CompactTagBox onClick={handleClick}>
        <Typography variant='tiny'>{category.name} &gt; {categoryValue.value}</Typography>
      </CompactTagBox>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 'top', horizontal: -4 }}
        sx={{borderRadius: 0}}
        disableScrollLock={true}
        PaperProps={{ sx: { boxShadow: 3} }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList dense={true}>
            <MenuItem onClick={handleInclude}>
              <Typography variant="small"> Include "{categoryValue.value}" </Typography>
            </MenuItem>
            <MenuItem onClick={handleExclude}>
              <Typography variant="small"> Exclude "{categoryValue.value}" </Typography>
            </MenuItem>
          </MenuList>

        </ClickAwayListener>
      </Popover>
    </>
  )
}

export const CompactTagBox = styled(Box)(({ theme }) => {
  const isLight = theme.palette.mode === 'light'
  const borderColor = isLight ? 'transparent' : theme.palette.grey[500_32]
  return {
    borderRadius: theme.spacing(1),
    padding: "1px 6px",
    border: `1px solid ${borderColor}`,
    backgroundColor: "transparent",
    display: 'inline-block',
    cursor: 'pointer'
  }
})

