import { AssetExternalShare } from '../../../../@types/asset-external-share.ts'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import { ASSET_SHARE_PADDING_X } from '../field/AssetShareField.tsx'
import { AVATAR_SIZE, ICON_SIZE } from '../../../admin/groups/field/GroupMembersField.tsx'
import Iconify from '../../../Iconify.tsx'
import { useTheme } from '@mui/material/styles'

type Props = {
  shares: AssetExternalShare[],
  searchTerm: string
}

export function SearchResultsCRM({ searchTerm }: Props) {
  if (!Boolean(searchTerm)) {
    return <></>
  }
  return (
    <Box>
      <Box px={ASSET_SHARE_PADDING_X}><Typography variant='smallHighlight'>CRM</Typography></Box>
      <LeadGen />
    </Box>
  )
}

function LeadGen(){
  const theme = useTheme()
  return (
    <Stack direction='row' spacing={2} alignItems='center' py={2} px={ASSET_SHARE_PADDING_X}>
      <Avatar sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, bgcolor: theme.palette.bg.warmGrey2 }}>
        <Iconify icon='ph:user' sx={{ height: ICON_SIZE, width: ICON_SIZE }} />
      </Avatar>
      <Box>
        <Typography component="div" variant="standard">Share directly with Hubspot and Salesforce</Typography>
        <Typography component="div" variant="caption" color="text.deemphasized">Coming soon...</Typography>
      </Box>
    </Stack>
  )
}