import { Query, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  addToConversation,
  createConversation,
  deleteConversation,
  getConversation,
  getConversationMessage,
  getConversationMessages,
  getConversations,
  upsertMessageFeedback,
} from '../clients/ConversationsClient'
import {
  ContentConversationMessage,
  GetContentConversationMessageResponse,
  MessageFeedbackRating,
  MessageFeedbackType,
  MessageStatus,
} from '../@types/conversation.ts'

export function useConversations() {
  return useQuery({
    queryKey: ['conversations'],
    queryFn: () => getConversations(),
  })
}

export function useConversation(conversationId: string | undefined) {
  return useQuery({
    enabled: Boolean(conversationId),
    queryKey: ['conversations', conversationId],
    queryFn: () => getConversation(conversationId!!),
  })
}

export function useConversationMessages(conversationId: string | undefined) {
  return useQuery({
    enabled: Boolean(conversationId),
    queryKey: ['conversation', conversationId, 'messages'],
    queryFn: () => getConversationMessages(conversationId!!),
  })
}

export function useConversationMessage(conversationId: string, messageId: string) {
  return useQuery({
    queryKey: ['conversation', conversationId, 'messages', messageId],
    queryFn: () => getConversationMessage(conversationId, messageId),
  })
}

export function usePollIncompleteMessage(message: ContentConversationMessage) {
  return useQuery({
    initialData: message,
    enabled: message.status != MessageStatus.COMPLETE,
    queryKey: ['conversation', message.contentConversationId, 'messages', message.contentConversationMessageId],
    queryFn: () => getConversationMessage(message.contentConversationId, message.contentConversationMessageId),
    refetchInterval: (data: GetContentConversationMessageResponse, query: Query) => {
      const complete = data?.status == MessageStatus.COMPLETE
      const attempts = query.state.errorUpdateCount + query.state.dataUpdateCount
      const exceededAttempts = query.state.errorUpdateCount + query.state.dataUpdateCount >= 20
      return complete || exceededAttempts ? 0 : 1000 + (attempts * 100)
    },
  })
}


type CreateConversationParams = {
  message: string
}

export function useCreateConversation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: CreateConversationParams) => createConversation(params.message),
    onSuccess: (_data, _params) => {
      queryClient.invalidateQueries(['conversations'])
    },
  })
}


type AddToConversationParams = {
  conversationId: string
  message: string
}

export function useAddToConversation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: AddToConversationParams) => addToConversation(params.conversationId, params.message),
    onSuccess: (_data, { conversationId }) => {
      queryClient.invalidateQueries(['conversation', conversationId, 'messages'])
    },
  })
}

export function useDeleteConversation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (conversationId: string) => deleteConversation(conversationId),
    onSuccess: () => {
      queryClient.invalidateQueries(['conversations'])
    },
  })
}

type UpsertMessageFeedbackParams = {
  conversationId: string
  messageId: string,
  rating: MessageFeedbackRating,
  feedbackType?: MessageFeedbackType,
  moreDetails?: string
}

export function useUpsertMessageFeedback() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: UpsertMessageFeedbackParams) =>
      upsertMessageFeedback(params.conversationId, params.messageId, params.rating, params.feedbackType, params.moreDetails),
    onSuccess: (data, params) => {
      queryClient.setQueryData(
        ['conversation', params.conversationId, 'messages', params.messageId],
        (oldData?: ContentConversationMessage) => oldData ? { ...oldData, feedback: data } : oldData,
      )
    },
  })
}