import useAuth from './useAuth.ts'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import * as scimClient from '../clients/SCIMClient.ts'

export function useIsScimEnabled() {
  const { settings } = useAuth()
  return settings.scimEnabled
}

export function useScimStatus() {
  const scimEnabled = useIsScimEnabled()
  return useQuery({
    enabled: scimEnabled,
    queryKey: ['scim', 'status'],
    queryFn: async () => scimClient.getStatus(),
    staleTime: 60_000
  })
}

export function useManualSCIMSync(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => scimClient.manualSync(),
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-users'])
      queryClient.invalidateQueries(['scim', 'status'])
    }
  })
}