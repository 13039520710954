import api from '@api'
import { AssetContentStateResponse } from '../@types/asset-content'

export async function getAssetContentState(assetId: string) {
  const response = await api.get<AssetContentStateResponse>(`/api/v1/assets/${assetId}/content-state`)
  return response.data
}

export async function retryExtractionWorkflow(assetId: string) {
  const response = await api.post(`/api/v1/assets/${assetId}/content-state/workflow/retry`)
  return response.data
}