import { Asset, VideoMetadata } from "../../@types/asset";
import { Box, Stack } from "@mui/material";
import AssetProperty from "../asset-detail/AssetProperty";
import { format, parseISO } from "date-fns";
import DefaultUpdateableFields from "./DefaultUpdateableFields";
import { fFileSize } from "../../utils/formatFileSize";

type VideoMetadataProps = {
  asset: Asset
}



export default function VideoMetadataCmp({ asset }: VideoMetadataProps) {
  const metadata = asset.properties as VideoMetadata
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 3,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        },
      }}
    >
      <AssetProperty title="Uploaded" property={format(parseISO(asset.createdAt), "MMM d, yyyy h:mm aa")}/>
      <AssetProperty title="Uploaded by" property={`${asset.createdBy.firstName} ${asset.createdBy.lastName}`}/>
      <AssetProperty title="Downloads" property={asset.downloadCount}/>
      <AssetProperty title="File Size" property={fFileSize(asset.fileSizeBytes)} />
      <AssetProperty title="Dimensions" property={`${metadata.widthPixels}W x ${metadata.heightPixels}H`} />
      <AssetProperty title="Frames Per Second" property={metadata.fps} />
    </Box>
  )
}
