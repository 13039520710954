import { AnalyticsUserResult } from '../../../../@types/analytics/analytics-user'
import { Box, Grid } from '@mui/material'
import { UserTimeline } from './UserTimeline'
import { UserTopAssets } from './UserTopAssets'
import UserAssetRequestsTotal from './UserAssetRequestsTotal'
import UserAssetFlagsTotal from './UserAssetFlagsTotal'
import UserAssetChangeRequestsTotal from './UserChangeRequestsTotal'


export function UserAnalyticsTableRowDetails({ user }: { user: AnalyticsUserResult }) {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <UserAssetRequestsTotal userId={user.userId} />
        </Grid>
        <Grid item xs={4}>
          <UserAssetFlagsTotal userId={user.userId} />
        </Grid>
        <Grid item xs={4}>
          <UserAssetChangeRequestsTotal userId={user.userId} />
        </Grid>

        <Grid item xs={6}>
          <UserTimeline userId={user.userId} />
        </Grid>

        <Grid item xs={6}>
          <UserTopAssets userId={user.userId}/>
        </Grid>

      </Grid>
    </Box>
  )
}