import { Box, Divider, Grid, Typography } from '@mui/material'
import { BrandColors } from './BrandColors'
import { useCompanyBranding } from '../../hooks/useCompanySettings'

export function BrandCustomization() {
  const { data: brandInfo } = useCompanyBranding()
  return (
    <Box>
      <Typography
        variant='h2'
      >
        Brand Customization
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant='standard' paragraph mb={4}>
        Brand customization allows you to apply your brand across the Masset platform.
      </Typography>

      <Grid container spacing={12} rowSpacing={2}>
        <Grid item xs={12} sm={7}>
          <BrandColors brandInfo={brandInfo} />
        </Grid>
        <Grid item xs={12} sm={5} />
      </Grid>
    </Box>
  )
}