import { Box, Button } from '@mui/material'
import { PreviousConversationList } from './PreviousConversationList.tsx'
import NewConversationButton from './NewConversationButton.tsx'

const SIDEBAR_WIDTH = 160 + (32 * 2)

export function PreviousConversationsSidebar() {
  return (
    <Box sx={{
      width: SIDEBAR_WIDTH,
      pt: 4,
      pl: 2,
      minHeight: 'calc(100vh - 160px)',
      borderRight: '1px solid #ffffff11',
    }}>
      <Box pr={2}>
        <NewConversationButton />
      </Box>
      <PreviousConversationList />
    </Box>

  )
}