import Iconify from '../Iconify'
import { Box, Button, Dialog, Divider, IconButton, Stack, Typography } from '@mui/material'
import { useState } from 'react';
import { Asset } from '../../@types/asset'
import { useSnackbar } from 'notistack'
import { useNewAssetRevisionMutation } from '../../hooks/useAsset'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as Yup from 'yup'
import { NewVersionForm } from './NewVersionForm'
import { LoadingButton } from '@mui/lab'
import { FormProvider } from '../hook-form'


type FormValues = {
  file: File | null
  notifyDownloaders: boolean
  notifySharers: boolean
  notifyViewers: boolean
}

const resolver = yupResolver(Yup.object().shape({
  file: Yup.mixed<any>().nullable().required(),
}))
const defaultValues = {
  file: null,
  notifyDownloaders: true,
  notifyViewers: false,
  notifySharers: false,
}

export function AssetUploadNewVersionButton({ asset }: { asset: Asset }) {
  // hooks
  const { mutateAsync: addNewRevision, isLoading } = useNewAssetRevisionMutation()
  const methods = useForm<FormValues>({ resolver, defaultValues })
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit, formState, reset } = methods


  // state
  const [open, setOpen] = useState<boolean>(false)

  // handlers
  const handleUploadClick = () => setOpen(true)
  const handleClose = () => {
    reset()
    setOpen(false)
  }
  const handleConfirm = (data: FormValues) => {
    const notifyOptions = {
      notifyDownloaders: data.notifyDownloaders,
      notifySharers: data.notifySharers,
      notifyViewers: data.notifyViewers,
    }
    const params = { assetId: asset.assetId, file: data.file!!, notifyOptions }
    addNewRevision(params).then(() => {
      enqueueSnackbar(<>
        <Stack spacing={1} direction='row' alignItems='center'>
          <Typography variant='smallHighlight'>👍 Success!</Typography>
          <Typography component='div' variant='small' noWrap>Your asset has been updated with a new
            version.</Typography>
        </Stack>
      </>)
      handleClose()
    })
  }

  return (
    <>
      <Button
        variant='outlined'
        fullWidth
        size='small'
        onClick={handleUploadClick}
        startIcon={<Iconify icon='eva:upload-outline' />}
      >
        Upload New Version
      </Button>

      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth={true}
        onClose={handleClose}
      >

        <Box p={7}>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={handleClose}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Typography variant='h2' mb={1}>Upload New Version</Typography>
          <Typography variant='small'>Select a file to become the new version of this asset. All metadata associated
            with the file will be updated. The existing filename, description, and tags will remain
            unchanged.</Typography>

          <Box my={3}>
            <FormProvider methods={methods}>
              <NewVersionForm />
            </FormProvider>
          </Box>

          <Stack direction='column' spacing={2}>
            <LoadingButton
              loading={isLoading}
              fullWidth={true}
              variant='contained'
              color='primary'
              size='large'
              disabled={!formState.isValid}
              onClick={handleSubmit(handleConfirm)}
            >
              Upload
            </LoadingButton>
            <Button
              fullWidth={true}
              variant='outlined'
              size='large'
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  )
}