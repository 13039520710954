import { useConversations } from '../../hooks/useConversations.ts'
import { useMemo } from 'react'
import { ConversationGrouping, ContentConversation } from '../../@types/conversation.ts'
import {
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  endOfDay,
  format,
  isSameYear,
  isWithinInterval,
  startOfDay,
  subDays,
} from 'date-fns'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import { ConversationGroup } from './ConversationGroup.tsx'
import { Stack } from '@mui/material'

export function PreviousConversationList() {
  const { data } = useConversations()
  const groupedConversations = useMemo(() => groupConversations(data?.conversations), [data])
  return (
    <Stack spacing={4}>
      {
        groupedConversations.map(it => {
          return (
            <ConversationGroup key={it.priority} group={it} />
          )
        })
      }
    </Stack>
  )
}


function groupConversations(conversations: ContentConversation[] | undefined) {
  if (!conversations) return [] as ConversationGrouping[]
  const withTypes = conversations.map(it => {
    const createdAt = new Date(it.createdAt)
    const groupingType = getGroupingType(createdAt)
    const groupingValue = getGroupingValue(groupingType, createdAt)
    return { ...it, groupingType, groupingValue }
  })
  const groups = groupBy(withTypes, it => it.groupingValue)
  const chunks = Object.values(groups)
    .map(it => {
      const firstItem = it[0]
      if (!firstItem) return null
      return {
        name: getGroupName(firstItem),
        priority: firstItem.groupingValue,
        conversations: orderBy(it, c => Math.abs(differenceInMinutes(new Date(c.createdAt), new Date()))),
      }
    })
    .filter(it => !!it)
  return orderBy(chunks, 'priority')
}

function isWithin30Days(date: Date) {
  return isWithinInterval(new Date(date), {
    start: startOfDay(subDays(new Date(), 30)),
    end: endOfDay(new Date()),
  })
}

function getGroupingType(date: Date) {
  const now = new Date()
  if (isWithin30Days(date)) return GroupingType.PREV_30_DAYS
  if (Math.abs(differenceInMonths(date, now)) < 6) return GroupingType.MONTH
  if (isSameYear(date, now)) return GroupingType.MONTH
  return GroupingType.PREV_YEARS
}

function getGroupingValue(type: GroupingType, date: Date) {
  const now = new Date()
  if (type == GroupingType.PREV_30_DAYS) {
    return 0
  } else if (type == GroupingType.MONTH) {
    return 100 + Math.abs(differenceInMonths(date, now))
  } else if (type == GroupingType.PREV_YEARS) {
    return 1000 + Math.abs(differenceInYears(date, now))
  }
  return 10000 + Math.abs(differenceInYears(date, now))
}

function getGroupName(firstItem: { groupingType: GroupingType; groupingValue: number; createdAt: string | Date }) {
  const createdAt = new Date(firstItem.createdAt)
  if (firstItem.groupingType == GroupingType.PREV_30_DAYS) return 'Previous 30 Days'
  if (firstItem.groupingType == GroupingType.MONTH) return format(createdAt, 'MMMM')
  if (firstItem.groupingType == GroupingType.PREV_YEARS) return format(createdAt, 'y')
  return ''
}

enum GroupingType {
  PREV_30_DAYS = 1,
  MONTH = 2,
  PREV_YEARS = 3
}
