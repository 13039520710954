import { Asset } from '../../../../@types/asset'
import { DEFAULT_ASSET_SHARES_DATA, useSharesForAsset } from '../../../../hooks/useSharing'
import { AssetShareInternalForm } from './AssetShareInternalForm'
import { Box, CircularProgress } from '@mui/material'
import { ASSET_SHARE_PADDING_X } from '../field/AssetShareField'

export function AssetShareInternalTab({ asset }: { asset: Asset }) {
  const { data, isFetching } = useSharesForAsset(asset.assetId)

  // calculated props
  const { shares, generalAccessType, generalAccessShareLevel } = data || DEFAULT_ASSET_SHARES_DATA

  if (isFetching) {
    return <Box px={ASSET_SHARE_PADDING_X} mb={4}><CircularProgress size={24} /></Box>
  }

  return (
    <AssetShareInternalForm
      assetId={asset.assetId}
      shares={shares}
      generalAccessType={generalAccessType}
      generalAccessShareLevel={generalAccessShareLevel}
    />
  )
}