import { Autocomplete, Avatar, CircularProgress, Stack, TextField, Typography } from '@mui/material'
import { useMemo, useState } from 'react';
import * as React from 'react';
import { useUserSearch } from '../../hooks/useUsers'
import { User } from '../../@types/user'
import { useDebounceValue } from 'usehooks-ts'
import { useTheme } from '@mui/material/styles'
import orderBy from 'lodash/orderBy'
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import keyBy from 'lodash/keyBy'

export type UserSearchSelectProps = {
  ignoredUserIds?: string[],
  onChange?: (users: User[]) => void
}

export function UserSearchSelect({ onChange, ignoredUserIds }: UserSearchSelectProps) {
  // hooks
  const [term, setTerm] = useState<string>('')
  const [debouncedTerm] = useDebounceValue(term, 200)
  const { data, isFetching } = useUserSearch(debouncedTerm, 50)

  // handlers
  const handleOnChange = (_: React.SyntheticEvent, options: User[]) => onChange && onChange(options)

  // calculated props
  const users = useMemo(
    () => filterToPermittedUsers(data?.users, ignoredUserIds),
    [data?.users, ignoredUserIds]
  )

  return (
    <Autocomplete
      multiple={true}
      filterOptions={x => x}
      filterSelectedOptions={true}
      noOptionsText={debouncedTerm ? "No users found" : null}
      loading={isFetching}
      isOptionEqualToValue={(option, value) => option.userId == value.userId}
      onChange={handleOnChange}
      onBlur={() => setTerm("")}
      onClose={() => setTerm("")}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(ev) => setTerm(ev.target.value)}
          label='Enter a name or email'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isFetching ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      renderOption={(props, option, state) => {
        return (
          <li {...props} key={option.userId}>
            <UserOption user={option} term={debouncedTerm} />
          </li>
        )
      }}
      options={users}
    />
  )
}


function UserOption({ user, term }: { user: User, term: string }) {
  const theme = useTheme()
  const initials = user.firstName.charAt(0)
  const name = `${user.firstName} ${user.lastName}`

  // calculate matches of the search term
  const nameMatches = match(name, term, { insideWords: true });
  const emailMatches = match(user.email, term, { insideWords: true });

  // parse the parts
  const nameParts = parse(name, nameMatches);
  const emailParts = parse(user.email, emailMatches);
  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <Avatar sx={{ height: 32, width: 32, bgcolor: theme.palette.bg.mintDark }}>{initials}</Avatar>
      <Stack direction='column'>
        <div>
          {nameParts.map((part,idx) => {
            return (
              <Typography
                key={idx}
                component="span"
                sx={{fontWeight: part.highlight ? "bold" : "" }}
                color={part.highlight ? "text.mint" : "inherit"}
              >
                {part.text}
              </Typography>
            )
          })}
        </div>

        <div>
          {emailParts.map((part,idx) => {
            return (
              <Typography
                key={idx}
                component="span"
                variant="small"
                sx={{fontWeight: part.highlight ? "bold" : "" }}
                color={part.highlight ? "text.mint" : "inherit"}
              >
                {part.text}
              </Typography>
            )
          })}
        </div>
      </Stack>

    </Stack>
  )
}

function filterToPermittedUsers(users: User[] | undefined, ignoredUserIds: string[] | undefined) {
  const ignored = keyBy(ignoredUserIds || [], (it) => it)
  const filtered = users?.filter(it => !ignored[it.userId])
  return orderBy(filtered, 'firstName', 'asc')
}