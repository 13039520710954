import Typography from '@mui/material/Typography'
import { Box, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { MassetBotAvatar } from './MassetBotAvatar.tsx'

export function MassetBotIntro(){
  const theme = useTheme()
  return (
    <Box sx={{
      background: theme.palette.background.neutral,
      borderRadius: 1,
      padding: 2,
    }}>
      <Stack direction="row" spacing={2} >
        <Box>
          <MassetBotAvatar />
        </Box>
        <Stack direction="column" spacing={1} alignItems="start" textAlign="left">
          <Typography sx={{fontWeight: "bolder"}}>MassetBot</Typography>
          <Typography variant="standard">Hey there! The crazy people at Masset just brought me on board!</Typography>
          <Typography variant="standard">I'm still learning, but I'm excited to help you out!</Typography>
          {/*<Typography variant="standard">Not sure where to start? Here are a few common questions related to this asset:</Typography>*/}

          {/*<Typography variant="standard" pl={3}>*/}
          {/*  <ol>*/}
          {/*    <li>Question 1</li>*/}
          {/*    <li>Question 2</li>*/}
          {/*    <li>Question 3</li>*/}
          {/*  </ol>*/}
          {/*</Typography>*/}
        </Stack>
      </Stack>
    </Box>
  )
}