import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createAssetExternalShare,
  deleteAssetExternalShare,
  getAssetExternalShares,
} from '../clients/AssetExternalShareClient.ts'
import { CreateAssetExternalShareRequest, GetAssetExternalSharesResponse } from '../@types/asset-external-share.ts'

export const DEFAULT_ASSET_EXTERNAL_SHARES_DATA = {
  shares: [],
} as GetAssetExternalSharesResponse


export function useAssetExternalShares(assetId: string) {
  return useQuery({
    queryKey: ['assets', assetId, 'external-shares'],
    queryFn: async () => getAssetExternalShares(assetId),
  })
}


type CreateAssetExternalShareParams = CreateAssetExternalShareRequest & { assetId: string }
export function useCreateAssetExternalShare() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: CreateAssetExternalShareParams) => createAssetExternalShare(params.assetId, params),
    onSuccess: (_, { assetId }) => {
      queryClient.invalidateQueries(['assets', assetId, 'external-shares'])
    },
  })
}

type DeleteAssetExternalShareParams = { assetId: string, externalShareId: string }
export function useDeleteAssetExternalShare() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: DeleteAssetExternalShareParams) => deleteAssetExternalShare(params.assetId, params.externalShareId),
    onSuccess: (_, { assetId }) => {
      queryClient.invalidateQueries(['assets', assetId, 'external-shares'])
    },
  })
}