import { Asset, AssetStorageType } from "../../@types/asset";
import { Box } from "@mui/material";
import { format, parseISO } from "date-fns"
import AssetProperty from "../asset-detail/AssetProperty";
import { fFileSize } from "../../utils/formatFileSize";

type DefaultMetadataProps = {
  asset: Asset
}

export default function DefaultMetadata({ asset }: DefaultMetadataProps) {
  const isExternal = asset.storageType == AssetStorageType.EXTERNAL_HTTP
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 3,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        },
        mb: 4
      }}
    >
      <AssetProperty title="Uploaded" property={format(parseISO(asset.createdAt), "MMM d, yyyy h:mm aa")}/>
      <AssetProperty title="Uploaded by" property={`${asset.createdBy.firstName} ${asset.createdBy.lastName}`}/>
      <AssetProperty title="Downloads" property={asset.downloadCount}/>
      {!isExternal && <AssetProperty title="File Size" property={fFileSize(asset.fileSizeBytes)}/>}
    </Box>
  )
}