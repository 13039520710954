import { Box, Card, CardProps } from "@mui/material";
import * as React from "react";
import { fShortenNumber } from "../../../../../utils/formatNumber";

interface Props extends Omit<CardProps, "title"> {
  title: React.ReactNode;
  total: number;
  icon: React.ReactElement;
}

export default function TotalWidget({ title, total, icon, sx, ...other }: Props) {
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        pl: 3,
        ...sx
      }}
      {...other}
    >
      <Box>
        <Box sx={{ mb: 1, typography: "h2" }}>{fShortenNumber(total)}</Box>
        <Box sx={{ color: "text.secondary", typography: "smallHighlight" }}>{title}</Box>
      </Box>

      <Box
        sx={{
          width: 64,
          height: 64,
          lineHeight: 0,
          borderRadius: "50%",
          bgcolor: "background.neutral"
        }}
      >
        {icon}
      </Box>
    </Card>
  );
}