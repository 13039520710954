import { AssetExtractedContentType } from './asset-content.ts'

export type ContentAuditResponse = {
  results: ContentAuditEntry[],
  offset: number,
  limit: number,
  totalResultCount: number
}

export type ContentAuditSummaryResponse = {
  countNeedAttention: number,
  countMissingFromConversations: number,
  countLackingFileAccess: number,
  total: number
}

export type ContentAuditEntry = {
  assetId: string,
  assetPreviewId: string,
  name: string,
  hasChunks: boolean,
  hasRawFile: boolean,
  existingContentTypes: AssetExtractedContentType[],
  desiredContentTypes: AssetExtractedContentType[],
  oldestContentCreatedDate: string | Date,
  score: number,
  grade: ContentGrade
}

export type ContentAnalysisRequestOptions = {
  includedGrades: ContentGrade[],
  hasFileAccess: boolean | null,
  availableInConversations: boolean | null,
  sortBy: ContentAnalysisSortBy,
  sortDirection: 'asc' | 'desc',
  limit: number,
  offset: number
}

export enum ContentAnalysisSortBy {
  NAME = 1,
  GRADE_LEVEL = 2
}

export enum ContentGrade {
  GOLD = 100,
  SILVER = 75,
  BRONZE = 50,
  UNCERTIFIED = 0
}
