import { Account } from '../../../../@types/account'
import { OauthProvider } from '../../../../@types/oauth'
import { GoogleDriveIntegrationDetails } from './GoogleDriveIntegrationDetails'
import { YouTubeIntegrationDetails } from './YouTubeIntegrationDetails.tsx'

export function IntegrationDetailsPivot({account} : {account: Account}){
  if(account.provider == OauthProvider.GOOGLE_DRIVE){
    return <GoogleDriveIntegrationDetails account={account} />
  }

  if(account.provider == OauthProvider.GOOGLE_YOUTUBE_DATA){
    return <YouTubeIntegrationDetails account={account} />
  }
  return <></>
}