import { Alert, Typography } from '@mui/material'
import Iconify from '../Iconify'

export function InternalAssetWarning({}: {}){
  return (
    <Alert
      severity='error'
      icon={<Iconify icon='eva:lock-outline' />}
    >
      <Typography variant="standard">This asset is for <b>internal use only</b>. To prevent accidental distribution, downloading has been disabled.</Typography>
    </Alert>
  )
}