import { Account } from '../../../../@types/account.ts'
import { OauthProvider } from '../../../../@types/oauth.ts'
import { GrantGoogleDriveAccess } from './GrantGoogleDriveAccess.tsx'
import { Asset } from '../../../../@types/asset.ts'

export function AccessResolutionByAccountType({ account, asset }: {
  asset: Asset,
  account: Account | null | undefined
}) {
  if (account?.provider == OauthProvider.GOOGLE_DRIVE) {
    return <GrantGoogleDriveAccess account={account} asset={asset} />
  }
  return <></>
}