import { GetHostedContentRoomBootstrapResponse } from '../@types/hosted-content-room.ts'
import { createContext, ReactNode, useContext } from 'react'

type HostedContentRoomContextType = {
  room: GetHostedContentRoomBootstrapResponse
}

const HostedContentRoomContext = createContext<HostedContentRoomContextType>({} as HostedContentRoomContextType)

export const HostedContentRoomProvider = ({ room, children }: {
  room: GetHostedContentRoomBootstrapResponse,
  children: ReactNode
}) => {
  return (
    <HostedContentRoomContext.Provider value={{
      room
    }}>
      {children}
    </HostedContentRoomContext.Provider>
  )
}


export function useHostedContentRoomContext() {
  return useContext(HostedContentRoomContext)
}