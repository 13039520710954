import { useContext, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material'
import { ASSET_SHARE_PADDING_X, InternalFormShareItem } from './AssetShareField'
import { ShareItem } from './ShareItem'
import { NoShareMatchesItem } from './NoShareMatchesItem'
import { UserShareItem } from '../../../../@types/sharing'
import { AssetShareFieldContext } from './AssetShareFieldContext'

type FilteredShareItemsListProps = {
  items: InternalFormShareItem[],
}

export function FilteredGroupMemberList({ items }: FilteredShareItemsListProps) {
  const { searchTerm } = useContext(AssetShareFieldContext)
  const filteredMembers = useMemo(() => {
    const toMatch = searchTerm.toLowerCase()
    return items.filter(it => matchShareItem(it, toMatch))
  }, [items, searchTerm])
  return (
    <Box>
      <Box px={ASSET_SHARE_PADDING_X}><Typography variant='smallHighlight'>Already shared with</Typography></Box>
      <Stack>
        {filteredMembers.map(it => <ShareItem key={it.uniqueId} item={it} />)}
      </Stack>
      {filteredMembers.length === 0 && <NoShareMatchesItem />}
    </Box>
  )
}

function matchShareItem(item: InternalFormShareItem, term: string) {
  return item.share.name.toLowerCase().includes(term) ||
    (item.share as UserShareItem | null)?.email?.toLowerCase()?.includes(term)
}
