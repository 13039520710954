import TotalWidget from '../../../asset-detail/tabs/analytics/common/TotalWidget'
import { useUserTotalAssetRequests } from '../../../../hooks/analytics/useUserAnalytics'
import NewAssetRequestIllustration from '../../../../assets/illustration_new_asset'

export default function UserAssetRequestsTotal({ userId }: { userId: string }) {
  const { data, isLoading } = useUserTotalAssetRequests(userId)

  if(isLoading){
    return <></>
  }

  return (
    <TotalWidget
      title="New Asset Requests"
      total={data?.total || 0}
      icon={<NewAssetRequestIllustration />}
    />
  )
}