import { styled } from '@mui/material/styles'
import { Box, IconButton } from '@mui/material'
import { AssetPreview } from '../../AssetPreview'
import { Asset, AssetPreviewType } from '../../../@types/asset'
import Iconify from '../../Iconify'
import { useAssetFavoriteMutation } from '../../../hooks/useAsset'

const AssetDetailsPreviewContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.bg.warmGreyLight : theme.palette.background.neutral,
  borderRadius: theme.spacing(3),
  position: 'relative',
}))

const FilledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}))

type AssetDetailsPreviewProps = {
  asset: Asset,
  allowFavorites?: boolean
}

export function AssetDetailsPreview({ asset, allowFavorites = false }: AssetDetailsPreviewProps) {
  const { mutate: updateAssetFavorite } = useAssetFavoriteMutation()
  const toggleFavorite = () => updateAssetFavorite({ assetId: asset.assetId, isFavorite: !asset.isFavorite })
  return (
    <AssetDetailsPreviewContainer>
      <AssetPreview asset={asset} previewType={AssetPreviewType.THUMBNAIL_XL} />
      {
        allowFavorites && (
          <Box sx={{ position: 'absolute', top: 15, right: 15 }}>
            <FilledIconButton onClick={toggleFavorite} size='medium' color='warning'>
              <Iconify icon={asset.isFavorite ? 'eva:star-fill' : 'eva:star-outline'} />
            </FilledIconButton>
          </Box>
        )
      }
    </AssetDetailsPreviewContainer>
  )
}