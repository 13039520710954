import { useTheme } from "@mui/material/styles";
import { memo } from "react";

function DownloadIllustration() {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="100%" height="100%"
           viewBox="0 0 765.59976 667.74407">
        <path d="M930.66209,783.11289v-72.34S958.85375,762.05886,930.66209,783.11289Z"
              transform="translate(-217.20012 -116.12797)" fill="#f1f1f1" />
        <path d="M932.40355,783.10016l-53.28962-48.92124S935.95913,748.09445,932.40355,783.10016Z"
              transform="translate(-217.20012 -116.12797)" fill="#f1f1f1" />
        <path
          d="M487.41711,244.90908h-2.9776V163.33871A47.21069,47.21069,0,0,0,437.22885,116.128H264.41091a47.21068,47.21068,0,0,0-47.21079,47.2106V610.84006a47.21071,47.21071,0,0,0,47.21066,47.21075H437.22862a47.21071,47.21071,0,0,0,47.21084-47.21057v-307.868h2.97765Z"
          transform="translate(-217.20012 -116.12797)" fill="#3f3d56" />
        <path
          d="M439.13364,128.41057H416.57519a16.75025,16.75025,0,0,1-15.50845,23.07636H302.06159a16.75025,16.75025,0,0,1-15.50845-23.07639H265.4835A35.25642,35.25642,0,0,0,230.227,163.66692V610.51178a35.25643,35.25643,0,0,0,35.25641,35.25646H439.13364a35.25644,35.25644,0,0,0,35.25646-35.25641h0V163.667A35.2564,35.2564,0,0,0,439.13364,128.41057Z"
          transform="translate(-217.20012 -116.12797)" fill="#fff" />
        <circle id="b5dcbba4-f290-467d-a612-e64d8f6895fb" data-name="Ellipse 44" cx="135.10849" cy="261.2336"
                r="84.4462" fill={PRIMARY_MAIN} />
        <path
          d="M377.332,383.132l-21,21.63a4.77423,4.77423,0,0,1-3.5,1.41h-.06983a4.8137,4.8137,0,0,1-3.51025-1.41l-20.98975-21.63c-.06982-.08-.1499-.15-.22021-.22a4.6411,4.6411,0,0,1,.22021-6.55,5.169,5.169,0,0,1,7.08008,0l12.43994,13v-33.52a5.01994,5.01994,0,0,1,10.02979,0v33.52l12.43017-13a5.1813,5.1813,0,0,1,7.08985,0c.07031.07.14013.14.22021.22A4.65,4.65,0,0,1,377.332,383.132Z"
          transform="translate(-217.20012 -116.12797)" fill="#fff" />
        <polygon points="590.258 653.281 577.998 653.28 572.166 605.992 590.26 605.993 590.258 653.281"
                 fill="#9f616a" />
        <path d="M810.58458,781.29294l-39.53076-.00147v-.5a15.3873,15.3873,0,0,1,15.38647-15.38623h.001l24.144.001Z"
              transform="translate(-217.20012 -116.12797)" fill="#2f2e41" />
        <polygon points="497.121 652.877 485.277 649.71 491.857 602.52 509.337 607.194 497.121 652.877"
                 fill="#9f616a" />
        <path
          d="M714.27193,781.29294l-38.189-10.212.12914-.483A15.38731,15.38731,0,0,1,695.05064,759.708l.00094.00026,23.3245,6.2372Z"
          transform="translate(-217.20012 -116.12797)" fill="#2f2e41" />
        <polygon
          points="597.311 388.269 607.58 400.199 594.299 642.642 565.931 642.642 551.628 456.091 507.282 647.645 477.79 640.412 504.606 397.113 597.311 388.269"
          fill="#2f2e41" />
        <path
          d="M728.66209,347.872l28.24578-14.16734,43.437.76353,37.37966,19.12735L816.45736,459.809l9.18685,55.37972-.00006,0a226.53237,226.53237,0,0,1-108.3351.8917l-.28441-.06756s21.11382-74.91647,12.12564-97.77867Z"
          transform="translate(-217.20012 -116.12797)" fill="#cbcbcb" />
        <path d="M810.37758,291.264a32.00239,32.00239,0,1,0,0,.23684Q810.378,291.38244,810.37758,291.264Z"
              transform="translate(-217.20012 -116.12797)" fill="#9f616a" />
        <path
          d="M764.04483,272.97783a9.07922,9.07922,0,0,1,6.11686-2.46756c3.41906-.11108,8.54205,1.10309,10.72226,3.8826,1.75227,2.23392,1.83609,5.3074,1.84278,8.14658l.0185,7.83578c.00548,2.31821.03367,4.73535,1.0869,6.80054s3.47091,3.63379,5.67965,2.92978c2.62014-.83515,3.77228-4.31754,6.45-4.94373,2.011-.47027,4.07671.99927,4.95577,2.86811a12.43905,12.43905,0,0,1,.71577,6.086c-.25348,4.103-6.13809,5.73063-6.97123,9.75611-.482,2.3285-2.16944,6.97348,0,6,10-1,14.38929-6.84091,18.96613-12.33927l10.32256-12.40088a12.12136,12.12136,0,0,0,2.65863-4.30245,11.95064,11.95064,0,0,0,.27283-3.4307q-.08443-4.6234-.251-9.24429c-.097-2.69165-.77448-6.08762-3.41048-6.64078-1.371-.28769-3.18544.214-3.91012-.98483a2.72068,2.72068,0,0,1-.124-1.911c.58175-3.07633,1.56389-6.294.55141-9.2566-1.527-4.4681-6.785-6.30154-11.431-7.14453s-9.86156-1.56823-12.77774-5.28192a40.50078,40.50078,0,0,0-2.53625-3.565,9.95578,9.95578,0,0,0-4.7546-2.39807,26.27877,26.27877,0,0,0-17.28013,1.53363c-2.24741,1.02554-4.50483,2.40658-6.97022,2.25033-2.56085-.16232-4.74826-1.96456-7.27649-2.403-4.08371-.70822-7.98776,2.35126-10.00021,5.97455-2.49362,4.48959-2.72247,10.63785.80026,14.37484,1.75745,1.86438,4.37952,3.145,5.10938,5.601.29708.99969.22812,2.07641.49087,3.08563a5.5701,5.5701,0,0,0,4.48868,3.88364C760.09766,275.67913,762.21039,274.53511,764.04483,272.97783Z"
          transform="translate(-217.20012 -116.12797)" fill="#2f2e41" />
        <path
          d="M724.76359,425.31265a11.46191,11.46191,0,0,0,16.65043,5.627l57.35319,30.3181,1.85723-13.97143L744.8949,414.42367a11.52408,11.52408,0,0,0-20.13131,10.889Z"
          transform="translate(-217.20012 -116.12797)" fill="#9f616a" />
        <path
          d="M801.19249,427.72835a11.462,11.462,0,0,1-17.47818,1.84754L721.1172,446.61047l.54489-17.73844,62.26939-16.17355a11.52408,11.52408,0,0,1,17.261,15.02987Z"
          transform="translate(-217.20012 -116.12797)" fill="#9f616a" />
        <path
          d="M825.51941,354.36022l12.20514-.76465s14.28969,18.8552,6.36361,39.31583c0,0,1.37314,73.49863-30.27647,70.47963s-41.6496-3.019-41.6496-3.019l9.5-26.5,21.25276-6.56178s-6.55012-28.89425,5.84856-40.91623Z"
          transform="translate(-217.20012 -116.12797)" fill="#cbcbcb" />
        <path
          d="M737.38718,353.71455l-1.72509-8.84252s-25.43966-.59763-30.46983,37.95119c0,0-22.87692,57.69232-.45355,65.12057s47.08873,0,47.08873,0l-1.8579-25.44183-24.67276-5.03534s12.7455-16.48928,5.8054-30.79193Z"
          transform="translate(-217.20012 -116.12797)" fill="#cbcbcb" />
        <path d="M981.79988,783.872h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z" transform="translate(-217.20012 -116.12797)"
              fill="#cbcbcb" />
      </svg>
    </>
  );
}

export default memo(DownloadIllustration);