import AssetHeader from "../asset/AssetHeader";
import {Box, Container} from "@mui/material";
import {Outlet} from 'react-router-dom';
import ProfileNavigation from "./ProfileNavigation";
import { ProfileProvider } from "../../contexts/ProfileContext";


export default function ProfileLayout() {
  return (
    <ProfileProvider>
      <Container sx={{p: 4}}>
        <AssetHeader/>
        <Box sx={{mt: 4, display: "flex", height: 1}}>
          <ProfileNavigation />
          <Box sx={{flex: 1}}>
            <Outlet/>
          </Box>
        </Box>
      </Container>
    </ProfileProvider>
  )
}