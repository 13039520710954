import { Asset } from '../../@types/asset'
import { Box, Stack, Typography } from '@mui/material'
import { AssetContentState, AssetExtractedContentType } from '../../@types/asset-content'
import { useAssetContentState } from '../../hooks/useAssetContentState'
import { RawFileReadiness } from './raw-file/RawFileReadiness'
import { RawTextReadiness } from './RawTextReadiness'
import { DocumentSummaryReadiness } from './document-summary/DocumentSummaryReadiness'
import { GeneratedQuestionsReadiness } from './generated-questions/GeneratedQuestionsReadiness'
import { ImageSummaryReadiness } from './image-summary/ImageSummaryReadiness'
import { OCRTextReadiness } from './ocr/OCRReadiness'
import { TranscriptionReadiness } from './transcription/TranscriptionReadiness'
import { PaletteReadiness } from './PaletteReadiness'
import { LabelsReadiness } from './LabelsReadiness'
import { DescriptionReadiness } from './description/DescriptionReadiness.tsx'

export function AssetAiReadiness({ asset }: { asset: Asset }) {
  const { data: state } = useAssetContentState(asset.assetId)
  return (
    <Box>
      <Typography variant='h6'>AI Readiness</Typography>
      <Typography variant='small' color='text.deemphasized' paragraph>
        The more analysis Masset performs on your assets, the better it will perform with
        your employees and prospects.
      </Typography>
      <Readiness asset={asset} states={state?.states} />
    </Box>
  )
}


function Readiness({ asset, states }: { asset: Asset, states?: AssetContentState[] }) {
  return (
    <Stack spacing={2}>
      {states?.map(it => {
        return <StateContentPivot key={it.contentType} asset={asset} state={it} />
      })}
    </Stack>
  )
}

function StateContentPivot({ asset, state }: { asset: Asset, state: AssetContentState }){
  if(state.contentType == AssetExtractedContentType.RAW_FILE){
    return <RawFileReadiness asset={asset} state={state} />
  }

  if(state.contentType == AssetExtractedContentType.RAW_TEXT){
    return <RawTextReadiness asset={asset} state={state} />
  }

  if(state.contentType == AssetExtractedContentType.DOCUMENT_SUMMARY){
    return <DocumentSummaryReadiness asset={asset} state={state} />
  }

  if(state.contentType == AssetExtractedContentType.GENERATED_QUESTIONS){
    return <GeneratedQuestionsReadiness asset={asset} state={state} />
  }

  if(state.contentType == AssetExtractedContentType.IMAGE_SUMMARY){
    return <ImageSummaryReadiness asset={asset} state={state} />
  }

  if(state.contentType == AssetExtractedContentType.OCR_TEXT){
    return <OCRTextReadiness asset={asset} state={state} />
  }

  if(state.contentType == AssetExtractedContentType.TRANSCRIPTION){
    return <TranscriptionReadiness asset={asset} state={state} />
  }

  if(state.contentType == AssetExtractedContentType.PALETTE){
    return <PaletteReadiness asset={asset} state={state} />
  }

  if(state.contentType == AssetExtractedContentType.LABELS){
    return <LabelsReadiness asset={asset} state={state} />
  }

  if(state.contentType == AssetExtractedContentType.ASSET_DESCRIPTION){
    return <DescriptionReadiness asset={asset} state={state} />
  }

  return <></>
}