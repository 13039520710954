import { Stack } from '@mui/material'
import Iconify from '../../Iconify'
import { useContext, useState } from 'react';
import { AdminAnalyticsContext } from '../../../contexts/AdminAnalyticsContext'
import { Range } from 'react-date-range'
import { DateRangeDropdown } from '../../date-range/DateRangeDropdown'
import AssetAnalyticsPagination from '../AssetAnalyticsPagination'
import { CategoryValueFilterMode } from '../../../@types/analytics/analytics-asset'
import { AddFilterButton } from '../AnalyticsAddFilterButton'
import { LoadingButton } from '@mui/lab'
import { useAssetAnalyticsExport } from '../../../hooks/analytics/useUserAnalytics'
import { UserAnalyticsContext } from '../../../contexts/UserAnalyticsContext'
import UserAnalyticsPagination from './UserAnalyticsPagination'

export default function UserAnalyticsFilterBar() {
  const { exportAnalytics } = useAssetAnalyticsExport()
  const [isExporting, setIsExporting] = useState<boolean>(false)
  const handleExport = async () => {
    setIsExporting(true)
    await exportAnalytics()
    setIsExporting(false)
  }
  return (
    <Stack direction='column' spacing={1}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <DefaultFilters />
        <Stack direction='row' spacing={2}>
          <LoadingButton
            variant='text'
            size='small'
            color='inherit'
            startIcon={<Iconify icon={'eva:download-outline'} />}
            onClick={handleExport}
            loading={isExporting}
            loadingPosition="start"
          >
            Export
          </LoadingButton>
          <UserAnalyticsPagination />
        </Stack>
      </Stack>
    </Stack>
  )
}


function DefaultFilters() {
  // context
  const {setDateRange, filters: {boundingStartDate, boundingEndDate}} = useContext(UserAnalyticsContext)

  // handlers
  const handleRangeChange = (range: Range) => setDateRange({ startDate: range.startDate, endDate: range.endDate })

  // calculated properties
  const defaultRange = { startDate: boundingStartDate, endDate: boundingEndDate, key: "selection"} as Range

  return (
    <Stack
      direction='row'
      spacing={3}
      alignItems='center'
    >
      <DateRangeDropdown
        onChange={handleRangeChange}
        defaultRange={defaultRange}
      />
      {/*<AddFilterButton />*/}
    </Stack>
  )
}


