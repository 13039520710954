import { Box, Typography } from "@mui/material";
import { ProfileUserInfoForm } from "../../components/profile/user-info/ProfileUserInfoForm";
import Page from "../../components/Page";

export default function ProfileInfo() {

  return (
    <Page title="Personal Info">
      <Box mb={12}>
        <Typography
          variant="h2"
          mb={4}
        >
          User Profile
        </Typography>

        <ProfileUserInfoForm />

      </Box>
    </Page>
  )
}