import api from "@api";
import { CreateUserDto, UpdateUserDto, User, UserSearchResponse, UserSortBy, UserType } from '../@types/user'
import { SortDirection } from '@mui/material'

export const getUsers = async() => {
    const response = await api.get<User[]>("/api/v1/users")
    return response.data
}

export const searchForUsers = async(
  term: string,
  sortBy: UserSortBy,
  sortDirection: 'asc' | 'desc',
  limit: number = 10,
  offset: number = 0,
) => {
    const options = {
        term: term || "",
        sortBy,
        sortDirection,
        limit,
        offset
    }
    const response = await api.post<UserSearchResponse>("/api/v1/users/search", options)
    return response.data
}

export const getUser = async(userId: string) => {
    const response = await api.get<User>(`/api/v1/users/${userId}`)
    return response.data
}

export const createUser = async(data: CreateUserDto) => {
    const response = await api.post<User>(`/api/v1/users`, data)
    return response.data
}

export const updateUser = async(userId: string, data: UpdateUserDto) => {
    const response = await api.put<User>(`/api/v1/users/${userId}`, data)
    return response.data
}

export const setUserRole = async(userId: string, userType: UserType) => {
    const response = await api.put<User>(`/api/v1/users/${userId}/role`, { userType })
    return response.data
}

export const deleteUser = async (userId: string) => {
    await api.delete(`/api/v1/users/${userId}`)
}

export const bulkUploadUsers = async(file: File) => {
    const formData = new FormData()
    formData.append("file", file)
    const response = await api.post("/api/v1/users/bulk-create", formData, {
        headers: {"Content-Type": "multipart/form-data"}
    })
    return response.data
}
