export function fMsToTime(duration: number) {
  const milliseconds = Math.floor((duration % 1000) / 100)
  const seconds = Math.floor((duration / 1000) % 60)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  const hourStr = (hours < 10) ? "0" + hours : hours;
  const minuteStr = (minutes < 10) ? "0" + minutes : minutes;
  const secondStr = (seconds < 10) ? "0" + seconds : seconds;

  return hourStr + ":" +
    minuteStr + ":" +
    secondStr + "." +
    String(milliseconds).padStart(3, '0');
}

export function fMsToGrowingTimeStamp(duration: number) {
  const seconds = Math.floor((duration / 1000) % 60)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  const hourStr = (hours < 10) ? "0" + hours : hours;
  const minuteStr = (minutes < 10) ? "0" + minutes : minutes;
  const secondStr = (seconds < 10) ? "0" + seconds : seconds;

  if(hours == 0){
    return minuteStr + ":" + secondStr
  }

  return hourStr + ":" + minuteStr + ":" + secondStr
}