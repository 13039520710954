import { ContentRoomLogoSettings } from '../../@types/hosted-content-room'
import { Box } from '@mui/material'
import { useParams } from 'react-router'

type CompanyLogoProps = {
  settings: ContentRoomLogoSettings
}
export function CompanyLogo({settings} : CompanyLogoProps) {
  const { shortCode } = useParams()
  if(!settings.hasLogo) return <></>
  return (
    <Box
      sx={{ width: 40, height: 40, borderRadius: '20%' }}
      component='img'
      src={`/api/v1/cr/${shortCode}/logo`}
    />
  )
}