import { useAsset } from '../../hooks/useAsset.ts'
import { AssetAiReadiness } from '../asset-ai/AssetAiReadiness.tsx'

export function AnalysisRowDetails({assetId} : {assetId: string}){
  const { data: asset, isLoading, isError, error } = useAsset(assetId)
  if(isLoading) return <></>
  if(isError) return <></>
  return (
    <AssetAiReadiness asset={asset} />
  )
}