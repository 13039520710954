import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import {ReactNode} from "react";


type CompanyGuardProps = {
  children: ReactNode;
};

export default function CompanyGuard({ children }: CompanyGuardProps) {
  const { isAuthenticated, company  } = useAuth();

  if (isAuthenticated && !company) {
    return <Navigate to="/login/companies" />;
  }

  return <>{children}</>;
}