import { SearchAssetResult } from '../../@types/search'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material'
import Image from '../../components/Image'
import { format, parseISO } from 'date-fns'
import { Link as RouterLink } from 'react-router-dom'
import { srcForPreviewItem } from './images'
import { useDownload } from '../../hooks/useDownload'
import { AssetPreviewType, AssetStorageType } from '../../@types/asset'
import { alpha, styled } from '@mui/material/styles'
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import Iconify from '../Iconify'
import { SearchContext } from '../../contexts/SearchContext'
import { UserType } from '../../@types/user'
import useAuth from '../../hooks/useAuth'


type SearchAssetCardProps = {
  asset: SearchAssetResult
}

const RootStyle = styled(Card)<{isSelected: boolean}>(({ theme, isSelected }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.bg.warmGrey0 : theme.palette.background.paper,
  padding: theme.spacing(1),
  border: isSelected ? `1px solid ${alpha(theme.palette.bg.mint, .4)}` : `1px solid transparent`,
}))

const PreviewContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.bg.warmGreyLight : theme.palette.background.neutral,
  borderRadius: '20px',
  cursor: 'pointer',
  overflow: 'hidden',
}))

type SelectableContainerProps = { isSelectable: boolean, selected: boolean }
const SelectableContainer = styled(Box)<SelectableContainerProps>(({ isSelectable, selected }) => ({
  transition: 'opacity 125ms ease-in-out',
  opacity: isSelectable && !selected ? .35 : 1,
  pointerEvents: isSelectable ? "none" : "inherit"
}))

const SelectableOverlay = styled(Box)<{isSelected: boolean}>(({ theme, isSelected }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  cursor: "pointer",
  zIndex: 999,
  background: isSelected ? alpha(theme.palette.bg.mint,.05) : undefined
}))


export default function SearchAssetCard({ asset }: SearchAssetCardProps) {
  // hooks
  const { selectionModeEnabled, selectIsSelected, addSelectedItem, removeSelectedItem } = useContext(SearchContext)
  const { consumeAsset } = useDownload()
  const { user } = useAuth()

  // handlers
  const handleSelectionClick = () => {
    if(!selectionModeEnabled) return
    if(selectIsSelected(asset.assetId)){
      removeSelectedItem(asset.assetId)
    } else {
      addSelectedItem(asset.assetId)
    }
  }

  // calculated props
  const imgSrc = srcForPreviewItem(asset.assetPreviewId, AssetPreviewType.THUMBNAIL_SM)
  const isSelected = selectIsSelected(asset.assetId)
  const consumeText = asset.storageType === AssetStorageType.EXTERNAL_HTTP ? "Visit Link" : "Download"

  const isAdmin = user?.userType == UserType.ADMIN
  const downloadDisabled = asset?.isInternal && !isAdmin

  return (
    <RootStyle isSelected={isSelected}>
      <SelectionCheckbox canSelect={selectionModeEnabled} isSelected={isSelected} />

      { selectionModeEnabled && <SelectableOverlay onClick={handleSelectionClick} isSelected={isSelected} /> }

      <SelectableContainer
        isSelectable={selectionModeEnabled}
        selected={isSelected}
      >
        <RouterLink to={`/assets/detail/${asset.assetId}`}>
          <PreviewContainer>
            <FavoriteIcon asset={asset} />
            <Image alt={asset.name} src={imgSrc} ratio='1/1' backgroundType='contain' />
          </PreviewContainer>
        </RouterLink>

        <CardContent sx={{ p: 2, pb: 0 }}>
          <Stack spacing={2}>
            <Typography variant='smallHighlight' noWrap>
              {asset.name}
            </Typography>

            <Stack direction='row' justifyContent='space-between'>
              <Box>
                <Typography component='div' variant='small' color='text.deemphasized'>Uploaded</Typography>
                <Typography component='div'
                            variant='small'>{format(parseISO(asset.createdAt), 'MMM d, yyyy')}</Typography>
              </Box>
            </Stack>

            <OverflowTypography variant='small' color='text.secondary' noWrap mt={3}>
              {asset.description}
            </OverflowTypography>

            <Divider sx={{ mt: 2 }} />
          </Stack>
        </CardContent>
        <CardActions disableSpacing sx={{ pb: 0 }}>
          {
            downloadDisabled && (
              <Tooltip title="This asset is for internal use only. To prevent accidental distribution, downloading has been disabled.">
                <Stack alignItems="center" justifyContent="center">
                  <Iconify icon="eva:lock-outline" color="text.red" />
                </Stack>
              </Tooltip>
            )
          }
            <Button
              color='primary'
              size='small'
              variant='text'
              onClick={() => consumeAsset(asset)}
              disabled={downloadDisabled}
            >
              {consumeText}

            </Button>

          <Button
            color='primary'
            size='small'
            variant='text'
            component={RouterLink}
            to={`/assets/detail/${asset.assetId}`}
          >Details</Button>
        </CardActions>
      </SelectableContainer>
    </RootStyle>
  )
}

interface OverflowTypographyProps extends TypographyProps {
  children: ReactNode;
}

const FavoriteIcon = ({ asset }: { asset: SearchAssetResult }) => {
  if (!asset.isFavorite) {
    return <></>
  }
  return (
    <Box sx={{ position: 'absolute', top: 15, right: 15, zIndex: 99, color: 'warning.main' }}>
      <Iconify fontSize={16} icon={asset.isFavorite ? 'eva:star-fill' : 'eva:star-outline'} />
    </Box>
  )
}

function SelectionCheckbox({ canSelect, isSelected }: { canSelect: boolean, isSelected: boolean }) {
  if (!canSelect) return <></>
  return (
    <Box sx={{ position: 'absolute', top: 5, left: 5, zIndex: 99 }}>
      <Checkbox
        disableRipple
        size='small'
        checked={isSelected}
      />
    </Box>
  )
}

const OverflowTypography = ({ children, ...props }: OverflowTypographyProps) => {
  const ref = useRef<HTMLSpanElement>(null)
  const [tooltipEnabled, setTooltipEnabled] = useState(false)

  useEffect(() => {
    const compareSize = () => {
      if (ref.current) {
        const compare = ref.current.scrollWidth > ref.current.clientWidth
        setTooltipEnabled(compare)
      }
    }
    compareSize()
    window.addEventListener('resize', compareSize)
    return () => window.removeEventListener('resize', compareSize)
  }, [])

  return (
    // @ts-ignore
    <Tooltip title={children} disableHoverListener={!tooltipEnabled}>
      <Typography
        ref={ref}
        noWrap
        overflow='hidden'
        textOverflow='ellipsis'
        {...props}
      >
        {children}
      </Typography>
    </Tooltip>
  )
}