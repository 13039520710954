import Page from '../../components/Page'
import { UserAnalyticsProvider } from '../../contexts/UserAnalyticsContext'
import { UserAnalyticsTable } from '../../components/analytics/user/UserAnalyticsTable'

export function GlobalUserAnalytics() {
  return (
    <Page title='Asset Analytics'>
      <UserAnalyticsProvider>
        <UserAnalyticsTable />
      </UserAnalyticsProvider>
    </Page>
  )
}