import { ContentRoom } from '../../@types/content-room'
import { useState } from 'react'
import { useDeleteContentRoom } from '../../hooks/useContentRooms'
import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material'
import Iconify from '../Iconify'
import { LoadingButton } from '@mui/lab'

export function ContentRoomDeleteButton({ room }: { room: ContentRoom }) {
  const [open, setOpen] = useState(false)
  const { mutateAsync: deleteRoom, isLoading } = useDeleteContentRoom()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleDelete = async () => {
    await deleteRoom({ contentRoomId: room.contentRoomId })
    handleClose()
  }

  return (
    <>
      <Button
        size='small'
        variant='text'
        color='red'
        onClick={handleOpen}
        startIcon={<Iconify color='text.red' icon='eva:trash-2-outline' />}
        sx={{ '& .MuiButton-startIcon': { marginRight: '4px', marginTop: '-2px' } }}
      >Delete</Button>

      <Dialog
        open={open}
        maxWidth='xs'
        fullWidth={true}
        onClose={handleClose}
      >

        <Box p={7} sx={{ textAlign: 'center' }}>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={handleClose}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Iconify sx={{ mb: 2, fontSize: 48 }} icon='eva:alert-circle-outline' color='text.mint' />

          <Typography variant='h2' mb={4}>
            Delete this Content Room?
          </Typography>

          <Typography variant='standard' color='text.secondary' component='div' mb={2}>
            Deleting this Content Room will permanently remove it from Masset.
          </Typography>

          <Typography variant='standard' color='text.red' component='div' mb={4}>
            Users will no longer be access content using the associated link.
          </Typography>

          <Stack spacing={2}>
            <LoadingButton
              loading={isLoading}
              fullWidth={true}
              variant='contained'
              color='primary'
              size='large'
              onClick={handleDelete}
            >
              Delete Content Room
            </LoadingButton>
            <Button
              fullWidth={true}
              variant='outlined'
              size='large'
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  )
}