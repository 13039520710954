import { Account } from '../../../@types/account'
import { OAUTH_PROVIDER_NAMES, OauthProvider } from '../../../@types/oauth'
import { GoogleDriveIntegrationDescription } from './descriptions/GoogleDriveIntegrationDescription'
import { GenericIntegrationDescription } from './descriptions/GenericIntegrationDescription.tsx'
import { YouTubeIntegrationDescription } from './descriptions/YouTubeIntegrationDescription.tsx'

type IntegrationDescriptionProps = {
  account: Account
}

// eventually we might want to move this to a consolidated endpoint instead of writing frontend code for each
// in the short term, our number of integrations will be small, so this is fine
export function IntegrationDescription({account} : IntegrationDescriptionProps){
  if(account.provider == OauthProvider.GOOGLE_DRIVE){
    return <GoogleDriveIntegrationDescription account={account} />
  }
  if(account.provider == OauthProvider.GOOGLE_YOUTUBE_DATA){
    return <YouTubeIntegrationDescription account={account} />
  }
  return <GenericIntegrationDescription
    name={OAUTH_PROVIDER_NAMES[account.provider]}
    description={""}
    createdDate={account.createdAt}
  />
}