import useAuth from './useAuth'

const useMassetSettings = () => {
  const context = useAuth();

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context.settings;
};

export default useMassetSettings;
