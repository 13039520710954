import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getUsers, searchForUsers } from '../clients/UserClient'
import keyBy from 'lodash/keyBy'
import { CreateUserDto, UpdateUserDto, User, UserSearchResponse, UserSortBy, UserType } from '../@types/user'
import * as userClient from '../clients/UserClient.ts'

export const DEFAULT_USER_DATA = { users: [] as User[], usersById: {} as { [key: string]: User } }
export const DEFAULT_USER_SEARCH_DATA = { users: [], offset: 0, limit: 0, totalResultCount: 0 } as UserSearchResponse

export function useUsers(){
  return useQuery({
    queryKey: ['users'],
    queryFn: async() => getUsers(),
    select: data => ({
      users: data || [],
      usersById: keyBy(data, 'userId') || {},
    })
  })
}

/**
 * This uses the same endpoint as the other user search, but behaves differently
 * on the admin page (enabled by default when search term is empty)
 * @param term
 * @param limit
 */
export type AdminUserSearchOptions = {
  term: string,
  limit: number,
  page: number,
  sortBy: UserSortBy,
  sortDirection: 'asc' | 'desc'
}
export function useAdminUserSearch(options: AdminUserSearchOptions){
  return useQuery({
    queryKey: ['admin-users', options],
    queryFn: async() => {
      return searchForUsers(
        options.term,
        options.sortBy,
        options.sortDirection,
        options.limit,
        options.limit * (options.page-1)
      )
    },
    keepPreviousData: true
  })
}

export function useUserSearch(term: string, limit: number = 10){
  return useQuery({
    queryKey: ['users', term, limit],
    queryFn: async() => {
      const runQuery = Boolean(term)
      if(!runQuery) return Promise.resolve(DEFAULT_USER_SEARCH_DATA)
      return searchForUsers(term, UserSortBy.NAME, 'asc', limit)
    },
    keepPreviousData: true
  })
}


export type SaveUserParams = {
  userId: string | undefined,
  data: CreateUserDto | UpdateUserDto
}
export function useSaveUser(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({userId, data}: SaveUserParams) => {
      return !userId ? userClient.createUser(data) : userClient.updateUser(userId, data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-users'])
      queryClient.invalidateQueries(['users'])
    }
  })
}

export type DeleteUserParams = {
  userId: string
}
export function useDeleteUser(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({userId}: DeleteUserParams) => userClient.deleteUser(userId),
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-users'])
      queryClient.invalidateQueries(['users'])
    }
  })
}


export type SetUserRoleParams = {
  userId: string,
  userType: UserType
}
export function useSetUserRole(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({userId, userType}: SetUserRoleParams) => userClient.setUserRole(userId, userType),
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-users'])
      queryClient.invalidateQueries(['users'])
    }
  })
}