import {
  Box,
  Button, Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useGoogleDriveFiles } from '../../../../../hooks/integrations/useGoogleDrive'
import { Account } from '../../../../../@types/account'
import Iconify from '../../../../Iconify'
import { useDrivePicker } from '../../../../google-picker/useDrivePicker'
import { useQueryClient } from '@tanstack/react-query'

export function GoogleDrivePermittedFiles({ account }: { account: Account }) {
  const { openPicker } = useDrivePicker()
  const queryClient = useQueryClient()
  const { data, isLoading } = useGoogleDriveFiles(account.accountId)

  // handlers
  const handlePickerResponse = () => queryClient.invalidateQueries(['google-drive-files'])
  const handlePickerClick = () => openPicker(account.accountId, { callback: handlePickerResponse, multiSelect: true })

  // calculated props
  const files = data?.files || []
  return (
    <Paper
      elevation={1}
      sx={{
        p: 4,
        position: 'relative',
      }}
    >
      <Box sx={{ float: 'right' }}>
        <Button
          variant='contained'
          color='mint'
          size='small'
          startIcon={<Iconify icon={'eva:plus-outline'} />}
          onClick={handlePickerClick}
        >
          Grant Access
        </Button>
      </Box>
      <Typography variant='h2' paragraph>Permitted Files</Typography>
      <Typography variant='standard' paragraph>
        Masset has access to the following Google Drive files on the connected account:
      </Typography>
      <TableContainer
        sx={{
          minHeight: 200,
          maxHeight: 350,
        }}
      >
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell key='icon'></TableCell>
              <TableCell key='name' width='100%'>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && files
              .map((file) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={file.fileId}>
                    <TableCell key='icon'>
                      <Box sx={{ width: 16, height: 16 }}>
                        <img src={file.iconLink} />
                      </Box>
                    </TableCell>
                    <TableCell key='name'>
                      <Link href={file.webViewLink} target='_blank' sx={{ cursor: 'pointer' }}>
                        {file.name}
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}