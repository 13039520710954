export type AcceptInviteDto = {
  newPassword: string
  newPasswordConfirmation: string
}

export type CheckInviteTokenResponse = {
  status : InviteTokenStatus
}

export enum InviteTokenStatus {
  VALID = "VALID",
  NOT_FOUND = "NOT_FOUND",
  USER_ALREADY_INITIALIZED = "USER_ALREADY_INITIALIZED"
}