import { useParams } from 'react-router'
import { useAsset } from '../hooks/useAsset'
import { AssetForbidden } from '../components/asset/AssetForbidden'
import { AxiosError } from 'axios'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { AssetDetailsPreview } from '../components/asset/preview/AssetDetailsPreview'
import LoadingScreen from '../components/LoadingScreen'
import Iconify from '../components/Iconify'
import { useEffect } from 'react';
import { AssetStorageType } from '../@types/asset'
import Page from '../components/Page'
import { useNavigate } from 'react-router-dom'

export function AssetDownload() {
  const { assetId } = useParams()
  const navigate = useNavigate()
  const { data: asset, isError, error } = useAsset(assetId)
  const isRequestError = error instanceof AxiosError
  const isExternal = asset?.storageType === AssetStorageType.EXTERNAL_HTTP
  const headerText = isExternal ? "Redirecting to" : "Downloading"
  const handleDownload = () => {
    if (isExternal) {
      window.open(`/api/v1/assets/${assetId}/redirect`, '_self')
    } else {
      window.location.assign(`/api/v1/assets/${assetId}/download-redirect`)
    }
  }

  useEffect(() => {
    if (asset) {
      setTimeout(() => handleDownload(), 1000)
    }
  }, [asset])

  if (isError && isRequestError && error?.response?.status === 403) {
    return <AssetForbidden assetId={assetId} />
  }

  if (!asset) {
    return <LoadingScreen />
  }

  return (
    <Page title='Download'>
      <Button
        variant='text'
        size={'small'}
        onClick={() => navigate('/assets/search', { state: { preservePrevSearch: true } })}
        sx={{ mb: 1, '& .MuiButton-startIcon': { marginRight: '4px' } }}
        startIcon={<Iconify icon={'eva:chevron-left-fill'} />}
      >Back</Button>

      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <Stack direction='column' spacing={2}>
            <AssetDetailsPreview asset={asset} allowFavorites={false} />
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <Stack direction='column' spacing={4}>

            <Typography variant='h2'>{headerText} "{asset.name}" </Typography>
            <Box>
              <Typography>Your download should start automatically... </Typography>
              <Typography variant="caption" color="text.deemphasized">If the download doesn't start, click the button below to try again.</Typography>
            </Box>

            {asset && !isExternal && (
              <Box>
                <Button
                  variant='contained'
                  startIcon={<Iconify icon={'eva:download-fill'} />}
                  onClick={handleDownload}
                  sx={{ minWidth: 250 }}
                >
                  Download
                </Button>
              </Box>
            )}

            {asset && isExternal && (
              <Box>
                <Button
                  variant='contained'
                  startIcon={<Iconify icon={'eva:link-fill'} />}
                  onClick={handleDownload}
                  sx={{ minWidth: 250 }}
                >
                  Go to linked Asset
                </Button>
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Page>
  )
}