import { GenericIntegrationDescription } from './GenericIntegrationDescription'
import { Account } from '../../../../@types/account'

export function YouTubeIntegrationDescription({account} : {account: Account}) {
  return (
    <GenericIntegrationDescription
      name="YouTube"
      description="The YouTube app allows Masset to connect to YouTube to get video information, captions, and other metadata for analysis."
      createdDate={account.createdAt}
    />
  )
}