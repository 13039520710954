import { Box, Button, Dialog } from '@mui/material'
import { useState } from 'react'
import { NewConversationInput } from './NewConversationInput.tsx'
import { ContentConversation } from '../../@types/conversation.ts'
import { useNavigate } from 'react-router-dom'

export default function NewConversationButton() {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const handleConversationCreated = (conversation: ContentConversation) => {
    setOpen(false)
    navigate(`/conversations/${conversation.contentConversationId}`)
  }
  return (<>
      <Button
        fullWidth
        variant='outlined'
        color='inherit'
        onClick={() => setOpen(true)}
        sx={{
          mb: 4,
        }}
      >
        New Conversation
      </Button>

      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth={true}
        onClose={() => setOpen(false)}
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: "blur(4px)"
            }
          },
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            p: 1,
          }}
        >
          <NewConversationInput onConversationCreated={handleConversationCreated} />
        </Box>
      </Dialog>
    </>
  )
}