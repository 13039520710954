import { MassetBotInput } from './MassetBotInput'
import { useState } from 'react';
import { Asset } from '../../@types/asset'
import { MassetBotMessage, MassetBotMessageType } from '../../@types/massetbot'
import { sendBotMessage } from '../../clients/MassetBotClient'
import { Stack } from '@mui/material'
import { MassetBotMessages } from './MassetBotMessages'
import { MassetBotIntro } from './MassetBotIntro'

interface MassetBotChatProps {
  asset: Asset
}

export function MassetBotChat({ asset }: MassetBotChatProps) {
  const [messages, setMessages] = useState<MassetBotMessage[]>([])
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false)
  const handleSend = (msg: string) => {
    const userMessage = { type: MassetBotMessageType.USER, text: msg }
    setMessages( old => [...old, userMessage])
    setAwaitingResponse(true)
    sendBotMessage(asset.assetId, userMessage, messages).then((data) => {
      const botMessage = { type: MassetBotMessageType.BOT, text: data.botMessage }
      setMessages(old => [...old, botMessage])
      setAwaitingResponse(false)
    })
  }
  return (
    <Stack  spacing={4}>
      <Stack
        spacing={2}
        sx={{
          maxHeight: 450,
          overflowY: "scroll"
        }}
      >
        <MassetBotIntro />
        <MassetBotMessages messages={messages} awaitingResponse={awaitingResponse} />
      </Stack>
      <MassetBotInput onSend={handleSend} />
    </Stack>
  )
}


