import { AssetContentStateErrorCode, AssetContentStateErrorData } from './asset-content-errors.ts'

export type AssetContentState = {
  order: number,
  assetId: string,
  contentType: AssetExtractedContentType,
  status: AssetContentStateStatus,
  errorCode: AssetContentStateErrorCode | null,
  errorData: AssetContentStateErrorData | null,
  updatedAt: Date
}

export enum AssetExtractedContentType {
  RAW_TEXT = 1,
  GENERATED_QUESTIONS = 2,
  DOCUMENT_SUMMARY = 3,
  IMAGE_SUMMARY = 4,
  VIDEO_SUMMARY = 5,
  OCR_TEXT = 6,
  TRANSCRIPTION = 7,
  TOPICS = 8,
  PALETTE = 9,
  LABELS = 10,
  ASSET_DESCRIPTION = 11,
  RAW_FILE = 100
}

export enum AssetContentStateStatus {
  SUCCESS = 1,
  FAILED = 2,
  MISSING = 3,
  IN_PROGRESS = 4
}

export type AssetContentStateResponse = {
  assetId: string,
  states: AssetContentState[]
}

export type GetAssetTranscriptionResponse = AssetTranscription
export type GetAssetDocumentSummaryResponse = AssetDocumentSummary
export type RetryAssetDocumentSummaryResponse = AssetDocumentSummary
export type UpdateAssetDocumentSummaryResponse = AssetDocumentSummary
export type GetAssetGeneratedQuestionsResponse = AssetGeneratedQuestions
export type GetAssetImageSummaryResponse = AssetImageSummary
export type RetryAssetImageSummaryResponse = AssetImageSummary
export type UpdateAssetImageSummaryResponse = AssetImageSummary
export type GetAssetOCRContentsResponse = AssetOCRContents
export type RetryAssetOCRContentsResponse = AssetOCRContents
export type UpdateAssetOCRContentsResponse = AssetOCRContents

export type AssetDocumentSummary = {
  summary: string,
  createdAt: Date | string,
  assetContentId: string
}

export type AssetImageSummary = {
  summary: string,
  createdAt: Date | string,
  assetContentId: string
}

export type AssetOCRContents = {
  contents: string,
  createdAt: Date | string,
  assetContentId: string
}

export type AssetTranscription = {
  segments: TranscriptionSegment[],
  createdAt: Date | string
}

export type TranscriptionSegment = {
  startMs: number | null,
  stopMs: number | null,
  text: string
}

export type AssetGeneratedQuestions = {
  questions: AssetGeneratedQuestion[],
  createdAt: Date | string
}

export type AssetGeneratedQuestion = {
  text: string
}