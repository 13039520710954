import {SearchContext} from "../../contexts/SearchContext";
import Scrollbar from "../Scrollbar";
import {Box, Divider} from "@mui/material";
import {useContext} from "react";
import SearchAssetCard from "./SearchAssetCard";
import SearchCurrentTags from "./SearchCurrentTags";
import SearchOptionsHeader from "./SearchOptionsHeader";
import SearchResultsPagination from "./SearchResultsPagination";
import EmptyContent from "../EmptyContent";
import RequestNewAssetCard from "./RequestNewAssetCard";

export default function SearchResults() {
    const {results} = useContext(SearchContext)
    return (
        <Scrollbar>
            <Box component="main">
                <SearchCurrentTags/>
                <Divider sx={{mt: 3, mb: 4}}/>
                <Box sx={{mb: 5}} >
                   <SearchOptionsHeader />
                </Box>


                {/* results */}
                <Box
                    sx={{
                        display: 'grid',
                        gap: 3,
                        gridTemplateColumns: {
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                            md: 'repeat(3, 1fr)',
                            lg: 'repeat(4, 1fr)',
                        },
                    }}
                >
                    {results.map((asset) => {
                        return (
                            <SearchAssetCard key={asset.assetId} asset={asset}/>
                        )
                    })}

                  <RequestNewAssetCard />
                </Box>

              <Box sx={{ mt: 5 }}>
                <SearchResultsPagination scrollOnPageChange />
              </Box>
            </Box>
        </Scrollbar>
    )
}