import { AssetPreviewType } from '../../@types/asset'

export const srcForPreviewItem = (id: string | null | undefined, assetPreviewType: AssetPreviewType = AssetPreviewType.THUMBNAIL_SM) => id ? `/api/v1/asset-previews/${id}?size=${assetPreviewType}` : ''

export function srcForHostedContentRoomPreviewItem(
  roomShortCode: string,
  assetId: string,
  id: string | null | undefined,
  assetPreviewType: AssetPreviewType = AssetPreviewType.THUMBNAIL_SM,
) {
  return id ? `/api/v1/cr/${roomShortCode}/assets/${assetId}/previews/${id}?size=${assetPreviewType}` : ''
}