import { useCompanyInfo, useUpdateCompanyInfo } from '../../hooks/useCompanySettings'
import { Box, Button, InputAdornment, Stack, Typography } from '@mui/material'
import { FormProvider, RHFTextField } from '../hook-form'
import { useForm } from 'react-hook-form'
import { CompanyInfoResponse } from '../../@types/company'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { DomainClaims } from './DomainClaims'
import Iconify from '../Iconify'
import { useSnackbar } from 'notistack'

export function CompanyInfo() {
  const { data: companyInfo } = useCompanyInfo()
  const { mutateAsync: updateCompanyInfo } = useUpdateCompanyInfo()
  const { enqueueSnackbar } = useSnackbar()
  const methods = useForm<CompanyInfoFormValues>({
    values: getDefaultValues(companyInfo),
    resolver: validator,
    mode: 'onChange',
  })

  // handlers
  const handleSave = async (data: CompanyInfoFormValues) => {
    await updateCompanyInfo(data)
    enqueueSnackbar(
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography component='div' variant='small' noWrap>Your company info has been updated.</Typography>
      </Stack>,
    )
  }

  return (
    <FormProvider methods={methods}>
      <Stack spacing={6}>
        <Box>
          <Typography variant='h4' mb={2}>Company Information</Typography>

          <Stack spacing={3}>
            <RHFTextField
              name='name'
              label='Company Name'
              size='small'
              variant='filled'
            />

            <RHFTextField
              name='websiteUrl'
              label='Website'
              size='small'
              variant='filled'
            />

          </Stack>
        </Box>

        <Box>
          <Typography variant='h4' mb={2}>Social accounts</Typography>
          <Stack spacing={2}>

            <RHFTextField
              label='LinkedIn'
              name='linkedInUrl'
              placeholder='https://www.linkedin.com/company/...'
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Iconify width={24} icon='eva:linkedin-fill' color='#006097' />
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              label='Facebook'
              name='facebookLink'
              placeholder='https://www.facebook.com/...'
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Iconify
                      width={24}
                      icon='eva:facebook-fill'
                      color='#1877F2'
                    />
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              label='Instagram'
              name='instagramUrl'
              placeholder='https://www.instagram.com/...'
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Iconify
                      width={24}
                      icon='ant-design:instagram-filled'
                      color='#DF3E30'
                    />
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              label='X (formerly Twitter)'
              size='small'
              name='twitterUrl'
              placeholder='https://www.twitter.com/...'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Iconify
                      width={24}
                      icon='eva:twitter-fill'
                      color='#1C9CEA'
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Box>

        <Box mt={6}>
          <DomainClaims claims={companyInfo?.domains || []} />
        </Box>

        <Box>
          <Button
            variant='contained'
            size='small'
            onClick={methods.handleSubmit(handleSave)}
          >
            Update Company Information
          </Button>
        </Box>
      </Stack>
    </FormProvider>
  )
}

type CompanyInfoFormValues = {
  name: string,
  websiteUrl: string | null,
  linkedInUrl: string | null,
  facebookUrl: string | null,
  instagramUrl: string | null,
  twitterUrl: string | null,
}

function getDefaultValues(info?: CompanyInfoResponse): CompanyInfoFormValues {
  return {
    name: info?.name || '',
    websiteUrl: info?.websiteUrl || '',
    linkedInUrl: info?.linkedInUrl || '',
    facebookUrl: info?.facebookUrl || '',
    instagramUrl: info?.instagramUrl || '',
    twitterUrl: info?.twitterUrl || '',
  }
}

const validator = yupResolver(Yup.object().shape({
  name: Yup.string(),
  websiteUrl: Yup.string().nullable(),
}))


// todo: move this to common util location
// const getTimezoneOptions = (showOffset: boolean) => {
//   const timeZones = moment.tz.names();
//   const offsetTmz = [];
//   for (const i in timeZones) {
//     const tz = timeZones[i];
//     const tzOffset = moment.tz(tz).format('Z');
//     const value = parseInt(tzOffset
//       .replace(':00', '.00')
//       .replace(':15', '.25')
//       .replace(':30', '.50')
//       .replace(':45', '.75')).toFixed(2);
//     const timeZoneOption = {
//       label: showOffset ? tz + ' (GMT' + tzOffset + ')' : tz,
//       value: value
//     };
//     offsetTmz.push(timeZoneOption);
//   }
//   return offsetTmz;
// }