import { Stack, Typography } from '@mui/material'
import { useContext } from 'react'
import { SearchContext } from '../../contexts/SearchContext'
import { Category, CategoryValue } from '../../@types/category'
import Iconify from '../Iconify'
import { styled } from '@mui/material/styles'
import { useCategoryValuesWithCategories } from '../../hooks/useCategories'

const UNKNOWN_MERGED_VALUE = {
    category: {name: "Unknown"} as Category,
    categoryValue: {value: "Unknown"} as CategoryValue
}

const TagContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? theme.palette.bg.warmGrey0 : theme.palette.background.paper,
  padding: theme.spacing(2),
  minHeight: 48,
  borderRadius: theme.spacing(1),
}));

export default function SearchCurrentTags() {
    const {filters: {categoryValueIds}, removeCategoryValues} = useContext(SearchContext)
    const categoryValuesWithCategories = useCategoryValuesWithCategories()

    return (
        <>
            <Typography variant="smallHighlight" >Tags Currently Selected</Typography>
            <TagContainer
                direction="row"
                spacing={1}
                sx={{mt: 2}}
            >
                {categoryValueIds.length === 0 && (
                    <Typography variant="small" color="text.deemphasized">No tags currently selected</Typography>
                )}
                {categoryValueIds.map(id => {
                    const mergedEntry = categoryValuesWithCategories[id] || UNKNOWN_MERGED_VALUE
                    return (
                        <Stack
                            direction="row"
                            key={id}
                            onClick={() => removeCategoryValues([id])}
                            sx={{cursor: "pointer"}}
                        >
                            <Iconify
                                icon="eva:close-fill"
                                color="text.red"
                            />
                            <Typography variant="small" color="text.secondary">
                                {mergedEntry.category.name}:{mergedEntry.categoryValue.value}
                            </Typography>
                        </Stack>
                    )
                })}
            </TagContainer>
        </>
    )
}