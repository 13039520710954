import { Asset } from '../../@types/asset'
import { Box, Typography } from '@mui/material'
import { ReadOnlyTag } from '../tags/ReadOnlyTag'
import { useCategoryValuesWithCategories } from '../../hooks/useCategories'

type Props = { asset: Asset }

export function AssetTagList({ asset }: Props) {
  const categoryValuesWithCategories = useCategoryValuesWithCategories()
  const toRender =  asset.selectedCategoryValueUUIDs
    .map(uuid => categoryValuesWithCategories[uuid])
    .filter(x => !!x)
  return (
    <Box>
      <Typography variant="h4" sx={{mb: 2}}>Tags</Typography>
      <Box>
        {
          toRender.map(pair => (
              <ReadOnlyTag
                key={pair.categoryValue.categoryValueId}
                categoryValue={pair.categoryValue}
                category={pair.category} />
            ))
        }
      </Box>
      { toRender.length === 0 && (
        <Typography variant="small" color="text.deemphasized">No assigned tags</Typography>
      )}
    </Box>
  )
}

