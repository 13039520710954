import api from '@api'
import {
  GoogleDriveFileAccessResponse, GoogleDriveFilesResponse,
  GooglePickerInfoResponse,
} from '../@types/integrations/google-drive/google-drive'

export async function getGooglePickerInfo(oauthAccountId: string) {
  const response = await api.get<GooglePickerInfoResponse>(`/api/v1/integrations/google-drive/accounts/${oauthAccountId}/picker-info`)
  return response.data
}

export async function getFileAccessInfo(oauthAccountId: string, fileId: string) {
  const response = await api.get<GoogleDriveFileAccessResponse>(`/api/v1/integrations/google-drive/accounts/${oauthAccountId}/files/${fileId}/access`)
  return response.data
}

export async function getGoogleDriveFiles(oauthAccountId: string) {
  const response = await api.get<GoogleDriveFilesResponse>(`/api/v1/integrations/google-drive/accounts/${oauthAccountId}/files`)
  return response.data
}