import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import Iconify from '../../Iconify'
import Typography from '@mui/material/Typography'
import { useAssetTranscription } from '../../../hooks/useAssetContent'
import { Asset } from '../../../@types/asset'
import { fMsToGrowingTimeStamp } from '../../../utils/formatDuration'

interface ViewTranscriptPopoverProps {
  asset: Asset
}

export function ViewTranscriptPopover({ asset }: ViewTranscriptPopoverProps) {
  const [open, setOpen] = useState(false)
  const { data: transcript } = useAssetTranscription(asset.assetId, open)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <>
      <Button
        variant='text'
        size='small'
        onClick={handleOpen}
      >
        View Transcript
      </Button>

      <Dialog
        open={open}
        maxWidth='md'
        fullWidth={true}
        onClose={handleClose}
      >

        <DialogTitle>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Typography variant='h2' paragraph>Transcript (Auto-Generated)</Typography>
        </DialogTitle>

        <DialogContent>
          <Table>
            <TableBody>
              {transcript?.segments.map(function(it, idx) {
                return (
                  <TableRow key={idx}>
                    <TableCell
                      sx={{ textAlign: 'right' }}
                    >
                      <Typography
                        variant='standard'
                        color='text.deemphasized'
                        sx={{ fontFamily: 'monospace' }}
                      >
                        {it.startMs != null ? fMsToGrowingTimeStamp(it.startMs) : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='standard'>
                        {it.text}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })}

            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  )
}