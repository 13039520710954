import { Asset, AssetType } from "../../@types/asset";
import { Box, Typography } from "@mui/material";
import ProgressBar from "../ProgressBar";
import DefaultMetadata from "./DefaultMetadata";
import VideoMetadataCmp from "./VideoMetadataCmp";
import ImageMetadataCmp from "./ImageMetadataCmp";
import AudioMetadataCmp from "./AudioMetadataCmp";
import { Alert } from "@mui/lab";

type AssetMetadataProps = {
    asset: Asset | null
}

export default function AssetMetadataCmp({asset}: AssetMetadataProps) {
    const cmp = getAssetComponent(asset)
    return (
        <Box>
            {cmp}
        </Box>
    )
}

function getAssetComponent(asset: Asset | null){
    if(asset === null){
        return (
          <Alert severity="info" variant="outlined" icon={<></>}>
              <Typography variant="small" component="div" >Hold Tight</Typography>
              <Typography variant="small" color="text.deemphasized">Your asset is being processed for metadata. Large files may take a while.</Typography>
          </Alert>
        )
    }

    switch (asset.assetType){
        case AssetType.VIDEO:
            return <VideoMetadataCmp asset={asset} />
        case AssetType.IMAGE:
            return <ImageMetadataCmp asset={asset} />
        case AssetType.AUDIO:
            return <AudioMetadataCmp asset={asset} />
        default:
            return <DefaultMetadata asset={asset} />
    }
}