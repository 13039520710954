import { Asset } from '../../../../@types/asset.ts'
import { AssetContentState } from '../../../../@types/asset-content.ts'
import { MissingIdErrorData } from '../../../../@types/asset-content-errors.ts'
import { Link, Stack, Typography } from '@mui/material'
import { fDateTime } from '../../../../utils/formatTime.ts'

export function RawFileMissingIdError({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const data = state.errorData as MissingIdErrorData
  return (
    <Stack spacing={2}>
      <Typography variant='standardHighlight' color="warning.main" paragraph>
        Could Not Find Identifier
      </Typography>

      <Typography variant='standard' paragraph>
        We detected this asset as an integration link, and expected to find a unique identifier. However none was found. Please verify the link is correct and try again.
      </Typography>

      <Typography variant='standard' paragraph>
        <Link href={asset.storageExternalRef} target="_blank">{asset.storageExternalRef}</Link>
      </Typography>

      <Typography variant='standard'>We last attempted to download this
        asset <b>{fDateTime(state.updatedAt)}</b></Typography>

    </Stack>
  )
}