import { AnalyticType, OccurenceFilter, OccurrenceOperator } from '../../../@types/analytics/analytics-asset'
import { Box, Button, Divider, Popover, Stack, Typography } from '@mui/material'
import { fNumber } from '../../../utils/formatNumber'
import { useContext, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { AdminAnalyticsContext, LocalCustomFilter } from '../../../contexts/AdminAnalyticsContext'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { FormProvider, RHFSelect, RHFTextField } from '../../hook-form'

export function OccurrenceCustomFilter({ filter }: { filter: LocalCustomFilter }) {
  const { updateCustomOccurrenceFilter, removeCustomFilter } = useContext(AdminAnalyticsContext)

  // state
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const ref = useRef<HTMLDivElement | null>(null)

  // handlers
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleCancel = () => {
    handleClose()
    if (filter.isDraft) {
      removeCustomFilter(filter.id)
    }
  }
  const handleApply = (data: FormValues) => {
    handleClose()
    updateCustomOccurrenceFilter(filter.id, data.metric, data.operator, data.num)
  }

  // effects
  useEffect(() => {
    if (ref.current && filter.isDraft) {
      setAnchorEl(ref.current)
    }
  }, [ref.current, filter.isDraft])

  // calculated props
  if (!filter.occurrenceFilter) return <></>
  const occurrenceFilter = filter.occurrenceFilter
  const metric = ANALYTIC_TYPE_EN[occurrenceFilter.event] || ''
  const operator = OPERATORS_EN[occurrenceFilter.operator] || ''
  const open = Boolean(anchorEl)
  const id = open ? filter.id : undefined

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        spacing={1}
        onClick={handleOpen}
        ref={ref}
        sx={{ cursor: 'pointer' }}
      >
        <Typography variant='small'># {metric}</Typography>
        <Typography variant='small'>{operator}</Typography>
        <Typography variant='smallHighlight'>{fNumber(occurrenceFilter.numOccurrences)}</Typography>
      </Stack>


      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: -4, horizontal: 0 }}
      >
        <Box p={2} pt={3}>
          <OccurrenceFilterForm
            filter={occurrenceFilter}
            onCancel={handleCancel}
            onApply={handleApply}
          />
        </Box>


      </Popover>
    </>
  )
}

type FormValues = {
  metric: AnalyticType
  operator: OccurrenceOperator
  num: number
}
type OccurrenceFilterFormProps = {
  filter: OccurenceFilter,
  onCancel: () => void,
  onApply: (data: FormValues) => void
}

function OccurrenceFilterForm({ filter, onCancel, onApply }: OccurrenceFilterFormProps) {

  // form setup
  const FormSchema = Yup.object().shape({
    metric: Yup.mixed<AnalyticType>()
      .required()
      .test(value => {
        return Object.values(AnalyticType).map(it => it+"").includes(value+"")
      }),
    operator: Yup.mixed<OccurrenceOperator>()
      .required()
      .test(value => ['lt', 'gt', 'eq'].includes(value as string)),
    num: Yup.number().typeError("Must be a number").required().min(0, "Must be greater than or equal to 0"),
  })
  const resolver = yupResolver(FormSchema)
  const defaults = {
    metric: filter.event,
    operator: filter.operator,
    num: filter.numOccurrences,
  }
  const methods = useForm<FormValues>({ resolver, defaultValues: defaults, mode: 'onChange' })
  const { handleSubmit, reset, formState: { isValid } } = methods

  return (
    <Box>
      <FormProvider methods={methods}>
        <Stack direction='column' spacing={2}>
          <RHFSelect
            name='metric'
            label='Event type'
            size='small'
          >
            <option value={AnalyticType.DOWNLOAD}>Number of Downloads</option>
            <option value={AnalyticType.FAVORITE}>Number of Favorites</option>
            <option value={AnalyticType.SHARE}>Number of Shares</option>
            <option value={AnalyticType.VIEW}>Number of Views</option>
          </RHFSelect>

          <RHFSelect
            name='operator'
            label='Is'
            size='small'
          >
            <option value='gt'>Greater Than (&gt;)</option>
            <option value='lt'>Less Than (&lt;)</option>
            <option value='eq'>Equals (=)</option>
          </RHFSelect>

          <RHFTextField
            name='num'
            label='Number of events'
            placeholder=''
            type='number'
            size='small'
          />
        </Stack>

      </FormProvider>

      <Divider sx={{ my: 2 }} />

      <Stack direction='row' spacing={1} alignItems='center' justifyContent='end'>
        <Button color='inherit' variant='outlined' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          variant='contained'
          size='small'
          color='mint'
          onClick={handleSubmit(onApply)}
        >
          Apply
        </Button>
      </Stack>
    </Box>

  )
}


const ANALYTIC_TYPE_EN = {
  [AnalyticType.DOWNLOAD]: 'Downloads',
  [AnalyticType.SHARE]: 'Shares',
  [AnalyticType.VIEW]: 'Views',
  [AnalyticType.FAVORITE]: 'Favorites',
}

const OPERATORS_EN = {
  'eq': '=',
  'lt': '<',
  'gt': '>',
}