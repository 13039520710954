import { DEFAULT_FILTERS, SearchFilters } from "../SearchContext";
import { setFiltersToQueryParams, useSearchParamsAsFilters } from "./searchQueryParamHelpers";
import { useSessionStorage } from "usehooks-ts";
import { useLocation } from "react-router-dom";


export function useStoredSearchFilters(){
  const { state : locationState } = useLocation()
  const [sessionFilters, setSessionFilters] = useSessionStorage<SearchFilters>("search-filters", DEFAULT_FILTERS)
  const queryParamFilters = useSearchParamsAsFilters()

  const storeFilters = (filters: SearchFilters) => {
    setSessionFilters(filters)
    setFiltersToQueryParams(filters)
  }

  // default search state is calculated as such:
  // 1. loaded from prev session search (if preserved)
  // 2. overridden by query params if specified
  const preservedFilters = (locationState as any)?.preservePrevSearch ? sessionFilters : {}
  const filters = {
    ...DEFAULT_FILTERS,
    ...preservedFilters,
    ...queryParamFilters
  }

  return {
    filters,
    storeFilters
  }
}