import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Iconify from '../../components/Iconify'
import Page from '../../components/Page'
import { EditGroupForm } from '../../components/admin/groups/EditGroupForm'

export default function EditGroup() {
  const navigate = useNavigate()

  return (
    <Page title='Edit Group'>
      <Box mb={12}>
        <Button
          variant='text'
          size='small'
          startIcon={<Iconify icon={'eva:chevron-left-fill'} />}
          onClick={() => navigate('/admin/groups')}
        >
          Back to groups
        </Button>
        <Typography
          variant='h2'
          mb={4}
        >
          Create New Group
        </Typography>

        <EditGroupForm group={null} />
      </Box>
    </Page>
  )
}