import { useParams } from 'react-router'
import Page from '../../components/Page'
import { useAccount } from '../../hooks/useAccounts'
import { OAUTH_PROVIDER_NAMES } from '../../@types/oauth'
import { IntegrationDetailsPivot } from '../../components/profile/integrations/details/IntegrationDetailsPivot'

export function ProfileIntegrationDetails(){
  const { accountId } = useParams()
  const { data: account } = useAccount(accountId)
  const providerName = account?.provider && OAUTH_PROVIDER_NAMES[account.provider]
  return (
    <Page title={`${providerName}`}>
      { account && <IntegrationDetailsPivot account={account} /> }
    </Page>
  )
}