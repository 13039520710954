import Page from '../../components/Page'
import { AssetAnalyticsProvider } from '../../contexts/AdminAnalyticsContext'
import { AssetAnalyticsTable } from '../../components/analytics/AssetAnalyticsTable'

export default function GlobalAssetAnalytics() {
  return (
    <Page title='Asset Analytics'>
      <AssetAnalyticsProvider>
        <AssetAnalyticsTable />
      </AssetAnalyticsProvider>
    </Page>
  )
}