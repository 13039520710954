// @mui
// hooks
// components
import { useState } from "react";

import * as React from "react";
import { Box, Dialog, IconButton, Stack, Tab, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Iconify from "../Iconify";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AssetLinkManual } from "./AssetLinkManual";
import { AssetLinkUpload } from "./AssetLinkUpload";


const TriggerZoneStyle = styled(Box)(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(2, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

export type FormValuesProps = {
  location: string;
};


export default function AssetLink() {
  const [open, setOpen] = useState(false)
  const [tab, setTab] = React.useState('manual');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <>
      <TriggerZoneStyle onClick={() => {
        setOpen(true)
      }}>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Iconify icon="eva:link-outline" fontSize={24}/>
          <Typography>Link to an Existing Asset</Typography>
        </Stack>
      </TriggerZoneStyle>

      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setOpen(false)}
      >

        <Box p={7}>
          <Box sx={{ position: "absolute", right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon="eva:close-fill"/>
            </IconButton>
          </Box>

          <Typography variant="h2" mb={3}>Link an Asset</Typography>

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 5 }}>
              <TabList onChange={handleTabChange}>
                <Tab label="By Link" value="manual"/>
                <Tab label="By File Upload" value="upload"/>
              </TabList>
            </Box>
            <TabPanel value="manual"><AssetLinkManual setOpen={setOpen}/></TabPanel>
            <TabPanel value="upload"><AssetLinkUpload setOpen={setOpen}/></TabPanel>
          </TabContext>
        </Box>
      </Dialog>
    </>
  );
}
