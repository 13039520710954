import {
  CreateContentConversationMessageResponse,
  CreateContentConversationResponse, GetContentConversationMessageResponse, GetContentConversationMessagesResponse,
  GetContentConversationResponse,
  GetConversationsResponse, MessageFeedbackRating, MessageFeedbackType, UpsertMessageFeedbackResponse,
} from '../@types/conversation'
import api from '@api'

export async function getConversations() {
  const response = await api.get<GetConversationsResponse>('/api/v1/content-conversations')
  return response.data
}

export async function createConversation(initialMessage: string) {
  const response = await api.post<CreateContentConversationResponse>('/api/v1/content-conversations', { initialMessage })
  return response.data
}

export async function getConversation(conversationId: string) {
  const response = await api.get<GetContentConversationResponse>(`/api/v1/content-conversations/${conversationId}`)
  return response.data
}

export async function getConversationMessages(conversationId: string) {
  const response = await api.get<GetContentConversationMessagesResponse>(
    `/api/v1/content-conversations/${conversationId}/messages`,
  )
  return response.data
}

export async function getConversationMessage(conversationId: string, messageId: string) {
  const response = await api.get<GetContentConversationMessageResponse>(
    `/api/v1/content-conversations/${conversationId}/messages/${messageId}`,
  )
  return response.data
}

export async function addToConversation(conversationId: string, message: string) {
  const response = await api.post<CreateContentConversationMessageResponse>(
    `/api/v1/content-conversations/${conversationId}/messages`,
    { message },
  )
  return response.data
}

export async function deleteConversation(conversationId: string) {
  const response = await api.delete(`/api/v1/content-conversations/${conversationId}`)
  return response.data
}

export async function upsertMessageFeedback(
  conversationId: string,
  messageId: string,
  rating: MessageFeedbackRating,
  feedbackType: MessageFeedbackType | null = null,
  moreDetails: string | null = null,
) {
  const response = await api.put<UpsertMessageFeedbackResponse>(
    `/api/v1/content-conversations/${conversationId}/messages/${messageId}/feedback/upsert`,
    { rating, feedbackType, moreDetails }
  )
  return response.data
}
