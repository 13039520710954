import { Asset } from '../../../../../@types/asset'
import TopUsers, { NO_LIMIT } from '../common/TopUsers'
import { TimeRange } from '../../../../../@types/analytics/analytics-common'
import { useState } from 'react'
import AllTopUsersDialog from '../common/AllTopUsersDialog'
import { TopDownloader } from '../../../../../@types/analytics/downloads'
import { useTopDownloaders } from '../../../../../hooks/analytics/useAssetDownloadAnalytics'

export default function TopDownloaders({ asset, range }: { asset: Asset, range: TimeRange }) {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { data } = useTopDownloaders(asset.assetId, 5, { range })
  const { data: allDownloadersData } = useTopDownloaders(asset.assetId, NO_LIMIT, { range }, modalOpen)
  const allDownloaders = allDownloadersData?.downloaders.map(toUserListItem)
  const topDownloaders = data?.downloaders.map(toUserListItem)
  return (
    <>
      <TopUsers
        title='Top Downloaders'
        list={topDownloaders}
        moreResults={data?.hasMore}
        onSeeAllClick={() => setModalOpen(true)}
      />

      <AllTopUsersDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        users={allDownloaders}
      />
    </>
  )
}

function toUserListItem(it: TopDownloader){
  return {
    userId: it.userId,
    firstName: it.firstName,
    lastName: it.lastName,
    metricValue: it.numDownloads,
    metricLabel: it.numDownloads === 1 ? 'download' : 'downloads',
    metricIcon: 'eva:download-fill'
  }
}