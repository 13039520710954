import { useQuery } from '@tanstack/react-query'
import { getContentAudit, getContentAuditSummary } from '../clients/ContentAuditClient.ts'
import { ContentAnalysisFilterContext, ContentAnalysisFilterState } from '../contexts/ContentAnalysisFilterContext.tsx'
import { useContext } from 'react'
import { ContentAnalysisRequestOptions } from '../@types/content-audit.ts'

export function useContentAudit() {
  const context = useContext(ContentAnalysisFilterContext)
  const options = toOptions(context)
  return useQuery({
    queryKey: ['content-audit', options],
    queryFn: async () => getContentAudit(options)
  })
}

export function useContentAuditSummary() {
  return useQuery({
    queryKey: ['content-audit', 'summary'],
    queryFn: async () => getContentAuditSummary(),
    staleTime: 3000
  })
}

function toOptions(filters: ContentAnalysisFilterState): ContentAnalysisRequestOptions {
  return {
    sortBy: filters.sortBy,
    sortDirection: filters.sortDirection,
    includedGrades: [...filters.includedGrades],
    hasFileAccess: filters.hasFileAccess,
    availableInConversations: filters.availableInConversations,
    offset: filters.currentPage * filters.pageSize,
    limit: filters.pageSize,
  }
}
