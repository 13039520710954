// @mui
import * as Yup from 'yup';
// hooks
// components
import { FormProvider, RHFUploadMultiFile } from "../hook-form";
import { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import { CustomFile } from "../upload";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { AssetUploadContext } from "../../contexts/AssetUploadContext";
import { FileRejection } from "react-dropzone";


// ----------------------------------------------------------------------

export type FormValuesProps = {
  files: CustomFile[];
};


export const UPLOAD_LIMIT_SIZE = 1024 * 1024 * 1024 * 8 // 8 gb

export default function AssetFileUpload() {
  const navigate = useNavigate()
  const { createUploadBatch } = useContext(AssetUploadContext)

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(Yup.object().shape({
      files: Yup.array(),
    })),
    defaultValues: { files: [] },
  });

  const { setValue } = methods;

  const handleDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setValue('files', acceptedFiles);

      // trigger the server actions
      if(acceptedFiles.length > 0){
        createUploadBatch(acceptedFiles, rejectedFiles).then(() => {
          navigate("/assets/upload/finalize")
        })
      }

      // if there are no accepted files, it will fallthrough to here
    },
    []
  );

  return (
    <FormProvider methods={methods}>
      <RHFUploadMultiFile
        name="files"
        maxSize={UPLOAD_LIMIT_SIZE}
        maxFiles={40}
        onDrop={handleDrop}
        showPreview={false}
      />
    </FormProvider>
  );
}
