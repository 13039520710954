import api from '@api'
import { GetSlackUserRegistrationResponse } from '../@types/integrations/slack/slack'

export const getSlackUserRegistrationBySlackIds = async (teamId: string, userId: string) => {
  const params = { slackTeamId: teamId, slackUserId: userId }
  const response = await api.get<GetSlackUserRegistrationResponse>(
    `/api/v1/integrations/slack/user-registrations/lookup`,
    { params }
  )
  return response.data
}

export const createSlackUserRegistration = async (teamId: string, userId: string) => {
  const data = { slackTeamId: teamId, slackUserId: userId }
  const response = await api.post(`/api/v1/integrations/slack/user-registrations`, data)
  return response.data
}

export const deleteSlackUserRegistration = async (registrationId: string) => {
  const response = await api.delete(`/api/v1/integrations/slack/user-registrations/${registrationId}`)
  return response.data
}