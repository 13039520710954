import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { Alert, Stack, Typography } from "@mui/material";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { LoadingButton } from "@mui/lab";
import { createPasswordResetToken } from "../../clients/PasswordTokenClient";
import { useState } from "react";

const FORM_VALIDATOR = yupResolver(Yup.object().shape({
  email: Yup.string().required("Email is required")
}), { abortEarly: false });

const DEFAULT_VALUES = {
  email: ""
};

type FormValues = {
  email: string;
};


export default function PasswordResetRequestForm({ onSubmitted }: { onSubmitted: () => void }) {
  const [isError, setIsError] = useState<boolean>(false);

  const methods = useForm<FormValues>({
    resolver: FORM_VALIDATOR,
    defaultValues: DEFAULT_VALUES,
    criteriaMode: "all",
    mode: "onChange"
  });

  const {
    handleSubmit,
    formState: { isValid, errors, isSubmitting }
  } = methods;

  const onError = () => setIsError(true);

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await createPasswordResetToken(data.email);
      if (response.status === 200) {
        onSubmitted()
      } else {
        onError()
      }
    } catch (error) {
      onError()
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} mb={4}>
        {
          isError && (
            <Alert severity="error" variant="outlined">
              <Typography variant="small">Hmm... something went wrong. Try requesting again.</Typography>
            </Alert>
          )
        }

        <RHFTextField
          name="email"
          label="Email"
          size="small"
          variant="filled"
        />

      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        disabled={!isValid}
      >
        Request Password Reset
      </LoadingButton>
    </FormProvider>
  );
}