import api from '@api'
import {
  GetHostedContentRoomAssetPreviewMetadataResponse,
  GetHostedContentRoomAssetPreviewsResponse,
  GetHostedContentRoomAssetResponse,
  GetHostedContentRoomAssetsResponse,
  GetHostedContentRoomBootstrapResponse,
} from '../@types/hosted-content-room'
import { AssetPreviewType } from '../@types/asset.ts'

export async function getHostedContentRoomBootstrap(shortCode: string) {
  const { data } = await api.get<GetHostedContentRoomBootstrapResponse>(`/api/v1/cr/${shortCode}/bootstrap`)
  return data
}

export async function loginToHostedContentRoom(shortCode: string, password: string) {
  const { data } = await api.post<GetHostedContentRoomBootstrapResponse>(`/api/v1/cr/${shortCode}/login`, { password })
  return data
}

export async function getHostedContentRoomAssets(shortCode: string, offset: number = 0) {
  const params = { offset, limit: 100 }
  const { data } = await api.get<GetHostedContentRoomAssetsResponse>(`/api/v1/cr/${shortCode}/assets`, { params })
  return data
}

export async function getHostedContentRoomAsset(shortCode: string, assetId: string) {
  const { data } = await api.get<GetHostedContentRoomAssetResponse>(`/api/v1/cr/${shortCode}/assets/${assetId}`)
  return data
}

export async function getHostedContentRoomAssetPreviews(shortCode: string, assetId: string) {
  const { data } = await api.get<GetHostedContentRoomAssetPreviewsResponse>(`/api/v1/cr/${shortCode}/assets/${assetId}/previews`)
  return data
}

export async function getHostedContentRoomAssetPreviewMetadata(
  shortCode: string,
  assetId: string,
  previewId: string,
  type: AssetPreviewType
) {
  const { data } = await api.get<GetHostedContentRoomAssetPreviewMetadataResponse>(
    `/api/v1/cr/${shortCode}/assets/${assetId}/previews/${previewId}/metadata`,
    { params: { size: type } },
  )
  return data
}

export async function setPCRAssetFavorite(shortCode: string, assetId: string, isFavorite: boolean) {
  const { data } = await api.put(`/api/v1/cr/${shortCode}/assets/${assetId}/favorite`, { isFavorite })
  return data
}