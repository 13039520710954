import { Asset } from '../../../@types/asset'
import { TimeRange } from '../../../@types/analytics/analytics-common'
import { Grid } from '@mui/material'
import TotalDownloads from '../../asset-detail/tabs/analytics/download/TotalDownloads'
import DownloadRank from '../../asset-detail/tabs/analytics/download/DownloadRank'
import BucketedDownloads from '../../asset-detail/tabs/analytics/download/BucketedDownloads'
import TopDownloaders from '../../asset-detail/tabs/analytics/download/TopDownloaders'

export default function DownloadMetricsTableRowLayout({ asset, range }: { asset: Asset, range: TimeRange }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TotalDownloads asset={asset} range={range} />
      </Grid>
      <Grid item xs={6}>
        <DownloadRank asset={asset} range={range} />
      </Grid>
      <Grid item xs={6}>
        <BucketedDownloads asset={asset} range={range} />
      </Grid>
      <Grid item xs={6}>
        <TopDownloaders asset={asset} range={range} />
      </Grid>
    </Grid>
  )
}