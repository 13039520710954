import { FormProvider } from '../../../hook-form'
import { useForm } from 'react-hook-form'
import { AssetShareField, InternalFormShareItem } from '../field/AssetShareField'
import { GeneralAccessType, GroupShareItem, ShareLevel, SharingItem, UserShareItem } from '../../../../@types/sharing'
import { useContext, useMemo } from 'react';
import { internalFormShareForSharingItem } from '../field/AssetShareFieldHooks'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Iconify from '../../../Iconify'
import { useUpdateAssetShares } from '../../../../hooks/useSharing'
import { DialogContext } from '../../../../contexts/DialogContext'
import { GeneralAccessSelector } from './GeneralAccessSelector'
import { DEFAULT_SHARING_ACCESS_TYPE, DEFAULT_SHARING_SHARE_LEVEL } from '../../../../utils/appConstants'

export type InternalAssetShareFormValues = {
  shares: InternalFormShareItem[],
  generalAccessType: GeneralAccessType,
  generalAccessShareLevel: ShareLevel | null
}

type AssetShareInternalFormProps = {
  assetId: string,
  shares: SharingItem[],
  generalAccessType: GeneralAccessType,
  generalAccessShareLevel: ShareLevel | null
}

export function AssetShareInternalForm({ assetId, shares, generalAccessType, generalAccessShareLevel  }: AssetShareInternalFormProps) {
  const { setOpen } = useContext(DialogContext)
  const { mutateAsync: updateShares, isLoading } = useUpdateAssetShares()
  const defaultShares = useMemo(() => {
    return shares.map(it => internalFormShareForSharingItem(it, it.shareLevel))
  }, [shares])
  const methods = useForm<InternalAssetShareFormValues>({
    defaultValues: {
      shares: defaultShares,
      generalAccessType: generalAccessType || DEFAULT_SHARING_ACCESS_TYPE,
      generalAccessShareLevel: generalAccessShareLevel || DEFAULT_SHARING_SHARE_LEVEL
    },
    mode: 'onChange',
  })
  const { handleSubmit: rhfHandleSubmit } = methods

  // handlers
  const handleClose = () => setOpen(false)
  const handleSubmit = async (data: InternalAssetShareFormValues) => {
    const toUpdate = data.shares.map(it => ({
      groupId: (it.share as GroupShareItem | null)?.groupId,
      userId: (it.share as UserShareItem | null)?.userId,
      shareLevel: it.shareLevel,
    }))
    const generalAccessType = data.generalAccessType
    const generalAccessShareLevel = generalAccessType == GeneralAccessType.RESTRICTED ? null : data.generalAccessShareLevel
    await updateShares({ assetId: assetId, shares: toUpdate, generalAccessType, generalAccessShareLevel })
    handleClose()
  }
  const handleCancel = () => handleClose()

  return (
    <>
      <FormProvider methods={methods}>
        <AssetShareField name='shares' />
        <Divider sx={{ my: 3 }} />
        <Typography variant="h4" my={2} px={5}>General Access</Typography>
        <Box px={5}>
          <GeneralAccessSelector accessTypeName="generalAccessType" accessLevelName="generalAccessShareLevel" />
        </Box>
      </FormProvider>

      <Divider sx={{ my: 3 }} />

      <Stack spacing={2} px={5} mb={3}>
        <LoadingButton
          loadingPosition='start'
          loading={isLoading}
          fullWidth={true}
          startIcon={<Iconify icon='eva:arrow-ios-forward-fill' />}
          variant='contained'
          color='primary'
          onClick={rhfHandleSubmit(handleSubmit)}
        >
          Save
        </LoadingButton>
        <Button
          fullWidth={true}
          variant='text'
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Stack>
    </>
  )
}