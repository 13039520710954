import Page from '../components/Page';
import AssetFileUpload from "../components/asset-upload/AssetFileUpload";
import AssetLink from "../components/asset-upload/AssetLink";
import { Box, Divider, Stack, Typography } from "@mui/material";


// ----------------------------------------------------------------------

export default function AssetUpload() {
    return (
        <Page title="Asset Upload">
          <Stack direction="column" spacing={5}>
            <AssetFileUpload />
            <Divider sx={{width: "66%", alignSelf: "center"}}>
              <Typography color="text.deemphasized">or</Typography>
            </Divider>
            <Box sx={{width: "66%", alignSelf: "center"}}>
              <AssetLink />
            </Box>
          </Stack>

        </Page>
    );
}
