import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { Alert, Stack, Typography } from "@mui/material";
import RHFPasswordField from "../../components/hook-form/RHFPasswordField";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { LoadingButton } from "@mui/lab";
import { resetPasswordByToken } from "../../clients/PasswordTokenClient";
import { useState } from "react";

const FORM_VALIDATOR = yupResolver(Yup.object().shape({
  newPassword: Yup.string()
    .password()
    .min(12)
    .required("New Password is required"),
  newPasswordConfirmation: Yup.string().test("passwords-match", "Passwords must match", function(value) {
    return this.parent.newPassword === value;
  })
}), { abortEarly: false });

const DEFAULT_VALUES = {
  newPassword: "",
  newPasswordConfirmation: ""
};
type FormValues = {
  newPassword: string;
  newPasswordConfirmation: string;
  afterSubmit?: string; // error message after submit
};


export default function PasswordResetSubmitForm({ resetToken, onSuccess }: {
  resetToken: string,
  onSuccess: () => void
}) {
  const [isError, setIsError] = useState<boolean>(false)
  const methods = useForm<FormValues>({
    resolver: FORM_VALIDATOR,
    defaultValues: DEFAULT_VALUES,
    criteriaMode: "all",
    mode: "onChange"
  });

  const {
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = methods;

  const onError = () => setIsError(true)

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await resetPasswordByToken(resetToken, data);
      if (response.status === 200) {
        onSuccess();
      } else {
        onError();
      }
    } catch (error) {
      onError();
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} mb={4}>
        {
          isError && (
            <Alert severity="error" variant="outlined">
              <Typography >Hmm... something went wrong</Typography>
              <Typography variant="small">Verify that your password meets minimum requirements and try again.</Typography>
            </Alert>
          )
        }

        <RHFPasswordField
          name="newPassword"
          label="Password"
          size="small"
          variant="filled"
        />

        <RHFTextField
          name="newPasswordConfirmation"
          label="Confirm your Password"
          type="password"
          size="small"
          variant="filled"
        />

      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        disabled={!isValid}
      >
        Reset Password
      </LoadingButton>
    </FormProvider>
  );
}