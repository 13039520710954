import { Box, MenuItem, Select, SelectChangeEvent, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Asset } from "../../../@types/asset";
import { useMemo, useState } from 'react';
import * as React from 'react';
import { AnalyticsInterval, timeRangeForInterval } from '../../../@types/analytics/analytics-common'
import DownloadMetricsAssetDetailsLayout from "./analytics/DownloadMetricsAssetDetailsLayout";
import ShareMetricsAssetDetailsLayout from "./analytics/ShareMetricsAssetDetailsLayout";
import ViewMetricsAssetDetailsLayout from "./analytics/ViewMetricsAssetDetailsLayout";

type MetricType = "download" | "share" | "view"

export default function AssetDetailAnalyticsTab({ asset }: { asset: Asset }) {
  const [interval, setInterval] = useState<AnalyticsInterval>(AnalyticsInterval.LAST_30_DAYS);
  const [metric, setMetric] = useState<MetricType>("download");
  const handleIntervalChange = (event: React.MouseEvent<HTMLElement>, newInterval: AnalyticsInterval) => {
    setInterval(newInterval);
  };
  const handleMetricChange = (event: SelectChangeEvent) => {
    setMetric(event.target.value as MetricType);
  };
  const range = useMemo(() => timeRangeForInterval(interval), [interval])

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box>
          <Select
            size="small"
            value={metric}
            defaultValue={metric}
            onChange={handleMetricChange}
          >
            <MenuItem value="download">Downloads</MenuItem>
            <MenuItem value="share">Shares</MenuItem>
            <MenuItem value="view">Views</MenuItem>
          </Select>
        </Box>

        <ToggleButtonGroup
          size="small"
          color="primary"
          value={interval}
          exclusive
          onChange={handleIntervalChange}
          aria-label="Interval"
        >
          <ToggleButton value={AnalyticsInterval.LAST_12_MONTHS} sx={{ px: 1, py: "2px" }}>12 Months</ToggleButton>
          <ToggleButton value={AnalyticsInterval.LAST_30_DAYS} sx={{ px: 1, py: "2px" }}>30 Days</ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <Box>
        {metric === "download" && <DownloadMetricsAssetDetailsLayout asset={asset} range={range} />}
        {metric === "share" && <ShareMetricsAssetDetailsLayout asset={asset} range={range} />}
        {metric === "view" && <ViewMetricsAssetDetailsLayout asset={asset} range={range} />}
      </Box>
    </Stack>
  );
}
