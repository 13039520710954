import { createContext, ReactNode, useState } from 'react'

type IAssetShareFieldContext = {
  fieldName: string,
  searchTerm: string,
  setSearchTerm: (term: string) => void
}

const AssetShareFieldContext = createContext<IAssetShareFieldContext>({} as IAssetShareFieldContext)

function AssetShareFieldProvider({fieldName, children}: {fieldName: string, children: ReactNode}){
  const [searchTerm, setSearchTerm] = useState<string>('')
  return (
    <AssetShareFieldContext.Provider
      value={{
       fieldName,
        searchTerm,
        setSearchTerm
      }}
    >
      {children}
    </AssetShareFieldContext.Provider>
  )
}

export {AssetShareFieldContext, AssetShareFieldProvider}