// CONVERSATIONS
export type GetContentConversationResponse = ContentConversation
export type CreateContentConversationResponse = ContentConversation
export type UpdateContentConversationResponse = ContentConversation
export type GetConversationsResponse = {
  conversations: ContentConversation[]
}

export type ContentConversation = {
  contentConversationId: string,
  name: string,
  createdAt: string | Date
}

export type ConversationGrouping = {
  name: string,
  priority: number,
  conversations: ContentConversation[]
}

// MESSAGES
export type GetContentConversationMessageResponse = ContentConversationMessage
export type CreateContentConversationMessageResponse = ContentConversationMessage
export type UpdateContentConversationMessageResponse = ContentConversationMessage
export type GetContentConversationMessagesResponse = {
  messages: ContentConversationMessage[]
}

export type UpsertMessageFeedbackResponse = MessageFeedback

export type ContentConversationMessage = {
  contentConversationMessageId: string
  contentConversationId: string
  userQuery: string
  response: string | null,
  status: MessageStatus,
  category: MessageCategory | null,
  relatedQuestions: MessageRelatedQuestion[]
  sources: MessageSource[],
  searchResults: MessageSearchResult[],
  createdAt: string | Date,
  feedback: MessageFeedback | null
}

export type MessageFeedback = {
  contentConversationMessageFeedbackId: string,
  contentConversationMessageId: string,
  rating: MessageFeedbackRating | null,
  feedbackType: MessageFeedbackType | null,
  moreDetails: string | null
}

export type MessageRelatedQuestion = {
  contentConversationMessageRelatedQuestionId: string,
  contentConversationMessageId: string,
  questionText: string,
}

export type MessageSource = {
  contentConversationMessageSourceId: string,
  contentConversationMessageId: string,
  sourceNum: number,
  assetId: string | null,
  assetName: string | null,
  assetDescription: string | null,
  assetPreviewId: string | null,
  chunks: MessageSourceChunk[]
}

export type MessageSearchResult = {
  contentConversationMessageSearchResultId: string,
  contentConversationMessageId: string,
  assetId: string | null,
  assetName: string | null,
  assetDescription: string | null,
  assetPreviewId: string | null,
  sortOrder: number,
  chunk: MessageSearchResultChunk | null
}

export type MessageSourceChunk = {
  contentConversationMessageSourceId: string,
  contentConversationMessageChunkId: string,
  assetChunkId: string,
  content: string | null,
  score: number
}

export type MessageSearchResultChunk = {
  contentConversationMessageSourceId: string,
  contentConversationMessageChunkId: string,
  assetChunkId: string,
  content: string | null,
}

export enum MessageStatus {
  CREATED = 1,
  QUEUED = 2,
  GENERATING_RESPONSE = 10,
  GENERATING_QUESTIONS = 20,
  COMPLETE = 100
}

export enum MessageCategory {
  QUESTION_AND_ANSWER = 1,
  SEARCH= 2,
  OTHER = 3
}

export enum MessageFeedbackRating {
  POSITIVE = 1,
  NEGATIVE = 2
}

export enum MessageFeedbackType {
  FACTUALLY_INCORRECT = 1,
  MISSING_SOURCES = 2,
  INCOMPLETE_ANSWER = 3,
  BAD_STYLING = 4,
  UNABLE_TO_EXECUTE_REQUEST = 5,
  REFUSED_TO_EXECUTE_REQUEST = 6,
  OTHER = 100
}