import { Rank, TimeRange } from '../@types/analytics/analytics-common'
import api from '@api'
import { BucketedShares, TopSharers, TotalShares } from '../@types/analytics/shares'

export async function getTopSharers(assetId: string, options: { range: TimeRange }, limit: number = 5) {
  const params = { limit, start: options.range?.start, end: options.range?.end }
  const response = await api.get<TopSharers>(`/api/v1/analytics/assets/${assetId}/shares/top-users`, { params })
  return response.data
}

export async function getBucketedShares(assetId: string, options: { range: TimeRange }) {
  const params = { start: options.range?.start, end: options.range?.end }
  const response = await api.get<BucketedShares>(`/api/v1/analytics/assets/${assetId}/shares/bucketed`, { params })
  return response.data
}

export async function getShareRank(assetId: string, options: { range: TimeRange }) {
  const params = { start: options.range?.start, end: options.range?.end }
  const response = await api.get<Rank>(`/api/v1/analytics/assets/${assetId}/shares/rank`, { params })
  return response.data
}

export async function getTotalShares(assetId: string, options: { range: TimeRange }) {
  const params = { start: options.range?.start, end: options.range?.end }
  const response = await api.get<TotalShares>(`/api/v1/analytics/assets/${assetId}/shares/total`, { params })
  return response.data
}