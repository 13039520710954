import { ReactNode } from 'react'
import { AssetPermission } from '../@types/asset'
import { ShareLevel } from '../@types/sharing'
import { useHasPermissionForAccessLevel } from '../hooks/usePermissions'

type Props = {
  permission: AssetPermission
  level: ShareLevel,
  children: ReactNode;
};

export function VisibleForPermission({ permission, level, children }: Props) {
  const isPermitted = useHasPermissionForAccessLevel(permission, level)
  if(!isPermitted) {
    return <></>;
  }
  return <>{children}</>;
}

