import api from "@api";
import { PasswordResetDto, PasswordResetTokenResponse } from "../@types/password-reset";

export const createPasswordResetToken = async (email: string) => {
  const response = await api.post(`/api/v1/password-reset-token`, { email })
  return response
}

export const getPasswordResetToken = async (resetToken: string) => {
  const response = await api.get<PasswordResetTokenResponse>(`/api/v1/password-reset-token/${resetToken}`)
  return response.data
}

export const resetPasswordByToken = async(resetToken: string, passwordResetDto: PasswordResetDto) => {
  const response = await api.post(`/api/v1/password-reset-token/${resetToken}/reset`, passwordResetDto)
  return response
}

