import { OauthProvider } from '../../../@types/oauth'
import Image from '../../Image'
import { Box } from '@mui/material'

export function IntegrationLogo({ provider, size = "medium", objectPosition = 'top' }: {
  provider: OauthProvider,
  objectPosition?: 'top' | 'center',
  size?: "small" | "medium" | "large"
}) {
  const src = `/assets/integrations/${provider}.png`
  const dimensions = SIZES[size] || 64
  return (
    <Box
      sx={{
        height: dimensions,
        width: dimensions,
        borderRadius: 8,
      }}
    >
      <Image
        src={src}
        ratio='1/1'
        backgroundType='contain'
        sx={{
          'img': {
            objectPosition: objectPosition,
          },
        }}
      />
    </Box>
  )
}

const SIZES = {
  "small" : 32,
  "medium" : 64,
  "large" : 96
}