import { ReactNode } from "react";
import { Box, Typography } from "@mui/material";

type AssetPropertyProps = { title: ReactNode, property: ReactNode }
export default function AssetProperty({ title, property }: AssetPropertyProps) {
  return (
    <Box>
      <Typography component="div" variant="small" color="text.deemphasized">{title}</Typography>
      <Typography component="div" variant="small">{property}</Typography>
    </Box>
  )
}
