import { ProfileUserInfo, UpdatePasswordRequest, UpdatePasswordResponse, UpdateProfileError } from "../@types/profile";
import { createContext, ReactNode, useReducer } from "react";
import { ActionMap } from "../@types/reducer";
import * as ProfileClient from "../clients/ProfileClient"

type State = {
  userInfo: ProfileUserInfo | undefined
}

type ActionCreators = {
  loadUserInfo: () => Promise<ProfileUserInfo>
  updateUserInfo: (updateUserInfoRequest: ProfileUserInfo) => Promise<ProfileUserInfo | UpdateProfileError>
  updatePassword: (updatePasswordRequest: UpdatePasswordRequest) => Promise<UpdatePasswordResponse>
}

// create the context
type ContextType = State & ActionCreators
const Context = createContext<ContextType>({} as ContextType)

// define the valid set of action types
enum ActionTypes {
  SetUserInfo = "LOAD_USER_INFO"
}

// define the valid set of Payloads
type Payload = {
  [ActionTypes.SetUserInfo]: { userInfo: ProfileUserInfo }
}

// define the valid set of Actions
type Action = ActionMap<Payload>[keyof ActionMap<Payload>]

// define the reducer
const Reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.SetUserInfo:
      return { ...state, userInfo: action.payload.userInfo }
    default:
      return state
  }
}

// define the HoC for the context provider (so we can wrap action functions)
const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, { userInfo: undefined });

  const loadUserInfo = async () => {
    const userInfo = await ProfileClient.loadUserInfo()
    dispatch({ type: ActionTypes.SetUserInfo, payload: { userInfo } })
    return userInfo
  }

  const updateUserInfo = async (updateUserInfoRequest: ProfileUserInfo) => {
    try{
      const userInfo = await ProfileClient.updateUserInfo(updateUserInfoRequest)
      dispatch({ type: ActionTypes.SetUserInfo, payload: { userInfo } })
      return userInfo
    }catch(e){
      return e.response.data as UpdateProfileError
    }
  }

  const updatePassword = async (updatePasswordRequest: UpdatePasswordRequest) => {
    const result = await ProfileClient.updatePassword(updatePasswordRequest)
    return result
  }

  return (
    <Context.Provider
      value={{
        ...state,
        loadUserInfo,
        updateUserInfo,
        updatePassword
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context as ProfileContext, Provider as ProfileProvider }