import { useCallback } from 'react'
import { useEventListener } from 'usehooks-ts'

type Props = {
  onSuccess?: (accountId: string) => void
}

export function useOauthPopup(props?: Props){

  const handleMessage = (event: MessageEvent) => {
    if(event.data.source == "oauth-popup"){
      props?.onSuccess && props?.onSuccess(event.data.accountId)
    }
  }
  useEventListener("message", handleMessage)

  const trigger = useCallback(function(slug: string){
    const width = 1024;
    const height = 960;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = `Masset - Authorize External Account`;
    const url = `/api/v1/oauth/initialize/${slug}`;
    window.open(url, title, `width=${width},height=${height},left=${left},top=${top},popup=true`);
  }, [])

  return { trigger }
}