export enum ShareLevel {
  NONE = 0,
  VIEW = 1,
  EDIT = 2,
  OWNER = 3
}

export enum GeneralAccessType {
  RESTRICTED = 1,
  ORGANIZATION = 2
}

export enum ShareType {
  USER = 1,
  GROUP = 2
}

export type SharingSearchResponse = {
  shares: SharingSearchResult[]
}

export type GroupShareItem = {
  shareType: ShareType.GROUP,
  shareLevel: ShareLevel,
  uniqueId: string,
  assetId: string,
  groupId: string,
  name: string,
  numMembers: number,
}

export type UserShareItem = {
  shareType: ShareType.USER,
  shareLevel: ShareLevel,
  uniqueId: string,
  assetId: string,
  userId: string,
  name: string,
  email: string
}

export type SharingItem = UserShareItem | GroupShareItem
export type SharingSearchResult = Omit<UserShareItem, "shareLevel" | "assetId"> | Omit<GroupShareItem, "shareLevel" | "assetId">

export type AssetSharesResponse = {
  shares: SharingItem[],
  generalAccessType: GeneralAccessType,
  generalAccessShareLevel: ShareLevel | null
}

export type UpdateAssetSharesRequest = {
  shares: UpdateAssetShareItem[]
}

export type UpdateAssetShareItem = {
  groupId?: string,
  userId?: string,
  shareLevel: ShareLevel
}