import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room.ts'
import { useHostedContentRoomAssetPreviews } from '../../../../hooks/useHostedContentRoom.ts'
import { DefaultAssetSinglePageDisplay } from './DefaultAssetSinglePageDisplay.tsx'
import { DefaultAssetMultiPageDisplay } from './DefaultAssetMultiPageDisplay.tsx'

export function DefaultAssetDisplay({ asset }: { asset: HostedContentRoomAsset }) {
  const { data: result, isLoading, isError } = useHostedContentRoomAssetPreviews(asset.roomShortCode, asset.assetId)
  if(isLoading){
    return <></>
  }

  if(isError){
    return <></>
  }

  if(result.previews.length == 1){
    return <DefaultAssetSinglePageDisplay asset={asset} preview={result.previews[0]} />
  }

  if(result.previews.length > 1){
    return <DefaultAssetMultiPageDisplay asset={asset} previews={result.previews} />
  }

  return <></>
}