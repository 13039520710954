import Page from '../../components/Page'
import { useHostedContentRoomAsset } from '../../hooks/useHostedContentRoom'
import { useParams } from 'react-router'
import { AssetDisplayPivot } from '../../components/hosted-content-room/asset/display/AssetDisplayPivot.tsx'
import { CircularProgress, Paper, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import Iconify from '../../components/Iconify.tsx'
import { m } from 'framer-motion'
import { varBounce } from '../../components/animate'
import { HostedContentRoomModalLayout } from '../../layouts/hosted-content-room/HostedContentRoomModalLayout.tsx'
import { useTheme } from '@mui/material/styles'
import { useHostedContentRoomContext } from '../../contexts/HostedContentRoomContext.tsx'

export function HostedContentRoomAssetPage() {
  const { shortCode, assetShortCode } = useParams()
  const { room } = useHostedContentRoomContext()
  const {
    data: asset,
    isLoading: assetIsLoading,
    isError,
    error,
  } = useHostedContentRoomAsset(shortCode!!, assetShortCode)

  if (assetIsLoading) {
    return <LoadingPage />
  }

  if (isError) {
    return <ErrorPage error={error as AxiosError} />
  }

  return (
    <Page title={asset.name} suffix={`| ${room?.companyName}`}>
      <AssetDisplayPivot asset={asset} />
    </Page>
  )
}

function LoadingPage() {
  return (
    <Page
      title=''
      sx={{
        minHeight: 'calc(100vh)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={128} thickness={1} />
    </Page>
  )
}

function ErrorPage({ error }: { error: any }) {
  const isRequestError = error instanceof AxiosError
  const notFound = isRequestError && error?.response?.status === 404
  if(notFound){
    return <NotFoundErrorPage />
  }
  return <></>
}

function NotFoundErrorPage(){
  const theme = useTheme()
  return (
    <HostedContentRoomModalLayout>
      <Page title='Missing Asset' >
        <Paper
          elevation={2}
          sx={{
            p: 6,
            borderRadius: 2,
            textAlign: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Iconify
            icon='ph:file-magnifying-glass-fill'
            sx={{
              color: theme.palette.primary.main,
              height: 96,
              width: 96,
              position: 'absolute',
              top: -64,
              left: '50%',
              marginLeft: '-48px',
            }}
          />
          <Stack spacing={1}>
            <m.div variants={varBounce().in}>
              <Typography variant='h2' paragraph>
                Hmmm, looks like we couldn't find that file...
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: 'text.secondary' }}>
                If you're sure you have the correct link, you might need to reach out to the sender.
                It may have expired since it was originally sent to you.
              </Typography>
            </m.div>
          </Stack>
        </Paper>
      </Page>
    </HostedContentRoomModalLayout>
  )
}

