import { useContext } from 'react';
import { Box, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { fShortenNumber } from '../../../utils/formatNumber.ts'
import Iconify from '../../Iconify.tsx'
import { useTheme } from '@mui/material/styles'
import { useContentAuditSummary } from '../../../hooks/useContentAudit.ts'
import { ContentAnalysisFilterContext } from '../../../contexts/ContentAnalysisFilterContext.tsx'
import { ContentGrade } from '../../../@types/content-audit.ts'

export default function NeedsAttentionWidget() {
  const theme = useTheme()
  const {  setIncludedGrades } = useContext(ContentAnalysisFilterContext)
  const { data: summary, isLoading, isError } = useContentAuditSummary()

  const handleClick = () => {
    setIncludedGrades([ContentGrade.SILVER, ContentGrade.BRONZE, ContentGrade.UNCERTIFIED], true)
  }

  if(isLoading || isError){
    return <></>
  }

  return (
    <Paper
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        pl: 3,
      }}
      onClick={handleClick}
    >
      <Box>
        <Stack direction="row" alignItems="baseline">
          <Box sx={{ mb: 1, typography: "h2" }}>{summary.countNeedAttention}&nbsp;</Box>
          <Box sx={{ color: "text.deemphasized", typography: "smallHighlight" }}>/ {fShortenNumber(summary.total)}</Box>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography variant="smallHighlight" color="text.secondary">Assets Need Attention&nbsp;</Typography>
          <Tooltip
            title="Assets that are Silver, Bronze or Uncertified are classified as needing attention."
            sx={{display: "flex"}}
          >
            <Box>
              <Iconify icon="eva:question-mark-circle-outline" width={16} height={16} />
            </Box>
          </Tooltip>
        </Stack>
      </Box>

      <Box
        sx={{
          width: 64,
          height: 64,
          lineHeight: 0,
          borderRadius: "50%",
          bgcolor: "background.neutral"
        }}
      >
        <Iconify icon="ph:certificate-duotone" color={theme.palette.error.main} width={64} height={64} />
      </Box>

    </Paper>
  )
}