import {
  ContentAnalysisRequestOptions,
  ContentAuditResponse,
  ContentAuditSummaryResponse,
} from '../@types/content-audit.ts'
import api from '@api'

export async function getContentAudit(options: ContentAnalysisRequestOptions) {
  const response = await api.post<ContentAuditResponse>(
    `/api/v1/content/assets/audit`,
    options,
  )
  return response.data
}

export async function getContentAuditSummary() {
  const response
    = await api.get<ContentAuditSummaryResponse>(`/api/v1/content/assets/audit/summary`)
  return response.data
}