import {
  Asset,
  AssetLabels,
  AssetPalettes,
  AssetPreviews,
  AssetTasksResponseDto,
  UpdateAssetDto
} from "../@types/asset";
import { createContext, ReactNode, useReducer } from "react";
import * as assetClient from "../clients/AssetClient";
import { ActionMap } from "../@types/reducer";
import { pollForCompletion } from "../clients/AssetTaskPoller";

type State = {
  assets: { [k: string]: Asset }
}

type ActionCreators = {
  loadAsset: (assetId: string) => Promise<void>
  updateAsset: (assetId: string, updates: UpdateAssetDto) => Promise<void>
  deleteAsset: (assetId: string) => Promise<void>
  retryTasks: (assetId: string) => Promise<void>
  overrideAssetPreviews: (assetId: string, file: File) => Promise<AssetPreviews>
}

// create the context
type ContextType = State & ActionCreators
const Context = createContext<ContextType>({} as ContextType)

// define the valid set of action types
enum ActionTypes {
  SetAsset = "SET_ASSET",
  SetTasksState = "SET_TASKS_STATE",
  RemoveAsset = "REMOVE_ASSET",
  SetAssetPreviews = "SET_ASSET_PREVIEWS"
}

// define the valid set of Payloads
type Payload = {
  [ActionTypes.SetAsset]: {
    asset: Asset
  },
  [ActionTypes.SetTasksState]: {
    assetId: string,
    palettes: AssetPalettes,
    labels: AssetLabels
  }
  [ActionTypes.RemoveAsset]: {
    assetId: string
  },
  [ActionTypes.SetAssetPreviews]: {
    assetId: string,
    previews: AssetPreviews
  }
}

// define the valid set of Actions
type Action = ActionMap<Payload>[keyof ActionMap<Payload>];

// define the Reducer
const Reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.SetAsset:
      return {
        ...state,
        assets: {
          ...state.assets,
          [action.payload.asset.assetId]: action.payload.asset
        }
      }
    case ActionTypes.SetTasksState: {
      const existingAsset = state.assets[action.payload.assetId]
      if (!existingAsset) {
        return state
      }
      const withCompletedTasks = {
        ...existingAsset,
        palettes: action.payload.palettes,
        labels: action.payload.labels
      }
      return {
        ...state,
        assets: {
          ...state.assets,
          [action.payload.assetId]: withCompletedTasks
        }
      }
    }
    case ActionTypes.SetAssetPreviews:
      const existingAsset = state.assets[action.payload.assetId]
      if (!existingAsset) { return state }
      const updated = { ...existingAsset, previews: action.payload.previews }
      return {
        ...state,
        assets: { ...state.assets, [action.payload.assetId]: updated }
      }
    case ActionTypes.RemoveAsset:
      const { [action.payload.assetId]: toRemove, ...rest } = state.assets
      return { ...state, assets: rest }
    default:
      return state
  }
}

const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, { assets: {} });

  const loadAsset = async (assetId: string) => {
    const asset = await assetClient.getAsset(assetId)
    dispatch({ type: ActionTypes.SetAsset, payload: { asset } })

    // until we have Pusher implemented, just poll for the task completion
    const processResponse = (dto: AssetTasksResponseDto) => dispatch({ type: ActionTypes.SetTasksState, payload: dto })
    pollForCompletion(assetId, asset)
      .then(processResponse)
      .catch(processResponse)
  }

  const updateAsset = async (assetId: string, updates: UpdateAssetDto) => {
    const asset = await assetClient.updateAsset(assetId, updates)
    dispatch({ type: ActionTypes.SetAsset, payload: { asset } })
  }

  const deleteAsset = async (assetId: string) => {
    await assetClient.deleteAsset(assetId)
    dispatch({ type: ActionTypes.RemoveAsset, payload: { assetId } })
  }

  const overrideAssetPreviews = async(assetId: string, file: File) => {
    const previews = await assetClient.overrideAssetPreview(assetId, file)
    dispatch({ type: ActionTypes.SetAssetPreviews, payload: {assetId, previews }})
    return previews
  }

  const retryTasks = async (assetId: string) => {
    const tasks = await assetClient.retryTasks(assetId)
    const payload = {
      assetId: tasks.assetId,
      labels: tasks.labels,
      palettes: tasks.palettes
    }
    dispatch({ type: ActionTypes.SetTasksState, payload })

    const processResponse = (dto: AssetTasksResponseDto) => dispatch({ type: ActionTypes.SetTasksState, payload: dto })
    pollForCompletion(assetId)
      .then(processResponse)
      .catch(processResponse)
  }

  return (
    <Context.Provider
      value={{
        ...state,
        loadAsset,
        updateAsset,
        deleteAsset,
        retryTasks,
        overrideAssetPreviews
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context as AssetContext, Provider as AssetProvider }