import { styled } from '@mui/material/styles'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { useEffect } from 'react';
import Page from '../../../components/Page'
import { useSearchParams } from 'react-router-dom'

const RootStyle = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 425,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

export function OauthSuccess(){
  const [params] = useSearchParams()
  const handleClose = () => window.close()

  useEffect(() => {
    window.opener?.postMessage({source: "oauth-popup", success: true, accountId: params.get("accountId")})
  }, [params])

  return (
    <Page title='Masset Account Success'>
      <RootStyle>
        <Container maxWidth='sm'>
          <ContentStyle>
            <Stack direction='column' spacing={4} alignItems="center">
              <Box
                sx={{ width: 64, height: 64, borderRadius: 1 }}
                component='img'
                src='/assets/icons/logos/masset_slack_logo_512x512.jpg'
              />

              <Box>
              <Typography variant='h1' textAlign='center'>
                Your account was
              </Typography>
              <Typography variant='h1' textAlign='center' color='text.mint'>successfully connected!</Typography>
              </Box>

              <Button
                fullWidth
                variant="contained"
                color='primary'
                onClick={handleClose}
              >Back to Masset</Button>
            </Stack>

          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  )
}