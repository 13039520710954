import { Button, Drawer, Grid, Stack, Typography } from '@mui/material'
import { useContext } from 'react'
import { AssetUpload, AssetUploadContext } from '../contexts/AssetUploadContext'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormProvider } from '../components/hook-form'
import Page from '../components/Page'
import Scrollbar from '../components/Scrollbar'
import UpdateAssetTags from './asset/UpdateAssetTags'
import AssetUploadPreview from '../components/AssetUploadPreview'
import BulkUploadAssetMetadata from '../components/upload/BulkUploadAssetMetadata'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useSnackbar } from 'notistack'
import ActionBar from '../components/action/ActionBar'
import { ScrollPosition, trackWindowScroll } from 'react-lazy-load-image-component'
import { AssetUploadSharing } from '../components/asset-upload/AssetUploadSharing'
import useMassetSettings from '../hooks/useMassetSettings'
import { DEFAULT_SHARING_ACCESS_TYPE, DEFAULT_SHARING_SHARE_LEVEL } from '../utils/appConstants'
import { BootstrapSettings } from '../@types/auth'

const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

export type AssetUploadFinalizeMultipleFormProps = {
  shouldBulkUpdateName: boolean,
  shouldBulkUpdateDescription: boolean,
  name: string,
  description: string,
  selectedCategoryValueUUIDs: string[],
  isInternal: boolean,
  isThirdParty: boolean
}

function getDefaults(settings: BootstrapSettings) {
  return {
    shouldBulkUpdateName: false,
    shouldBulkUpdateDescription: false,
    name: "",
    description: "",
    selectedCategoryValueUUIDs: [],
    generalAccessType: settings.defaultAssetGeneralAccessType || DEFAULT_SHARING_ACCESS_TYPE,
    generalAccessShareLevel: settings.defaultAssetShareLevel || DEFAULT_SHARING_SHARE_LEVEL,
    isInternal: false,
    isThirdParty: false
  }
}

const FormShape = Yup.object().shape({
  shouldBulkUpdateName: Yup.boolean().required(),
  shouldBulkUpdateDescription: Yup.boolean().required(),
  name: Yup.string()
    .when("shouldBulkUpdateName", {
      is: true,
      then: Yup.string().required()
    }),
  description: Yup.string()
    .when("shouldBulkUpdateDescription", {
      is: true,
      then: Yup.string().required()
    })
})

const formValidationResolver = yupResolver(FormShape)


export default function AssetUploadFinalizeMultiple() {
  const { enqueueSnackbar } = useSnackbar()
  const settings = useMassetSettings()
  const { uploads, bulkFinalizeAssets } = useContext(AssetUploadContext)
  const navigate = useNavigate();
  const methods = useForm<AssetUploadFinalizeMultipleFormProps>({
    resolver: formValidationResolver,
    defaultValues: getDefaults(settings),
    mode: "onChange"
  })
  const { handleSubmit, formState } = methods
  const { isValid } = formState

  const onSubmit = async (data: AssetUploadFinalizeMultipleFormProps) => {
    const assetIds = uploads.map(it => it.asset!.assetId)
    await bulkFinalizeAssets({ ...data, assetIds })
    enqueueSnackbar((<>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="smallHighlight">HOORAY!</Typography>
        <Typography component="div" variant="small" noWrap>Your assets have been uploaded!</Typography>
      </Stack>
    </>))
    navigate("/assets/upload")
  }

  const onCancel = () => navigate("/assets/upload")

  const uploadsReady = uploads.reduce((memo, it) => {
    return memo && !!it.asset?.assetId
  }, true)

  return (
    <FormProvider methods={methods}>
      <Page title="Asset Upload" mb={12}>
        <RootStyle>
          <Scrollbar sx={{ flexGrow: 1 }}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6"> Preview </Typography>
                <PerfPreviewImages uploads={uploads} />
              </Grid>
              <Grid item xs={12} md={7}>
                <Stack spacing={6}>
                  <BulkUploadAssetMetadata/>
                  <AssetUploadSharing
                    subtext="Who has access to see these assets by default?"
                  />
                  <UpdateAssetTags
                    subtext="Select all that apply to all files. More tags can be added to individual files after the batch upload."/>

                </Stack>
              </Grid>
            </Grid>
          </Scrollbar>
          <Drawer
            anchor="bottom"
            variant="permanent"
            sx={{}}
          >
            <ActionBar
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                disabled={!uploadsReady || !isValid}
              >
                Save
              </Button>
            </ActionBar>
          </Drawer>

        </RootStyle>
      </Page>
    </FormProvider>
  )
}

function PreviewImages({uploads, scrollPosition}: {uploads: AssetUpload[], scrollPosition: ScrollPosition}){
  return (
    <Grid container spacing={2}>
      {uploads.map(upload => {
        return (
          <Grid item xs={4} key={upload.key}>
            <AssetUploadPreview upload={upload} compact={true} scrollPosition={scrollPosition}/>
          </Grid>
        )
      })}
    </Grid>
  )
}

const PerfPreviewImages = trackWindowScroll(PreviewImages)