import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import { UserType } from "../@types/user";
import { useSnackbar } from "notistack";
import { Stack, Typography } from "@mui/material";
import Iconify from "../components/Iconify";

// ----------------------------------------------------------------------

type RoleGuardProps = {
  permittedRoles: UserType[]
  redirectSnackbar?: ReactNode | boolean
  children: ReactNode
};

export default function PageRoleGuard({ permittedRoles, redirectSnackbar = true, children }: RoleGuardProps) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  if (!user) {
    return <Login/>;
  }

  const isPermitted = permittedRoles.includes(user.userType)
  if (!isPermitted) {

    // provide a redirect message if provided
    if (redirectSnackbar === true) {
      enqueueSnackbar(<DefaultRedirectSnackbar/>, { variant: "warning" })
    } else if (redirectSnackbar) {
      enqueueSnackbar(redirectSnackbar, { variant: "warning" })
    }

    return <Navigate to="/"/>;
  }

  return <>{children}</>;
}

function DefaultRedirectSnackbar() {
  const { closeSnackbar } = useSnackbar();

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="smallHighlight">🤔 Hmmm. Looks like you don't have access to that page.</Typography>
      <Stack sx={{cursor: 'pointer'}} onClick={() => closeSnackbar()}><Iconify icon="eva:close-fill"/></Stack>
    </Stack>
  )
}
