import { OauthProvider } from './oauth.ts'

export enum AssetContentStateErrorCode {
  UNAUTHENTICATED = 4001,
  FORBIDDEN = 4003,
  NOT_FOUND = 4004,
  GONE = 4010,

  // 41xx range is for common errors that don't make to http errors
  MISSING_ID = 4101,
  NO_VALID_ACCOUNT = 4102,
  INVALID_EXTERNAL_URL = 4103,

  // 45xx-49xx range is for integration-specific related errors
  GOOGLE_DOC_INVALID_DOC_TYPE = 4503,

  // 5000+ range is for unknowns or server-side misconfigurations
  UNKNOWN_ERROR = 5000,
  INTERNAL_SENSITIVE_ERROR = 5001,
  CANT_DOWNLOAD_NON_REMOTE = 5002,
  MISSING_EXTERNAL_STORAGE_REF = 5003,
  INVALID_URL_PROTECTED_IP_RANGE = 5004,
  REMOTE_DOWNLOAD_MISSING_BODY = 5005,
  TRANSCRIPTION_MISSING_TRANSFER_PATH = 5101
}

export type AssetContentStateErrorData =
  ForbiddenErrorData |
  NotFoundErrorData |
  NoValidAccountErrorData |
  UnauthenticatedErrorData

export type ForbiddenErrorData = {
  authenticated: boolean | null,
  oauthAccountId: string | null
}

export type NotFoundErrorData = {
  oauthAccountId: string | null
}

export type NoValidAccountErrorData = {
  accountType: OauthProvider
}

export type UnauthenticatedErrorData = {
  authenticated: boolean | null,
  oauthAccountId: string | null
}

export type GoneErrorData = {}
export type MissingIdErrorData = {}