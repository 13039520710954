import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { SearchDateFilter, SearchSortBy } from "../../@types/search";
import { DEFAULT_DATE_FILTER, DEFAULT_SORT_BY, SearchFilters } from "../SearchContext";
import baseX from "base-x";
import { Buffer } from "buffer";

const QUERY_KEY_PAGE = "p";
const QUERY_KEY_TERM = "q";
const QUERY_KEY_CATEGORY_VALUES = "cv";
const QUERY_KEY_SORT = "sort";
const QUERY_KEY_DATE_FILTER = "dt";
const QUERY_KEY_FAVORITE = "fav"
const BASE62 = baseX("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ");


export function useSearchParamsAsFilters() : Partial<SearchFilters> {
  const [searchParams] = useSearchParams();

  // only load it once on page load
  return useMemo(() => {
    const searchFilters = {} as Partial<SearchFilters>;

    if (searchParams.has(QUERY_KEY_TERM)) {
      searchFilters.searchTerm = searchParams.get(QUERY_KEY_TERM)!!;
    }

    if (searchParams.has(QUERY_KEY_PAGE)) {
      const p = searchParams.get(QUERY_KEY_PAGE)!!;
      const page = parseInt(p, 10);
      searchFilters.currentPage = isNaN(page) ? 0 : page - 1;
    }

    if (searchParams.has(QUERY_KEY_SORT)) {
      searchFilters.sortBy = searchParams.get(QUERY_KEY_SORT)!! as SearchSortBy;
    }

    if (searchParams.has(QUERY_KEY_DATE_FILTER)) {
      searchFilters.dateFilter = searchParams.get(QUERY_KEY_DATE_FILTER)!! as SearchDateFilter;
    }

    if (searchParams.has(QUERY_KEY_CATEGORY_VALUES)) {
      const encodedUuids = searchParams.getAll(QUERY_KEY_CATEGORY_VALUES);
      searchFilters.categoryValueIds = encodedUuids.map(base62ToUuid);
    }

    if (searchParams.has(QUERY_KEY_FAVORITE)) {
      searchFilters.includeOnlyFavorites = searchParams.get(QUERY_KEY_FAVORITE) == "true";
    }

    return searchFilters;
  }, [searchParams]);
}

export function setFiltersToQueryParams(filters: SearchFilters) {
  // query term
  updateQueryString(QUERY_KEY_TERM, filters.searchTerm);

  // page
  const page = filters.currentPage + 1;
  updateQueryString(QUERY_KEY_PAGE, page === 1 ? null : page);

  // sort
  updateQueryString(QUERY_KEY_SORT, filters.sortBy === DEFAULT_SORT_BY ? null : filters.sortBy);

  // date filter
  updateQueryString(
    QUERY_KEY_DATE_FILTER,
    filters.dateFilter === DEFAULT_DATE_FILTER ? null : filters.dateFilter
  );

  // category values
  updateQueryString(
    QUERY_KEY_CATEGORY_VALUES,
    filters.categoryValueIds.length ? filters.categoryValueIds.map(uuidToBase62) : null
  );

  // favorites
  updateQueryString(QUERY_KEY_FAVORITE, filters.includeOnlyFavorites)
}

function updateQueryString(queryKey: string, queryValue: any) {
  const currentSearchParams = new URLSearchParams(window.location.search);
  const oldValue = currentSearchParams.get(queryKey) ?? "";
  if (queryValue === oldValue) return;

  if (queryValue) {
    if (Array.isArray(queryValue)) {
      currentSearchParams.delete(queryKey);
      queryValue.forEach(it => currentSearchParams.append(queryKey, it));
    } else {
      currentSearchParams.set(queryKey, queryValue);
    }
  } else {
    currentSearchParams.delete(queryKey);
  }

  const newUrl = [window.location.pathname, currentSearchParams.toString()]
    .filter(Boolean)
    .join("?");

  window.history.replaceState(null, "", newUrl);
}

function uuidToBase62(input: string) {
  const withoutDashes = new Buffer(input.replace(/-/g, ""), "hex");
  return ensureLength(BASE62.encode(withoutDashes), 22);
}

function base62ToUuid(input: string) {
  const res = ensureLength(
    new Buffer(BASE62.decode(input)).toString("hex"),
    32
  );
  // insert dashes on return
  return `${res.slice(0, 8)}-${res.slice(8, 12)}-${res.slice(12, 16)}-${res.slice(16, 20)}-${res.slice(20)}`;
}

function ensureLength(input: string, targetLength: number) {
  input = input.toString();
  return `${"0".repeat(32)}${input}`.slice(-targetLength);
}
