import api from '@api'
import { MassetBotMessage, MassetBotSendMessageResponse } from '../@types/massetbot'

export const sendBotMessage = async(
  assetId: string,
  newMessage: MassetBotMessage,
  messageHistory: MassetBotMessage[]
) => {
  const data = { message: newMessage, history: messageHistory }
  const response = await api.post<MassetBotSendMessageResponse>(`/api/v1/massetbot/asset/${assetId}`, data)
  return response.data
}
