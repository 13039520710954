import { useState } from 'react';
import * as React from 'react';
import { Box, ClickAwayListener, Popover } from '@mui/material'
import { AnalyticsReadOnlyTag, CompactTagBox } from './AnalyticsReadOnlyTag'
import { CategoryValueWithCategory } from '../../@types/category'
import { useCategoryValuesWithCategories } from '../../hooks/useCategories'

export function CompactCategoryValues({ ids, maxItems = 3 }: { ids: string[], maxItems?: number }) {
  const categoryValuesWithCategories = useCategoryValuesWithCategories()
  const allValues = ids.map(it => categoryValuesWithCategories[it]).filter(it => !!it)
  const renderValues = allValues.slice(0, maxItems)
  const overflowValues = allValues.slice(maxItems, allValues.length)
  const hasOverflow = Boolean(overflowValues.length)
  return (
    <>
      {
        renderValues.map(it => {
          if (!it.categoryValue || !it.category) return null
          return (
            <Box
              key={it.categoryValue.categoryValueId}
              mr={1}
              sx={{ display: 'inline-block' }}
            >
              <AnalyticsReadOnlyTag
                categoryValue={it.categoryValue}
                category={it.category}
              />
            </Box>
          )
        })
      }
      {
        hasOverflow && <Overflow items={overflowValues} />
      }
    </>
  )
}

function Overflow({ items }: { items: CategoryValueWithCategory[] }) {
  // state
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  // handlers
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  // calculated props
  const open = Boolean(anchorEl)
  const id = open ? 'tag-picker' : undefined


  return (
    <>
      <Box sx={{ display: 'inline-block' }} onClick={handleClick}>
        <CompactTagBox>...</CompactTagBox>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 'top', horizontal: -4 }}
        sx={{ borderRadius: 0 }}
        disableScrollLock={true}
        PaperProps={{ sx: {p: 1, pr: 2, minWidth: 175, maxHeight: 300, overflowY: "scroll", boxShadow: 3 } }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            sx={{

            }}
          >
            {
              items.map(it => {
                return (
                  <Box
                    key={it.categoryValue.categoryValueId}
                    mb={1.5}
                  >
                    <AnalyticsReadOnlyTag
                      categoryValue={it.categoryValue}
                      category={it.category}
                    />
                  </Box>
                )
              })
            }
          </Box>
        </ClickAwayListener>
      </Popover>
    </>
  )
}