import { Account } from '../../../@types/account'
import { Box } from '@mui/material'

type IntegrationActionsProps = {
  account: Account
}
export function IntegrationActions({account} : IntegrationActionsProps ){
  return (
    <Box
      sx={{
        minWidth: 128
      }}
    ></Box>
  )
}