import { Asset, AssetPermission } from '../../../@types/asset'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useAssetOCRContents, useEditOCRContents, useRetryOCRContents } from '../../../hooks/useAssetContent'
import { fDateTime } from '../../../utils/formatTime'
import { useTheme } from '@mui/material/styles'
import { useRef, useState } from 'react';
import Iconify from '../../Iconify.tsx'
import { LoadingButton } from '@mui/lab'
import { VisibleForPermission } from '../../../guards/VisibleForPermission.tsx'

interface ImageSummaryContentsProps {
  asset: Asset
}

export function OCRPopover({ asset }: ImageSummaryContentsProps) {
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>()
  const [mode, setMode] = useState<'read' | 'edit'>('read')
  const { data: ocr } = useAssetOCRContents(asset.assetId)
  const { mutateAsync: editOCR, isLoading: saving } = useEditOCRContents()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleEdit = () => setMode('edit')
  const handleEditCancel = () => setMode('read')
  const handleEditSave = () => {
    const data = {
      assetId: asset.assetId,
      assetContentId: ocr!!.assetContentId,
      contents: inputRef?.current?.value || '',
    }
    editOCR(data).then(() => setMode('read'))
  }
  const emptyContents = ocr && ocr.contents.length == 0
  return (
    <>
      <Button
        variant='text'
        size='small'
        onClick={handleOpen}
      >
        View Results
      </Button>

      <Dialog
        open={open}
        maxWidth='md'
        fullWidth={true}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant='h2' paragraph>OCR Extracted Text (Auto-Generated)</Typography>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          {/* read only mode components */}
          {mode == 'read' && (
            <Stack spacing={3}>
              {ocr && (<Typography variant='small' component='div'>
                Generated on {fDateTime(ocr.createdAt)}
              </Typography>)}
              {ocr && (
                <Box
                  sx={{
                    pl: 2,
                    borderLeft: `4px solid ${theme.palette.primary.darker}`,
                  }}
                >
                  <Typography variant='small' sx={{ whiteSpace: 'pre-line' }}>
                    {emptyContents ? 'No text was found in this image' : ocr?.contents}
                  </Typography>
                </Box>
              )}

              <VisibleForPermission permission={AssetPermission.EDIT_METADATA} level={asset.accessLevel}>
                <Stack
                  direction='row'
                  spacing={1}
                >
                  <Button
                    variant='outlined'
                    size='small'
                    startIcon={<Iconify icon='ph:pencil' />}
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>

                </Stack>
              </VisibleForPermission>
            </Stack>
          )}

          {mode == 'edit' && (
            <Stack spacing={3}>
              <TextField
                autoFocus={true}
                inputRef={inputRef}
                multiline={true}
                defaultValue={ocr?.contents}
              />

              <Stack
                direction='row'
                spacing={1}
              >
                <LoadingButton
                  size='small'
                  onClick={handleEditSave}
                  loading={saving}
                  loadingPosition='start'
                  startIcon={<Iconify icon='ph:floppy-disk' />}
                  variant='outlined'
                >
                  Save
                </LoadingButton>
                <Button
                  variant='outlined'
                  size='small'
                  color='inherit'
                  onClick={handleEditCancel}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          )}

        </DialogContent>

      </Dialog>

    </>

  )
}