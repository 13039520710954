import { Box, Container } from '@mui/material'
import { Outlet } from 'react-router-dom'
import AssetHeader from '../asset/AssetHeader'


export default function AnalyticsLayout() {
  return (
    <Container sx={{ p: 4 }}>
      <AssetHeader />
      <Box sx={{ mt: 4 }}>
        <Outlet />
      </Box>
    </Container>
  )
}