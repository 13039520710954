import { Box, Typography } from "@mui/material";
import SettingMode from "../../components/settings/drawer/SettingMode";
import ThemeSelector from "../../components/profile/appearance/ThemeSelector";
import Page from "../../components/Page";

export default function ProfileAppearance() {

  return (
    <Page title="Appearance">
      <Box mb={12}>
        <Typography variant="h2" mb={2}>
          Theme preferences
        </Typography>

        <Typography component="div" variant="standard" mb={4}>
          Choose how Masset looks to you. Choose a specific theme, or sync with your system and automatically switch between day and night themes.
        </Typography>

        <ThemeSelector />

      </Box>
    </Page>
  )
}