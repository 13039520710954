import { Stack, SxProps, ToggleButton, Typography } from "@mui/material";
import Iconify from "../Iconify";
import { Theme } from "@mui/material/styles";

type ToggleTagProps = {
  selected: boolean,
  categoryValueId: string,
  displayText: string,
  onChange: (categoryValueId: string, selected: boolean) => void
  sx?: SxProps<Theme>
}
export default function ToggleTag(
  {
    selected,
    categoryValueId,
    displayText,
    onChange,
    sx
  }: ToggleTagProps
) {
  return (
    <ToggleButton
      value={categoryValueId}
      selected={selected}
      color="tag"
      size="small"
      sx={sx}
      onChange={() => {
        onChange(categoryValueId, !selected)
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Iconify icon={selected ? "eva:checkmark-fill" : "eva:radio-button-off-fill"}/>
        <Typography variant="small">{displayText}</Typography>
      </Stack>
    </ToggleButton>
  )
}