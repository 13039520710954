import { Asset } from '../../../../../@types/asset'
import { TimeRange } from '../../../../../@types/analytics/analytics-common'
import RankWidget from '../common/RankWidget'
import { useViewRank } from '../../../../../hooks/analytics/useAssetViewAnalytics'

export default function ViewRank({ asset, range }: { asset: Asset, range: TimeRange }) {
  const { data, isLoading } = useViewRank(asset.assetId, { range })

  if (isLoading) {
    return <></>;
  }

  return (
    <RankWidget rank={data?.rank || 0} total={data?.maxRank || 0} />
  );
}