// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import UploadIllustration from '../../assets/illustration_upload.tsx';

// ----------------------------------------------------------------------

export default function CompactBlockContent() {
  return (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="center"
      direction="column"
      sx={{ width: 1, textAlign: 'center' }}
    >
      <UploadIllustration sx={{ width: 100 }} />

      <Box sx={{ p: 1 }}>
        <Typography variant="small" sx={{ color: 'text.secondary' }}>
          Drop files here or click&nbsp;
          <Typography
            variant="small"
            component="span"
            sx={{ color: 'primary.main', textDecoration: 'underline' }}
          >
            browse
          </Typography>
        </Typography>
      </Box>
    </Stack>
  );
}
