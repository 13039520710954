import * as React from 'react';
import { Box, Container } from '@mui/material'
import AssetHeader from '../asset/AssetHeader.tsx'
import { PreviousConversationsSidebar } from '../../components/conversations/PreviousConversationsSidebar.tsx'

type Props = {
  children: React.ReactNode
}

export function ConversationsLayout({ children }: Props) {
  return (
    <Container sx={{ p: 4 }}>
      <AssetHeader />

      <Box sx={{
        display: 'flex',
        height: 1
      }}>
        <PreviousConversationsSidebar />
        {children}
      </Box>

    </Container>
  )
}