import { Asset } from '../../../../../@types/asset'
import moment from 'moment-timezone'
import { dateFormatForTimeRange, TimeRange } from '../../../../../@types/analytics/analytics-common'
import TrendGraph from '../common/TrendGraph'
import { useBucketedDownloads } from '../../../../../hooks/analytics/useAssetDownloadAnalytics'

export default function BucketedDownloads({ asset, range }: {
  asset: Asset,
  range: TimeRange
}) {
  const { data } = useBucketedDownloads(asset.assetId, { range })
  const seriesData = data?.buckets?.map(it => ({ date: moment.utc(it.bucket), y: it.numDownloads })) || []
  const series = { name: 'Downloads', data: seriesData }
  const dateFormat = dateFormatForTimeRange(range)
  return (
    <TrendGraph title='Downloads Over Time' series={series} dateFormat={dateFormat} />
  )
}