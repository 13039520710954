import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material'
// type
import { CustomFile, UploadProps } from './type'
//
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import Iconify from '../Iconify'

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 3),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  sx,
  customBlockContent,
  previewBackgroundType,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && {
            padding: '12% 0',
          }),
          // this is my attempt to make sure things look good when there is no block content
          minHeight: 250,
          display: "flex"
        }}
      >
        <input {...getInputProps()} />

        {!file && customBlockContent && customBlockContent}
        {!file && !customBlockContent && <BlockContent/>}

        {file && <FilePreview file={file} />}

      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}

type FilePreviewProps = {
  file: CustomFile | string | null;
  previewBackgroundType?: 'cover' | 'contain'
}
function FilePreview({file, previewBackgroundType }: FilePreviewProps){
  const hasImagePreview = typeof file === 'string' || file?.preview

  if(file && hasImagePreview){
    return (
      <Image
        alt="file preview"
        src={typeof file === 'string' ? file : file.preview}
        backgroundType={previewBackgroundType || "cover"}
        sx={{
          top: 8,
          left: 8,
          borderRadius: 1,
          position: 'absolute',
          width: 'calc(100% - 16px)',
          height: 'calc(100% - 16px)',
        }}
      />
    )
  }

  if(typeof file === 'string'){
    return <></>
  }

  return (
    <Stack direction="row" spacing={2} px={3} alignItems="center" sx={{width: "100%"}} justifyContent="center">
      <Iconify icon="eva:file-outline" sx={{minHeight: 64, minWidth: 64}}/>
      <Typography >{file?.name}</Typography>
    </Stack>
  )
}
