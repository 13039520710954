import { AssetExternalShare } from '../../../../@types/asset-external-share.ts'
import { Stack } from '@mui/material'
import { SearchResultsExternalShares } from './SearchResultsExternalShares.tsx'
import { SearchResultsCRM } from './SearchResultsCRM.tsx'

type Props = {
  shares: AssetExternalShare[],
  searchTerm: string
}
export function ExternalSharesSearchResults({shares, searchTerm}: Props){
  if (!Boolean(searchTerm)) {
    return <></>
  }
  return (
    <Stack>
      <SearchResultsExternalShares shares={shares} searchTerm={searchTerm} />
      <SearchResultsCRM shares={shares} searchTerm={searchTerm} />
    </Stack>
  )
}